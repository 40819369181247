import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { lotBidSubtitle, lotUrl, nameTranslation } from '../../lib/formatter';
import CardWithDescription from '../common/CardWithDescription';
import vectorRight from '../../assets/icons/vector-right.svg';

const LotsListContainerStyle = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const LotsListTitleStyle = styled.h3`
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-transform: capitalize;
  color: ${props => props.theme.darkText};
`;

const LotsListIconStyle = styled.img`
  margin-right: 0.25rem;
`;

function AuctionLotsList({ auction, lots }) {
  const { t } = useTranslation();

  return (
    <LotsListContainerStyle>
      <LotsListTitleStyle>
        <LotsListIconStyle src={vectorRight} /> {t('top_lots')}
      </LotsListTitleStyle>
      {lots.map(lot => (
        <CardWithDescription
          key={lot.id}
          image={lot.payload?.attachments?.[0]?.urls?.[1]?.medium}
          subtitle={lotBidSubtitle(lot)}
          text={nameTranslation(lot, 'en')}
          url={lotUrl(auction, lot)}
        />
      ))}
    </LotsListContainerStyle>
  );
}

AuctionLotsList.propTypes = {
  auction: PropTypes.object,
  lots: PropTypes.array,
};

export default AuctionLotsList;
