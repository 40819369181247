import {
  ASPECT_RATIO_BIG,
  ASPECT_RATIO_SMALL,
  ASPECT_RATIO_VERTICAL,
  ASPECT_RATIO_HORIZONTAL,
  ASPECT_RATIO_CAROUSEL,
  DEFAULT_CANVAS,
  VERTICAL_DIMENSIONS,
  HORIZONTAL_DIMENSIONS,
  LAYOUT_TYPE_CAROUSEL,
  LAYOUT_TYPE_LIMITED,
  LAYOUT_TYPE_UNLIMITED,
  CAROUSEL_DIMENSIONS,
  LOGO_DIMENSIONS,
} from './constants';
import vavatoLogo from '../assets/images/vavato-logo.svg';

export function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max);
}

export function center({ width, height }) {
  if (!width || !height) return {};

  return {
    x: width / 2,
    y: height / 2,
  };
}

function isLandscape(dimensions) {
  return dimensions.width > dimensions.height;
}

export function scale(firstDimensions, secondDimensions) {
  if (!firstDimensions || !secondDimensions) return 1.0;

  const relativeWidth = firstDimensions.width / secondDimensions.width;
  const relativeHeight = firstDimensions.height / secondDimensions.height;

  if (isLandscape(secondDimensions)) {
    return Math.max(relativeWidth, relativeHeight);
  }

  return Math.min(relativeWidth, relativeHeight);
}

export function transformZoomScale(dimensions, scale, zoom) {
  if (!dimensions || !scale || !zoom) return dimensions;

  return {
    width: dimensions.width * scale * zoom,
    height: dimensions.height * scale * zoom,
  };
}

export function transformFocalPoint(dimensions, focalPoint) {
  if (!dimensions || !focalPoint) return dimensions;

  return {
    x: dimensions.width * focalPoint.x,
    y: dimensions.height * focalPoint.y,
  };
}

export function transformCoordinates({
  inputDimensions,
  outputDimensions,
  focalPoint,
  zoom,
}) {
  const outputCenter = center(outputDimensions);
  const outputScale = scale(outputDimensions, inputDimensions);
  const zoomedDimensions = transformZoomScale(
    inputDimensions,
    outputScale,
    zoom,
  );
  const idealCenter = transformFocalPoint(zoomedDimensions, focalPoint);

  const idealTop = 0 - (idealCenter.y - outputCenter.y);
  const idealLeft = 0 - (idealCenter.x - outputCenter.x);

  const top = Math.floor(idealTop);
  const left = Math.floor(idealLeft);
  const width = Math.floor(zoomedDimensions.width);
  const height = Math.floor(zoomedDimensions.height);

  return { top, left, width, height };
}

export function layoutLimitedAspectRatio(layout, firstItem) {
  if (layout === 1) {
    return ASPECT_RATIO_BIG;
  } else if (layout === 2 || (layout === 3 && firstItem)) {
    return ASPECT_RATIO_VERTICAL;
  } else {
    return ASPECT_RATIO_SMALL;
  }
}

export function layoutUnlimitedAspectRatio(layout) {
  if (layout === 1) {
    return ASPECT_RATIO_HORIZONTAL;
  } else if (layout === 2) {
    return ASPECT_RATIO_BIG;
  } else {
    return ASPECT_RATIO_SMALL;
  }
}

export function layoutRatio(layoutType, layout = null, firstItem = false) {
  if (layoutType === LAYOUT_TYPE_UNLIMITED) {
    return layoutUnlimitedAspectRatio(layout);
  } else if (layoutType === LAYOUT_TYPE_LIMITED) {
    return layoutLimitedAspectRatio(layout, firstItem);
  } else if (layoutType === LAYOUT_TYPE_CAROUSEL) {
    return ASPECT_RATIO_CAROUSEL;
  }
}

export function canvasDimensions(aspectRatio) {
  if (aspectRatio === ASPECT_RATIO_VERTICAL) {
    return {
      width: VERTICAL_DIMENSIONS.width,
      height: VERTICAL_DIMENSIONS.height,
    };
  } else if (aspectRatio === ASPECT_RATIO_HORIZONTAL) {
    return {
      width: HORIZONTAL_DIMENSIONS.width,
      height: HORIZONTAL_DIMENSIONS.height,
    };
  } else if (aspectRatio === ASPECT_RATIO_CAROUSEL) {
    return {
      width: CAROUSEL_DIMENSIONS.width,
      height: CAROUSEL_DIMENSIONS.height,
    };
  } else {
    return {
      width: DEFAULT_CANVAS.width,
      height: DEFAULT_CANVAS.height,
    };
  }
}

export function createImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });
}

export function drawCanvas(img, canvas, focalPoint, zoom) {
  const ctx = canvas?.ctx;
  const canvasWidth = canvas?.width;
  const canvasHeight = canvas?.height;
  if (!img || !ctx) return;

  const inputDimensions = {
    width: img.naturalWidth,
    height: img.naturalHeight,
  };
  const outputDimensions = {
    width: canvasWidth,
    height: canvasHeight,
  };

  const { top, left, width, height } = transformCoordinates({
    inputDimensions,
    outputDimensions,
    focalPoint,
    zoom,
  });

  ctx.fillStyle = 'rgba(255, 255, 255)';
  ctx.fillRect(
    0,
    0,
    width * DEFAULT_CANVAS.zoom_area,
    height * DEFAULT_CANVAS.zoom_area,
  );
  ctx.drawImage(img, left, top, width, height);
}

export async function drawCanvasWithLogo(
  img,
  canvas,
  focalPoint,
  zoom,
  borderTop,
) {
  const ctx = canvas?.ctx;
  const canvasWidth = canvas?.width;
  const canvasHeight = canvas?.height;
  if (!img || !ctx) return;

  const verticalSpacing = 20;
  const logoImg = await createImage(vavatoLogo);

  const footerOffset = LOGO_DIMENSIONS.height + 2 * verticalSpacing;

  const inputDimensions = {
    width: img.naturalWidth,
    height: img.naturalHeight,
  };
  const outputDimensions = {
    width: canvasWidth,
    height: canvasHeight - footerOffset,
  };

  const { top, left, width, height } = transformCoordinates({
    inputDimensions,
    outputDimensions,
    focalPoint,
    zoom,
  });

  ctx.fillStyle = 'rgba(255, 255, 255)';
  ctx.fillRect(
    0,
    0,
    width * DEFAULT_CANVAS.zoom_area,
    height * DEFAULT_CANVAS.zoom_area,
  );
  ctx.drawImage(img, left, top, width, height);

  ctx.fillStyle = 'rgba(255, 255, 255)';
  ctx.fillRect(0, canvasHeight - footerOffset, canvasWidth, footerOffset);

  if (borderTop) {
    ctx.fillStyle = 'rgba(255, 255, 255)';
    ctx.fillRect(0, 0, canvasWidth, footerOffset);
  }

  const logoImgOffsetX = (canvasWidth - LOGO_DIMENSIONS.width) / 2;
  const logoImgOffsetY =
    canvasHeight - LOGO_DIMENSIONS.height - verticalSpacing;
  ctx.drawImage(
    logoImg,
    logoImgOffsetX,
    logoImgOffsetY,
    LOGO_DIMENSIONS.width,
    LOGO_DIMENSIONS.height,
  );
}
