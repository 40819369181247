import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';

import { Button } from 'vavato-ui';
import { useTranslation } from 'react-i18next';
import SelectedCounter from '../common/SelectedCounter';

const FooterStyle = styled.div`
  display: flex;
  margin: 1rem 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

function LayoutFooter({
  loading,
  saveTitle,
  onSave,
  nextTitle,
  nextLink,
  selectedLots,
  defaultSaveAndGo,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  async function saveAndGo() {
    const success = await onSave();
    if (success) {
      history.push(nextLink);
    }
  }

  return (
    <FooterStyle>
      <Link to="/">
        <Button className="outline">{t('footer.cancel')}</Button>
      </Link>
      <SelectedCounter selectedLots={selectedLots} />
      <div>
        <Button
          primary
          disabled={loading}
          onClick={() => {
            onSave(false);
          }}
        >
          {saveTitle}
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            defaultSaveAndGo() ? saveAndGo() : onSave(true);
          }}
        >
          {nextTitle}
        </Button>
      </div>
    </FooterStyle>
  );
}

LayoutFooter.propTypes = {
  loading: PropTypes.bool,
  saveTitle: PropTypes.string,
  onSave: PropTypes.func,
  nextTitle: PropTypes.string,
  nextLink: PropTypes.string,
  selectedLots: PropTypes.number,
  defaultSaveAndGo: PropTypes.func,
};

export default LayoutFooter;
