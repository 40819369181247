import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Menu = styled.div`
  display: ${props => (props.open ? 'flex' : 'none')};
  right: 65px;
  background: white;
  position: fixed;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  padding: 15px 10px;
  min-width: 220px;

  & > ul {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
  }

  & > ul li {
    flex: 1;
    display: flex;
    cursor: pointer;
  }

  & > ul li a,
  & > ul li a:active,
  & > ul li a:visited,
  & > ul li a:hover {
    color: #000;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
  }

  & li:hover {
    background: ${props => props.theme.background};
  }
`;

function EllipsisMenu({ open, children, closeMenu, exceptionRef }) {
  const wrapperRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickListener);

    return () => {
      document.removeEventListener('mousedown', handleClickListener);
    };
  });

  const handleClickListener = event => {
    let clickedInside;

    if (exceptionRef) {
      clickedInside =
        (wrapperRef.current && wrapperRef.current.contains(event.target)) ||
        exceptionRef.current === event.target ||
        (exceptionRef.current && exceptionRef.current.contains(event.target));
    } else {
      clickedInside = wrapperRef && wrapperRef.current.contains(event.target);
    }

    if (!clickedInside) {
      closeMenu();
    }
  };

  function renderChildren() {
    if (!children) {
      return [];
    }
    if (!children.length) {
      return <li>{children}</li>;
    }
    return children.map(child => (
      <li key={children.indexOf(child)}>{child}</li>
    ));
  }

  return (
    <Menu open={open} ref={wrapperRef}>
      <ul className="unstyled"> {renderChildren()} </ul>
    </Menu>
  );
}

EllipsisMenu.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.array,
  closeMenu: PropTypes.func,
  exceptionRef: PropTypes.object,
};

export default EllipsisMenu;
