import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DialogContainerStyle = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
`;

const DialogModalStyle = styled.div`
  padding: 0 2rem;
  overflow: scroll;
  border-radius: 3px;
  background-color: white;
`;

function ModalDialog({ children, onClose }) {
  return (
    <DialogContainerStyle onClick={onClose}>
      <DialogModalStyle
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {children}
      </DialogModalStyle>
    </DialogContainerStyle>
  );
}

ModalDialog.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
};

export default ModalDialog;
