import styled from 'styled-components';
import React, { useState, useEffect, useContext, useMemo } from 'react';

import { ToasterContext } from 'vavato-ui';
import LayoutHeader from '../../components/layout/LayoutContentHeader';
import CampaignListHeader from '../../components/campaign/CampaignListHeader';
import CampaignsListWeek from '../../components/campaign/CampaignListWeek';
import { useFetchCampaignsData } from '../../hooks/services/CampaignsAPI';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { DEFAULT_SEGMENT, PARAM_DATE_FORMAT } from '../../lib/constants';
import LayoutHeaderWithSideMenu from '../../components/layout/LayoutHeaderWithSideMenu';
import Campaign from '../../models/Campaign';
import CampaignDetails from '../../components/campaign/CampaignDetails';
import CampaignReportsModal from '../../components/campaign/CampaignReportsModal';
import NewExternalCampaignModal from '../../components/campaign/NewExternalCampaignModal';
import FacebookCampaignDetails from '../../components/campaign/FacebookCampaignDetails';
import LoaderOverrided from '../../components/common/LoaderOverrided';
import { useQueryClient } from 'react-query';

const FACEBOOK_CAMPAIGN = 'Facebook';
const INSTAGRAM_CAMPAIGN = 'Instagram';

const LayoutContainerStyle = styled.div`
  min-height: 100vh;
  position: relative;
`;

const CalendarContainer = styled.div`
  overflow: auto;
  position: relative;
`;

function CampaignList() {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { error } = useContext(ToasterContext);
  const [campaigns, setCampaigns] = useState([]);
  const queryClient = useQueryClient();

  const segmentsParam =
    params.get('segments') ||
    `["${DEFAULT_SEGMENT.name}","${FACEBOOK_CAMPAIGN}"]`;
  const selectedSegments = JSON.parse(segmentsParam);

  const dateFilter = params.get('date') || moment().format(PARAM_DATE_FORMAT);
  const searchValue = params.get('search') || '';

  const [loading, setLoading] = useState(true);
  const [openCampaign, setOpenCampaign] = useState(null);
  const [openReportsModal, setOpenReportsModal] = useState(false);
  const [openNewExternalCampaignModal, setOpenNewExternalCampaignModal] =
    useState(false);
  // const [viewMode, setViewMode] = useState('day');
  const viewMode = 'week';

  const links = [
    { path: '/', name: 'home' },
    { path: '/campaigns', name: 'campaigns.title' },
  ];

  const fetchCampaignsParams = useMemo(
    () => ({
      from: moment(dateFilter).startOf('isoWeek').format(PARAM_DATE_FORMAT),
      to: moment(dateFilter).endOf('isoWeek').format(PARAM_DATE_FORMAT),
      search: searchValue,
      campaign_type: '[]',
    }),
    [dateFilter, searchValue],
  );

  const {
    data: campaignsData,
    error: campaignsError,
    isError: campaignsIsError,
    isLoading: campaignsIsLoading,
    isFetching: campaignsIsFetching,
  } = useFetchCampaignsData(fetchCampaignsParams);

  const mailCampaigns = campaigns?.filter(c => !isAdCampaign(c)) || [];
  const fbAdCampaigns = campaigns?.filter(c => isFbAdCampaign(c)) || [];
  const igAdCampaigns = campaigns?.filter(c => isIgAdCampaign(c)) || [];

  const campaignsBySegment = { [DEFAULT_SEGMENT.name]: [] };
  let segmentList = [];

  mailCampaigns.forEach(campaign => {
    const segmentName =
      campaign?.mailchimp_segment?.name_on_campaign ||
      campaign?.mailchimp_segment?.name ||
      DEFAULT_SEGMENT.name;

    if (!segmentList.includes(segmentName))
      segmentList = [...segmentList].concat(segmentName);
    if (Object.keys(campaignsBySegment).includes(segmentName)) {
      campaignsBySegment[segmentName] = [
        ...campaignsBySegment[segmentName],
      ].concat(campaign);
    } else {
      campaignsBySegment[segmentName] = [campaign];
    }
  });

  segmentList = [...segmentList].concat(FACEBOOK_CAMPAIGN);

  useEffect(() => {
    if (campaignsData?.data) {
      setCampaigns(Campaign.groupComplementaryCampaigns(campaignsData?.data));
      setLoading(false);
    }
  }, [campaignsData]);

  useEffect(() => {
    if (openCampaign == null) {
      queryClient.invalidateQueries(['campaigns', fetchCampaignsParams]);
    }
  }, [openCampaign, queryClient, fetchCampaignsParams]);

  if (campaignsIsError) {
    error(campaignsError?.message);
  }

  function onChangeSegmentsFilter(value) {
    const filterJSON = JSON.stringify(value);
    const params = new URLSearchParams(location.search);
    params.set('segments', filterJSON);
    history.push({ search: params.toString() });
  }

  function onChangeDateFilter(value) {
    const params = new URLSearchParams(location.search);
    params.set('date', value);
    history.push({ search: params.toString() });
  }

  function onChangeSearchValue(value) {
    const params = new URLSearchParams(location.search);
    params.set('search', value);
    history.push({ search: params.toString() });
  }

  function isAdCampaign(campaign) {
    return (
      campaign?.campaign_type_name?.includes(FACEBOOK_CAMPAIGN) ||
      campaign?.campaign_type_name?.includes(INSTAGRAM_CAMPAIGN)
    );
  }

  function isFbAdCampaign(campaign) {
    return campaign?.campaign_type_name?.includes(FACEBOOK_CAMPAIGN);
  }

  function isIgAdCampaign(campaign) {
    return campaign?.campaign_type_name?.includes(INSTAGRAM_CAMPAIGN);
  }

  function renderViewMode() {
    switch (viewMode) {
      case 'week':
        return (
          <CalendarContainer>
            {Object.keys(campaignsBySegment).map((segment, index) => {
              return (
                selectedSegments.includes(segment) && (
                  <CampaignsListWeek
                    key={`swimlane_${segment}`}
                    loading={
                      loading || campaignsIsLoading || campaignsIsFetching
                    }
                    setOpenCampaign={setOpenCampaign}
                    campaigns={campaignsBySegment[segment]}
                    date={dateFilter}
                    datesHeader={index === 0}
                    segmentName={segment}
                  />
                )
              );
            })}

            {(!!fbAdCampaigns.length || !!igAdCampaigns.length) &&
              selectedSegments.includes(FACEBOOK_CAMPAIGN) && (
                <CampaignsListWeek
                  loading={loading || campaignsIsLoading || campaignsIsFetching}
                  setOpenCampaign={setOpenCampaign}
                  campaigns={fbAdCampaigns.concat(igAdCampaigns)}
                  date={dateFilter}
                  datesHeader={false}
                  segmentName={FACEBOOK_CAMPAIGN}
                />
              )}
          </CalendarContainer>
        );
      default:
        return <></>;
    }
  }

  function renderCampaignDetails() {
    return isAdCampaign(openCampaign) ? (
      <FacebookCampaignDetails
        campaign={openCampaign}
        setOpenCampaign={setOpenCampaign}
      />
    ) : (
      <CampaignDetails
        campaign={openCampaign}
        setOpenCampaign={setOpenCampaign}
      />
    );
  }

  function renderReportsModal() {
    return (
      <CampaignReportsModal
        setOpenReportsModal={setOpenReportsModal}
        baseDate={dateFilter}
      />
    );
  }

  function renderNewExternalCampaignModal() {
    return (
      <NewExternalCampaignModal
        setOpenNewExternalCampaignModal={setOpenNewExternalCampaignModal}
      />
    );
  }

  return (
    <LayoutContainerStyle>
      <LayoutHeaderWithSideMenu>
        <LayoutHeader prevLink={`/`} links={links} />
        <CampaignListHeader
          viewMode={viewMode}
          dateFilter={dateFilter}
          setDateFilter={onChangeDateFilter}
          searchValue={searchValue}
          setSearchValue={onChangeSearchValue}
          segmentList={segmentList}
          selectedSegments={selectedSegments}
          setSelectedSegments={onChangeSegmentsFilter}
          setOpenReportsModal={setOpenReportsModal}
          setOpenNewExternalCampaignModal={setOpenNewExternalCampaignModal}
        />
        {renderViewMode()}
        {openCampaign && renderCampaignDetails()}
        {openReportsModal && renderReportsModal()}
        {openNewExternalCampaignModal && renderNewExternalCampaignModal()}
        <LoaderOverrided show={loading} />
      </LayoutHeaderWithSideMenu>
    </LayoutContainerStyle>
  );
}

export default CampaignList;
