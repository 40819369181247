import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DropdownMenuStyle = styled.div`
  height: 30px;
  width: 200px;
  padding: 6px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  border: 2px solid #c4c4c4;
  background-color: #fff;
`;

const SelectStyle = styled.select`
  border: 0;
  height: 30px;
  width: 100%;
  display: flex;
  color: ${props => props.theme.text};
  font-size: 0.8rem;
  line-height: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  background-color: #fff;

  :focus {
    outline: none;
  }
`;

function DropdownMenu({ placeholder, options, selected, onSelect, disabled }) {
  return (
    <DropdownMenuStyle>
      <SelectStyle
        data-testid="select"
        value={selected}
        disabled={disabled}
        onChange={e => onSelect(parseInt(e.target.value))}
      >
        <option value="0" disabled>
          {placeholder}
        </option>
        {options.map(option => (
          <option key={option.name} value={option.id}>
            {option.name_on_campaign || option.name}
          </option>
        ))}
      </SelectStyle>
    </DropdownMenuStyle>
  );
}

DropdownMenu.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  selected: PropTypes.any,
  disabled: PropTypes.bool,
};

export default DropdownMenu;
