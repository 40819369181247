import api from '../../lib/api';
import { useQuery } from 'react-query';
import { formatURLParams } from '../../lib/formatter';

export function useFetch(url, params = {}, config = {}) {
  const queryParams = formatURLParams(params);
  const fetchURL = `${url}${queryParams}`;
  const fetchFunc = async () => await api.get(fetchURL);
  return useQuery(fetchURL, fetchFunc, { ...config, enabled: false });
}
