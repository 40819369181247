import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    color: 'white',
    width: 45,
    height: 45,
    '& $text': {
      fill: '#A29060',
    },
    '& $circle': {
      r: '11.5',
      strokeWidth: '0.5',
      stroke: '#a29060',
    },
    '&$active': {
      color: '#A29060',
      strokeWidth: '0',
      '& $text': {
        fill: 'white',
      },
    },
    '&$completed': {
      color: '#A29060',
      strokeWidth: '0',
    },
  },
  horizontal: {
    top: '22px',
    left: 'calc(-50% + 40px)',
    right: 'calc(50% + 40px)',
  },
  line: {
    borderColor: '#A29060',
  },
  active: {},
  completed: {
    cursor: 'pointer !important',
  },
  labelContainer: {
    color: '#000000',
    fontFamily: 'Merriweather Sans',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '1px',
    '&$active': {
      color: '#000000',
      fontFamily: 'Merriweather Sans',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '1px',
    },
    '&$completed': {
      color: '#000000',
      fontFamily: 'Merriweather Sans',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '1px',
    },
  },
  rootStepper: {
    paddingLeft: '250px',
    paddingRight: '250px',
  },
  rootLabel: {},
});

function CustomStepper(props) {
  const classes = useStyles();
  const history = useHistory();
  function handleClick(id, url) {
    history.push(`/auctions/${id}/todomail${url}`);
  }
  return (
    <div>
      <Stepper
        activeStep={props.activeStep}
        alternativeLabel
        classes={{
          root: classes.rootStepper,
        }}
        connector={
          <StepConnector
            classes={{
              horizontal: classes.horizontal,
              line: classes.line,
            }}
          />
        }
      >
        {props.steps.map((label, index) => (
          <Step key={index}>
            <StepLabel
              onClick={() => {
                props.activeStep > index && handleClick(props.id, label.url);
              }}
              classes={{
                root: classes.rootLabel,
                label: classes.labelContainer,
                active: classes.active,
                completed: classes.completed,
              }}
              StepIconProps={{
                classes: {
                  root: classes.root,
                  active: classes.active,
                  completed: classes.completed,
                },
              }}
            >
              {label.name}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

CustomStepper.propTypes = {
  activeStep: PropTypes.any,
  steps: PropTypes.array,
  id: PropTypes.string,
};

export default withStyles(makeStyles)(CustomStepper);
