class Auction {
  static hasNeedsReviewChange(auction, field) {
    if (auction.status !== 'needs_review') return false;
    return auction.needs_review_changes?.includes(field);
  }

  static dirtyTitles(obj1, obj2) {
    return Object.keys(obj1).some(key => obj1[key] !== obj2[key]);
  }

  static getAudienceLocales(result) {
    const sentenceLines = result?.sentence_lines;
    if (!sentenceLines) return '';
    const location = sentenceLines.find(item =>
      item.content.includes('Language'),
    );
    return location?.children?.join(', ');
  }

  static fbPlanningsScheduleStatus(auction) {
    if (auction.plannings.some(planning => planning.ad_active === 'processing'))
      return 'processing';
    if (
      auction.plannings.some(planning => planning.ad_active === 'unscheduled')
    )
      return 'unscheduled';
    return 'scheduled';
  }

  static fbDisableScheduleAll(auction) {
    if (
      auction.plannings.some(planning => planning.ad_id === null) ||
      auction.plannings.some(planning => planning.ad_set_id === null)
    ) {
      return true;
    }
    return false;
  }
}

export default Auction;
