import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../hooks/contexts/AuthenticationContext';

const ProfileContainerStyle = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileInfoStyle = styled.p`
  color: white;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding-right: 20px;
  padding-left: 20px;
`;

const ProfileLogoutStyle = styled.a`
  cursor: pointer;
  font-size: 11px;
  font-weight: normal;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
`;

function LayoutProfile() {
  const { t } = useTranslation();
  const { admin, revoke } = useAuthentication();

  return (
    <ProfileContainerStyle>
      <ProfileInfoStyle data-testid="admin-info">
        {admin?.email}
      </ProfileInfoStyle>
      <ProfileLogoutStyle onClick={revoke}>
        {t('authentication.sign_out')}
      </ProfileLogoutStyle>
    </ProfileContainerStyle>
  );
}

export default LayoutProfile;
