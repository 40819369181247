import api from '../../lib/api';
import { useMutation } from 'react-query';
import { formatURLParams } from '../../lib/formatter';

export async function fetchLotData(id, params = {}) {
  const queryParams = formatURLParams(params);
  const fetchURL = `lots/${id}${queryParams}`;
  return await api.get(fetchURL);
}

export function useUpdateLotData() {
  return useMutation(
    async ({ id, params }) => await api.patch(`lots/${id}`, { lot: params }),
  );
}
