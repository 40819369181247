import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { darken } from 'polished';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatURLParams } from '../../lib/formatter';
import ModalDialog from '../common/ModalDialog';
import cancelIcon from '../../assets/icons/cancel.svg';
import { PARAM_DATE_FORMAT } from '../../lib/constants';
import DatePicker from '../common/DatePicker';
import moment from 'moment-timezone';
import Checkbox from '../common/CustomCheckbox';

const REPORTS_URL = `${process.env.REACT_APP_API_URL}reports/auctions`;

const RowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 20px;
`;

const HeaderStyle = styled(RowStyle)`
  width: 600px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const CancelIconStyle = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const ReportFormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowTitleStyle = styled.span`
  color: ${props => props.theme.text};
  margin: 0 10px;
  font-size: 14px;
`;
const InputsContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    min-width: 120px;
  }
`;

const InputRowStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
`;

const FooterStyle = styled(RowStyle)`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  justify-content: flex-end;
`;

const RightFooterStyle = styled.div`
  margin-right: -20px;
`;

const PreviewLinkStyle = styled.a`
  cursor: pointer;
  text-transform: uppercase;
  color: ${props => props.theme.primary};

  :hover {
    color: ${props => darken(0.2, props.theme.primary)};
    text-decoration: none;
  }
`;

function AuctionExportModal({ setOpenExportModal, baseDate }) {
  const { t } = useTranslation();

  const [dateFrom, setDateFrom] = useState(
    moment(baseDate.from).format(PARAM_DATE_FORMAT),
  );
  const [dateTo, setDateTo] = useState(
    moment(baseDate.to).format(PARAM_DATE_FORMAT),
  );

  const [includeDeleted, setIncludeDeleted] = useState(true);

  function generateReport(event) {
    event.preventDefault();
    const params = {
      date_from: dateFrom,
      date_to: dateTo,
      include_deleted: includeDeleted,
    };
    const queryParams = formatURLParams(params);
    window.open(REPORTS_URL + queryParams, '_blank');
  }

  return (
    <ModalDialog
      onClose={() => {
        setOpenExportModal(false);
      }}
    >
      <HeaderStyle>
        <span>{t('auction_list.export.title')}</span>
        <CancelIconStyle
          src={cancelIcon}
          onClick={() => {
            setOpenExportModal(false);
          }}
        />
      </HeaderStyle>
      <ReportFormContainer>
        <InputsContainerStyle>
          <InputRowStyle>
            <RowTitleStyle>
              {t('auction_list.export.include_past')}
            </RowTitleStyle>
            <Checkbox
              key={'include_past'}
              value={includeDeleted}
              onChange={() => setIncludeDeleted(!includeDeleted)}
            ></Checkbox>
          </InputRowStyle>
          <InputRowStyle>
            <RowTitleStyle>{t('auction_list.export.from')}</RowTitleStyle>
            <DatePicker date={dateFrom} setDate={setDateFrom} />
            <RowTitleStyle>{t('auction_list.export.to')}</RowTitleStyle>
            <DatePicker date={dateTo} setDate={setDateTo} min={dateFrom} />
          </InputRowStyle>
        </InputsContainerStyle>

        <FooterStyle>
          <RightFooterStyle>
            <PreviewLinkStyle onClick={generateReport}>
              {t('auction_list.export.generate')}
            </PreviewLinkStyle>
          </RightFooterStyle>
        </FooterStyle>
      </ReportFormContainer>
    </ModalDialog>
  );
}

AuctionExportModal.propTypes = {
  setOpenExportModal: PropTypes.func,
  baseDate: PropTypes.object,
};

export default AuctionExportModal;
