import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RadioButton } from 'vavato-ui';

const RadioInputStyle = styled.div`
  input {
    width: 16px;
    height: 16px;
  }
`;

function RadioInput({ name, option, disabled, checked, onCheck }) {
  return (
    <RadioInputStyle>
      <RadioButton
        name={name}
        value={option}
        disabled={disabled}
        checked={checked}
        onCheck={onCheck}
      />
    </RadioInputStyle>
  );
}

RadioInput.propTypes = {
  name: PropTypes.string,
  option: PropTypes.any,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
};

export default RadioInput;
