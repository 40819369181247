import moment from 'moment-timezone';
import {
  HEADER_DATE_FORMAT,
  FB_COPY_DATE_FORMAT,
  PARAM_DATE_FORMAT,
} from './constants';

export function haveDateInstances(auction, dateReference) {
  const reference = moment(dateReference);
  const endDate = moment(auction.end_date);
  if (reference.diff(endDate) >= 0) return false;
  if (!weekDaysBetween(reference, endDate).includes('Saturday')) {
    return false;
  }
  return true;
}

function weekDaysBetween(initialDate, finalDate) {
  const diff = finalDate.diff(initialDate, 'days');

  if (diff >= 7) {
    return moment.weekdays();
  }

  const firstDay = initialDate.day();
  const weekdaysBetween = [];

  for (let i = 0; i <= diff; i++) {
    weekdaysBetween.push(moment.weekdays((firstDay + i) % 7));
  }

  return weekdaysBetween;
}

export function formatDate(date) {
  return moment(date).format(HEADER_DATE_FORMAT);
}

export function formatFbCopyDate(date) {
  return moment(date).format(FB_COPY_DATE_FORMAT);
}

export function formatDateRange(from, to) {
  return `${formatDate(from)} to ${formatDate(to)}`;
}

export function daysOfWeek(date) {
  const fromDate = moment(date).startOf('isoWeek');
  const toDate = moment(date).endOf('isoWeek');

  const diff = toDate.diff(fromDate, 'day');
  const range = [];
  for (let i = 0; i <= diff; i++) {
    range.push(fromDate.clone().add(i, 'day'));
  }
  return range;
}

export function isPastDate(date) {
  return moment().diff(moment(date)) > 0;
}

export function formatDateToUrl(date) {
  return moment(date).format(PARAM_DATE_FORMAT);
}
