import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from '../pages/Login';
import AuctionPlan from '../pages/auctions/Plan';
import AuctionList from '../pages/auctions/List';
import CampaignList from '../pages/campaigns/List';
import AuctionSelectLots from '../pages/auctions/todomail/SelectLots';
import AuctionConfigureLots from '../pages/auctions/todomail/ConfigureLots';
import AuctionConfigureCampaign from '../pages/auctions/todomail/ConfigureCampaign';
import AuctionPreviewCampaign from '../pages/auctions/todomail/PreviewCampaign';
import { useAuthentication } from '../hooks/contexts/AuthenticationContext';
import CampaignDetailsPage from '../components/campaign/CampaignDetailsPage';

function PrivateRoute({ component: Component, ...rest }) {
  const { admin } = useAuthentication();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return admin ? (
          <Component />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        );
      }}
    />
  );
}

function Routes() {
  return (
    <Switch>
      <PrivateRoute exact path="/">
        <Redirect to="/auctions" />
      </PrivateRoute>
      <PrivateRoute exact path="/auctions" component={AuctionList} />
      <PrivateRoute exact path="/campaigns" component={CampaignList} />
      <PrivateRoute exact path="/auctions/:id/plan" component={AuctionPlan} />
      <PrivateRoute
        exact
        path="/campaigns/:id"
        component={CampaignDetailsPage}
      />
      <PrivateRoute
        exact
        path="/auctions/:id/todomail/select_lots"
        component={AuctionSelectLots}
      />
      <PrivateRoute
        exact
        path="/auctions/:id/todomail/configure_lots"
        component={AuctionConfigureLots}
      />
      <PrivateRoute
        exact
        path="/auctions/:id/todomail/configure_campaign"
        component={AuctionConfigureCampaign}
      />
      <PrivateRoute
        exact
        path="/auctions/:id/todomail/preview_campaign"
        component={AuctionPreviewCampaign}
      />
      <Route path="/login" component={Login} />
    </Switch>
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export default Routes;
