import React from 'react';
import PropTypes from 'prop-types';

import CampaignInput from './CampaignInput';
import CounterInput from '../common/CounterInput';

function CampaignCounterInput({ name, counter, setCounter }) {
  return (
    <CampaignInput name={name}>
      <CounterInput counter={counter} setCounter={setCounter} />
    </CampaignInput>
  );
}

CampaignCounterInput.propTypes = {
  name: PropTypes.string,
  counter: PropTypes.number,
  setCounter: PropTypes.func,
};

export default CampaignCounterInput;
