import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import StatusLabel from '../common/StatusLabel';

const ToolTipTitle = styled.span`
  display: block;
`;

const ToolTipField = styled.span`
  display: block;
`;

function statusWithLink(status) {
  return [
    'lots_available',
    'to_do_mail',
    'email_ready',
    'needs_review',
  ].includes(status);
}

function statusLink(auction) {
  return `/auctions/${auction.external_id || auction.atlas_external_id}/plan`;
}

function AuctionStatusLabel({ auction, clickable = true }) {
  const { t } = useTranslation();

  const toolTipFields =
    auction.status === 'needs_review' ? auction.needs_review_changes : '';

  function clickLink(event) {
    if (!clickable) {
      event.preventDefault();
    }
  }

  return statusWithLink(auction.status) ? (
    <Link
      key={statusLink(auction)}
      to={statusLink(auction)}
      onClick={e => {
        clickLink(e);
      }}
      target="_blank"
      data-tip
      data-for={`statusTip_${auction.external_id || auction.atlas_external_id}`}
    >
      {toolTipFields && (
        <ReactTooltip
          id={`statusTip_${auction.external_id || auction.atlas_external_id}`}
          place="top"
          effect="solid"
        >
          <ToolTipTitle>{t('needs_review.changes')}</ToolTipTitle>
          {toolTipFields.map(field => {
            return (
              <ToolTipField key={field}>
                {t(`needs_review.${field}`)}
              </ToolTipField>
            );
          })}
        </ReactTooltip>
      )}
      <StatusLabel status={auction.status} />
    </Link>
  ) : (
    <StatusLabel status={auction.status} />
  );
}

AuctionStatusLabel.propTypes = {
  auction: PropTypes.object,
  clickable: PropTypes.bool,
};

export default AuctionStatusLabel;
