import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from './CustomCheckbox';

function CheckboxSelector({ options, selected, setSelected }) {
  function addSelected(option) {
    setSelected(prevSelected => [...prevSelected, option]);
  }

  function removeSelected(option) {
    const newSelected = selected.filter(item => item.id !== option.id);
    setSelected(newSelected);
  }

  function onSelect(value, index) {
    const option = options[index];

    if (value) {
      addSelected(option);
    } else {
      removeSelected(option);
    }
  }

  if (!options) {
    return null;
  }

  return (
    <>
      {options.map((option, index) => {
        const isSelected = selected.some(type => type.id === option.id);
        return (
          <Checkbox
            key={option.name}
            value={isSelected}
            onChange={() => onSelect(!isSelected, index)}
            disabled={option.disabled}
          >
            {option.name}
          </Checkbox>
        );
      })}
    </>
  );
}

CheckboxSelector.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

export default CheckboxSelector;
