import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { TEMPLATE_OPTIONS, DEFAULT_SEGMENT } from '../../lib/constants';
import DropdownMenu from '../../components/common/DropdownMenu';
import { useTranslation } from 'react-i18next';
import LabeledInput from '../common/LabeledInput';
import { useFetchSegmentsData } from '../../hooks/services/CampaignsAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useFeature } from '../../hooks/contexts/FeatureContext';

const BLACK_FRIDAY_TEMPLATE = 'v2black';

const CampaignSubHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 0.7rem;
`;

const UpperMenuTitle = styled.span`
  margin: 0.5rem 1rem;
  font-weight: 700;
  font-size: 0.75rem;
  color: ${props => props.theme.text};
  width: 100%;
  text-align: center;
`;

const TemplateMenuTitle = styled.span`
  font-weight: 700;
  font-size: 0.75rem;
  color: ${props => props.theme.text};
`;

const EditConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  span {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  p {
    margin-top: 2px;
    margin-bottom: 2px;
    width: 20px;
  }
  input[type='text'] {
    width: 200px;
    height: 10px;
  }
  div {
    height: 30px;
  }
`;

const CampaignLocalesEditContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LoaderDiv = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
`;

function CampaignDetailsSubHeader({ campaignConfig, setCampaignConfig }) {
  const { t } = useTranslation();
  const feature = useFeature();

  const { data: segmentsData, isLoading: segmentsIsLoading } =
    useFetchSegmentsData();

  const segments = segmentsData?.data
    ? [DEFAULT_SEGMENT].concat(segmentsData?.data)
    : [];

  function editCampaignConfig(value, locale, type) {
    setCampaignConfig({
      ...campaignConfig,
      [type]: {
        ...campaignConfig?.[type],
        [locale]: value,
      },
    });
  }

  function editBfConfig(value, locale, type) {
    setCampaignConfig({
      ...campaignConfig,
      black_friday_banner: {
        ...campaignConfig?.black_friday_banner,
        [locale]: {
          ...campaignConfig?.black_friday_banner?.[locale],
          [type]: value,
        },
      },
    });
  }

  function segmentChange(event) {
    const selected =
      event === DEFAULT_SEGMENT.id
        ? {}
        : {
            ...segments.find(segment => segment.id === event),
          };

    setCampaignConfig({
      ...campaignConfig,
      mailchimp_segment: selected,
    });
  }

  return (
    <CampaignSubHeader>
      <div>
        <div>
          <TemplateMenuTitle>
            {t('campaign_preview.template')}
          </TemplateMenuTitle>
          <DropdownMenu
            selected={campaignConfig?.mail_template?.id}
            onSelect={e => {
              setCampaignConfig({
                ...campaignConfig,
                mail_template: {
                  ...TEMPLATE_OPTIONS.find(template => template.id === e),
                },
              });
            }}
            placeholder={t('campaign_preview.select_template')}
            options={TEMPLATE_OPTIONS}
          />
        </div>
        {feature?.segmentation && (
          <div>
            {segmentsIsLoading ? (
              <LoaderDiv>
                <FontAwesomeIcon
                  spin
                  icon={faSpinner}
                  className="circle"
                  color="#6092a2"
                  size="1x"
                />
              </LoaderDiv>
            ) : (
              <div>
                <TemplateMenuTitle>
                  {t('campaign_preview.segment')}
                </TemplateMenuTitle>
                <DropdownMenu
                  selected={
                    campaignConfig?.mailchimp_segment?.id || DEFAULT_SEGMENT.id
                  }
                  onSelect={e => {
                    segmentChange(e);
                  }}
                  placeholder={t('campaign_preview.select_segment')}
                  options={segments || []}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <CampaignLocalesEditContainer>
        <EditConfigContainer>
          <UpperMenuTitle>{`${t('campaigns.subtitle')}:`}</UpperMenuTitle>
          {['en', 'fr', 'nl'].map(locale => {
            return (
              <LabeledInput
                value={campaignConfig?.mail_subtitle?.[locale] || ''}
                key={`subtitle_input_${locale}`}
                label={locale}
                placeholder={''}
                onChange={e => {
                  editCampaignConfig(e.target.value, locale, 'mail_subtitle');
                }}
              />
            );
          })}
        </EditConfigContainer>
        <EditConfigContainer>
          <UpperMenuTitle>{`${t('campaigns.subject')}:`}</UpperMenuTitle>
          {['en', 'fr', 'nl'].map(locale => {
            return (
              <LabeledInput
                type={'subject'}
                value={campaignConfig?.mail_subject?.[locale] || ''}
                key={`subject_input_${locale}`}
                label={locale}
                placeholder={''}
                onChange={e => {
                  editCampaignConfig(e.target.value, locale, 'mail_subject');
                }}
              />
            );
          })}
        </EditConfigContainer>
        {campaignConfig?.mail_template?.name === BLACK_FRIDAY_TEMPLATE && (
          <>
            <EditConfigContainer>
              <UpperMenuTitle>{`${t(
                'campaigns.black_friday_image',
              )}:`}</UpperMenuTitle>
              {['en', 'fr', 'nl'].map(locale => {
                return (
                  <LabeledInput
                    type={'black_friday_image'}
                    value={
                      campaignConfig?.black_friday_banner?.[locale]?.image || ''
                    }
                    key={`bf_input_image_${locale}`}
                    label={locale}
                    placeholder={''}
                    onChange={e => {
                      editBfConfig(e.target.value, locale, 'image');
                    }}
                  />
                );
              })}
            </EditConfigContainer>
            <EditConfigContainer>
              <UpperMenuTitle>{`${t(
                'campaigns.black_friday_url',
              )}:`}</UpperMenuTitle>
              {['en', 'fr', 'nl'].map(locale => {
                return (
                  <LabeledInput
                    type={'black_friday_url'}
                    value={
                      campaignConfig?.black_friday_banner?.[locale]?.url || ''
                    }
                    key={`bf_input_url_${locale}`}
                    label={locale}
                    placeholder={''}
                    onChange={e => {
                      editBfConfig(e.target.value, locale, 'url');
                    }}
                  />
                );
              })}
            </EditConfigContainer>
          </>
        )}
      </CampaignLocalesEditContainer>
    </CampaignSubHeader>
  );
}

CampaignDetailsSubHeader.propTypes = {
  campaignConfig: PropTypes.object,
  setCampaignConfig: PropTypes.func,
};

export default CampaignDetailsSubHeader;
