import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Switch } from 'vavato-ui';
import CampaignInput from './CampaignInput';

const SwitchContainerStyle = styled.div`
  margin-right: 1rem;
`;

function CampaignSwitchInput({ name, checked, onCheck }) {
  return (
    <CampaignInput name={name}>
      <SwitchContainerStyle>
        <Switch checked={checked} onCheck={e => onCheck(e.target.checked)} />
      </SwitchContainerStyle>
    </CampaignInput>
  );
}

CampaignSwitchInput.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
};

export default CampaignSwitchInput;
