import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { TEMPLATE_OPTIONS, DEFAULT_SEGMENT } from '../../lib/constants';
import DropdownMenu from '../../components/common/DropdownMenu';
import { useTranslation } from 'react-i18next';
import LabeledInput from '../common/LabeledInput';
import { useFetchSegmentsData } from '../../hooks/services/CampaignsAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useFeature } from '../../hooks/contexts/FeatureContext';

const BLACK_FRIDAY_TEMPLATE = 'v2black';

const CampaignConfiguration = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 0.7rem;
`;

const UpperMenuTitle = styled.span`
  margin-top: 1.6rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 0.85rem;
  color: ${props => props.theme.text};
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const TemplateMenuTitle = styled.span`
  margin: ${props =>
    props.isSegment ? '1.6rem 1rem 0.5rem 1rem' : '0.5rem 1rem'};
  font-weight: 700;
  font-size: 0.85rem;
  color: ${props => props.theme.text};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const EditConfigContainer = styled.div`
  display: flex;
  flex-direction: row;
  p {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 10px;
    width: 20px;
  }
  input[type='text'] {
    width: 200px;
    height: 10px;
  }
  div {
    height: 30px;
  }
`;

const CampaignLocalesEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
`;

const LoaderDiv = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  margin: 0.5rem 1rem;
`;

const SelectDiv = styled.div`
  div {
    margin-left: 1rem;
  }
`;
function CampaignDetailsPageConfiguration({
  campaignConfig,
  setCampaignConfig,
}) {
  const { t } = useTranslation();
  const feature = useFeature();

  const { data: segmentsData, isLoading: segmentsIsLoading } =
    useFetchSegmentsData();

  const segments = segmentsData?.data
    ? [DEFAULT_SEGMENT].concat(segmentsData?.data)
    : [];

  function editCampaignConfig(value, locale, type) {
    setCampaignConfig({
      ...campaignConfig,
      [type]: {
        ...campaignConfig?.[type],
        [locale]: value,
      },
    });
  }

  function editUtmTagsConfig(value, locale, type) {
    setCampaignConfig({
      ...campaignConfig,
      campaign_utm_tags: {
        ...campaignConfig?.campaign_utm_tags,
        [type]: {
          ...campaignConfig?.campaign_utm_tags?.[type],
          [locale]: value,
        },
      },
    });
  }

  function editBfConfig(value, locale, type) {
    setCampaignConfig({
      ...campaignConfig,
      black_friday_banner: {
        ...campaignConfig?.black_friday_banner,
        [locale]: {
          ...campaignConfig?.black_friday_banner?.[locale],
          [type]: value,
        },
      },
    });
  }

  function segmentChange(event) {
    const selected =
      event === DEFAULT_SEGMENT.id
        ? { name: DEFAULT_SEGMENT.utm_content_name }
        : {
            ...segments.find(segment => segment.id === event),
          };
    const utmContentFromSegment = selected.name.replace(/\s+/g, '');

    setCampaignConfig({
      ...campaignConfig,
      mailchimp_segment: selected,
      campaign_utm_tags: {
        ...campaignConfig?.campaign_utm_tags,
        utm_content: {
          ...campaignConfig?.campaign_utm_tags?.utm_content,
          en: utmContentFromSegment,
          fr: utmContentFromSegment,
          nl: utmContentFromSegment,
        },
      },
    });
  }

  return (
    <CampaignConfiguration>
      <div>
        <div>
          <TemplateMenuTitle>
            {t('campaign_preview.template')}
          </TemplateMenuTitle>
          <SelectDiv>
            <DropdownMenu
              selected={campaignConfig?.mail_template?.id}
              onSelect={e => {
                setCampaignConfig({
                  ...campaignConfig,
                  mail_template: {
                    ...TEMPLATE_OPTIONS.find(template => template.id === e),
                  },
                });
              }}
              placeholder={t('campaign_preview.select_template')}
              options={TEMPLATE_OPTIONS}
            />
          </SelectDiv>
        </div>
        {feature?.segmentation && (
          <div>
            {segmentsIsLoading ? (
              <LoaderDiv>
                <FontAwesomeIcon
                  spin
                  icon={faSpinner}
                  className="circle"
                  color="#6092a2"
                  size="1x"
                />
              </LoaderDiv>
            ) : (
              <div>
                <TemplateMenuTitle isSegment>
                  {t('campaign_preview.segment')}
                </TemplateMenuTitle>
                <SelectDiv>
                  <DropdownMenu
                    selected={
                      campaignConfig?.mailchimp_segment?.id ||
                      DEFAULT_SEGMENT.id
                    }
                    onSelect={e => {
                      segmentChange(e);
                    }}
                    placeholder={t('campaign_preview.select_segment')}
                    options={segments || []}
                  />
                </SelectDiv>
              </div>
            )}
          </div>
        )}
      </div>
      <CampaignLocalesEditContainer>
        <UpperMenuTitle>{`${t('campaigns.subtitle')}:`}</UpperMenuTitle>
        <EditConfigContainer>
          {['en', 'fr', 'nl'].map(locale => {
            return (
              <LabeledInput
                value={campaignConfig?.mail_subtitle?.[locale] || ''}
                key={`subtitle_input_${locale}`}
                label={locale}
                placeholder={''}
                onChange={e => {
                  editCampaignConfig(e.target.value, locale, 'mail_subtitle');
                }}
              />
            );
          })}
        </EditConfigContainer>
        <UpperMenuTitle>{`${t('campaigns.subject')}:`}</UpperMenuTitle>
        <EditConfigContainer>
          {['en', 'fr', 'nl'].map(locale => {
            return (
              <LabeledInput
                type={'subject'}
                value={campaignConfig?.mail_subject?.[locale] || ''}
                key={`subject_input_${locale}`}
                label={locale}
                placeholder={''}
                onChange={e => {
                  editCampaignConfig(e.target.value, locale, 'mail_subject');
                }}
              />
            );
          })}
        </EditConfigContainer>
        <UpperMenuTitle>{`${t(
          'campaigns.mail_preview_text',
        )}:`}</UpperMenuTitle>
        <EditConfigContainer>
          {['en', 'fr', 'nl'].map(locale => {
            return (
              <LabeledInput
                type={'mail_preview_text'}
                value={campaignConfig?.mail_preview_text?.[locale] || ''}
                key={`mail_preview_text_input_${locale}`}
                label={locale}
                placeholder={''}
                onChange={e => {
                  editCampaignConfig(
                    e.target.value,
                    locale,
                    'mail_preview_text',
                  );
                }}
              />
            );
          })}
        </EditConfigContainer>
        <UpperMenuTitle>{`${t('campaigns.mail_from_name')}:`}</UpperMenuTitle>
        <EditConfigContainer>
          {['en', 'fr', 'nl'].map(locale => {
            return (
              <LabeledInput
                type={'mail_from_name'}
                value={campaignConfig?.mail_from_name?.[locale] || ''}
                key={`mail_from_name_input_${locale}`}
                label={locale}
                placeholder={''}
                onChange={e => {
                  editCampaignConfig(e.target.value, locale, 'mail_from_name');
                }}
              />
            );
          })}
        </EditConfigContainer>
        <UpperMenuTitle>{`${t('campaigns.utm_campaign')}:`}</UpperMenuTitle>
        <EditConfigContainer>
          {['en', 'fr', 'nl'].map(locale => {
            return (
              <LabeledInput
                type={'utm_campaign'}
                value={
                  campaignConfig?.campaign_utm_tags?.utm_campaign?.[locale] ||
                  ''
                }
                key={`utm_campaign_input_${locale}`}
                label={locale}
                placeholder={''}
                onChange={e => {
                  editUtmTagsConfig(e.target.value, locale, 'utm_campaign');
                }}
              />
            );
          })}
        </EditConfigContainer>
        <UpperMenuTitle>{`${t('campaigns.utm_medium')}:`}</UpperMenuTitle>
        <EditConfigContainer>
          {['en', 'fr', 'nl'].map(locale => {
            return (
              <LabeledInput
                type={'utm_medium'}
                value={
                  campaignConfig?.campaign_utm_tags?.utm_medium?.[locale] || ''
                }
                key={`utm_medium_input_${locale}`}
                label={locale}
                placeholder={''}
                onChange={e => {
                  editUtmTagsConfig(e.target.value, locale, 'utm_medium');
                }}
              />
            );
          })}
        </EditConfigContainer>
        <UpperMenuTitle>{`${t('campaigns.utm_source')}:`}</UpperMenuTitle>
        <EditConfigContainer>
          {['en', 'fr', 'nl'].map(locale => {
            return (
              <LabeledInput
                type={'utm_source'}
                value={
                  campaignConfig?.campaign_utm_tags?.utm_source?.[locale] || ''
                }
                key={`utm_source_input_${locale}`}
                label={locale}
                placeholder={''}
                onChange={e => {
                  editUtmTagsConfig(e.target.value, locale, 'utm_source');
                }}
              />
            );
          })}
        </EditConfigContainer>
        <UpperMenuTitle>{`${t('campaigns.banner_url')}:`}</UpperMenuTitle>
        <EditConfigContainer>
          {['en', 'fr', 'nl'].map(locale => {
            return (
              <LabeledInput
                type={'banner_image_url'}
                value={campaignConfig?.banner_image_url?.[locale] || ''}
                key={`banner_image_url_input_${locale}`}
                label={locale}
                placeholder={''}
                onChange={e => {
                  editCampaignConfig(
                    e.target.value,
                    locale,
                    'banner_image_url',
                  );
                }}
              />
            );
          })}
        </EditConfigContainer>
        <UpperMenuTitle>{`${t('campaigns.banner_link')}:`}</UpperMenuTitle>
        <EditConfigContainer>
          {['en', 'fr', 'nl'].map(locale => {
            return (
              <LabeledInput
                type={'banner_destination_link'}
                value={campaignConfig?.banner_destination_link?.[locale] || ''}
                key={`banner_destination_link_input_${locale}`}
                label={locale}
                placeholder={''}
                onChange={e => {
                  editCampaignConfig(
                    e.target.value,
                    locale,
                    'banner_destination_link',
                  );
                }}
              />
            );
          })}
        </EditConfigContainer>
        {feature?.utm_content && (
          <div>
            <UpperMenuTitle>{`${t('campaigns.utm_content')}:`}</UpperMenuTitle>
            <EditConfigContainer>
              {['en', 'fr', 'nl'].map(locale => {
                return (
                  <LabeledInput
                    type={'utm_content'}
                    value={
                      campaignConfig?.campaign_utm_tags?.utm_content?.[locale]
                    }
                    key={`utm_content_input_${locale}`}
                    label={locale}
                    placeholder={''}
                    onChange={e => {
                      editUtmTagsConfig(e.target.value, locale, 'utm_content');
                    }}
                  />
                );
              })}
            </EditConfigContainer>
          </div>
        )}
        {campaignConfig?.mail_template?.name === BLACK_FRIDAY_TEMPLATE && (
          <>
            <UpperMenuTitle>{`${t(
              'campaigns.black_friday_image',
            )}:`}</UpperMenuTitle>
            <EditConfigContainer>
              {['en', 'fr', 'nl'].map(locale => {
                return (
                  <LabeledInput
                    type={'black_friday_image'}
                    value={
                      campaignConfig?.black_friday_banner?.[locale]?.image || ''
                    }
                    key={`bf_input_image_${locale}`}
                    label={locale}
                    placeholder={''}
                    onChange={e => {
                      editBfConfig(e.target.value, locale, 'image');
                    }}
                  />
                );
              })}
            </EditConfigContainer>
            <UpperMenuTitle>{`${t(
              'campaigns.black_friday_url',
            )}:`}</UpperMenuTitle>
            <EditConfigContainer>
              {['en', 'fr', 'nl'].map(locale => {
                return (
                  <LabeledInput
                    type={'black_friday_url'}
                    value={
                      campaignConfig?.black_friday_banner?.[locale]?.url || ''
                    }
                    key={`bf_input_url_${locale}`}
                    label={locale}
                    placeholder={''}
                    onChange={e => {
                      editBfConfig(e.target.value, locale, 'url');
                    }}
                  />
                );
              })}
            </EditConfigContainer>
          </>
        )}
      </CampaignLocalesEditContainer>
    </CampaignConfiguration>
  );
}

CampaignDetailsPageConfiguration.propTypes = {
  campaignConfig: PropTypes.object,
  setCampaignConfig: PropTypes.func,
};

export default CampaignDetailsPageConfiguration;
