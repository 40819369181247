import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { ToasterContext } from 'vavato-ui';
import moment from 'moment-timezone';
import AuctionVavatoStatus from './AuctionVavatoStatus';
import AuctionStatusLabel from './AuctionStatusLabel';
import AdStatusLabel from './AdStatusLabel';
import {
  faEye,
  faEyeSlash,
  faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { useTogglePlanningVisibility } from '../../hooks/services/PlanningsAPI';
import { useDeleteExternalPlanning } from '../../hooks/services/ExternalPlanningsAPI';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import EllipsisMenu from '../common/EllipsisMenu';

import {
  BACKEND_AUCTION_STATUS_MAPPING,
  VAVATO_WEBSITE_URL_PREFIX,
  TITAN_WEBSITE_URL_PREFIX,
  SIMPLE_DATE_FORMAT,
} from '../../lib/constants';
import Auction from '../../models/Auction';
import Campaign from '../../models/Campaign';

import {
  formatCurrencyRounded,
  formatDeliveryType,
  nameTranslation,
} from '../../lib/formatter';

import dragGridIcon from '../../assets/icons/drag-grid.svg';

const StyledLink = styled(Link)`
  font-weight: 400 !important;
  &.changed {
    color: ${props => props.theme.changedText} !important;
  }
`;

const EllipsisLink = styled(Link)`
  margin: 10px;
  display: flex;
  flex: 1;
  flex-direction: flex-start;
`;

const TableRow = styled.tr`
  height: 46px !important;
  opacity: ${props => (props.isDragging ? 0.5 : 1)};
  border-top: ${props => (props.isOver ? '1px solid #808080 !important' : '')};
  border-bottom: ${props =>
    props.isOver ? '1px solid #808080 !important' : ''};
  td {
    opacity: ${props => (props.isHidden ? 0.5 : 1)};
    font-size: 12px !important;
    &.empty {
      color: #999;
    }
    &.changed {
      color: ${props => props.theme.changedText};
    }
  }
`;

const EllipsisTd = styled.td`
  svg {
    width: 0.275em !important;
  }
  nav {
    min-width: 200px;
    padding: 8px 16px;
  }
`;

const AuctionImage = styled.img`
  width: 42px;
  height: 42px;
  margin: 0.5rem;
  object-fit: cover;
  border-radius: 3px;
`;

const AuctionDraggableStyle = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
  cursor: ${props => (props.isDragging ? 'move' : 'pointer')};
`;

const AuctionHideableStyle = styled.td`
  cursor: pointer;
  opacity: 1 !important;
  svg {
    width: 1em !important;
  }
`;

const StyledDeleteLink = styled.div`
  font-weight: 400 !important;
  text-decoration: underline;
  cursor: pointer;
`;

function AuctionTableRow({
  isHideable,
  auction,
  isDraggable,
  isDragging,
  isDeletable,
  isOver,
  dragRef,
  showCampaignStatus,
  campaign,
  planning,
  setSetupLoading,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const { error, success } = useContext(ToasterContext);
  const ellipsisTdRef = useRef();

  const lotsCount = auction.payload?.lots_count || 0;

  const { mutateAsync: toggleVisibilityMutate } = useTogglePlanningVisibility(
    planning.id,
    planning?.campaign_id?.toString(),
  );

  const lastPlanning =
    campaign?.plannings?.length + campaign?.external_plannings?.length === 1;

  const { mutateAsync: deleteExternalPlanningMutate } =
    useDeleteExternalPlanning(
      planning.id,
      planning?.campaign_id?.toString(),
      lastPlanning,
    );

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openEllipsisMenu, setOpenEllipsisMenu] = useState(false);

  function lotsCountClassName(auction, count) {
    if (count === 0) return 'empty';
    return Auction.hasNeedsReviewChange(auction, 'lots_count') ? 'changed' : '';
  }

  function openNewTabPage(event, path) {
    event.preventDefault();
    window.open(path, '_blank');
  }

  function planLink(auction) {
    const url = `/auctions/${
      auction.external_id || auction.atlas_external_id
    }/plan`;
    return (
      <EllipsisLink
        key="plan_link"
        onClick={e => {
          openNewTabPage(e, url);
        }}
        to={url}
      >
        {t('auction_list.shortcuts.plan')}
      </EllipsisLink>
    );
  }

  function todoMailLink(auction) {
    const url = `/auctions/${
      auction.external_id || auction.atlas_external_id
    }/todomail/select_lots`;
    return (
      <EllipsisLink
        key="todo_mail_link"
        onClick={e => {
          openNewTabPage(e, url);
        }}
        to={url}
      >
        {t('auction_list.shortcuts.todo_mail')}
      </EllipsisLink>
    );
  }

  function previewLink(auction) {
    const url = `/auctions/${
      auction.external_id || auction.atlas_external_id
    }/todomail/preview_campaign`;
    return (
      <EllipsisLink
        key="preview_link"
        onClick={e => {
          openNewTabPage(e, url);
        }}
        to={url}
      >
        {t('auction_list.shortcuts.preview')}
      </EllipsisLink>
    );
  }

  function ellipsisContent(auction) {
    if (auction.status === 'lots_available') {
      return [planLink(auction)];
    } else if (auction.status === 'to_do_mail') {
      return [planLink(auction), todoMailLink(auction)];
    } else {
      return [planLink(auction), todoMailLink(auction), previewLink(auction)];
    }
  }

  function needsReviewClass(field) {
    return Auction.hasNeedsReviewChange(auction, field) ? 'changed' : '';
  }

  function auctionStatusSquare(auction, showCampaignStatus, campaign) {
    const campaignStatus = Campaign.isStarting(
      planning.auction || {
        campaign_id: planning.campaign_id,
        auction: auction,
      },
      campaign,
    )
      ? 'bidding_not_yet_open'
      : 'bidding_closed';

    if (showCampaignStatus) {
      return (
        <td>
          <AuctionVavatoStatus
            status={BACKEND_AUCTION_STATUS_MAPPING[campaignStatus]}
          />
        </td>
      );
    } else {
      return (
        <td>
          <AuctionVavatoStatus
            status={BACKEND_AUCTION_STATUS_MAPPING[auction.payload?.status]}
          />
        </td>
      );
    }
  }

  async function changePlanningVisibility(e) {
    e.preventDefault();
    try {
      setSetupLoading(true);
      await toggleVisibilityMutate();
      success(t('toaster.planning_visibility_change'));
      setSetupLoading(false);
    } catch (err) {
      error(err.response?.data?.message);
      setSetupLoading(false);
    }
  }

  function renderHideShowIcon(auction) {
    return planning.id ? (
      <AuctionHideableStyle onClick={changePlanningVisibility}>
        <FontAwesomeIcon
          icon={planning.hidden ? faEye : faEyeSlash}
          className="hideshow"
          color="#aaa"
          size="2x"
          data-tip
          data-for={`auction_${auction.id}_hide_show`}
          data-testid={'hideshow'}
        />
        <ReactTooltip
          id={`auction_${auction.id}_hide_show`}
          place="top"
          effect="solid"
        >
          <span>
            {t(`campaigns.plannings.${planning.hidden ? 'show' : 'hide'}`)}
          </span>
        </ReactTooltip>
      </AuctionHideableStyle>
    ) : (
      <></>
    );
  }

  function renderConfirmationModal() {
    return (
      <ConfirmationModal
        onConfirm={deleteExternalPlanning}
        onCancel={() => {
          setOpenConfirmationModal(false);
        }}
        message={t('delete_confirmation')}
      />
    );
  }

  function openConfirmation(e) {
    e.preventDefault();

    setOpenConfirmationModal(true);
  }

  async function deleteExternalPlanning() {
    try {
      setSetupLoading(true);
      await deleteExternalPlanningMutate();
      success(t('toaster.external_auction_deleted'));
      setSetupLoading(false);
      if (lastPlanning) {
        history.push('/campaigns/');
      }
    } catch (err) {
      error(err.response?.data?.message);
      setSetupLoading(false);
    }
  }

  function renderDeleteLink(campaign) {
    return planning.id ? (
      <StyledDeleteLink
        onClick={e => {
          openConfirmation(e, campaign);
        }}
      >
        <span>{t('campaigns.external_auction.delete')}</span>
      </StyledDeleteLink>
    ) : (
      <></>
    );
  }

  function openMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    setOpenEllipsisMenu(!openEllipsisMenu);
  }

  function renderEllipsisMenu() {
    return (
      <EllipsisMenu
        open={openEllipsisMenu}
        closeMenu={() => setOpenEllipsisMenu(false)}
        exceptionRef={ellipsisTdRef}
      >
        {ellipsisContent(auction)}
      </EllipsisMenu>
    );
  }

  return (
    <TableRow
      key={auction.external_id || auction.atlas_external_id}
      isDragging={isDragging}
      isOver={isOver}
      ref={dragRef}
      isHidden={planning.hidden}
    >
      {isHideable && renderHideShowIcon(auction)}

      {isDraggable && (
        <AuctionDraggableStyle isDragging={isDragging}>
          <img src={dragGridIcon} />
          <AuctionImage
            src={auction.payload?.attachments?.[0]?.urls?.[0]?.small}
          />
        </AuctionDraggableStyle>
      )}
      {auction.attachment_url ? (
        <td>
          <AuctionImage src={auction.attachment_url} />
        </td>
      ) : (
        auctionStatusSquare(auction, showCampaignStatus, campaign)
      )}

      <td>
        {auction.payload?.slug || auction.external_id ? (
          <StyledLink
            to={{
              pathname: auction.atlas_external_id
                ? `${TITAN_WEBSITE_URL_PREFIX}/${auction.payload?.slug || ''}`
                : `${VAVATO_WEBSITE_URL_PREFIX}/${auction.external_id}`,
            }}
            target="_blank"
          >
            {auction.external_id || auction.atlas_external_id}
          </StyledLink>
        ) : (
          'EXT'
        )}
      </td>
      <td>
        {auction.payload?.slug || auction.external_id ? (
          <StyledLink
            to={{
              pathname: auction.atlas_external_id
                ? `${TITAN_WEBSITE_URL_PREFIX}/${auction.payload?.slug || ''}`
                : `${VAVATO_WEBSITE_URL_PREFIX}/${auction.external_id}`,
            }}
            className={needsReviewClass('name_translations')}
            target="_blank"
          >
            {nameTranslation(auction, 'en')}
          </StyledLink>
        ) : (
          nameTranslation(auction, 'en')
        )}
      </td>
      <td className={needsReviewClass('start_date')}>
        {moment(auction.start_date).format(SIMPLE_DATE_FORMAT)}
      </td>
      <td className={needsReviewClass('end_date')}>
        {moment(auction.end_date).format(SIMPLE_DATE_FORMAT)}
      </td>
      <td>{auction.payload?.release_location?.country}</td>
      <td className={lotsCountClassName(auction, lotsCount)}>{lotsCount}</td>
      <td>
        {formatCurrencyRounded(auction.payload?.lowest_opening_bid).toString()}
      </td>
      <td>
        {auction.first_lot_with_payload?.payload?.seller_entity
          ?.profileable_name || auction.payload?.company_name}
      </td>
      <td>
        {auction.payload?.delivery_type
          ? t(
              `constants.delivery_type.${formatDeliveryType(
                auction.payload?.delivery_type,
              )}`,
            )
          : ''}
      </td>
      <td>{auction.ad_status && <AdStatusLabel auction={auction} />}</td>
      <td>{auction.status && <AuctionStatusLabel auction={auction} />}</td>
      {isDeletable && auction.planning?.id ? (
        <td>{renderDeleteLink()}</td>
      ) : (
        <EllipsisTd
          onClick={e => {
            openMenu(e);
          }}
          ref={ellipsisTdRef}
        >
          {auction.status !== 'new_auction' && (
            <FontAwesomeIcon icon={faEllipsisV} color="#aaa" size="2x" />
          )}
          {openEllipsisMenu && renderEllipsisMenu()}
        </EllipsisTd>
      )}
      <td>{openConfirmationModal && renderConfirmationModal()}</td>
    </TableRow>
  );
}

AuctionTableRow.propTypes = {
  isHideable: PropTypes.bool,
  auction: PropTypes.object,
  isDraggable: PropTypes.bool,
  isDragging: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isOver: PropTypes.bool,
  dragRef: PropTypes.any,
  showCampaignStatus: PropTypes.bool,
  campaign: PropTypes.object,
  planning: PropTypes.object,
  setSetupLoading: PropTypes.func,
};

export default AuctionTableRow;
