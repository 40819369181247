import React, { Suspense } from 'react';
import { Router } from 'react-router-dom';
import { ToasterManager } from 'vavato-ui';
import { ThemeProvider } from 'styled-components';
import { QueryClientProvider } from 'react-query';

import './lib/i18n';
import Theme from './lib/theme';
import Routes from './lib/routes';
import history from './lib/history';
import queryClient from './lib/queryClient';
import Loader from './components/common/LoaderOverrided';
import { AuthenticationProvider } from './hooks/contexts/AuthenticationContext';
import { FeatureProvider } from './hooks/contexts/FeatureContext';
import { isDevEnvironment } from './lib/envHelper';

function App() {
  const favicon = document.getElementById('favicon');

  if (isDevEnvironment() && !favicon?.href?.includes('dev')) {
    favicon.href = 'static/favicon-dev.png';
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={Theme}>
        <ToasterManager>
          <Router history={history}>
            <Suspense fallback={<Loader show={true} />}>
              <AuthenticationProvider>
                <FeatureProvider>
                  <Routes />
                </FeatureProvider>
              </AuthenticationProvider>
            </Suspense>
          </Router>
        </ToasterManager>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
