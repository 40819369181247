import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Table } from 'vavato-ui';
import AuctionTableRow from './AuctionTableRow';
import DraggableAuctionTableRow from './DraggableAuctionTableRow';
import { AUCTIONS_LIST_AD_STATUS_TABLE_COLUMNS } from '../../lib/constants';

const TableContainerStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: scroll;
  padding-bottom: 10px;
  margin-right: 20px;
  margin-left: 20px;
  overflow-x: hidden;
  max-height: ${props => (props.isDraggable ? '' : 'calc(100vh - 250px)')};
`;

const TableHeadRow = styled.tr`
  height: 32px !important;
  td {
    font-size: 10px !important;
  }
`;

function AuctionsListTableContent({
  auctions,
  isHideable,
  isDraggable,
  isDeletable,
  showCampaignStatus,
  campaign,
  onDrop,
  plannings,
  setSetupLoading,
}) {
  const { t } = useTranslation();
  function tableHead() {
    let columns = AUCTIONS_LIST_AD_STATUS_TABLE_COLUMNS;

    if (isDraggable) {
      columns = ['', ...columns];
    }
    if (isHideable) {
      columns = ['', ...columns];
    }

    return (
      <TableHeadRow>
        {columns.map((column, index) => {
          return (
            <td key={`${column}_${index}`}>
              {t(column ? `auction_list.table.${column}` : '')}
            </td>
          );
        })}
      </TableHeadRow>
    );
  }

  function renderTableRow(auction, index) {
    let planning;
    if (!auction.id && auction.planning) {
      planning = auction.planning;
    } else {
      planning =
        (plannings &&
          plannings.find(planning => {
            return planning.auction_id === auction.id;
          })) ||
        {};
    }

    return isDraggable ? (
      <DraggableAuctionTableRow
        setSetupLoading={setSetupLoading}
        planning={planning}
        isHideable={isHideable}
        key={auction.external_id || auction.atlas_external_id}
        auction={auction}
        isDraggable={true}
        onDrop={onDrop}
        showCampaignStatus={showCampaignStatus}
        campaign={campaign}
      />
    ) : (
      <AuctionTableRow
        setSetupLoading={setSetupLoading}
        planning={planning}
        key={auction.external_id || auction.atlas_external_id || index}
        auction={auction}
        isDraggable={false}
        isDeletable={isDeletable}
        showCampaignStatus={showCampaignStatus}
        campaign={campaign}
      />
    );
  }

  return (
    <TableContainerStyle isDraggable={isDraggable}>
      <Table>
        <thead>{tableHead()}</thead>
        <tbody>
          {auctions &&
            auctions.map((auction, index) => {
              return auction && renderTableRow(auction, index);
            })}
        </tbody>
      </Table>
    </TableContainerStyle>
  );
}

AuctionsListTableContent.propTypes = {
  auctions: PropTypes.array,
  isDraggable: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isHideable: PropTypes.bool,
  showCampaignStatus: PropTypes.bool,
  campaign: PropTypes.object,
  onDrop: PropTypes.func,
  plannings: PropTypes.array,
  setSetupLoading: PropTypes.func,
};

export default AuctionsListTableContent;
