import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { darken } from 'polished';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatURLParams } from '../../lib/formatter';
import ModalDialog from '../common/ModalDialog';
import cancelIcon from '../../assets/icons/cancel.svg';
import RadioInput from '../common/RadioInput';
import {
  CAMPAIGN_REPORT_FORMATS,
  PARAM_DATE_FORMAT,
} from '../../lib/constants';
import DatePicker from '../common/DatePicker';
import moment from 'moment-timezone';

const REPORTS_URL = `${process.env.REACT_APP_API_URL}reports/mailings`;

const RowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 20px;
`;

const HeaderStyle = styled(RowStyle)`
  width: 600px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const CancelIconStyle = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const ReportFormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowTitleStyle = styled.span`
  color: ${props => props.theme.text};
  margin: 0 10px;
  font-size: 14px;
`;
const InputsContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    min-width: 120px;
  }
`;

const InputRowStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
`;

const FooterStyle = styled(RowStyle)`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  justify-content: flex-end;
`;

const RightFooterStyle = styled.div`
  margin-right: -20px;
`;

const PreviewLinkStyle = styled.a`
  cursor: pointer;
  text-transform: uppercase;
  color: ${props => props.theme.primary};

  :hover {
    color: ${props => darken(0.2, props.theme.primary)};
    text-decoration: none;
  }
`;

function CampaignReportsModal({ setOpenReportsModal, baseDate }) {
  const { t } = useTranslation();

  const [dateFrom, setDateFrom] = useState(
    moment(baseDate).startOf('isoWeek').format(PARAM_DATE_FORMAT),
  );
  const [dateTo, setDateTo] = useState(
    moment(baseDate).endOf('isoWeek').format(PARAM_DATE_FORMAT),
  );
  const [format, setFormat] = useState('csv');

  function generateReport(event) {
    event.preventDefault();
    const url = `${REPORTS_URL}.${format}`;
    const params = { date_from: dateFrom, date_to: dateTo };
    const queryParams = formatURLParams(params);
    window.open(url + queryParams, '_blank');
  }

  return (
    <ModalDialog
      onClose={() => {
        setOpenReportsModal(false);
      }}
    >
      <HeaderStyle>
        <span>{t('campaigns.campaign_reports')}</span>
        <CancelIconStyle
          src={cancelIcon}
          onClick={() => {
            setOpenReportsModal(false);
          }}
        />
      </HeaderStyle>
      <ReportFormContainer>
        <InputsContainerStyle>
          <InputRowStyle>
            <RowTitleStyle>{t('campaigns.report.format')}</RowTitleStyle>
            {CAMPAIGN_REPORT_FORMATS.map(option => (
              <RadioInput
                key={`campaigns.report.${option}`}
                name={`campaigns.report.${option}`}
                option={t(`campaigns.report.${option}`)}
                checked={format === option}
                onCheck={() => {
                  setFormat(option);
                }}
              />
            ))}
          </InputRowStyle>
          <InputRowStyle>
            <RowTitleStyle>{t('campaigns.report.from')}</RowTitleStyle>
            <DatePicker date={dateFrom} setDate={setDateFrom} />
            <RowTitleStyle>{t('campaigns.report.to')}</RowTitleStyle>
            <DatePicker date={dateTo} setDate={setDateTo} min={dateFrom} />
          </InputRowStyle>
        </InputsContainerStyle>

        <FooterStyle>
          <RightFooterStyle>
            <PreviewLinkStyle onClick={generateReport}>
              {t('campaigns.generate_report')}
            </PreviewLinkStyle>
          </RightFooterStyle>
        </FooterStyle>
      </ReportFormContainer>
    </ModalDialog>
  );
}

CampaignReportsModal.propTypes = {
  setOpenReportsModal: PropTypes.func,
  baseDate: PropTypes.string,
};

export default CampaignReportsModal;
