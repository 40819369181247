import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: flex;
  height: 40px;
  font-size: 0.8rem;
  position: relative;
  justify-content: flex-end;
  color: ${props => rgba(props.theme.text, 0.4)};

  & > svg {
    top: 12px;
    height: 17px;
    margin-left: 12px;
    position: absolute;
    margin-right: calc(100% - 28px);
  }
`;

const Input = styled.input.attrs(() => {
  'text';
})`
  flex: none;
  width: 100%;
  font-size: 0.8rem;
  border-radius: 5px;
  padding: 8px 16px 8px 36px;
  border: 2px solid ${props => rgba(props.theme.text, 0.4)};

  &::placeholder {
    color: ${props => rgba(props.theme.text, 0.4)};
  }

  &:focus,
  &:active {
    outline: none;
    border: 2px solid ${props => rgba(props.theme.text, 0.7)};
  }
`;

function CustomSearchField(props) {
  const [searchValue, setSearchValue] = useState(props.defaultValue);

  function onKeyPress(e) {
    if (e.key === 'Enter') {
      if (!props.onEnter) {
        return;
      }

      props.onEnter(searchValue);
    }
  }

  return (
    <Container>
      <FontAwesomeIcon icon={faSearch} />
      <Input
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        onChange={e => setSearchValue(e.target.value)}
        onKeyPress={onKeyPress}
      />
    </Container>
  );
}

CustomSearchField.propTypes = {
  onEnter: PropTypes.func,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default CustomSearchField;
