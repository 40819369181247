import { darken } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';

import filterIcon from '../../assets/icons/filter.svg';
import StatusLabel from '../common/StatusLabel';
import Checkbox from '../common/CustomCheckbox';
import { AUCTION_STATUS } from '../../lib/constants';

const StatusFilterContainerStyle = styled.div`
  display: flex;
  height: auto;
  position: relative;
  flex-direction: column;
`;

const StatusFilterStyle = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.secondary};

  :active {
    background-color: ${props => darken(0.1, props.theme.secondary)};
  }
`;

const StatusFilterMenuStyle = styled.ul`
  display: flex;
  top: 40px;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
  padding: 1rem;
  position: absolute;
  list-style: none;
  border-radius: 3px;
  padding-left: 0.5rem;
  flex-direction: column;
  box-shadow: 0px 0px 8px rgba(137, 150, 159, 0.3);
  background-color: ${props => props.theme.cardBackground};
`;

const StatusFilterIconStyle = styled.img``;

const StatusFilterLabelStyle = styled.div`
  min-width: 130px;
`;

const StatusFilterSubmitStyle = styled.a`
  margin-top: 0.75rem;
  font-weight: 300;
  text-align: center;
  cursor: pointer;

  :hover {
    color: ${props => darken(0.2, props.theme.text)};
    text-decoration: none;
  }
`;

function AuctionStatusFilter({ auctionStatus, setAuctionStatus }) {
  const [open, setOpen] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [allStatus, setAllStatus] = useState(false);

  useEffect(() => {
    if (auctionStatus.length > 0) {
      setStatuses(auctionStatus);
      setAllStatus(false);
    } else {
      setAllStatus(true);
    }
  }, [auctionStatus]);

  function addStatus(status) {
    setStatuses(prevStatuses => [...prevStatuses, status]);
  }

  function removeStatus(status) {
    setStatuses(prevStatuses =>
      prevStatuses.filter(prevStatus => prevStatus !== status),
    );
  }

  function onSelect(status) {
    if (status === 'all_status') {
      setStatuses([]);
      setAllStatus(prevAllStatus => !prevAllStatus);
      return;
    }

    setAllStatus(false);
    if (statuses.includes(status)) {
      removeStatus(status);
    } else {
      addStatus(status);
    }
  }

  function onFilter() {
    setOpen(false);
    setAuctionStatus(statuses);
  }

  return (
    <StatusFilterContainerStyle>
      <StatusFilterStyle onClick={() => setOpen(prevOpen => !prevOpen)}>
        <StatusFilterIconStyle data-testid={'status-filter'} src={filterIcon} />
      </StatusFilterStyle>
      {open ? (
        <StatusFilterMenuStyle>
          {AUCTION_STATUS.map(status => {
            let isSelected = statuses.includes(status);
            if (status === 'all_status' && allStatus) {
              isSelected = true;
            }

            return (
              <Checkbox
                key={status}
                value={isSelected}
                onChange={() => onSelect(status)}
              >
                <StatusFilterLabelStyle>
                  <StatusLabel key={status} status={status} />
                </StatusFilterLabelStyle>
              </Checkbox>
            );
          })}
          <StatusFilterSubmitStyle onClick={onFilter}>
            ok
          </StatusFilterSubmitStyle>
        </StatusFilterMenuStyle>
      ) : null}
    </StatusFilterContainerStyle>
  );
}

AuctionStatusFilter.propTypes = {
  auctionStatus: PropTypes.array,
  setAuctionStatus: PropTypes.func,
};

export default AuctionStatusFilter;
