import moment from 'moment-timezone';
import { CATEGORY_CAMPAIGN_SUBSTRING } from '../lib/constants';

const CAMPAIGN_TITLE_SCHEDULE_FORMAT = 'HH:mm';

class Campaign {
  constructor(args) {
    this.id = null;
    this.replica_of_id = null;
    this.scheduled_send_time = null;
    this.campaign_title = null;
    this.plannings = [];
    this.url = [];
    this.mailer_schedule_status = null;
    this.mail_content_error = false;
    this.mailchimp_ids = {};
    this.mail_template = null;
    this.mail_subtitle = null;
    this.mail_subject = null;
    this.mailchimp_segment = null;
    this.black_friday_banner = null;
    this.integration_error = null;
    this.campaign_utm_tags = {};
    this.facebook_integration_error = {};

    if (args) Object.assign(this, args);
  }

  /**
   *
   * @param {Object} campaignTypeData
   */
  static groupComplementaryCampaigns(campaignList) {
    if (!campaignList) return [];
    let complementaryIds = [];
    const groupedCampaignList = [];

    // The key is the id of the grouped campaign
    // The value is the campaign's id that grouped the other campaigns
    const facebookGroupedCampaigns = {};

    campaignList.forEach(campaign => {
      let campaignObject = {};
      if (
        complementaryIds.includes(campaign.id) &&
        (campaign.campaign_type.name === 'Facebook carousel' ||
          campaign.campaign_type.name === 'Instagram carousel')
      ) {
        const parentCampaignIndex = groupedCampaignList.findIndex(
          el => el.id === facebookGroupedCampaigns[campaign.id],
        );
        groupedCampaignList[parentCampaignIndex].facebook_integration_error = {
          ...groupedCampaignList[parentCampaignIndex]
            .facebook_integration_error,
          [campaign.plannings[0].id]: {
            integration_error: campaign.integration_error,
          },
        };
        return;
      }

      if (complementaryIds.includes(campaign.id)) return;

      complementaryIds = this.pushComplementaryCampaigns(
        complementaryIds,
        campaign,
      );

      (campaign.campaign_type.name === 'Facebook carousel' ||
        campaign.campaign_type.name === 'Instagram carousel') &&
        complementaryIds.forEach(id => {
          if (!facebookGroupedCampaigns[id]) {
            facebookGroupedCampaigns[id] = campaign.id;
          }
        });

      campaignObject = this.fillCampaignFields(campaign);
      groupedCampaignList.push(
        new Campaign({
          ...campaignObject,
          ...((campaign.campaign_type.name === 'Facebook carousel' ||
            campaign.campaign_type.name === 'Instagram carousel') && {
            facebook_integration_error: {
              [campaign.plannings[0].id]: {
                integration_error: campaign.integration_error,
              },
            },
          }),
        }),
      );
    });
    return groupedCampaignList;
  }

  static fillCampaignFields(campaign) {
    const campaignObject = {};
    campaignObject.id = campaign.id;
    campaignObject.replica_of_id = campaign.replica_of_id;
    campaignObject.auctions_order = campaign.auctions_order;
    campaignObject.scheduled_send_time = campaign.scheduled_send_time;
    campaignObject.campaign_title = campaignTitle(campaign);
    campaignObject.campaign_type_name = campaign?.campaign_type?.name;
    campaignObject.plannings = groupComplementaryPlannings(campaign, false);
    campaignObject.external_plannings = groupComplementaryPlannings(
      campaign,
      true,
    );
    campaignObject.url = campaignUrl(campaign);
    campaignObject.mailer_schedule_status = campaign.mailer_schedule_status;
    campaignObject.mail_content_error = campaign.mail_content_error;
    campaignObject.mailchimp_ids = campaign.mailchimp_ids;
    campaignObject.mail_template = campaign.mail_template;
    campaignObject.mail_subtitle = campaign.mail_subtitle;
    campaignObject.mail_subject = campaign.mail_subject;
    campaignObject.mail_preview_text = campaign.mail_preview_text;
    campaignObject.mail_from_name = campaign.mail_from_name;
    campaignObject.campaign_utm_tags = campaign.campaign_utm_tags;
    campaignObject.mailchimp_segment = campaign.mailchimp_segment;
    campaignObject.black_friday_banner = campaign.black_friday_banner;
    campaignObject.integration_error = campaign.integration_error;
    campaignObject.banner_image_url = campaign.banner_image_url;
    campaignObject.banner_destination_link = campaign.banner_destination_link;

    return campaignObject;
  }

  static pushComplementaryCampaigns(complementaryIds, campaign) {
    if (campaign?.complementary_campaign instanceof Array) {
      const ids = campaign?.complementary_campaign.map(x => x.id);
      return [...new Set(complementaryIds.concat(ids))];
    } else {
      const id = campaign?.complementary_campaign?.id;
      return [...new Set(complementaryIds.concat([id]))];
    }
  }

  static isStarting(planning, campaign) {
    const isComplementary = planning.campaign_id !== campaign.id;
    const isStartingCampaign = campaign.campaign_type_name?.includes('start');
    const isClosingCampaign = campaign.campaign_type_name?.includes('close');

    if (isStartingCampaign) return !isComplementary;

    if (isClosingCampaign) return isComplementary;

    const startDate = moment(planning.auction?.start_date);
    const campaignDate = moment(campaign?.scheduled_send_time);

    return campaignDate.diff(startDate, 'minute') <= 0;
  }

  static integrationComplete(campaign) {
    return !!Object.keys(campaign?.mailchimp_ids || {}).length;
  }
}

function campaignTitle(campaign) {
  const scheduleTime = moment(campaign.scheduled_send_time).format(
    CAMPAIGN_TITLE_SCHEDULE_FORMAT,
  );
  let title = `${scheduleTime} - ${campaign?.campaign_type?.name}`;
  if (title.toLowerCase().includes(CATEGORY_CAMPAIGN_SUBSTRING)) {
    title = `${title} - ${campaign.category?.name_translations?.en}`;
  }
  return removeTitleSuffix(title);
}

function removeTitleSuffix(title) {
  return title.replace('start', '').replace('close', '').trim();
}

function groupComplementaryPlannings(campaign, external) {
  const campaignPlannings = external
    ? campaign.external_plannings
    : campaign.plannings;

  let planningsList = [
    ...planningsWithCampaignError(campaignPlannings, campaign),
  ];

  if (campaign?.complementary_campaign instanceof Array) {
    campaign?.complementary_campaign.forEach(complementary => {
      const complementaryPlannings = external
        ? complementary?.external_plannings
        : complementary?.plannings;

      planningsList = planningsList.concat(
        planningsWithCampaignError(complementaryPlannings, complementary),
      );
    });
  } else {
    const complementaryPlannings = external
      ? campaign?.complementary_campaign?.external_plannings
      : campaign?.complementary_campaign?.plannings;

    if (complementaryPlannings) {
      planningsList = planningsList.concat(
        planningsWithCampaignError(
          complementaryPlannings,
          campaign?.complementary_campaign,
        ),
      );
    }
  }
  return sortByClosingStatusAndDate(planningsList, campaign);
}

function planningsWithCampaignError(plannings, campaign) {
  if (!plannings) {
    return [];
  }
  return plannings?.map(p => {
    return { ...p, integration_error: campaign?.integration_error };
  });
}

function sortByClosingStatusAndDate(plannings, campaign) {
  return plannings.sort((p1, p2) => {
    if (Campaign.isStarting(p1, campaign) && !Campaign.isStarting(p2, campaign))
      return 1;
    if (!Campaign.isStarting(p1, campaign) && Campaign.isStarting(p2, campaign))
      return -1;
    return moment(p1.auction?.end_date).diff(moment(p2.auction?.end_date));
  });
}

function campaignUrl(campaign) {
  return process.env.REACT_APP_API_URL + `campaigns/${campaign.id}/newsletter`;
}

export default Campaign;
