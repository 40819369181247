import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SegmentFilter from './SegmentFilter';
import SearchField from '../common/CustomSearchField';
import DatePickerFilter from '../../components/common/DatePickerFilter';
import { useFeature } from '../../hooks/contexts/FeatureContext';
import { Button } from 'vavato-ui';

const HeaderLayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: scroll;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin: 20px;
  flex: 1;
  height: 65px;
  position: relative;
  overflow: visible;
`;

const HeaderLeftContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  color: #575c5a;
  font-size: 16px;
`;

const HeaderRightContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #575c5a;
  font-size: 16px;
`;

const SearchFilter = styled(SearchField)`
  width: 140px;
`;

const SearchContainer = styled.div`
  margin-right: 15px;
`;

const ReportsButton = styled.div`
  overflow: hidden;
  button {
    height: 40px;
    line-height: 0px;
    white-space: nowrap;
  }
`;

const ExternalCampaignButton = styled.div`
  overflow: hidden;
  button {
    height: 40px;
    line-height: 0px;
    white-space: nowrap;
  }
`;

function CampaignListHeader({
  dateFilter,
  setDateFilter,
  searchValue,
  setSearchValue,
  segmentList,
  selectedSegments,
  setSelectedSegments,
  setOpenReportsModal,
  setOpenNewExternalCampaignModal,
}) {
  const { t } = useTranslation();
  const feature = useFeature();

  function addExternalCampaignButton() {
    if (feature?.extra_plannings) {
      return (
        <ExternalCampaignButton>
          <Button
            onClick={() => {
              setOpenNewExternalCampaignModal(true);
            }}
          >
            {t('campaigns.new_external_campaign')}
          </Button>
        </ExternalCampaignButton>
      );
    }
  }

  return (
    <HeaderLayoutContainer>
      <HeaderLeftContainer>
        <DatePickerFilter date={dateFilter} setSelectedDate={setDateFilter} />
      </HeaderLeftContainer>
      <HeaderRightContainer>
        {addExternalCampaignButton()}
        <ReportsButton>
          <Button
            onClick={() => {
              setOpenReportsModal(true);
            }}
          >
            {t('campaigns.campaign_reports')}
          </Button>
        </ReportsButton>
        <SearchContainer>
          <SearchFilter
            placeholder={t('campaigns.search_placeholder')}
            defaultValue={searchValue}
            onEnter={setSearchValue}
          />
        </SearchContainer>
        <SegmentFilter
          segmentList={segmentList}
          selectedSegments={selectedSegments}
          setSelectedSegments={setSelectedSegments}
        />
      </HeaderRightContainer>
    </HeaderLayoutContainer>
  );
}

CampaignListHeader.propTypes = {
  viewMode: PropTypes.string,
  dateFilter: PropTypes.string,
  setDateFilter: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  segmentList: PropTypes.array,
  selectedSegments: PropTypes.array,
  setSelectedSegments: PropTypes.func,
  setOpenReportsModal: PropTypes.func,
  setOpenNewExternalCampaignModal: PropTypes.func,
};

export default CampaignListHeader;
