import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import AuctionTableRow from './AuctionTableRow';

function DraggableAuctionTableRow({
  isHideable,
  auction,
  onDrop,
  campaign,
  showCampaignStatus,
  planning,
  setSetupLoading,
}) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'AUCTION',
    drop: item => {
      onDrop(item.id, auction.id);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'AUCTION',
      item: { id: auction.id },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [auction.id],
  );

  function setRef(ref) {
    drag(ref);
    drop(ref);
  }

  return (
    <AuctionTableRow
      setSetupLoading={setSetupLoading}
      planning={planning}
      isHideable={isHideable}
      dragRef={setRef}
      auction={auction}
      isDraggable={true}
      isDragging={isDragging}
      isOver={isOver}
      campaign={campaign}
      showCampaignStatus={showCampaignStatus}
    />
  );
}

DraggableAuctionTableRow.propTypes = {
  auction: PropTypes.object,
  onDrop: PropTypes.func,
  campaign: PropTypes.object,
  showCampaignStatus: PropTypes.bool,
  isHideable: PropTypes.bool,
  planning: PropTypes.object,
  setSetupLoading: PropTypes.func,
};

export default DraggableAuctionTableRow;
