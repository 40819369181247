import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CampaignInput from './CampaignInput';

const TextContainerStyle = styled.div`
  display: flex;
  margin-right: 1rem;
  align-items: baseline;
`;

const TextSpanStyle = styled.span`
  position: relative;
  left: 28px;
  margin: 8px;
  color: #c4c4c4;
  font-size: 0.8rem;
`;

const TextStyle = styled.input`
  height: 32px;
  width: 100px;
  padding: 12px;
  color: #595a5c;
  font-size: 0.8rem;
  border-radius: 5px;
  padding-left: 24px;
  border: 2px solid #c4c4c4;
`;

function CampaignTextInput({
  index,
  label,
  name,
  type,
  minValue,
  value,
  onChange,
  onBlur,
}) {
  return (
    <CampaignInput name={name}>
      <TextContainerStyle>
        <TextSpanStyle>{label}</TextSpanStyle>
        <TextStyle
          id={`${name}${index}`}
          value={value}
          type={type}
          min={minValue}
          onChange={e => onChange(e.target.value)}
          onBlur={e => onBlur(e.target.value)}
        />
      </TextContainerStyle>
    </CampaignInput>
  );
}

CampaignTextInput.propTypes = {
  index: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  minValue: PropTypes.number,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default CampaignTextInput;
