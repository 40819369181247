import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import BreadcumbsWithLinks from '../common/BreadcumbsWithLinks';
import { nameTranslation } from '../../lib/formatter';
import AuctionStatusLabel from '../auction/AuctionStatusLabel';

const LinksStyle = styled.div`
  margin: 10px 20px 0px 20px;
`;

const LinkStyle = styled(Link)`
  display: inline-block;
  margin: 20px;
`;

const TitleStyle = styled.span`
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  color: #595a5c;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
`;

const AuctionStatusLabelStyled = styled(AuctionStatusLabel)`
  display: inline-block;
  cursor: default;
`;
const StatusContainer = styled.div`
  margin-left: 30px;
  display: inline-block;
  width: 150px;
  text-transform: none !important;
`;

function LayoutHeader({ auction, links, prevLink }) {
  return (
    <>
      <LinksStyle>
        <BreadcumbsWithLinks links={links} />
      </LinksStyle>
      {auction && (
        <TitleStyle>
          <LinkStyle to={prevLink}>&lt;</LinkStyle>
          {`${
            auction.external_id || auction.atlas_external_id
          } - ${nameTranslation(auction, 'en')}`}
          {auction.status === 'needs_review' && (
            <StatusContainer data-testid="needs_review_container">
              <AuctionStatusLabelStyled auction={auction} clickable={false} />
            </StatusContainer>
          )}
        </TitleStyle>
      )}
    </>
  );
}

LayoutHeader.propTypes = {
  auction: PropTypes.object,
  links: PropTypes.array,
  prevLink: PropTypes.string,
};

export default LayoutHeader;
