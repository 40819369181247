import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LabeledInput from '../common/LabeledInput';

const ContainerStyle = styled.div`
  width: 100%;
  display: inline-grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  background-color: ${props => props.theme.lightBackground};
  border-radius: 3px;
`;

const ImageStyle = styled.img`
  width: 170px;
  margin: 1rem;
  border-radius: 3px;
`;

const InputContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 2rem 1rem 0;
`;

function AuctionTitleEdit({ image, titles, onChange }) {
  const inputs = [
    {
      language: 'en',
      placeholder: 'Home furniture',
      value: titles?.en,
    },
    {
      language: 'fr',
      placeholder: 'Meubles de maison',
      value: titles?.fr,
    },
    {
      language: 'nl',
      placeholder: 'Huismeubilair',
      value: titles?.nl,
    },
  ];

  return (
    <ContainerStyle>
      <ImageStyle src={image} />
      <InputContainerStyle>
        {inputs.map(input => (
          <LabeledInput
            key={input.language}
            label={input.language}
            placeholder={input.placeholder}
            value={input.value}
            onChange={e => onChange(input.language, e.target.value)}
          />
        ))}
      </InputContainerStyle>
    </ContainerStyle>
  );
}

AuctionTitleEdit.propTypes = {
  image: PropTypes.string,
  titles: PropTypes.object,
  onChange: PropTypes.func,
};

export default AuctionTitleEdit;
