import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContainerStyle = styled.div`
  position: relative;
  padding: 0;
  margin: 1rem;
  width: 170px;
  border-radius: 3px;
  background-color: ${props => props.theme.lightBackground};

  &:hover {
    box-shadow: 0px 0px 8px rgba(137, 150, 159, 0.3);
  }

  &.highlight {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
`;

const ContainerImageStyle = styled.div`
  width: 100%;
  height: 100px;
  overflow: hidden;
  border-radius: 3px 3px 0 0;
  background: ${props => `url(${props.image})`} no-repeat;
  background-size: auto 100%;
  background-position: center;
  border-bottom: ${props => (props.border ? `1px solid #a29060` : '')};
`;

function Card({ highlight, image, border, children, onChange }) {
  return (
    <ContainerStyle className={highlight ? 'highlight' : ''}>
      <ContainerImageStyle image={image} border={border} onClick={onChange} />
      {children}
    </ContainerStyle>
  );
}

Card.propTypes = {
  highlight: PropTypes.bool,
  image: PropTypes.string,
  border: PropTypes.bool,
  children: PropTypes.any,
  onChange: PropTypes.func,
};

export default Card;
