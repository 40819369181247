import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import queryClient from '../../../lib/queryClient';
import { useParams, useHistory, Link } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';

import { ToasterContext } from 'vavato-ui';
import Loader from '../../../components/common/LoaderOverrided';
import Checkbox from '../../../components/common/CustomCheckbox';
import SelectableCardWithDescription from '../../../components/common/SelectableCardWithDescription';
import LayoutContent from '../../../components/layout/LayoutContent';
import CustomStepper from '../../../components/common/CustomStepper';
import { TODO_MAIL_STEPPER_LABELS } from '../../../lib/constants';
import {
  lotBidSubtitle,
  lotUrl,
  nameTranslation,
} from '../../../lib/formatter';

import {
  useFetchAuctionData,
  useFetchAuctionLotsData,
} from '../../../hooks/services/AuctionAPI';
import { useUpdateLotData } from '../../../hooks/services/LotAPI';

const DEFAULT_LOTS_LIMIT = 30;

const SelectableCardsContainerStyle = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const SelectAllStyle = styled.div`
  margin-left: 0.5rem;
`;

const LoadAllStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

function AuctionSelectLots() {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const toasterContext = useContext(ToasterContext);
  const { error } = toasterContext;

  const links = [
    { path: '/', name: 'home' },
    { path: '/auctions', name: 'auctions_list' },
    { path: `/auctions/${id}/plan`, name: 'auction' },
    { path: '', name: 'todo_mail' },
  ];

  const [dirtyLots, setDirtyLots] = useState({});
  const [auctionLots, setAuctionLots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [setupLoading, setSetupLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [loadAllLots, setLoadAllLots] = useState(false);
  const selectedLots = countSelectedLots(auctionLots);
  const {
    data: auctionData,
    error: auctionError,
    isError: auctionIsError,
  } = useFetchAuctionData(id);

  const {
    data: auctionLotsData,
    error: auctionLotsError,
    isLoading: auctionLotsLoading,
    isError: auctionLotsIsError,
  } = useFetchAuctionLotsData(id, {
    lots_limit: loadAllLots ? '' : DEFAULT_LOTS_LIMIT,
  });

  const { mutateAsync: updateLotMutate } = useUpdateLotData();

  useEffect(() => {
    const auctionLots = auctionLotsData?.data;
    if (auctionLots) {
      const dirtyLotIds = Object.keys(dirtyLots);
      if (dirtyLotIds.length > 0) {
        const newLots = [...auctionLots];
        dirtyLotIds.map(async lotId => {
          // eslint-disable-next-line
          const newLot = newLots.find(auctionLot => auctionLot.id == lotId);
          newLot.highlight = dirtyLots[lotId];
        });
        setAuctionLots(newLots);
      } else {
        setAuctionLots(auctionLots);
      }

      const allSelected = auctionLots.every(lot => lot.highlight);
      if (allSelected) setSelectAll(true);
      setSetupLoading(false);
    }
    // eslint-disable-next-line
  }, [auctionLotsData]);

  async function updateLot(id, params) {
    await updateLotMutate({ id, params });
  }

  async function onSave() {
    if (dirtyLots.length === 0) return true;

    setLoading(true);
    const updatePromisses = Object.keys(dirtyLots).map(async lotId => {
      const params = { highlight: dirtyLots[lotId] };
      await updateLot(lotId, params);
    });

    try {
      await Promise.all(updatePromisses);
      return true;
    } catch (err) {
      error(err?.message);
    } finally {
      setLoading(false);
      queryClient.invalidateQueries(['auctionLots', id]);
    }
  }

  function onChangeHighlight(index, value) {
    const newLots = [...auctionLots];
    const newLot = newLots[index];
    newLot.highlight = value;
    setDirtyLots(prevDirtyLots => {
      return { ...prevDirtyLots, [newLot.id]: value };
    });
    setAuctionLots(newLots);
  }

  function onSelectAll() {
    const select = !selectAll;
    auctionLots.forEach((_lot, index) => {
      onChangeHighlight(index, select);
    });
    setSelectAll(select);
  }

  if (auctionIsError || auctionLotsIsError) {
    error(auctionError?.message || auctionLotsError?.message);
    history.push('/');
  }

  function countSelectedLots() {
    return auctionLots.filter(e => e.highlight).length;
  }

  return (
    <LayoutContent
      auction={auctionData?.data}
      links={links}
      loading={loading}
      saveTitle={t('footer.save')}
      onSave={onSave}
      nextTitle={t('footer.next')}
      nextLink={`/auctions/${id}/todomail/configure_lots`}
      prevLink={`/auctions/${id}/plan`}
      selectedLots={selectedLots}
      defaultSaveAndGo={() => {
        return true;
      }}
    >
      {setupLoading ? (
        <Loader show={true} />
      ) : (
        <>
          <CustomStepper
            activeStep={0}
            steps={TODO_MAIL_STEPPER_LABELS}
            id={id}
          />
          <SelectAllStyle>
            <Checkbox value={selectAll} onChange={onSelectAll}>
              {t('select_all')}
            </Checkbox>
          </SelectAllStyle>
          <SelectableCardsContainerStyle>
            {auctionLots.map((lot, index) => (
              <SelectableCardWithDescription
                key={lot.id}
                highlight={lot.highlight}
                image={lot.payload?.attachments?.[0]?.urls?.[1]?.medium}
                subtitle={lotBidSubtitle(lot)}
                text={`${t('lot')} ${
                  lot.lot_number || lot.atlas_external_id || ''
                } - ${nameTranslation(lot, 'en')}`}
                value={lot.highlight}
                url={lotUrl(auctionData?.data, lot)}
                onChange={() => onChangeHighlight(index, !lot.highlight)}
              />
            ))}
          </SelectableCardsContainerStyle>
          <LoadAllStyle>
            {auctionLotsLoading ? (
              <Loader show={true} />
            ) : (
              !loadAllLots &&
              auctionLots.length === DEFAULT_LOTS_LIMIT && (
                <Link
                  onClick={e => {
                    e.preventDefault();
                    setLoadAllLots(true);
                  }}
                >
                  {t('load_all_lots')}
                </Link>
              )
            )}
          </LoadAllStyle>
        </>
      )}
    </LayoutContent>
  );
}

export default AuctionSelectLots;
