import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs } from 'vavato-ui';

function BreadcumbsWithLinks({ links }) {
  const { t } = useTranslation();

  const allLinksButLast = links.slice(0, -1);
  const lastlink = links.slice(-1)[0];

  return (
    <Breadcrumbs>
      {allLinksButLast.map(link => (
        <Link key={link.path} to={link.path}>
          {t(link.name)}
        </Link>
      ))}
      <span>{t(lastlink.name)}</span>
    </Breadcrumbs>
  );
}

BreadcumbsWithLinks.propTypes = {
  links: PropTypes.array,
};

export default BreadcumbsWithLinks;
