import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ToasterContext, Button } from 'vavato-ui';

import ModalDialog from '../common/ModalDialog';
import cancelIcon from '../../assets/icons/cancel.svg';
import { useCreateExternalCampaign } from '../../hooks/services/CampaignsAPI';
import { useFetchCategoriesData } from '../../hooks/services/CategoriesAPI';
import ConfirmationModal from '../common/ConfirmationModal';

import {
  DELIVERY_TYPE_OPTIONS,
  CAMPAIGN_TABS,
  CAMPAIGN_TYPES,
  CTA_OPTIONS,
  BID_LABEL_OPTIONS,
  CAMPAIGN_TYPE_WITH_LOTS,
} from '../../lib/constants';

import {
  renderColumnLocaleInput,
  renderInlineLocaleInput,
  renderSingleTextInput,
  renderSingleNumberInput,
  renderDateInput,
  renderSelectInput,
  renderUploadInput,
  renderLotsCounterInput,
  validateFields,
  validateLotsFields,
  lotStructure,
  lotImageStructure,
  buildLotsPayload,
  renderUTMTagsCheckbox,
} from '../../lib/externalPlanningHelper';
import moment from 'moment-timezone';

const EditConfigContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.$flexDirection};
  p {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 10px;
    width: 20px;
  }
  input[type='text'] {
    width: 200px;
  }
  input[type='number'] {
    width: 200px;
  }
`;

const ExtraCampaignEditContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.$flexDirection};
  padding-bottom: 3rem;
  overflow-y: auto;
`;

const ExtraCampaignEditLotContainer = styled(ExtraCampaignEditContainer)`
  padding-bottom: 0px;
  border-bottom: 1px solid #c4c4c4;
`;

const RowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 20px;
`;

const HeaderStyle = styled(RowStyle)`
  width: 1040px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const FooterStyle = styled(RowStyle)`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  justify-content: flex-end;
`;

const RightFooterStyle = styled.div`
  margin-right: -20px;
`;

const CancelIconStyle = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const LocaleFieldsDivisor = styled.div`
  height: 0px;
  margin: 10px 0px;
`;

const TabsContainerStyle = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #595a5c;
  margin-bottom: 10px;
  padding-bottom: 5px;
`;

const TabStyle = styled.span`
  margin-right: 30px;
  font-size: 0.8em;
  cursor: pointer;
  color: #aaa;
  &.active {
    color: #595a5c;
  }
`;

function NewExternalCampaignModal({ setOpenNewExternalCampaignModal }) {
  const { t } = useTranslation();
  const { error, success } = useContext(ToasterContext);
  const [image, setImage] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [auctionLotsCount, setauctionLotsCount] = useState(1);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [includeUTMTags, setIncludeUTMTags] = useState(true);

  const startDateDefaultValue = moment().startOf('day');
  const endDateDefaultValue = moment().add(1, 'days').startOf('day');

  const [extraCampaignConfig, setExtraCampaignConfig] = useState({
    campaign_type: CAMPAIGN_TYPES[0],
    cta: CTA_OPTIONS[0],
    delivery_type: DELIVERY_TYPE_OPTIONS[0],
    send_datetime: startDateDefaultValue,
    start_date: startDateDefaultValue,
    end_date: endDateDefaultValue,
  });

  const [extraCampaignLotsConfig, setExtraCampaignLotsConfig] = useState([
    lotStructure(),
  ]);

  const [lotsImages, setLotsImages] = useState([
    lotImageStructure(auctionLotsCount),
  ]);

  const { mutateAsync: saveExternalCampaignMutate } =
    useCreateExternalCampaign();

  const [activeTab, setActiveTab] = useState(CAMPAIGN_TABS[0]);
  const [campaignType, setcampaignType] = useState(CAMPAIGN_TYPES[0].value);

  const { data: categoriesData } = useFetchCategoriesData();

  const categoryOptions = categoriesData?.data.map(category => {
    return {
      id: category.id,
      name: category.name_translations.en,
      value: category.id,
    };
  });

  const requiredSingleFields = [
    'starting_price',
    'lots_count',
    'start_date',
    'end_date',
    'delivery_type',
    'campaign_type',
    'cta',
    'send_datetime',
  ];

  function editExtraCampaignConfig(value, locale, type) {
    if (locale) {
      setExtraCampaignConfig({
        ...extraCampaignConfig,
        [type]: {
          ...extraCampaignConfig?.[type],
          [locale]: value,
        },
      });
    } else {
      setExtraCampaignConfig({
        ...extraCampaignConfig,
        [type]: value,
      });
    }
  }

  function editExtraCampaignLotsConfig(value, locale, type, index) {
    const updatingConfig = [...extraCampaignLotsConfig];

    if (locale) {
      updatingConfig[index][type][locale] = value;
    } else {
      updatingConfig[index][type] = value;
    }

    setExtraCampaignLotsConfig(updatingConfig);
  }

  async function save() {
    setSaveLoading(true);
    if (
      validateFields(
        { ...extraCampaignConfig },
        image,
        CAMPAIGN_TYPE_WITH_LOTS.includes(campaignType),
        requiredSingleFields,
      ) &&
      validateLotsFields(
        { ...extraCampaignLotsConfig },
        [...lotsImages],
        auctionLotsCount,
        CAMPAIGN_TYPE_WITH_LOTS.includes(campaignType),
      )
    ) {
      const params = buildParams(
        { ...extraCampaignConfig },
        [...extraCampaignLotsConfig],
        [...lotsImages],
        image,
      );
      try {
        await saveExternalCampaignMutate(params);

        success(t('campaigns.external_campaign.saved_successfully'));
        setSaveLoading(false);
        setOpenNewExternalCampaignModal(false);
        return true;
      } catch (err) {
        setSaveLoading(false);
        error(err?.response.data.message || err?.message);
      }
    } else {
      setSaveLoading(false);
      error(t('campaigns.external_campaign.missing_fields_error'));
    }
  }

  function buildParams(campaignState, lotsState, lotsImages, image) {
    Object.keys(campaignState).forEach(field => {
      campaignState[field] = campaignState[field]?.value
        ? campaignState[field].value
        : campaignState[field];
    });

    const formData = new FormData();

    const campaign_payload = {
      campaign_type_id: campaignState.campaign_type,
      scheduled_send_time: campaignState.send_datetime,
      category_id: campaignState.category,
    };

    const lots_payload = buildLotsPayload(lotsState);

    const planning_payload = {
      name: null,
      send_datetime: campaignState.send_datetime,
      hidden: false,
      settings: {
        cta: campaignState.cta,
        layout: 1,
        include_utm_tags: includeUTMTags,
        auction: {
          id: '',
          delivery_type: campaignState.delivery_type,
          start_date: campaignState.start_date,
          end_date: campaignState.end_date,
          lots_count: campaignState.lots_count,
          lowest_opening_bid: campaignState.starting_price,
          address: campaignState.release_location,
          image_url: null,
          links: { ...campaignState.link },
          name_translations: { ...campaignState.title },
          lots: lots_payload,
          category_id: campaignState.category,
        },
      },
    };

    lotsImages.forEach(image => {
      formData.append(`lot_images_data[${image.sequence}]`, image.data);
    });

    formData.append('external_campaign', JSON.stringify(campaign_payload));
    formData.append('external_planning', JSON.stringify(planning_payload));
    formData.append('image_data', image);

    return formData;
  }

  function onCampaignTypeSelect(e, name, options) {
    setcampaignType(options[e].value);

    if (isCategoryCampaignType(options[e].value)) {
      setExtraCampaignConfig({
        ...extraCampaignConfig,
        category: categoryOptions[0],
        campaign_type: options[e],
      });
    } else {
      onSelect(e, name, options);
    }
  }

  function onSelect(e, name, options) {
    editExtraCampaignConfig(
      options.find(element => {
        return element.id === e;
      }),
      null,
      name,
    );
  }

  function onSelectForLot(e, name, options, index) {
    editExtraCampaignLotsConfig(
      options.find(element => {
        return element.id === e;
      }),
      null,
      name,
      index,
    );
  }

  function onImageUpload(file) {
    setImage(file);
    setSaveLoading(false);
  }

  function onLotImageUpload(file, index) {
    const updatingLotsImages = [...lotsImages];

    updatingLotsImages[index] = { data: file, sequence: index };
    setLotsImages(updatingLotsImages);
    setSaveLoading(false);
  }

  function onauctionLotsCountChange(e) {
    if (auctionLotsCount < e) {
      setExtraCampaignLotsConfig([...extraCampaignLotsConfig, lotStructure()]);
      setLotsImages([...lotsImages, lotImageStructure(auctionLotsCount)]);
    } else {
      const updatingExtraCampaignLotsConfig = [...extraCampaignLotsConfig];
      const updatingLotsImages = [...lotsImages];

      updatingExtraCampaignLotsConfig.pop();
      updatingLotsImages.pop();

      setExtraCampaignLotsConfig(updatingExtraCampaignLotsConfig);
      setLotsImages(updatingLotsImages);
    }

    setauctionLotsCount(e);
  }

  function isCategoryCampaignType(campaignType) {
    if (campaignType === '8' || campaignType === '9') {
      return true;
    }

    return false;
  }

  function renderCampaignTab(tab) {
    return (
      <TabStyle
        key={tab}
        data-testid={`${tab}-tab`}
        className={activeTab === tab ? 'active' : ''}
        onClick={() => {
          setActiveTab(tab);
        }}
      >
        {t(`campaigns.external_campaign.tabs.${tab}`)}
      </TabStyle>
    );
  }

  function renderLotsConfig(lotConfig, index) {
    return (
      <ExtraCampaignEditLotContainer $flexDirection="row" key={index}>
        {renderUploadInput({
          image: lotsImages[index].data,
          saveLoading: saveLoading,
          setSaveLoading: setSaveLoading,
          onUpload: onLotImageUpload,
          index: index,
          title:
            extraCampaignLotsConfig?.length === 1
              ? `${t('campaigns.external_campaign.image_one')}:`
              : `${t('campaigns.external_campaign.image')}:`,
          lotsLayout: extraCampaignLotsConfig?.length,
        })}
        {renderColumnLocaleInput({
          name: 'title',
          title: `${t('campaigns.external_campaign.title')}:`,
          onChange: editExtraCampaignLotsConfig,
          value: lotConfig?.title || '',
          index: index,
        })}
        {renderColumnLocaleInput({
          name: 'link',
          title: `${t('campaigns.external_campaign.link')}:`,
          onChange: editExtraCampaignLotsConfig,
          value: lotConfig?.link || '',
          index: index,
        })}
        <EditConfigContainer $flexDirection="column" key={index}>
          {renderSelectInput({
            name: 'bid_label',
            options: BID_LABEL_OPTIONS,
            onSelect: onSelectForLot,
            value: lotConfig?.bid_label || '',
            title: `${t('campaigns.external_campaign.bid_label')}:`,
            index: index,
          })}
          {renderSingleNumberInput({
            name: 'bid_price',
            title: `${t('campaigns.external_campaign.bid_price')}:`,
            placeholder: '€',
            onChange: editExtraCampaignLotsConfig,
            value: lotConfig?.bid_price,
            index: index,
          })}
        </EditConfigContainer>
      </ExtraCampaignEditLotContainer>
    );
  }

  function renderNextOrSaveButton() {
    if (
      activeTab === 'campaign' ||
      (activeTab === 'auction' &&
        CAMPAIGN_TYPE_WITH_LOTS.includes(campaignType))
    ) {
      return (
        <Button
          primary
          onClick={() => {
            if (activeTab === 'campaign') {
              setActiveTab('auction');
            } else {
              setActiveTab('lots');
            }
          }}
        >
          {t('campaigns.next_step')}
        </Button>
      );
    } else {
      return (
        <Button
          primary
          onClick={() => {
            save();
          }}
        >
          {t('campaigns.add_external_campaign')}
        </Button>
      );
    }
  }

  function renderConfirmationModal() {
    return (
      <ConfirmationModal
        onConfirm={() => {
          setOpenNewExternalCampaignModal(false);
        }}
        onCancel={() => {
          setOpenConfirmationModal(false);
        }}
        message={t('close_confirmation')}
      />
    );
  }

  return (
    <ModalDialog
      onClose={() => {
        setOpenConfirmationModal(true);
      }}
    >
      <HeaderStyle>
        <span> {t('campaigns.add_external_campaign')} </span>
        <CancelIconStyle
          src={cancelIcon}
          onClick={() => {
            setOpenConfirmationModal(true);
          }}
        />
      </HeaderStyle>
      <TabsContainerStyle>
        {CAMPAIGN_TABS.map(tab => {
          return tab === 'lots' &&
            !CAMPAIGN_TYPE_WITH_LOTS.includes(campaignType)
            ? null
            : renderCampaignTab(tab);
        })}
      </TabsContainerStyle>
      {activeTab === 'campaign' && (
        <>
          <ExtraCampaignEditContainer $flexDirection="column">
            <EditConfigContainer>
              {renderSelectInput({
                name: 'campaign_type',
                options: CAMPAIGN_TYPES,
                onSelect: onCampaignTypeSelect,
                value: extraCampaignConfig.campaign_type || '',
                title: `${t('campaigns.external_campaign.campaign_type')}:`,
              })}
            </EditConfigContainer>
            <EditConfigContainer>
              {renderSelectInput({
                name: 'cta',
                options: CTA_OPTIONS,
                onSelect: onSelect,
                value: extraCampaignConfig.cta || '',
                title: `${t('campaigns.external_campaign.cta')}:`,
              })}
            </EditConfigContainer>
            <EditConfigContainer>
              {renderDateInput({
                name: 'send_datetime',
                defaultValue: startDateDefaultValue,
                onChange: editExtraCampaignConfig,
                value: extraCampaignConfig.send_datetime,
                title: `${t('campaigns.external_campaign.send_datetime')}:`,
              })}
            </EditConfigContainer>
            {isCategoryCampaignType(campaignType) && (
              <EditConfigContainer>
                {renderSelectInput({
                  name: 'category',
                  options: categoryOptions,
                  onSelect: onSelect,
                  value: extraCampaignConfig.category || '',
                  title: `${t('campaigns.external_campaign.category')}:`,
                })}
              </EditConfigContainer>
            )}
          </ExtraCampaignEditContainer>
        </>
      )}
      {activeTab === 'auction' && (
        <>
          <ExtraCampaignEditContainer $flexDirection="column">
            {renderInlineLocaleInput({
              name: 'title',
              title: `${t('campaigns.external_campaign.title')}:`,
              onChange: editExtraCampaignConfig,
              value: extraCampaignConfig.title,
              flexDirection: 'row',
            })}
            {renderInlineLocaleInput({
              name: 'link',
              title: `${t('campaigns.external_campaign.link')}:`,
              onChange: editExtraCampaignConfig,
              value: extraCampaignConfig.link,
              flexDirection: 'row',
            })}
            {renderUTMTagsCheckbox({
              value: includeUTMTags,
              onChange: setIncludeUTMTags,
              label: `${t('campaigns.external_auction.include_utm_tags')}`,
              hint: `${t('campaigns.external_auction.utm_tags_hint')}`,
            })}
            <LocaleFieldsDivisor></LocaleFieldsDivisor>
            <EditConfigContainer>
              {renderSingleTextInput({
                name: 'release_location',
                title: `${t('campaigns.external_campaign.release_location')}:`,
                placeholder: 'street, number, city, country',
                onChange: editExtraCampaignConfig,
                value: extraCampaignConfig.release_location,
              })}
              {renderSingleNumberInput({
                name: 'starting_price',
                title: `${t('campaigns.external_campaign.starting_price')}:`,
                placeholder: '€',
                onChange: editExtraCampaignConfig,
                value: extraCampaignConfig.starting_price,
              })}
              {renderSingleNumberInput({
                name: 'lots_count',
                title: `${t('campaigns.external_campaign.lots_count')}:`,
                placeholder: '',
                onChange: editExtraCampaignConfig,
                value: extraCampaignConfig.lots_count,
              })}
            </EditConfigContainer>
            <EditConfigContainer>
              {renderDateInput({
                name: 'start_date',
                defaultValue: startDateDefaultValue,
                onChange: editExtraCampaignConfig,
                value: extraCampaignConfig.start_date,
                title: `${t('campaigns.external_campaign.start_date')}:`,
              })}
              {renderDateInput({
                name: 'end_date',
                defaultValue: endDateDefaultValue,
                onChange: editExtraCampaignConfig,
                value: extraCampaignConfig.end_date,
                title: `${t('campaigns.external_campaign.end_date')}:`,
              })}
              {renderSelectInput({
                name: 'delivery_type',
                options: DELIVERY_TYPE_OPTIONS,
                onSelect: onSelect,
                value: extraCampaignConfig.delivery_type || '',
                title: `${t('campaigns.external_campaign.delivery_type')}:`,
              })}
            </EditConfigContainer>
            {!CAMPAIGN_TYPE_WITH_LOTS.includes(campaignType) && (
              <EditConfigContainer>
                {renderUploadInput({
                  image: image,
                  saveLoading: saveLoading,
                  setSaveLoading: setSaveLoading,
                  onUpload: onImageUpload,
                  title: `${t('campaigns.external_campaign.image')}:`,
                })}
              </EditConfigContainer>
            )}
          </ExtraCampaignEditContainer>
        </>
      )}
      {activeTab === 'lots' && (
        <>
          <ExtraCampaignEditContainer $flexDirection="column">
            {renderLotsCounterInput({
              title: `${t('campaigns.external_campaign.lots')}:`,
              counter: auctionLotsCount,
              setCounter: counter => onauctionLotsCountChange(counter),
            })}
            {extraCampaignLotsConfig.map((lotConfig, index) => {
              return renderLotsConfig(lotConfig, index);
            })}
          </ExtraCampaignEditContainer>
        </>
      )}
      <FooterStyle>
        <RightFooterStyle>{renderNextOrSaveButton()}</RightFooterStyle>
      </FooterStyle>
      {openConfirmationModal && renderConfirmationModal()}
    </ModalDialog>
  );
}

NewExternalCampaignModal.propTypes = {
  campaign: PropTypes.object,
  setOpenNewExternalCampaignModal: PropTypes.func,
  setSetupLoading: PropTypes.func,
  closeCampaignModal: PropTypes.func,
};

export default NewExternalCampaignModal;
