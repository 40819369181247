import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ToasterContext, Button } from 'vavato-ui';

import ModalDialog from '../common/ModalDialog';
import cancelIcon from '../../assets/icons/cancel.svg';
import { formatDate } from '../../lib/dateHelper';
import { useCreateExternalPlanning } from '../../hooks/services/ExternalPlanningsAPI';
import ConfirmationModal from '../common/ConfirmationModal';

import {
  DELIVERY_TYPE_OPTIONS,
  BID_LABEL_OPTIONS,
  EXTERNAL_AUCTION_TABS,
} from '../../lib/constants';
import moment from 'moment-timezone';

import {
  renderColumnLocaleInput,
  renderInlineLocaleInput,
  renderSingleTextInput,
  renderSingleNumberInput,
  renderDateInput,
  renderSelectInput,
  renderUploadInput,
  renderLotsCounterInput,
  validateFields,
  validateLotsFields,
  lotStructure,
  lotImageStructure,
  buildLotsPayload,
  renderUTMTagsCheckbox,
} from '../../lib/externalPlanningHelper';

const EditConfigContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.$flexDirection};
  p {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 10px;
    width: 20px;
  }
  input[type='text'] {
    width: 200px;
  }
  input[type='number'] {
    width: 200px;
  }
`;

const ExtraCampaignEditContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.$flexDirection};
  padding-bottom: 3rem;
  overflow-y: auto;
`;

const ExtraCampaignEditLotContainer = styled(ExtraCampaignEditContainer)`
  padding-bottom: 0px;
  border-bottom: 1px solid #c4c4c4;
`;

const ExtraAuctionEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  overflow-y: auto;
`;

const RowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 20px;
`;

const HeaderStyle = styled(RowStyle)`
  width: 1040px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const FooterStyle = styled(RowStyle)`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  justify-content: flex-end;
`;

const RightFooterStyle = styled.div`
  margin-right: -20px;
`;

const CancelIconStyle = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const LocaleFieldsDivisor = styled.div`
  height: 0px;
  margin: 10px 0px;
`;

const TabsContainerStyle = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #595a5c;
  margin-bottom: 10px;
  padding-bottom: 5px;
`;

const TabStyle = styled.span`
  margin-right: 30px;
  font-size: 0.8em;
  cursor: pointer;
  color: #aaa;
  &.active {
    color: #595a5c;
  }
`;

function AddExternalAuctionToCampaignModal({
  campaign,
  setOpenAddExternalAuctionToCampaignModal,
}) {
  const startDateDefaultValue = moment().startOf('day');
  const endDateDefaultValue = moment().add(1, 'days').startOf('day');

  const { t } = useTranslation();
  const { error, success } = useContext(ToasterContext);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);
  const [includeUTMTags, setIncludeUTMTags] = useState(true);
  const [image, setImage] = useState(null);
  const [activeTab, setActiveTab] = useState(EXTERNAL_AUCTION_TABS[0]);
  const [extraAuctionConfig, setExtraAuctionConfig] = useState({
    start_date: startDateDefaultValue,
    end_date: endDateDefaultValue,
    delivery_type: DELIVERY_TYPE_OPTIONS[0],
  });

  const [auctionLotsCount, setAuctionLots] = useState(1);

  const { mutateAsync: saveExternalAuctionMutate } = useCreateExternalPlanning(
    campaign.id,
  );

  const [extraAuctionLotsConfig, setExtraAuctionLotsConfig] = useState([
    lotStructure(),
  ]);
  const [lotsImages, setLotsImages] = useState([lotImageStructure]);

  const requiredSingleFields = [
    'starting_price',
    'lots_count',
    'start_date',
    'end_date',
    'delivery_type',
  ];

  function editExtraAuctionConfig(value, locale, type) {
    if (locale) {
      setExtraAuctionConfig({
        ...extraAuctionConfig,
        [type]: {
          ...extraAuctionConfig?.[type],
          [locale]: value,
        },
      });
    } else {
      setExtraAuctionConfig({
        ...extraAuctionConfig,
        [type]: value,
      });
    }

    if (type === 'link' && includeUTMTags && value.includes('?')) {
      error(t('campaigns.external_auction.invalid_url'));
    }
  }

  function editExtraAuctionLotsConfig(value, locale, type, index) {
    const updatingConfig = [...extraAuctionLotsConfig];

    if (locale) {
      updatingConfig[index][type][locale] = value;
    } else {
      updatingConfig[index][type] = value;
    }

    setExtraAuctionLotsConfig(updatingConfig);
  }

  function isCategoryCampaignType() {
    return campaign.campaign_type_name.toLowerCase().includes('category');
  }

  function onAuctionLotsChange(e) {
    if (auctionLotsCount < e) {
      setExtraAuctionLotsConfig([...extraAuctionLotsConfig, lotStructure()]);
      setLotsImages([...lotsImages, lotImageStructure(auctionLotsCount)]);
    } else {
      const updatingextraAuctionLotsConfig = [...extraAuctionLotsConfig];
      const updatingLotsImages = [...lotsImages];

      updatingextraAuctionLotsConfig.pop();
      updatingLotsImages.pop();

      setExtraAuctionLotsConfig(updatingextraAuctionLotsConfig);
      setLotsImages(updatingLotsImages);
    }

    setAuctionLots(e);
  }

  function onSelect(e, name, options) {
    editExtraAuctionConfig(
      options.find(element => {
        return element.id === e;
      }),
      null,
      name,
    );
  }

  function onSelectForLot(e, name, options, index) {
    editExtraAuctionLotsConfig(
      options.find(element => {
        return element.id === e;
      }),
      null,
      name,
      index,
    );
  }

  function onImageUpload(file) {
    setImage(file);
    setSaveLoading(false);
  }

  function onLotImageUpload(file, index) {
    const updatingLotsImages = [...lotsImages];

    updatingLotsImages[index] = { data: file, sequence: index };
    setLotsImages(updatingLotsImages);
    setSaveLoading(false);
  }

  function buildRequestParameters() {
    const formData = new FormData();

    const lots_payload = buildLotsPayload(extraAuctionLotsConfig);

    const payload = {
      name: null,
      send_datetime: null,
      hidden: false,
      settings: {
        include_utm_tags: includeUTMTags,
        cta: 'bid_now',
        layout: 1,
        auction: {
          id: '',
          delivery_type: extraAuctionConfig.delivery_type.name.replaceAll(
            ' ',
            '_',
          ),
          start_date: extraAuctionConfig.start_date,
          end_date: extraAuctionConfig.end_date,
          lots_count: extraAuctionConfig.lots_count,
          lowest_opening_bid: extraAuctionConfig.starting_price,
          address: extraAuctionConfig.release_location,
          image_url: null,
          links: { ...extraAuctionConfig.link },
          name_translations: { ...extraAuctionConfig.title },
          lots: lots_payload,
        },
      },
    };

    lotsImages.forEach(image => {
      formData.append(`lot_images_data[${image.sequence}]`, image.data);
    });

    formData.append('external_planning', JSON.stringify(payload));
    formData.append('campaign_id', campaign.id);
    formData.append('image_data', image);

    return formData;
  }

  async function save() {
    setSaveLoading(true);
    if (
      validateFields(
        { ...extraAuctionConfig },
        image,
        isCategoryCampaignType(),
        requiredSingleFields,
      ) &&
      validateLotsFields(
        { ...extraAuctionLotsConfig },
        lotsImages,
        auctionLotsCount,
        isCategoryCampaignType(),
      )
    ) {
      const params = buildRequestParameters();
      try {
        await saveExternalAuctionMutate(params);

        success(t('campaigns.external_auction.saved_successfully'));
        setOpenAddExternalAuctionToCampaignModal(false);
        return true;
      } catch (err) {
        setSaveLoading(false);
        error(err?.message);
      }
    } else {
      setSaveLoading(false);
      error(t('campaigns.external_auction.missing_fields_error'));
    }
  }

  function renderCampaignTab(tab) {
    return (
      <TabStyle
        key={tab}
        data-testid={`${tab}-tab`}
        className={activeTab === tab ? 'active' : ''}
        onClick={() => {
          setActiveTab(tab);
        }}
      >
        {t(`campaigns.external_auction.tabs.${tab}`)}
      </TabStyle>
    );
  }

  function renderNextOrSaveButton() {
    if (activeTab === 'auction' && isCategoryCampaignType()) {
      return (
        <Button
          primary
          onClick={() => {
            setActiveTab('lots');
          }}
        >
          {t('campaigns.next_step')}
        </Button>
      );
    } else {
      return (
        <Button
          primary
          onClick={() => {
            save();
          }}
        >
          {t('campaigns.add_external_auction')}
        </Button>
      );
    }
  }

  function renderLotsConfig(lotConfig, index) {
    return (
      <ExtraCampaignEditLotContainer $flexDirection="row" key={index}>
        {renderUploadInput({
          image: lotsImages[index].data,
          saveLoading: saveLoading,
          setSaveLoading: setSaveLoading,
          onUpload: onLotImageUpload,
          index: index,
          title:
            extraAuctionLotsConfig?.length === 1
              ? `${t('campaigns.external_auction.image_one')}:`
              : `${t('campaigns.external_auction.image')}:`,
          lotsLayout: extraAuctionLotsConfig?.length,
        })}
        {renderColumnLocaleInput({
          name: 'title',
          title: `${t('campaigns.external_auction.title')}:`,
          onChange: editExtraAuctionLotsConfig,
          value: lotConfig?.title || '',
          index: index,
        })}
        {renderColumnLocaleInput({
          name: 'link',
          title: `${t('campaigns.external_auction.link')}:`,
          onChange: editExtraAuctionLotsConfig,
          value: lotConfig?.link || '',
          index: index,
        })}
        <EditConfigContainer $flexDirection="column" key={index}>
          {renderSelectInput({
            name: 'bid_label',
            options: BID_LABEL_OPTIONS,
            onSelect: onSelectForLot,
            value: lotConfig?.bid_label || '',
            title: `${t('campaigns.external_auction.bid_label')}:`,
            index: index,
          })}
          {renderSingleNumberInput({
            name: 'bid_price',
            title: `${t('campaigns.external_auction.bid_price')}:`,
            placeholder: '€',
            onChange: editExtraAuctionLotsConfig,
            value: lotConfig?.bid_price,
            index: index,
          })}
        </EditConfigContainer>
      </ExtraCampaignEditLotContainer>
    );
  }

  function renderConfirmationModal() {
    return (
      <ConfirmationModal
        onConfirm={() => {
          setOpenAddExternalAuctionToCampaignModal(false);
        }}
        onCancel={() => {
          setOpenConfirmationModal(false);
        }}
        message={t('close_confirmation')}
      />
    );
  }

  return (
    <ModalDialog
      onClose={() => {
        setOpenConfirmationModal(true);
      }}
    >
      <HeaderStyle>
        <span>
          {formatDate(campaign.scheduled_send_time)} - {campaign.campaign_title}{' '}
          - {t('campaigns.add_external_auction')}
        </span>
        <CancelIconStyle
          src={cancelIcon}
          onClick={() => {
            setOpenConfirmationModal(true);
          }}
        />
      </HeaderStyle>
      <TabsContainerStyle>
        {EXTERNAL_AUCTION_TABS.map(tab => {
          return tab === 'lots' && !isCategoryCampaignType()
            ? null
            : renderCampaignTab(tab);
        })}
      </TabsContainerStyle>
      {activeTab === 'auction' && (
        <ExtraAuctionEditContainer>
          {renderInlineLocaleInput({
            name: 'title',
            title: `${t('campaigns.external_auction.title')}:`,
            onChange: editExtraAuctionConfig,
            value: extraAuctionConfig.title,
            flexDirection: 'row',
          })}
          {renderInlineLocaleInput({
            name: 'link',
            title: `${t('campaigns.external_auction.link')}:`,
            onChange: editExtraAuctionConfig,
            value: extraAuctionConfig.link,
            flexDirection: 'row',
          })}
          {renderUTMTagsCheckbox({
            value: includeUTMTags,
            onChange: setIncludeUTMTags,
            label: `${t('campaigns.external_auction.include_utm_tags')}`,
            hint: `${t('campaigns.external_auction.utm_tags_hint')}`,
          })}
          <LocaleFieldsDivisor></LocaleFieldsDivisor>
          <EditConfigContainer>
            {renderSingleTextInput({
              name: 'release_location',
              title: `${t('campaigns.external_auction.release_location')}:`,
              placeholder: 'street, number, city, country',
              onChange: editExtraAuctionConfig,
              value: extraAuctionConfig.release_location,
            })}
            {renderSingleNumberInput({
              name: 'starting_price',
              title: `${t('campaigns.external_auction.starting_price')}:`,
              placeholder: '€',
              onChange: editExtraAuctionConfig,
              value: extraAuctionConfig.starting_price,
            })}
            {renderSingleNumberInput({
              name: 'lots_count',
              title: `${t('campaigns.external_auction.lots_count')}:`,
              placeholder: '',
              onChange: editExtraAuctionConfig,
              value: extraAuctionConfig.lots_count,
            })}
          </EditConfigContainer>
          <EditConfigContainer>
            {renderDateInput({
              name: 'start_date',
              defaultValue: startDateDefaultValue,
              onChange: editExtraAuctionConfig,
              value: extraAuctionConfig.start_date,
              title: `${t('campaigns.external_auction.start_date')}:`,
            })}
            {renderDateInput({
              name: 'end_date',
              defaultValue: endDateDefaultValue,
              onChange: editExtraAuctionConfig,
              value: extraAuctionConfig.end_date,
              title: `${t('campaigns.external_auction.end_date')}:`,
            })}
            {renderSelectInput({
              name: 'delivery_type',
              options: DELIVERY_TYPE_OPTIONS,
              onSelect: onSelect,
              value: extraAuctionConfig.delivery_type || '',
              title: `${t('campaigns.external_auction.delivery_type')}:`,
            })}
          </EditConfigContainer>
          {!isCategoryCampaignType() && (
            <EditConfigContainer>
              {renderUploadInput({
                image: image,
                saveLoading: saveLoading,
                setSaveLoading: setSaveLoading,
                onUpload: onImageUpload,
                title: `${t('campaigns.external_auction.image')}:`,
              })}
            </EditConfigContainer>
          )}
        </ExtraAuctionEditContainer>
      )}
      {activeTab === 'lots' && isCategoryCampaignType() && (
        <>
          <ExtraCampaignEditContainer $flexDirection="column">
            {renderLotsCounterInput({
              title: `${t('campaigns.external_auction.lots')}:`,
              counter: auctionLotsCount,
              setCounter: counter => onAuctionLotsChange(counter),
            })}
            {extraAuctionLotsConfig.map((lotConfig, index) => {
              return renderLotsConfig(lotConfig, index);
            })}
          </ExtraCampaignEditContainer>
        </>
      )}

      <FooterStyle>
        <RightFooterStyle>{renderNextOrSaveButton()}</RightFooterStyle>
      </FooterStyle>
      {openConfirmationModal && renderConfirmationModal()}
    </ModalDialog>
  );
}

AddExternalAuctionToCampaignModal.propTypes = {
  campaign: PropTypes.object,
  setOpenAddExternalAuctionToCampaignModal: PropTypes.func,
  setSetupLoading: PropTypes.func,
  closeCampaignModal: PropTypes.func,
};

export default AddExternalAuctionToCampaignModal;
