import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ModalDialog from '../common/ModalDialog';
import closeIcon from '../../assets/icons/cancel.svg';
import { Button, FormField } from 'vavato-ui';
import { useTranslation } from 'react-i18next';
import Autocomplete from '../common/Autocomplete';
import AudienceInfo from './AudienceInfo';
import Checkbox from '../common/CustomCheckbox';
import moment from 'moment';
import { FACEBOOK_LIMIT_USAGE_WARNING } from '../../lib/constants';

const DEFAULT_PLACEMENTS = {
  facebook: true,
  instagram: false,
};

const ModalHeaderContainer = styled.div`
  width: 60vw;
  min-width: 500px;
  display: flex;
  justify-content: space-between;
`;

const ModalTitle = styled.p`
  display: flex;
  color: #343a40;
  font-size: 21px;
`;

const ModalClose = styled.img`
  cursor: pointer;
`;

const ModalFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  div {
    margin-bottom: 4px !important;
  }
`;

const ModalFooterContainer = styled.div`
  display: flex;
  margin: 1rem 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const PlacementsContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  input {
    padding: 7px;
  }
  label {
    margin-right: 2rem;
    color: #595a5c;
    font-size: 12px;
    font-weight: 700;
  }
`;

const UsageText = styled.span`
  font-size: 12px;
  color: ${props =>
    props.warning ? props.theme.changedText : props.theme.darkText};
`;

const UsageInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
function NewAdSetModal({
  setOpenNewAdSetModal,
  adSets,
  setAdSets,
  auction,
  adCampaignType,
  igAdCampaignType,
  setError,
}) {
  const { t } = useTranslation();

  const [selectedAudience, setSelectedAudience] = useState(null);
  const [adsetName, setAdsetName] = useState('');
  const [placements, setPlacements] = useState(DEFAULT_PLACEMENTS);
  const [limitUsage, setLimitUsage] = useState(null);
  const placementSelected = Object.keys(placements).some(
    key => !!placements[key],
  );

  useEffect(() => {
    if (selectedAudience?.name) {
      const date = moment(auction?.start_date).format('YYYYMMDD');
      setAdsetName(
        `${date}_${auction?.external_id || auction?.atlas_external_id}_${
          selectedAudience?.name
        }`,
      );
    } else {
      setAdsetName('');
    }
  }, [selectedAudience, auction]);

  function changePlacement(key) {
    const placementsUpdate = { ...placements };
    Object.keys(placementsUpdate).forEach(key => {
      placementsUpdate[key] = false;
    });
    placementsUpdate[key] = true;
    setPlacements(placementsUpdate);
  }

  function addNewAdset() {
    const updatedAdsets = [...adSets];
    const campaignType = placements.instagram
      ? igAdCampaignType
      : adCampaignType;
    updatedAdsets.push({
      id: null,
      name: adsetName,
      placements: placements,
      audience: selectedAudience,
      campaign_type_id: campaignType.id,
    });
    setAdSets(updatedAdsets);
    setOpenNewAdSetModal(false);
  }

  return (
    <ModalDialog
      onClose={() => {
        setOpenNewAdSetModal(false);
      }}
    >
      <ModalHeaderContainer>
        <ModalTitle>{t('auction_planning.adset.new_adset')}</ModalTitle>
        <ModalClose
          src={closeIcon}
          onClick={() => {
            setOpenNewAdSetModal(false);
          }}
        />
      </ModalHeaderContainer>
      <ModalFormContainer>
        <FormField name="audience" label="Audience">
          <Autocomplete
            onSelect={setSelectedAudience}
            placeholder={t('auction_planning.adset.audience.placeholder')}
            url={'/campaign_types/search_audiences'}
            selected={selectedAudience}
            disabled={!!selectedAudience}
            setLimitUsage={setLimitUsage}
            setError={setError}
          />
        </FormField>
        {selectedAudience && <AudienceInfo audience={selectedAudience} />}

        <FormField name="placement" label="Placement">
          <PlacementsContainer>
            {Object.keys(placements).map(key => {
              return (
                <Checkbox
                  key={`placement_${key}`}
                  value={placements[key]}
                  onChange={() => {
                    changePlacement(key);
                  }}
                >
                  {t(`auction_planning.adset.placements.${key}`)}
                </Checkbox>
              );
            })}
          </PlacementsContainer>
        </FormField>

        <FormField name="name" label="Name">
          <input
            disabled={!selectedAudience}
            type="text"
            value={adsetName}
            onChange={e => setAdsetName(e.target.value)}
            placeholder={t('auction_planning.adset.name_placeholder')}
          />
        </FormField>
      </ModalFormContainer>
      <ModalFooterContainer>
        <Button
          className="outline"
          onClick={() => {
            setOpenNewAdSetModal(false);
          }}
        >
          {t('footer.cancel')}
        </Button>
        {limitUsage?.limit?.toString() && (
          <UsageInfoContainer>
            <UsageText
              warning={limitUsage?.limit > FACEBOOK_LIMIT_USAGE_WARNING}
            >
              {`${t('auction_planning.adset.limit_usage')}: ${
                limitUsage?.limit
              }%`}
            </UsageText>
            {!!limitUsage?.regain_time && (
              <UsageText warning={true}>
                {`${t('auction_planning.adset.regain_time')}: ${
                  limitUsage?.regain_time
                }m`}
              </UsageText>
            )}
          </UsageInfoContainer>
        )}
        <Button
          disabled={!selectedAudience || !placementSelected}
          onClick={() => {
            addNewAdset();
          }}
        >
          {t('footer.create')}
        </Button>
      </ModalFooterContainer>
    </ModalDialog>
  );
}

NewAdSetModal.propTypes = {
  setOpenNewAdSetModal: PropTypes.func,
  adSets: PropTypes.array,
  setAdSets: PropTypes.func,
  auction: PropTypes.object,
  adCampaignType: PropTypes.object,
  igAdCampaignType: PropTypes.object,
  setError: PropTypes.func,
};

export default NewAdSetModal;
