import React from 'react';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const StyledTabs = withStyles({
  root: {
    background: '#F8F9FA',
    padding: '4px',
    height: '48px',
    width: '300px',
  },
  indicator: {
    display: 'none',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})(Tabs);

const StyledTab = withStyles({
  root: {
    display: 'flex',
    minWidth: '91px',
    minHeight: '40px',
    textTransform: 'none',
    '&$selected': {
      color: '#fff',
      background: '#6192A1',
      borderRadius: '3px',
    },
  },
  selected: {},
})(Tab);

function DateTabs({ selectedValue, handleChange, options, translationPath }) {
  const { t } = useTranslation();

  return (
    <StyledTabs value={selectedValue} onChange={handleChange}>
      {options.map(option => {
        return (
          <StyledTab
            key={option}
            label={t(`${translationPath}.${option}`)}
            value={option}
          />
        );
      })}
    </StyledTabs>
  );
}

DateTabs.propTypes = {
  selectedValue: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.array,
  translationPath: PropTypes.string,
};

export default DateTabs;
