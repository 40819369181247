import api from '../../lib/api';
import queryClient from '../../lib/queryClient';
import { useQuery, useMutation } from 'react-query';
import { formatURLParams } from '../../lib/formatter';

export function useFetchAuctionsData(params = {}, config = {}) {
  const queryParams = formatURLParams(params);
  const fetchURL = `auctions${queryParams}`;
  const fetchFunc = async () => await api.get(fetchURL);
  return useQuery(['auction', params], fetchFunc, config);
}

export function useFetchAuctionData(id, planning_id = null, config = {}) {
  const queryParams = formatURLParams({ planning_id });
  const fetchURL = `auctions/${id}${queryParams}`;
  const fetchFunc = async () => await api.get(fetchURL);
  return useQuery(['auction', id, planning_id], fetchFunc, config);
}

export function useUpdateAuctionData(id) {
  return useMutation(
    async params =>
      await api.patch(`auctions/${id}`, {
        auction: params,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auction', id]);
      },
    },
  );
}

export function useFetchAuctionLotsData(id, params = {}, config = {}) {
  const queryParams = formatURLParams(params);
  const fetchURL = `auctions/${id}/lots${queryParams}`;
  const fetchFunc = async () => await api.get(fetchURL);
  return useQuery(['auctionLots', id, params], fetchFunc, config);
}

export function useFetchAuctionCampaignTypesData(id, config = {}) {
  const fetchURL = `auctions/${id}/campaign_types`;
  const fetchFunc = async () => await api.get(fetchURL);
  return useQuery(['auctionCampaignTypes', id], fetchFunc, config);
}

export function useFetchAuctionPlanningsData(id, config = {}) {
  const fetchURL = `auctions/${id}/plannings`;
  const fetchFunc = async () => await api.get(fetchURL);
  return useQuery(['auctionPlannings', id], fetchFunc, config);
}

export function useCreateAuctionPlanningsData(id) {
  return useMutation(
    async params => await api.post(`auctions/${id}/plannings`, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auctionPlannings', id]);
        queryClient.invalidateQueries(['auctionCampaignTypes', id]);
      },
    },
  );
}

export function useUpdateAuctionPlanningsData(auctionId) {
  return useMutation(
    async ({ id, params }) =>
      await api.patch(`auctions/${auctionId}/plannings/${id}`, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auctionPlannings', auctionId]);
      },
    },
  );
}

export function useDeleteAuctionPlanningsData(id) {
  return useMutation(
    async planningId =>
      await api.delete(`auctions/${id}/plannings/${planningId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auctionPlannings', id]);
        queryClient.invalidateQueries(['auctionCampaignTypes', id]);
      },
    },
  );
}

export function useCreateAuctionFbCampaign(id) {
  return useMutation(
    async params => await api.post(`auctions/${id}/create_fb_campaign`, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auction', id, null]);
      },
    },
  );
}

export function useScheduleAllFbPlannings(id) {
  return useMutation(
    async () => await api.patch(`auctions/${id}/fb_schedule_all`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaigns']);
      },
    },
  );
}

export function useUnscheduleAllFbPlannings(id) {
  return useMutation(
    async () => await api.patch(`auctions/${id}/fb_unschedule_all`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaigns']);
      },
    },
  );
}
