import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import infoIcon from '../../assets/icons/info.svg';
import {
  LAYOUT_TYPE_CAROUSEL,
  LAYOUT_TYPE_LIMITED,
  LAYOUT_TYPE_UNLIMITED,
} from '../../lib/constants';
import CampaignLinkAdSets from './CampaignLinkAdSets';

const DropContainerStyle = styled.div`
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 3px;
  border: 1px solid rgba(97, 146, 161, 0.2);
  background-color: ${props => props.theme.lightBackground};
  padding-top: ${props => (props.canDrop ? '10px' : '15px')};
`;

const DropTextContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DropContentStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const DropIconStyle = styled.img`
  margin-right: 10px;
`;

const DraftTextStyle = styled.p`
  margin: 0;
  line-height: 2rem;
  font-size: 0.75rem;
  color: ${props => props.theme.text};
`;

function gridArea(layout) {
  if (layout === 1) {
    return "'one'";
  } else if (layout === 2) {
    return "'one two'";
  } else if (layout === 3) {
    return "'one two''one three'";
  } else if (layout === 4) {
    return "'one two''three four'";
  }
}

function cardSize(layout) {
  if (layout === 1) {
    return { width: 500, height: 500 / 2.0 };
  } else if (layout === 2) {
    return { width: 242, height: 242 / 1.5 };
  } else {
    return { width: 156, height: 156 / 1.5 };
  }
}

const LimitedDropGridStyle = styled.div`
  display: grid;
  gap: 2.5px;
  width: 300px;
  height: 200px;
  grid-template-areas: ${props => gridArea(props.layout)};

  & > div:nth-child(1) {
    grid-area: one;
  }

  & > div:nth-child(2) {
    grid-area: two;
  }

  & > div:nth-child(3) {
    grid-area: three;
  }

  & > div:nth-child(4) {
    grid-area: four;
  }
`;

const UnlimitedDropGridStyle = styled.div`
  width: 495px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  overflow: scroll;

  & > div {
    width: ${props => `${cardSize(props.layout).width}px`};
    height: ${props => `${cardSize(props.layout).height}px`};
    margin: 2.5px;
  }
`;

const CarouselDropGridStyle = styled.div`
  display: flex;
  max-width: 100%;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: flex-start;
  overflow-x: scroll;

  & > div {
    min-width: 156px;
    min-height: 156px;
    margin: 2.5px;
  }
`;

function CampaignDrop({
  layout,
  layoutType,
  canDrop,
  linkAdSet,
  setConfiguration,
  plannings,
  openPlanning,
  children,
  lastEditedLot,
}) {
  const { t } = useTranslation();
  const carouselLotsRef = React.useRef();
  if (
    layoutType === LAYOUT_TYPE_CAROUSEL &&
    lastEditedLot?.lot_index &&
    lastEditedLot?.planning_id === openPlanning &&
    carouselLotsRef.current
  ) {
    const indexNode = carouselLotsRef.current.children[lastEditedLot.lot_index];
    indexNode.scrollIntoView({
      block: 'center',
      inline: 'center',
      behavior: 'smooth',
    });
  }

  function renderLayoutGrid() {
    switch (layoutType) {
      case LAYOUT_TYPE_UNLIMITED:
        return (
          <UnlimitedDropGridStyle layout={layout}>
            {children}
          </UnlimitedDropGridStyle>
        );
      case LAYOUT_TYPE_CAROUSEL:
        return (
          <CarouselDropGridStyle ref={carouselLotsRef} layout={layout}>
            {children}
          </CarouselDropGridStyle>
        );
      case LAYOUT_TYPE_LIMITED:
        return (
          <LimitedDropGridStyle layout={layout}>
            {children}
          </LimitedDropGridStyle>
        );
    }
  }

  return (
    <DropContainerStyle canDrop={canDrop}>
      {canDrop ? (
        <DropContentStyle>
          <DropTextContainerStyle>
            <DropIconStyle src={infoIcon} />
            <DraftTextStyle>{t('campaign_config.drop_info')}</DraftTextStyle>
          </DropTextContainerStyle>
          {layoutType === LAYOUT_TYPE_CAROUSEL && (
            <CampaignLinkAdSets
              plannings={plannings}
              linkAdSet={linkAdSet}
              setLinkAdSet={value => setConfiguration('link', value)}
              openPlanning={openPlanning}
            />
          )}
        </DropContentStyle>
      ) : null}
      {renderLayoutGrid()}
    </DropContainerStyle>
  );
}

CampaignDrop.propTypes = {
  layout: PropTypes.number,
  layoutType: PropTypes.string,
  canDrop: PropTypes.bool,
  linkAdSet: PropTypes.any,
  setConfiguration: PropTypes.func,
  plannings: PropTypes.any,
  children: PropTypes.any,
  lastEditedLot: PropTypes.object,
  openPlanning: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
};

export default CampaignDrop;
