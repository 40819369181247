import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Menu = styled('nav')`
  display: ${props => (props.open ? 'flex' : 'none')};
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  padding: 2px 2px;
  position: absolute;
  min-width: 300px;
  max-height: 150px;
  overflow-y: scroll;
  width: 100%;
  z-index: 3;

  & > ul {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 2px 2px;
    text-indent: 0;
    list-style-type: none;
  }

  & > ul li {
    flex: 1;
    display: flex;
    cursor: pointer;
  }

  & > ul li a,
  & > ul li a:active,
  & > ul li a:visited,
  & > ul li a:hover {
    color: #000;
    text-decoration: none;
    font-size: 12px;
    font-weight: 400;
  }

  & li:hover {
    background: ${props => props.theme.background};
  }
`;

function AutocompleteDropdown({ children, open }) {
  function renderChildren() {
    if (!children) {
      return [];
    }
    if (!children.length) {
      return <li>{children}</li>;
    }
    return children.map(child => (
      <li key={children.indexOf(child)}>{child}</li>
    ));
  }
  return (
    <Menu className="className" open={open}>
      <ul className="unstyled">{renderChildren()}</ul>
    </Menu>
  );
}

AutocompleteDropdown.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.any,
};

export default AutocompleteDropdown;
