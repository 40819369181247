import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Counter } from 'vavato-ui';

const CounterStyle = styled.div`
  svg {
    padding: 1px;
    margin: 4px;
  }

  input {
    background-color: ${props => props.theme.lightBackground};
  }
`;

function CounterInput({ counter, setCounter }) {
  return (
    <CounterStyle>
      <Counter minValue={0} counter={counter} setCounter={setCounter} />
    </CounterStyle>
  );
}

CounterInput.propTypes = {
  counter: PropTypes.number,
  setCounter: PropTypes.func,
};

export default CounterInput;
