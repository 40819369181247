import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { lotBidSubtitle, lotUrl, nameTranslation } from '../../lib/formatter';
import DraggableCardWithDescription from '../common/DraggableCardWithDescription';

const LotsListTitleStyle = styled.p`
  display: inline-block;
  font-weight: 400;
  line-height: 2rem;
  font-size: 0.875rem;
`;

const LotsListContainerStyle = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -1rem;
`;

function CampaignLotsList({ lots, auction }) {
  const { t } = useTranslation();
  return (
    <>
      <LotsListTitleStyle>
        {t('campaign_config.lots_highlighted')}
      </LotsListTitleStyle>
      <LotsListContainerStyle>
        {lots.map(lot => (
          <DraggableCardWithDescription
            id={lot.id}
            key={lot.id.toString()}
            image={lot.payload?.attachments?.[0]?.urls?.[1]?.medium}
            subtitle={lotBidSubtitle(lot)}
            text={nameTranslation(lot, 'en')}
            url={lotUrl(auction, lot)}
          />
        ))}
      </LotsListContainerStyle>
    </>
  );
}

CampaignLotsList.propTypes = {
  lots: PropTypes.array,
  auction: PropTypes.object,
};

export default CampaignLotsList;
