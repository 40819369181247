import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Input = styled.input.attrs({
  type: 'checkbox',
})`
  display: inline-block;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
  width: 16px;
  height: 16px;
  margin: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;

  &:checked {
    border: 1px solid ${props => props.theme.text};
    background-color: ${props => props.theme.secondary};
  }

  &:active {
    background-color: ${props => props.theme.secondary};
    border: 2px solid ${props => props.theme.primary};
  }

  &:disabled {
    background-color: lightGray;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  line-height: 10px;
  top: ${props => (props.inside ? 0 : '')};
  right: ${props => (props.inside ? 0 : '')};
  position: ${props => (props.inside ? 'absolute' : '')};
  margin-left: ${props => (props.child ? '1rem' : 0)};

  & .checkmark-react,
  .exported-component & .checkmark-react {
    background: ${props => props.theme.secondary};
    cursor: pointer;
    position: relative;
    margin-left: -1.8em;
    margin-right: 12px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
  }
`;

const Text = styled.span`
  margin: 0;
  line-height: 1.5rem;
  font-size: ${props => (props.child ? '0.625rem' : '0.75rem')};
`;

function CustomCheckbox(props) {
  const checked = props.value || false;

  function handleChange() {
    if (props.onChange) {
      props.onChange();
    }
  }

  return (
    <Label inside={props.inside} child={props.child}>
      <Input
        disabled={props.disabled}
        checked={checked}
        onChange={e => {
          e.stopPropagation();
          handleChange();
        }}
      />
      {checked ? <span className="checkmark-react">&#x2713;</span> : null}
      <Text child={props.child}>{props.children}</Text>
    </Label>
  );
}

CustomCheckbox.propTypes = {
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.any,
  inside: PropTypes.bool,
  child: PropTypes.bool,
};

export default CustomCheckbox;
