import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ToasterContext, Button } from 'vavato-ui';

import ModalDialog from '../common/ModalDialog';
import cancelIcon from '../../assets/icons/cancel.svg';
import { formatDate } from '../../lib/dateHelper';
import {
  useReplicateCampaign,
  useFetchSegmentsData,
} from '../../hooks/services/CampaignsAPI';
import DropdownMenu from '../../components/common/DropdownMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DEFAULT_SEGMENT, DEFAULT_TIMEZONE } from '../../lib/constants';
import DateTimePicker from '../common/DateTimePickerOverrided';
import { useFeature } from '../../hooks/contexts/FeatureContext';

const RowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 20px;
`;

const HeaderStyle = styled(RowStyle)`
  width: 600px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const FooterStyle = styled(RowStyle)`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  justify-content: flex-end;
`;

const RightFooterStyle = styled.div`
  margin-right: -20px;
`;

const CancelIconStyle = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const LabeledInputStyle = styled.div`
  p {
    min-width: 120px;
  }
`;

const LoaderDiv = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
`;

const InputRowStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px;
  div {
    height: 38px !important;
  }
`;

const InputLabelStyle = styled.div`
  width: 100px;
  font-weight: 700;
  font-size: 0.75rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

function CampaignReplicateModal({
  campaign,
  closeModal,
  setSetupLoading,
  closeCampaignModal,
}) {
  const { t } = useTranslation();
  const { error, success } = useContext(ToasterContext);
  const feature = useFeature();

  const [datetime, setDatetime] = useState(campaign.scheduled_send_time);
  const [selectedSegment, setSelectedSegment] = useState({});

  const { data: segmentsData, isLoading: segmentsIsLoading } =
    useFetchSegmentsData();

  const segments = segmentsData?.data
    ? [DEFAULT_SEGMENT].concat(segmentsData?.data)
    : [];

  const { mutateAsync: replicateCampaignMutate } = useReplicateCampaign(
    campaign.id,
    {
      mailchimp_segment: selectedSegment,
      scheduled_send_time: datetime,
    },
  );

  async function replicateCampaign(e) {
    e.preventDefault();
    try {
      setSetupLoading(true);
      await replicateCampaignMutate();
      success(t('toaster.replicate_successful'));
      closeModal();
      if (feature?.campaign_details_modal) {
        closeCampaignModal();
      }
      setSetupLoading(false);
    } catch (err) {
      error(err.response.data.message);
      setSetupLoading(false);
    }
  }

  function segmentChange(e) {
    const selected =
      e === DEFAULT_SEGMENT.id
        ? {}
        : {
            ...segments.find(segment => segment.id === e),
          };

    setSelectedSegment(selected);
  }

  return (
    <ModalDialog onClose={closeModal}>
      <HeaderStyle>
        <span>
          {formatDate(campaign.scheduled_send_time)} - {campaign.campaign_title}{' '}
          - {t('campaigns.replica')}
        </span>
        <CancelIconStyle src={cancelIcon} onClick={closeModal} />
      </HeaderStyle>
      <LabeledInputStyle>
        <InputRowStyle>
          <InputLabelStyle>
            {t('campaigns.create_replica.segment')}:
          </InputLabelStyle>

          {segmentsIsLoading ? (
            <LoaderDiv>
              <FontAwesomeIcon
                spin
                icon={faSpinner}
                className="circle"
                color="#6092a2"
                size="1x"
              />
            </LoaderDiv>
          ) : (
            <DropdownMenu
              selected={selectedSegment?.id || DEFAULT_SEGMENT.id}
              onSelect={e => {
                segmentChange(e);
              }}
              placeholder={t('campaign_preview.select_segment')}
              options={segments || []}
            />
          )}
        </InputRowStyle>

        <InputRowStyle>
          <InputLabelStyle>
            {t('campaigns.create_replica.datetime')}:
          </InputLabelStyle>
          <DateTimePicker
            key={`datetime_replica`}
            datetime={datetime}
            setDatetime={date => {
              setDatetime(date);
            }}
            timezone={DEFAULT_TIMEZONE}
            onKeyDown={event => {
              event.preventDefault();
            }}
          />
        </InputRowStyle>
      </LabeledInputStyle>
      <FooterStyle>
        <RightFooterStyle>
          <Button primary onClick={replicateCampaign}>
            {t('campaigns.replicate')}
          </Button>
        </RightFooterStyle>
      </FooterStyle>
    </ModalDialog>
  );
}

CampaignReplicateModal.propTypes = {
  campaign: PropTypes.object,
  closeModal: PropTypes.func,
  setSetupLoading: PropTypes.func,
  closeCampaignModal: PropTypes.func,
};

export default CampaignReplicateModal;
