import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const PaginationContainer = styled.div`
  width: inherit;
  position: fixed;
  padding: 10px;
  display: flex;
  justify-content: center;
  bottom: 0;
  background: #fff;
`;

const useStyles = makeStyles({
  root: {
    '& .MuiPaginationItem-root': {
      backgroundColor: '#F8F9FA',
      color: '#595A5C',
    },
    '& .Mui-selected': {
      backgroundColor: '#6192A1',
      color: '#fff',
    },
  },
});

function AuctionsListTablePagination({ page, totalPages, changePage }) {
  const classes = useStyles();

  return (
    <PaginationContainer className={classes.root}>
      <Pagination
        count={totalPages}
        onChange={(_e, page) => {
          changePage(page);
        }}
        page={page}
        shape="rounded"
      />
    </PaginationContainer>
  );
}

AuctionsListTablePagination.propTypes = {
  page: PropTypes.number,
  totalPages: PropTypes.number,
  changePage: PropTypes.func,
};

export default AuctionsListTablePagination;
