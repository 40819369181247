import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import LayoutMenu from './LayoutMenu';
import LayoutPageHeader from './LayoutPageHeader';
import { MENU_LABELS } from '../../lib/constants';

const LayoutContainerStyle = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header header'
    'menu   content';
`;

const LayoutPageHeaderStyle = styled.div`
  grid-area: header;
`;

const LayoutMenuStyle = styled.div`
  grid-area: menu;
`;

const LayoutContentStyle = styled.div`
  width: ${props => (props.open ? 'calc(100vw - 230px)' : '100%')};
  grid-area: content;
`;

function LayoutHeaderWithSideMenu({ children }) {
  const [openMenu, setOpenMenu] = useState(true);
  return (
    <LayoutContainerStyle>
      <LayoutPageHeaderStyle>
        <LayoutPageHeader open={openMenu} setOpen={setOpenMenu} />
      </LayoutPageHeaderStyle>
      <LayoutMenuStyle>
        <LayoutMenu open={openMenu} labels={MENU_LABELS} />
      </LayoutMenuStyle>
      <LayoutContentStyle open={openMenu}>{children}</LayoutContentStyle>
    </LayoutContainerStyle>
  );
}

LayoutHeaderWithSideMenu.propTypes = {
  children: PropTypes.any,
};

export default LayoutHeaderWithSideMenu;
