import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Auction from '../../models/Auction';
import AuctionInfoElement from './AuctionInfoElement';

const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background-color: ${props => props.theme.lightBackground};
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 4px 32px;
  position: relative;
`;

const RowStyle = styled.div`
  width: 100%;
  display: flex;
`;

const LineStyle = styled.hr`
  width: 100%;
  opacity: 0.4;
`;

const InfoContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: flex-start;
`;

function AudienceInfo({ audience }) {
  const { t } = useTranslation();

  return (
    <ContainerStyle>
      <RowStyle>
        <InfoContainer>
          <AuctionInfoElement
            title={t('auction_planning.adset.audience.language')}
            text={Auction.getAudienceLocales(audience)}
          />
        </InfoContainer>
        <InfoContainer>
          <AuctionInfoElement
            title={t('auction_planning.adset.audience.countries')}
            text={audience?.targeting?.geo_locations?.countries?.join(', ')}
          />
        </InfoContainer>
      </RowStyle>
      <LineStyle />
      <RowStyle>
        <InfoContainer>
          <AuctionInfoElement
            title={t('auction_planning.adset.audience.campaigns_using')}
            text={audience?.campaigns_using.toString()}
          />
        </InfoContainer>
      </RowStyle>
    </ContainerStyle>
  );
}

AudienceInfo.propTypes = {
  audience: PropTypes.object,
};

export default AudienceInfo;
