import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EllipsisMenu from '../common/EllipsisMenu';
import AdStatusLabel from './AdStatusLabel';
import Auction from '../../models/Auction';
import {
  VAVATO_WEBSITE_URL_PREFIX,
  SIMPLE_DATE_FORMAT,
  ADSET_SCHEDULE_STATUS_COLORS,
} from '../../lib/constants';
import { nameTranslation, formatCurrencyRounded } from '../../lib/formatter';

const StyledLink = styled(Link)`
  font-weight: 400 !important;
  &.changed {
    color: ${props => props.theme.changedText} !important;
  }
`;

const TableRow = styled.tr`
  height: 46px;
  td {
    font-size: 12px;
    &.empty {
      color: #999;
    }
    &.changed {
      color: ${props => props.theme.changedText};
    }
  }
`;

const AuctionImage = styled.img`
  width: 42px;
  height: 42px;
  margin: 0.5rem;
  object-fit: cover;
  border-radius: 3px;
`;

const StatusSquare = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 1px;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  ${props => {
    return `background: ${ADSET_SCHEDULE_STATUS_COLORS[props.status]};`;
  }};
`;

const EllipsisTd = styled.td`
  svg {
    width: 0.275em !important;
  }
  div {
    right: inherit;
    padding: 8px 16px;
  }
`;

const EllipsisLink = styled(Link)`
  margin: 10px;
  display: flex;
  flex: 1;
  flex-direction: flex-start;
`;

function FacebookAuctionScheduleRow({ auction }) {
  const { t } = useTranslation();
  const lotsCount = auction.payload?.lots_count || 0;
  const ellipsisTdRef = useRef();

  const [openEllipsisMenu, setOpenEllipsisMenu] = useState(false);

  function lotsCountClassName(auction, count) {
    if (count === 0) return 'empty';
    return Auction.hasNeedsReviewChange(auction, 'lots_count') ? 'changed' : '';
  }

  function openNewTabPage(event, path) {
    event.preventDefault();
    window.open(path, '_blank');
  }

  function ellipsisContent(auction) {
    if (auction.status === 'lots_available') {
      return [planLink(auction)];
    } else if (auction.status === 'to_do_mail') {
      return [planLink(auction), todoMailLink(auction)];
    } else {
      return [planLink(auction), todoMailLink(auction), previewLink(auction)];
    }
  }

  function planLink(auction) {
    const url = `/auctions/${
      auction.external_id || auction.atlas_external_id
    }/plan`;
    return (
      <EllipsisLink
        key="plan_link"
        onClick={e => {
          openNewTabPage(e, url);
        }}
        to={url}
      >
        {t('auction_list.shortcuts.plan')}
      </EllipsisLink>
    );
  }

  function todoMailLink(auction) {
    const url = `/auctions/${
      auction.external_id || auction.atlas_external_id
    }/todomail/select_lots`;
    return (
      <EllipsisLink
        key="todo_mail_link"
        onClick={e => {
          openNewTabPage(e, url);
        }}
        to={url}
      >
        {t('auction_list.shortcuts.todo_mail')}
      </EllipsisLink>
    );
  }

  function previewLink(auction) {
    const url = `/auctions/${
      auction.external_id || auction.atlas_external_id
    }/todomail/preview_campaign`;
    return (
      <EllipsisLink
        key="preview_link"
        onClick={e => {
          openNewTabPage(e, url);
        }}
        to={url}
      >
        {t('auction_list.shortcuts.preview')}
      </EllipsisLink>
    );
  }

  function openMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    setOpenEllipsisMenu(!openEllipsisMenu);
  }

  function renderEllipsisMenu() {
    return (
      <EllipsisMenu
        open={openEllipsisMenu}
        closeMenu={() => setOpenEllipsisMenu(false)}
        exceptionRef={ellipsisTdRef}
      >
        {ellipsisContent(auction)}
      </EllipsisMenu>
    );
  }

  return (
    <TableRow key={auction.external_id || auction.atlas_external_id}>
      <td>
        <AuctionImage
          src={auction.payload?.attachments?.[0]?.urls?.[0]?.small}
        />
      </td>
      <td>
        <StatusSquare status={Auction.fbPlanningsScheduleStatus(auction)} />
      </td>
      <td>
        <StyledLink
          to={{
            pathname: `${VAVATO_WEBSITE_URL_PREFIX}/${
              auction.external_id || auction.atlas_external_id
            }`,
          }}
          target="_blank"
        >
          {auction.external_id || auction.atlas_external_id}
        </StyledLink>
      </td>
      <td>
        <StyledLink
          to={{
            pathname: `${VAVATO_WEBSITE_URL_PREFIX}/${
              auction.external_id || auction.atlas_external_id
            }`,
          }}
          target="_blank"
        >
          {nameTranslation(auction, 'en')}
        </StyledLink>
      </td>
      <td>{moment(auction.start_date).format(SIMPLE_DATE_FORMAT)}</td>
      <td>{moment(auction.end_date).format(SIMPLE_DATE_FORMAT)}</td>
      <td>{auction.payload?.release_location?.country}</td>
      <td className={lotsCountClassName(auction, lotsCount)}>{lotsCount}</td>
      <td>
        {formatCurrencyRounded(auction.payload?.lowest_opening_bid).toString()}
      </td>
      <td>
        <AdStatusLabel auction={auction} />
      </td>
      <EllipsisTd
        onClick={e => {
          openMenu(e);
        }}
        ref={ellipsisTdRef}
      >
        {auction.status !== 'new_auction' && (
          <FontAwesomeIcon icon={faEllipsisV} color="#aaa" size="2x" />
        )}
        {openEllipsisMenu && renderEllipsisMenu()}
      </EllipsisTd>
    </TableRow>
  );
}

FacebookAuctionScheduleRow.propTypes = {
  auction: PropTypes.object,
};

export default FacebookAuctionScheduleRow;
