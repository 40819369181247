import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../common/DropdownMenu';

const CampaignLinkAdSetsStyle = styled.div`
  display: flex;
  gap: 8px;
  color: ${props => props.theme.primaryText};
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  align-items: center;
  margin-bottom: 12px;
`;

function CampaignLinkAdSets({
  linkAdSet,
  setLinkAdSet,
  plannings,
  openPlanning,
}) {
  const { t } = useTranslation();

  function parseAdSetOptions() {
    if (!plannings) return [];

    const refAdSets = plannings.filter(
      planning =>
        planning.id !== openPlanning && planning.id === planning.settings.link,
    );
    const parsedAdSets = refAdSets.map(adSet => {
      return { id: adSet.id, name: adSet.name };
    });
    return [{ id: openPlanning, name: '' }, ...parsedAdSets];
  }

  return (
    <CampaignLinkAdSetsStyle data-testid="link_fb_ad_sets">
      {t('campaign_config.link_fb_ad_sets')}
      <DropdownMenu
        selected={linkAdSet}
        onSelect={setLinkAdSet}
        placeholder={t('select_adset')}
        options={parseAdSetOptions()}
      />
    </CampaignLinkAdSetsStyle>
  );
}

CampaignLinkAdSets.propTypes = {
  linkAdSet: PropTypes.any,
  setLinkAdSet: PropTypes.func,
  plannings: PropTypes.any,
  openPlanning: PropTypes.number,
};

export default CampaignLinkAdSets;
