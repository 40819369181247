import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import calendarIcon from '../../assets/icons/campaign-calendar.svg';
import clockIcon from '../../assets/icons/campaign-clock.svg';
import { useTranslation } from 'react-i18next';

const CampaignDateIcon = styled.img`
  display: inline-block;
  margin-right: 9px;
  margin-left: 20px;
`;

const CampaignDateText = styled.span`
  min-width: 120px;
`;

const CampaignTimeText = styled.span`
  width: auto;
`;

function CampaignDatetimeContainer({ label, date, showTime }) {
  const { t } = useTranslation();

  const momentDate = moment(date);
  return (
    <>
      <CampaignDateIcon src={calendarIcon} />
      <CampaignDateText>
        {`${
          label || t('campaign_preview.campaign_date.date')
        } ${momentDate.format('DD/MM/YYYY')}`}
      </CampaignDateText>
      {showTime && (
        <>
          <CampaignDateIcon src={clockIcon} />
          <CampaignTimeText>
            {`${t('campaign_preview.campaign_date.time')} ${momentDate.format(
              'HH:mm',
            )}`}
          </CampaignTimeText>
        </>
      )}
    </>
  );
}

CampaignDatetimeContainer.propTypes = {
  date: PropTypes.string,
  label: PropTypes.string,
  showTime: PropTypes.bool,
  pastCampaign: PropTypes.bool,
};

export default CampaignDatetimeContainer;
