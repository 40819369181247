import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import vectorDown from '../../assets/icons/vector-down.svg';
import vectorRight from '../../assets/icons/vector-right.svg';

const AccordionHeaderStyle = styled.div`
  display: flex;
  align-items: center;
`;

const AccordionTitleStyle = styled.p`
  display: inline-block;
  font-weight: 400;
  line-height: 2rem;
  font-size: 0.875rem;
  cursor: pointer;
`;

const AccordionIconStyle = styled.img`
  display: inline-block;
  margin-right: 1rem;
  cursor: pointer;
`;

function Accordion(props) {
  return (
    <AccordionHeaderStyle>
      <AccordionIconStyle
        src={props.isOpen ? vectorDown : vectorRight}
        onClick={props.setOpen}
      />
      <AccordionTitleStyle data-testid="open-campaign" onClick={props.setOpen}>
        {props.title}
      </AccordionTitleStyle>
    </AccordionHeaderStyle>
  );
}

Accordion.propTypes = {
  title: PropTypes.string,
  setOpen: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default Accordion;
