import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LabeledInputWithWarning from '../common/LabeledInputWithWarning';
import CardWithText from '../common/CardWithText';
import { nameTranslationByType, nameTranslation } from '../../lib/formatter';
import { LOT_TITLE_TYPES } from '../../lib/constants';

const ContainerStyle = styled.div`
  width: 100%;
  display: inline-grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  background-color: ${props => props.theme.lightBackground};
  border-radius: 3px;
`;

const InputContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
`;

const InputLabeledStyle = styled.div`
  width: ${props => props.width};
  display: flex;
  flex-direction: column;
  margin: 1rem 2rem 1rem 0;
`;

function LotTitleEdit({ index, lot, onChange, lotSubtitle, lotUrl }) {
  function inputs(type) {
    return [
      {
        language: 'en',
        placeholder: 'Bamboo artificial tree natural trunk - H. 162cmh',
        value: type
          ? nameTranslationByType(lot, 'en', type)
          : nameTranslation(lot, 'en'),
      },
      {
        language: 'fr',
        placeholder: "Tronc naturel d'arbre artificiel en bambou - H. 162cmh",
        value: type
          ? nameTranslationByType(lot, 'fr', type)
          : nameTranslation(lot, 'fr'),
      },
      {
        language: 'nl',
        placeholder: 'Bamboe kunstboom natuurlijke stam - H. 162cmh',
        value: type
          ? nameTranslationByType(lot, 'nl', type)
          : nameTranslation(lot, 'nl'),
      },
    ];
  }

  function renderLabeledInputs(type) {
    return inputs(type).map(input => (
      <LabeledInputWithWarning
        type={type}
        value={input.value}
        key={input.language}
        label={input.language}
        placeholder={input.placeholder}
        onChange={e => onChange(index, input.language, e.target.value, type)}
      />
    ));
  }

  return (
    <ContainerStyle>
      <CardWithText
        key={lot.id}
        text={lotSubtitle}
        image={lot.payload?.attachments?.[0]?.urls?.[1]?.medium}
        url={lotUrl}
      />
      <InputContainerStyle>
        {LOT_TITLE_TYPES.map(type => (
          <InputLabeledStyle key={type} width={'50%'}>
            {renderLabeledInputs(type)}
          </InputLabeledStyle>
        ))}
      </InputContainerStyle>
    </ContainerStyle>
  );
}

LotTitleEdit.propTypes = {
  lot: PropTypes.object,
  index: PropTypes.number,
  onChange: PropTypes.func,
  lotSubtitle: PropTypes.string,
  lotUrl: PropTypes.string,
};

export default LotTitleEdit;
