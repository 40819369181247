import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const OptionContainerStyle = styled.div`
  height: 100%;
  display: flex;
  padding: 0 0.5rem;
  border-radius: 3px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid rgba(97, 146, 161, 0.2);
  background-color: ${props => props.theme.lightBackground};
`;

const OptionTitleStyle = styled.h3`
  margin: 1rem;
  line-height: 2rem;
  font-weight: 700;
  font-size: 0.875rem;
  color: ${props => props.theme.text};
`;

function CampaignOptions({ name, children }) {
  return (
    <OptionContainerStyle>
      <OptionTitleStyle>{name}:</OptionTitleStyle>
      {children}
    </OptionContainerStyle>
  );
}

CampaignOptions.propTypes = {
  name: PropTypes.string,
  children: PropTypes.any,
};

export default CampaignOptions;
