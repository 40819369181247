import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Loader from '../common/LoaderOverrided';
import LayoutContentHeader from './LayoutContentHeader';
import LayoutContentFooter from './LayoutContentFooter';
import LayoutHeaderWithSideMenu from './LayoutHeaderWithSideMenu';

const LayoutContainerStyle = styled.div`
  min-height: 100vh;
`;

const LayoutContentStyle = styled.div`
  overflow: scroll;
  padding: 1rem 1.5rem;
  height: ${props => (props.hideFooter ? '100vh' : `calc(100vh - 130px)`)};
`;

const LayoutFooterStyle = styled.div`
  position: fixed;
  bottom: 0;
  width: inherit;
  height: 100px;
  padding: 0 20px;
`;

function LayoutContent({
  links,
  loading,
  auction,
  saveTitle,
  onSave,
  nextTitle,
  nextLink,
  prevLink,
  hideFooter,
  children,
  selectedLots,
  defaultSaveAndGo,
}) {
  return (
    <LayoutContainerStyle>
      <LayoutHeaderWithSideMenu>
        <LayoutContentStyle hideFooter={hideFooter}>
          <LayoutContentHeader
            prevLink={prevLink}
            links={links}
            auction={auction}
          />
          {children}
        </LayoutContentStyle>
        {hideFooter ? null : (
          <LayoutFooterStyle>
            <LayoutContentFooter
              saveTitle={saveTitle}
              onSave={onSave}
              nextTitle={nextTitle}
              nextLink={nextLink}
              selectedLots={selectedLots}
              defaultSaveAndGo={defaultSaveAndGo}
            />
          </LayoutFooterStyle>
        )}
        <Loader show={loading} />
      </LayoutHeaderWithSideMenu>
    </LayoutContainerStyle>
  );
}

LayoutContent.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
  auction: PropTypes.object,
  saveTitle: PropTypes.string,
  onSave: PropTypes.func,
  nextTitle: PropTypes.string,
  nextLink: PropTypes.string,
  prevLink: PropTypes.string,
  hideFooter: PropTypes.bool,
  links: PropTypes.array,
  selectedLots: PropTypes.number,
  defaultSaveAndGo: PropTypes.func,
};

export default LayoutContent;
