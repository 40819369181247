import { haveDateInstances } from '../lib/dateHelper';
class CampaignType {
  constructor(args) {
    this.id = null;
    this.name = null;
    this.defaults = null;
    this.schedule = null;
    this.klaviyo_feed_name = null;
    this.klaviyo_template_id = null;

    if (args) Object.assign(this, args);
  }

  static nameByCampaignTypeId(campaignTypes, campaignTypeId) {
    const campaignType = campaignTypes.find(
      campaignType => campaignType.id === campaignTypeId,
    );

    if (!campaignType) return '';

    return campaignType.name;
  }

  /**
   *
   * @param {Object} campaignTypeData
   */
  static fromApiFormat(campaignTypeData) {
    if (!campaignTypeData) return null;

    return new CampaignType({ ...campaignTypeData });
  }

  static fromApiOptions(campaignTypesData, auctionData) {
    const options = [...campaignTypesData];
    return options.map(option => {
      if (option.multiple) {
        const haveDates = haveDateInstances(auctionData, new Date());
        return new CampaignType({ ...option, disabled: !haveDates });
      } else {
        return new CampaignType({ ...option });
      }
    });
  }
}

export default CampaignType;
