import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import styled from 'styled-components';

import CardWithDescription from './CardWithDescription';

const DraggableCardStyle = styled.div`
  opacity: ${props => (props.isDragging ? 0.5 : 1)};
  cursor: ${props => (props.isDragging ? 'move' : 'pointer')};
`;

function DraggableCardWithDescription({
  id,
  image,
  subtitle,
  text,
  url,
  children,
}) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'CARD',
    item: { id },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <DraggableCardStyle ref={drag} isDragging={isDragging}>
      <CardWithDescription
        image={image}
        subtitle={subtitle}
        text={text}
        url={url}
      >
        {children}
      </CardWithDescription>
    </DraggableCardStyle>
  );
}

DraggableCardWithDescription.propTypes = {
  id: PropTypes.number,
  image: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  children: PropTypes.any,
};

export default DraggableCardWithDescription;
