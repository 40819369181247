import api from '../../lib/api';

export const SIGNIN_PATH = `${process.env.REACT_APP_API_URL}admins/auth/sign_in`;

export async function authenticate({ token }) {
  return await api.get('admins/auth', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function revoke({ token }) {
  await api.get('admins/auth/sign_out', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
