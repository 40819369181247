import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { lighten } from 'polished';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Loader from '../common/LoaderOverrided';
import { Button } from 'vavato-ui';

const EditRowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EditBodyStyle = styled(EditRowStyle)`
  margin-left: 1rem;
  gap: 1rem;
`;

const EditImageContainerStyle = styled.div`
  cursor: move;
  overflow: hidden;
  position: relative;
`;

const EmptyImageStyle = styled.div`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  display: flex;
  flex-wrap: wrap;
  align-content: end;
  justify-content: center;
  border: 1px dashed ${props => lighten(0.1, props.theme.tableHeaders)};
`;

const EditImageStyle = styled.div`
  border: 1px dashed ${props => lighten(0.1, props.theme.tableHeaders)};
  background-image: url(${props => props.$bgImage});
  background-attachment: fixed;
  background-size: 100% 100%;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  display: flex;
  flex-wrap: wrap;
  align-content: end;
  justify-content: center;

  &:hover div button {
    opacity: 1 !important;
  }
`;

const EditUploadButtonStyle = styled(Button)`
  z-index: 1;
  position: relative;
  opacity: ${props => props.$opacity};

  & > input[type='file'] {
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 2;
    width: 145px;
    height: 42px;
    cursor: pointer;
    position: absolute;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  & > input[type='file']::-webkit-file-upload-button {
    cursor: pointer;
  }
`;

function CampaignExternalAuctionUpload({
  canvasHeight,
  canvasWidth,
  setLoading,
  loading,
  saveUploadImage,
  uploadImage,
}) {
  const { t } = useTranslation();
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    if (uploadImage) {
      setImageURL(URL.createObjectURL(uploadImage));
    }
  }, [uploadImage]);

  function upload(e) {
    e.preventDefault();

    const file = e.target.files[0];
    if (!file) return;
    setLoading(true);

    saveUploadImage(file);
    setLoading(false);
  }

  return (
    <>
      <EditBodyStyle>
        <EditImageContainerStyle>
          {uploadImage ? (
            <EditImageStyle
              width={canvasWidth}
              height={canvasHeight}
              $bgImage={imageURL}
            >
              <div>
                <EditUploadButtonStyle primary $opacity="0">
                  <input type="file" onChange={e => upload(e)} />
                  <span>{t('image_editor.upload')}</span>
                </EditUploadButtonStyle>
              </div>
            </EditImageStyle>
          ) : (
            <EmptyImageStyle width={canvasWidth} height={canvasHeight}>
              <div>
                <EditUploadButtonStyle primary>
                  <input type="file" onChange={e => upload(e)} />
                  <span>{t('image_editor.upload')}</span>
                </EditUploadButtonStyle>
              </div>
            </EmptyImageStyle>
          )}
        </EditImageContainerStyle>
      </EditBodyStyle>

      {loading ? <Loader show={true} /> : null}
    </>
  );
}

CampaignExternalAuctionUpload.propTypes = {
  canvasHeight: PropTypes.number,
  canvasWidth: PropTypes.number,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  saveUploadImage: PropTypes.func,
  uploadImage: PropTypes.object,
};

export default CampaignExternalAuctionUpload;
