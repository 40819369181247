import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import calendarIcon from '../../assets/icons/calendar.svg';
import calendarArrow from '../../assets/icons/calendar-arrow.svg';
import moment from 'moment-timezone';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';

import { PARAM_DATE_FORMAT } from '../../lib/constants';
import { formatDateRange } from '../../lib/dateHelper';

const ContainerStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 40px;
  z-index: 1;
  align-items: center;
  .range-calendar {
    font-weight: 300;
    .Calendar__day {
      color: #a4a4a4;
      &.-selectedStart {
        border-radius: 5px !important;
        color: #fff;
      }
      &.-selectedEnd {
        border-radius: 5px !important;
        color: #fff;
      }
    }
    .Calendar__weekDays {
      color: #505050;
    }
    .Calendar__monthArrowWrapper {
      &.-right {
        transform: rotate(-180deg);
      }
      &.-left {
        transform: none;
      }
    }
    .Calendar__monthArrow {
      background-image: ${`url(${calendarArrow})`};
    }
  }
`;

const DateTextContainer = styled.span`
  color: #595a5c;
  font-size: 16px;
  margin-left: 16px;
  letter-spacing: 1px;
`;

const DatePickerContainer = styled.input`
  height: 40px;
  width: 40px;
  border-radius: 3px;
  border: none;
  color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6192a1;
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: center;
  cursor: pointer;
  background-image: ${`url(${calendarIcon})`};
  &:focus {
    outline: none;
  }
`;

function formatToDatepicker(date) {
  const momentDate = moment(date, PARAM_DATE_FORMAT);
  return {
    year: momentDate.year(),
    month: momentDate.month() + 1,
    day: momentDate.date(),
  };
}

function renderDatePickerBlock({ ref }) {
  return <DatePickerContainer data-testid="datepicker-input" ref={ref} />;
}

function DatePickerFilter({ date, setSelectedDate }) {
  const [datepickerDate, setDatepickerDate] = useState(
    formatToDatepicker(date),
  );

  function handleChange(value) {
    setDatepickerDate(value);
    if (value) {
      setSelectedDate(
        moment({ ...value, month: value.month - 1 }).format(PARAM_DATE_FORMAT),
      );
    }
  }

  return (
    <>
      <ContainerStyle>
        <DatePicker
          value={datepickerDate}
          onChange={handleChange}
          renderInput={renderDatePickerBlock}
          colorPrimary="#6192A1"
          colorPrimaryLight="#E6ECF2"
          calendarClassName="range-calendar"
        />
        <DateTextContainer>
          {formatDateRange(
            moment(date).startOf('isoWeek'),
            moment(date).endOf('isoWeek'),
          )}{' '}
        </DateTextContainer>
      </ContainerStyle>
    </>
  );
}

DatePickerFilter.propTypes = {
  date: PropTypes.string,
  setSelectedDate: PropTypes.func,
};

export default DatePickerFilter;
