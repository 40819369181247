import styled from 'styled-components';
import { ToasterContext } from 'vavato-ui';
import { useParams, useHistory } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Planning from '../../../models/Planning';
import {
  useFetchAuctionData,
  useFetchAuctionPlanningsData,
} from '../../../hooks/services/AuctionAPI';
import {
  SCREEN_SIZE,
  TODO_MAIL_STEPPER_LABELS,
  PREVIEW_LOCALES,
  TEMPLATE_OPTIONS,
} from '../../../lib/constants';

import { isPastDate } from '../../../lib/dateHelper';
import { useFetchCampaignTypesData } from '../../../hooks/services/CampaignTypesAPI';
import CustomStepper from '../../../components/common/CustomStepper';
import RadioInput from '../../../components/common/RadioInput';
import CampaignDatetimeContainer from '../../../components/campaign/CampaignDatetimeContainer';
import CampaignCurrencyContent from '../../../components/campaign/CampaignCurrencyContent';
import LayoutContent from '../../../components/layout/LayoutContent';
import Accordion from '../../../components/common/Accordion';
import Loader from '../../../components/common/LoaderOverrided';

import infoIcon from '../../../assets/icons/info.svg';
import mobileScreen from '../../../assets/icons/screen-mobile.svg';
import desktopScreen from '../../../assets/icons/screen-desktop.svg';
import mobileScreenActive from '../../../assets/icons/screen-mobile-active.svg';
import desktopScreenActive from '../../../assets/icons/screen-desktop-active.svg';
import { useFetchCampaignPreviewHTML } from '../../../hooks/services/CampaignsAPI';
import DropdownMenu from '../../../components/common/DropdownMenu';
import { useFeature } from '../../../hooks/contexts/FeatureContext';

const RadioInputStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PreviewUpperMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UpperMenuTitle = styled.span`
  color: #595a5c;
  margin-right: 10px;
  font-size: 14px;
`;

const PreviewContainerStyle = styled.div`
  padding-bottom: '20px';
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const PreviewIconsContainerStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const PreviewTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.past_campaign {
    color: #aaa;
  }
`;

const PreviewDetailsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  line-height: 24px;
  color: #343a40;
  letter-spacing: 1px;
  &.past_campaign {
    color: #aaa;
  }
`;

const PreviewInfoStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PreviewInfoIconStyle = styled.img`
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
`;

const PreviewInfoTextStyle = styled.p`
  margin: 0;
  line-height: 2rem;
  font-size: 0.75rem;
  color: ${props => props.theme.text};
`;

const PreviewDeviceIconStyle = styled.img`
  margin: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${props => (props.active ? props.theme.primary : '')};
`;

const PreviewIframeContainerStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  flex-direction: column;

  iframe {
    border-radius: 5px !important;
    border: 1px solid ${props => props.theme.tableHeaders} !important;
  }
`;

const PreviewSizeStyle = styled.div`
  width: 700px;
  text-align: right;
  color: ${props => (props.size >= 102 ? 'rgb(223, 58, 88)' : '#aaa')};
`;

const PreviewIframeStyle = styled.iframe``;

function AuctionPreviewCampaign() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { error, info } = useContext(ToasterContext);
  const encoder = new TextEncoder();
  const feature = useFeature();

  const links = [
    { path: '/', name: 'home' },
    { path: '/auctions', name: 'auctions_list' },
    { path: `/auctions/${id}/plan`, name: 'auction' },
    { path: '', name: 'todo_mail' },
  ];

  const DEFAULT_TEMPLATE_INDEX = feature?.world_cup_campaigns ? 2 : 0;

  const [setupLoading, setSetupLoading] = useState(true);
  const [openPlanning, setOpenPlanning] = useState(null);
  const [plannings, setPlannings] = useState([]);
  const [campaignId, setCampaignId] = useState(null);
  const [locale, setLocale] = useState(PREVIEW_LOCALES[0]);
  const [screenActive, setScreenActive] = useState('desktop');
  const [selectedTemplate, setSelectedTemplate] = useState(
    TEMPLATE_OPTIONS?.[DEFAULT_TEMPLATE_INDEX],
  );

  const templateName = selectedTemplate?.name;

  const iframeRef = React.createRef();

  const {
    data: auctionData,
    error: auctionError,
    isError: auctionIsError,
  } = useFetchAuctionData(id);

  const {
    data: auctionPlanningsData,
    error: auctionPlanningsError,
    isError: auctionPlanningsIsError,
  } = useFetchAuctionPlanningsData(id);

  const {
    data: campaignTypesData,
    error: campaignTypesError,
    isError: campaignTypesIsError,
  } = useFetchCampaignTypesData();

  const { data: campaignPreviewHTML } = useFetchCampaignPreviewHTML(
    campaignId,
    locale,
    screenActive,
    templateName,
    {
      onError: () => {
        setOpenPlanning(null);
        info(t('campaign_preview.error'));
      },
    },
  );

  useEffect(() => {
    if (auctionPlanningsData && campaignTypesData) {
      const planningsData = auctionPlanningsData?.data;
      const formattedPlannings = planningsData.map(planningData =>
        Planning.fromApiFormat(planningData, campaignTypesData?.data),
      );

      setPlannings(formattedPlannings);
      setSetupLoading(false);
    }
    // eslint-disable-next-line
  }, [auctionPlanningsData, campaignTypesData]);

  useEffect(() => {
    if (openPlanning) {
      const planning = plannings.find(planning => planning.id === openPlanning);
      if (!planning) return;

      const campaignId = planning.campaign_id;
      setCampaignId(campaignId);
    }

    // eslint-disable-next-line
  }, [openPlanning]);

  function scrollToAuction() {
    if (!id) return;
    const auctionNode = iframeRef.current.contentWindow.document.getElementById(
      `${id}`,
    );
    auctionNode.scrollIntoView(true);
    iframeRef.current.scrollIntoView(false);
  }

  function calcHtmlSize(html) {
    const size = Math.round(encoder.encode(html).length / 1000);
    return <PreviewSizeStyle size={size}>{`${size}Kb`}</PreviewSizeStyle>;
  }

  if (auctionIsError || auctionPlanningsIsError || campaignTypesIsError) {
    error(
      auctionError?.message ||
        auctionPlanningsError?.message ||
        campaignTypesError?.message,
    );
    history.push('/');
  }

  return (
    <LayoutContent
      auction={auctionData?.data}
      links={links}
      loading={setupLoading}
      hideFooter={true}
      prevLink={`/auctions/${id}/todomail/configure_campaign`}
      defaultSaveAndGo={() => {
        return true;
      }}
    >
      <CustomStepper activeStep={3} steps={TODO_MAIL_STEPPER_LABELS} id={id} />
      <PreviewUpperMenu>
        <RadioInputStyle>
          <UpperMenuTitle>{t('locales.title')}</UpperMenuTitle>
          {PREVIEW_LOCALES.map(option => (
            <RadioInput
              key={`radio.${option}`}
              name={`radio.${option}`}
              option={t(`locales.${option}`)}
              checked={locale === option}
              onCheck={() => {
                setLocale(option);
              }}
            />
          ))}
        </RadioInputStyle>
        <PreviewUpperMenu>
          <UpperMenuTitle>{t('campaign_preview.template')}</UpperMenuTitle>
          <DropdownMenu
            selected={selectedTemplate?.id}
            onSelect={e => {
              setSelectedTemplate({
                ...TEMPLATE_OPTIONS.find(template => template.id === e),
              });
            }}
            placeholder={t('campaign_preview.select_template')}
            options={TEMPLATE_OPTIONS}
          />
        </PreviewUpperMenu>
      </PreviewUpperMenu>

      {plannings.map(planning => {
        const isPastCampaign = isPastDate(
          planning?.campaign?.scheduled_send_time,
        );

        const isOpen = openPlanning === planning?.id;
        function onOpen() {
          const openId = !isOpen ? planning?.id : null;
          setOpenPlanning(openId);
        }

        return (
          <PreviewContainerStyle key={planning?.id}>
            <PreviewTitleContainer
              className={isPastCampaign ? 'past_campaign' : ''}
            >
              <Accordion
                title={Planning.title(planning)}
                isOpen={isOpen}
                setOpen={() => onOpen(planning?.id)}
              />
              <PreviewDetailsContainer
                className={isPastCampaign ? 'past_campaign' : ''}
              >
                {Planning.isAdType(planning) ? (
                  <>
                    <CampaignCurrencyContent
                      amount={planning?.settings?.budget}
                    />
                    <CampaignDatetimeContainer
                      label={t('campaign_preview.campaign_date.start_date')}
                      date={planning?.ad_start_datetime}
                      showTime={false}
                    />
                    <CampaignDatetimeContainer
                      label={t('campaign_preview.campaign_date.end_date')}
                      date={planning?.ad_end_datetime}
                      showTime={false}
                    />
                  </>
                ) : (
                  <CampaignDatetimeContainer
                    date={planning?.campaign?.scheduled_send_time}
                    showTime={true}
                  />
                )}
              </PreviewDetailsContainer>
            </PreviewTitleContainer>

            {planning?.id === openPlanning ? (
              <>
                {Planning.isAdType(planning) && (
                  <PreviewInfoStyle>
                    <PreviewInfoIconStyle src={infoIcon} />
                    <PreviewInfoTextStyle>
                      {t('campaign_preview.ad_delay')}
                    </PreviewInfoTextStyle>
                  </PreviewInfoStyle>
                )}
                <PreviewIconsContainerStyle>
                  <PreviewDeviceIconStyle
                    data-testid="mobile-icon"
                    active={screenActive === 'mobile'}
                    src={
                      screenActive === 'mobile'
                        ? mobileScreenActive
                        : mobileScreen
                    }
                    onClick={() => setScreenActive('mobile')}
                  />
                  <PreviewDeviceIconStyle
                    data-testid="desktop-icon"
                    active={screenActive === 'desktop'}
                    src={
                      screenActive === 'desktop'
                        ? desktopScreenActive
                        : desktopScreen
                    }
                    onClick={() => setScreenActive('desktop')}
                  />
                </PreviewIconsContainerStyle>
                {campaignPreviewHTML?.data ? (
                  <PreviewIframeContainerStyle>
                    {Planning.isAdType(planning) ? (
                      <div
                        data-testid="ad-iframe"
                        dangerouslySetInnerHTML={{
                          __html: campaignPreviewHTML?.data,
                        }}
                      ></div>
                    ) : (
                      <>
                        <PreviewIframeStyle
                          data-testid="mail-iframe"
                          width={SCREEN_SIZE[screenActive]}
                          height={SCREEN_SIZE.desktop}
                          srcDoc={campaignPreviewHTML?.data}
                          ref={iframeRef}
                          onLoad={scrollToAuction}
                        ></PreviewIframeStyle>
                        {calcHtmlSize(campaignPreviewHTML?.data)}
                      </>
                    )}
                  </PreviewIframeContainerStyle>
                ) : (
                  <Loader show={true} />
                )}
              </>
            ) : null}
          </PreviewContainerStyle>
        );
      })}
    </LayoutContent>
  );
}

export default AuctionPreviewCampaign;
