import api from '../../lib/api';
import { useQuery, useMutation } from 'react-query';
import queryClient from '../../lib/queryClient';
import { formatURLParams } from '../../lib/formatter';

export function useFetchCampaignsData(params = {}, config = {}) {
  const queryParams = formatURLParams(params);
  const fetchURL = `campaigns${queryParams}`;
  const fetchFunc = async () => await api.get(fetchURL);
  return useQuery(['campaigns', params], fetchFunc, config);
}

export function useFetchCampaignData(id, config = {}) {
  const fetchURL = `campaigns/${id}`;
  const fetchFunc = async () => await api.get(fetchURL);
  return useQuery(['campaigns', id], fetchFunc, config);
}

export function useFetchGroupedPlanningsData(id, config = {}) {
  const fetchURL = `campaigns/${id}/grouped_plannings`;
  const fetchFunc = async () => await api.get(fetchURL);
  return useQuery(['grouped_plannings', id], fetchFunc, config);
}

export function useScheduleCampaign(id, params = {}) {
  return useMutation(
    async () =>
      await api.patch(`campaigns/${id}/schedule`, { campaign: params }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaigns']);
      },
    },
  );
}

export function useUnscheduleCampaign(id) {
  return useMutation(
    async () => await api.patch(`campaigns/${id}/unschedule`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaigns']);
      },
    },
  );
}

export function useUpdateOnMailerCampaign(id, params) {
  return useMutation(
    async () =>
      await api.patch(`campaigns/${id}/update_on_mailer`, { campaign: params }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaigns']);
      },
    },
  );
}

export function useRecreateIntegrationCampaign(id) {
  return useMutation(
    async () => await api.post(`campaigns/${id}/recreate_integration`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaigns']);
      },
    },
  );
}

export function useFetchCampaignPreviewHTML(
  campaignId,
  locale,
  screenActive,
  template,
  config = {},
) {
  const params = { screen_mode: screenActive, locale, template };
  const queryParams = formatURLParams(params);
  const fetchURL =
    process.env.REACT_APP_API_URL +
    `campaigns/${campaignId}/newsletter${queryParams}`;
  const fetchFunc = async () => {
    if (!campaignId) return;
    return await api.get(fetchURL, { responseType: 'text' });
  };
  return useQuery(
    ['campaignPreviewHTML', { campaignId, locale, screenActive, template }],
    fetchFunc,
    config,
  );
}

export function useFetchCampaignLivePreviewHTML(
  campaignId,
  settings,
  lotsOrder,
  config = {},
) {
  const params = {
    cta: settings.cta,
    copy: settings.copy,
    language: settings.language,
    lots_order: lotsOrder,
  };
  const queryParams = formatURLParams(params);
  const fetchURL =
    process.env.REACT_APP_API_URL +
    `campaigns/${campaignId}/live_preview${queryParams}`;
  const fetchFunc = async () => {
    if (!campaignId) return;
    return await api.get(fetchURL, { responseType: 'text' });
  };
  return useQuery(
    ['campaignLivePreviewHTML', { campaignId, params }],
    fetchFunc,
    config,
  );
}

export function useFetchCampaignUtmTags(id, locale, config = {}) {
  const queryParams = formatURLParams({ locale });
  const fetchURL = `campaigns/${id}/utm_tags${queryParams}`;
  const fetchFunc = async () => await api.get(fetchURL);
  return useQuery(['campaignUtmTags', { id, locale }], fetchFunc, config);
}

export function useFetchSegmentsData(config = {}) {
  const fetchURL = 'campaigns/mail_campaign_segments';
  const fetchFunc = async () => await api.get(fetchURL);
  return useQuery('mail_campaign_segments', fetchFunc, config);
}

export function useReplicateCampaign(id, params = {}) {
  return useMutation(
    async () => await api.post(`campaigns/${id}/replicate`, { ...params }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaigns']);
      },
    },
  );
}

export function useDeleteReplicaCampaign(id) {
  return useMutation(async () => await api.delete(`campaigns/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['campaigns']);
    },
  });
}

export function useCreateExternalCampaign() {
  return useMutation(
    async params =>
      await api.post('campaigns/create_external_campaign', params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaigns']);
      },
    },
  );
}
