import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { VAVATO_AUCTION_STATUS } from '../../lib/constants';
import AuctionVavatoStatus from './AuctionVavatoStatus';
import { useTranslation } from 'react-i18next';

const FilterContainer = styled.div`
  height: 40px;
  font-size: 12px;
  display: flex;
  flex-direction: flex-start;
  background: #f8f9fa;
  div {
    border-right: 1px solid rgba(97, 146, 161, 0.2);
  }
  div:last-child {
    border-right: 0;
  }
`;

const FilterItem = styled.div`
  height: 100%;
  padding: 8px 8px;
  position: relative;
  display: flex;
  flex-direction: flex-start;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  ${props => {
    return props.isSelected ? 'background: #E6ECF2;' : '';
  }};
`;

const ItemSpan = styled.span`
  margin-left: 8px;
`;

function VavatoStatusFilter({ selected, handleChange }) {
  const { t } = useTranslation();

  return (
    <FilterContainer>
      {VAVATO_AUCTION_STATUS.map(status => {
        return (
          <FilterItem
            key={status}
            onClick={() => {
              handleChange('all_auctions'); // TODO: add actual status when add filtering
            }}
            isSelected={selected === status}
          >
            <AuctionVavatoStatus status={status} />
            <ItemSpan>{t(`auction_list.vavato_status.${status}`)}</ItemSpan>
          </FilterItem>
        );
      })}
    </FilterContainer>
  );
}

VavatoStatusFilter.propTypes = {
  selected: PropTypes.string,
  handleChange: PropTypes.func,
};

export default VavatoStatusFilter;
