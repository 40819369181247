import {
  ASPECT_RATIO_BIG,
  AUCTION_STATUS,
  CAMPAIGN_TYPES_EDIT_FIELD,
  CAMPAIGN_TYPE_EDIT_LOT,
  LAYOUT_TYPE_CAROUSEL,
  LAYOUT_TYPE_LIMITED,
  LAYOUT_TYPE_UNLIMITED,
  CAMPAIGN_TYPE_EDIT_AUCTION,
  FACEBOOK_VALIDATION,
  CAMPAIGN_TYPE_SYNC_LOTS,
  DEFAULT_SEGMENT,
} from '../lib/constants';
import { layoutRatio } from '../lib/image';
import i18n from '../lib/i18n';
import { auctionUrl, nameTranslation } from '../lib/formatter';
import { formatFbCopyDate, isPastDate } from '../lib/dateHelper';

const PLANNING_DIRTY_FIELDS = [
  'settings',
  'send_datetime',
  'lots_order',
  'ad_start_datetime',
  'ad_end_datetime',
];

const CREATIVE_DIRTY_FIELDS = ['lots_order', 'name'];

const CREATIVE_DIRTY_SETTINGS = ['language', 'layout', 'cta', 'copy', 'link'];

class Planning {
  constructor(args) {
    this.id = null;
    this.auction_id = null;
    this.campaign_type_name = null;
    this.campaign_type_id = null;
    this.settings = null;
    this.send_datetime = null;
    this.lots_order = null;
    this.multiple = null;
    this.dirtyImages = false;
    this.hidden = false;

    if (args) Object.assign(this, args);
  }

  static mergeSameTypePlannings(plannings, campaignTypeName) {
    let newPlannings = [...plannings];

    const mergedPlannings = plannings.filter(planning =>
      planning.campaign_type_name.includes(campaignTypeName),
    );

    if (mergedPlannings && mergedPlannings.length !== 0) {
      if (mergedPlannings.length > 1) {
        const mergedPlanning = new Planning({ ...mergedPlannings[0] });
        mergedPlanning.start_datetime = mergedPlannings[0].send_datetime;
        mergedPlanning.close_datetime = mergedPlannings[1].send_datetime;
        mergedPlanning.send_datetime = null;

        mergedPlanning.id = [mergedPlannings[0].id, mergedPlannings[1].id];
        mergedPlanning.campaign_type_id = [
          mergedPlannings[0].campaign_type_id,
          mergedPlannings[1].campaign_type_id,
        ];
        mergedPlanning.campaign_type_name =
          mergedPlannings[0].campaign_type_name
            .replace(' start', '')
            .replace(' close', '');

        newPlannings.push(mergedPlanning);
        const mergedPlanningIds = mergedPlannings.map(planning => planning.id);
        newPlannings = newPlannings.filter(
          planning => !mergedPlanningIds.includes(planning.id),
        );
      }
    }
    return newPlannings;
  }

  static mergeHappyUpdatePlannings(plannings) {
    let planningList = [...plannings];

    const toMergePlannings = plannings.filter(planning =>
      planning.campaign_type_name.includes('Happy update'),
    );

    if (toMergePlannings.length > 0) {
      const mergedPlanning = new Planning({ ...toMergePlannings[0] });
      const datetimeObject = {};
      toMergePlannings.forEach(p => {
        datetimeObject[p.id] = p.send_datetime;
      });
      mergedPlanning.send_datetime = datetimeObject;

      mergedPlanning.id = toMergePlannings.map(p => p.id);
      planningList.push(mergedPlanning);
      const mergedPlanningIds = toMergePlannings.map(planning => planning.id);

      planningList = planningList.filter(
        planning => !mergedPlanningIds.includes(planning.id),
      );
    }

    return planningList;
  }

  static unmergeSameTypePlannings(plannings) {
    let newPlannings = [...plannings];

    const mergedPlannings = newPlannings.filter(
      planning =>
        Planning.isMailType(planning) &&
        !planning.send_datetime &&
        (planning.start_datetime || planning.close_datetime),
    );

    mergedPlannings.forEach(mergedPlanning => {
      const firstPlanning = new Planning({ ...mergedPlanning });
      const secondPlanning = new Planning({ ...mergedPlanning });

      firstPlanning.id = mergedPlanning.id[0];
      secondPlanning.id = mergedPlanning.id[1];

      firstPlanning.campaign_type_id = mergedPlanning.campaign_type_id[0];
      secondPlanning.campaign_type_id = mergedPlanning.campaign_type_id[1];

      firstPlanning.send_datetime = mergedPlanning.start_datetime;
      secondPlanning.send_datetime = mergedPlanning.close_datetime;

      delete firstPlanning.start_datetime;
      delete secondPlanning.start_datetime;

      delete firstPlanning.close_datetime;
      delete secondPlanning.close_datetime;

      newPlannings = newPlannings.filter(
        newPlanning => newPlanning !== mergedPlanning,
      );
      newPlannings.push(firstPlanning);
      newPlannings.push(secondPlanning);
    });

    return newPlannings;
  }

  static unmergeHappyUpdatePlannings(plannings, originalPlannings) {
    let planningList = [...plannings];

    const mergedPlannings = planningList.filter(planning =>
      planning.campaign_type_name.includes('Happy update'),
    );

    mergedPlannings.forEach(mergedPlanning => {
      const unmergedPlannings = originalPlannings.filter(
        p => p.campaign.campaign_type_id === mergedPlanning.campaign_type_id,
      );

      planningList = planningList.filter(
        planningList => planningList !== mergedPlanning,
      );

      unmergedPlannings.forEach(planning => {
        if (mergedPlanning?.send_datetime[planning.id]) {
          const planningObject = new Planning({
            ...mergedPlanning,
            id: planning.id,
            send_datetime: mergedPlanning.send_datetime[planning.id],
          });
          planningList.push(planningObject);
        }
      });
    });

    return planningList;
  }

  /**
   *
   * @param {Array} plannings
   */
  static mergeAll(plannings) {
    if (!plannings || plannings.length === 0) return null;

    const originalPlannings = plannings.filter(planning => {
      return !planning.campaign.replica_of_id;
    });

    let mergedPlannings = Planning.mergeSameTypePlannings(
      originalPlannings,
      'Auctions of the day',
    );

    mergedPlannings = Planning.mergeHappyUpdatePlannings(mergedPlannings);

    return mergedPlannings;
  }

  /**
   *
   * @param {Array} plannings
   */
  static sortAll(plannings) {
    if (!plannings || plannings.length === 0) return null;

    return plannings.sort((a, b) => {
      const languageA = this.language(a);
      const languageB = this.language(b);
      if (!languageA || !languageB) return 0;
      return languageB.localeCompare(languageA);
    });
  }

  /**
   *
   * @param {Object} planningData
   */
  static fromApiFormat(planningData, campaignTypesData) {
    if (!planningData) return null;

    const lots_order = planningData.lots_order.map(lot_order =>
      parseInt(lot_order),
    );

    const campaignType = campaignTypesData.find(
      campaignType =>
        campaignType.id === planningData.campaign.campaign_type_id,
    );

    let campaign_type_name = '';
    let campaign_type_id = '';
    let multiple = false;
    let edit_type = CAMPAIGN_TYPE_EDIT_LOT;
    if (campaignType) {
      campaign_type_name = campaignType.name;
      campaign_type_id = campaignType.id;
      multiple = campaignType.multiple;
      edit_type = CAMPAIGN_TYPES_EDIT_FIELD[campaign_type_name];
    }

    return new Planning({
      ...planningData,
      lots_order,
      campaign_type_name,
      campaign_type_id,
      multiple,
      edit_type,
    });
  }

  /**
   *
   * @param {Planning} planning
   */
  static toApiFormat(planning) {
    if (!planning) return null;

    const lots_order = planning.fillLotsOrderArray();

    const params = {
      lots_order,
      settings: { ...planning.settings },
    };

    if (Planning.isAdType(planning)) {
      return {
        ...params,
        ad_start_datetime:
          planning.start_datetime || planning.ad_start_datetime,
        ad_end_datetime: planning.close_datetime || planning.ad_end_datetime,
      };
    }

    return {
      ...params,
      send_datetime: planning.send_datetime,
      auction_status: AUCTION_STATUS[4],
    };
  }

  fillLotsOrderArray() {
    const planningLotsOrderLength = this.lots_order.length;
    const planningLotsOrderRange = [...Array(planningLotsOrderLength).keys()];

    return planningLotsOrderRange.map(lotOrderIndex => {
      const lotOrder = this.lots_order[lotOrderIndex];
      if (lotOrder) {
        return lotOrder.toString();
      }

      return '';
    });
  }

  ratio(model) {
    const layoutType = Planning.layoutType(this.settings);
    const layout = this.settings?.layout;
    const firstLot = this.lots_order?.[0] === model.id;
    return layoutRatio(layoutType, layout, firstLot);
  }

  coverImage(model) {
    if (!model) return;
    if (this.edit_type === CAMPAIGN_TYPE_EDIT_AUCTION) {
      return Planning.checkCoverImage(model, ASPECT_RATIO_BIG);
    }

    const ratio = this.ratio(model);
    return Planning.checkCoverImage(model, ratio);
  }

  swapLotOrder(lotOrder, lotId) {
    const lotsOrder = [...this.lots_order];
    const lotOrderIndex = lotsOrder.findIndex(lot_id => lot_id === lotId);

    if (lotOrderIndex > -1) {
      const oldLotOrder = lotsOrder[lotOrder];
      lotsOrder[lotOrderIndex] = oldLotOrder;
    }

    lotsOrder[lotOrder] = lotId;
    this.lots_order = lotsOrder;
  }

  parsePairedPlannings(plannings) {
    if (!plannings) return [this];

    if (Planning.isAdType(this)) {
      const adSetRef = this.settings.link;
      const adSets = plannings.filter(p => Planning.isAdType(p));
      const pairedAdSets = adSets.filter(
        p => p.id === adSetRef || p.settings.link === adSetRef,
      );
      return pairedAdSets;
    }

    const campaignType = this.campaign_type_name.split(' ')[0];
    const pairedPlannings = plannings.filter(p =>
      p.campaign_type_name.includes(campaignType),
    );
    return pairedPlannings;
  }

  syncLotsOrder(plannings) {
    if (CAMPAIGN_TYPE_SYNC_LOTS.includes(this.campaign_type_name)) {
      const lotsOrder = this.lots_order;
      const layout = this.settings.layout;
      const pairedPlannings = this.parsePairedPlannings(plannings);
      pairedPlannings.forEach(p => {
        p.lots_order = lotsOrder;
        p.settings.layout = layout;
      });
    }
  }

  increaseLotsOrder(lots) {
    const lotsAvailable = lots.filter(lot => !this.lots_order.includes(lot.id));
    const lotsOffset = this.settings.layout - this.lots_order.length;
    if (lotsOffset < 1) return;

    const fillLots = lotsAvailable.slice(0, lotsOffset).map(lot => lot.id);
    this.lots_order = this.lots_order.concat(fillLots);
  }

  decreaseLotsOrder() {
    const reducedLots = this.lots_order.slice(0, this.settings.layout);
    this.lots_order = reducedLots;
  }

  isReference() {
    return Planning.isAdType(this) && this.id === this.settings.link;
  }

  static parsePlanningIds(planningIds, plannings) {
    if (Array.isArray(planningIds)) return planningIds;

    const planning = plannings.find(p => p.id === planningIds);
    if (CAMPAIGN_TYPE_SYNC_LOTS.includes(planning.campaign_type_name)) {
      const pairedPlannings = planning.parsePairedPlannings(plannings);
      return pairedPlannings.map(p => p.id);
    }

    return [planningIds];
  }

  static checkCoverImage(model, ratio) {
    const coverImage = model?.cover_image_url;
    if (coverImage) return coverImage;

    return Planning.presetCoverImage(model, ratio);
  }

  static hasCoverImage(model) {
    if (model?.cover_image_url) return true;
    return false;
  }

  static presetCoverImage(model, ratio) {
    const attachments = model?.attachments_image_url;
    if (!attachments) return;

    const firstAttachment = attachments[0];
    const attachmentId = firstAttachment.attachment_id;
    const coverImageUrl = firstAttachment.url[ratio];
    const coverImageSignedId = firstAttachment.signed_id[ratio];
    return {
      attachment_id: attachmentId,
      url: coverImageUrl,
      signed_id: coverImageSignedId,
    };
  }

  static groupAdPlanningsByAuction(plannings) {
    if (!plannings) return null;
    return { ...plannings?.[0]?.auction, plannings: plannings };
  }

  static isAdType(planning) {
    if (!planning || !planning?.type) return null;
    return (
      planning?.type?.includes('Facebook') ||
      planning?.type?.includes('Instagram')
    );
  }

  static isMailType(planning) {
    if (!planning || !planning?.type) return null;
    return !Planning.isAdType(planning);
  }

  static layoutType(configuration) {
    if (!configuration) return null;
    if (configuration.layout_unlimited) return LAYOUT_TYPE_UNLIMITED;
    if (configuration.layout_carousel) return LAYOUT_TYPE_CAROUSEL;
    return LAYOUT_TYPE_LIMITED;
  }

  static language(planning) {
    if (!planning || !planning.name) return null;
    if (
      planning.settings?.audience_info?.languages
        ?.toLowerCase()
        .includes('dutch')
    )
      return 'nl';
    if (
      planning.settings?.audience_info?.languages
        ?.toLowerCase()
        .includes('french')
    )
      return 'fr';
    if (
      planning.settings?.audience_info?.languages
        ?.toLowerCase()
        .includes('english')
    )
      return 'en';
    if (planning.name.includes('NL')) return 'nl';
    if (planning.name.includes('FR')) return 'fr';
    if (planning.name.includes('EN')) return 'en';
  }

  static title(planning) {
    const planningName = planning?.name;
    const segmentName =
      planning.campaign?.mailchimp_segment?.name_on_campaign ||
      planning.campaign?.mailchimp_segment?.name ||
      DEFAULT_SEGMENT.name;
    const suffix = planning.campaign?.replica_of_id
      ? `(Replica: ${segmentName})`
      : '';

    if (planningName) {
      return `${planning?.campaign_type_name} - ${planningName} ${suffix}`;
    }

    return `${planning?.campaign_type_name} ${suffix}`;
  }

  static copyText(locale, auction, planning) {
    return `${i18n.t(`facebook_campaign.primary_text.${locale}.discover`)} ${
      auction.payload.lots_count
    } ${i18n.t(
      `facebook_campaign.primary_text.${locale}.lots_with`,
    )} ${nameTranslation(auction, locale)} ${i18n.t(
      `facebook_campaign.primary_text.${locale}.place_bid`,
    )}${auction.payload.lowest_opening_bid}${i18n.t(
      `facebook_campaign.primary_text.${locale}.try_luck`,
    )} ${formatFbCopyDate(auction.end_date)} ${i18n.t(
      `facebook_campaign.primary_text.${locale}.via`,
    )} ${auctionUrl(auction, locale)}${Planning.copyUtmTagsSuffix(
      auction,
      planning,
    )}`;
  }

  static copyUtmTagsSuffix(auction, planning) {
    return `?utm_campaign=${
      auction?.external_id || auction?.atlas_external_id
    }&utm_source=${
      planning?.ad_placements?.facebook === true ? 'facebook' : 'instagram'
    }&utm_medium=cpc`;
  }

  static findPlanning(plannings, planningId) {
    return plannings.find(p => {
      if (Array.isArray(p.id) && Array.isArray(planningId))
        return JSON.stringify(p.id) === JSON.stringify(planningId);
      if (Array.isArray(p.id)) return p.id.includes(planningId);
      return p.id === planningId;
    });
  }

  static isLowBudget(plannings) {
    return plannings.some(
      planning =>
        Planning.isAdType(planning) &&
        planning.settings.budget < FACEBOOK_VALIDATION.MIN_BUDGET,
    );
  }

  static isLowLayout(plannings) {
    return plannings.some(
      planning =>
        Planning.isAdType(planning) &&
        planning.settings.layout < FACEBOOK_VALIDATION.MIN_LAYOUT,
    );
  }

  static isHighLayout(plannings) {
    return plannings.some(
      planning =>
        Planning.isAdType(planning) &&
        planning.settings.layout > FACEBOOK_VALIDATION.MAX_LAYOUT,
    );
  }

  static validate(plannings) {
    if (Planning.isLowBudget(plannings))
      return 'facebook_campaign.validate.low_budget';
    if (Planning.isLowLayout(plannings))
      return 'facebook_campaign.validate.low_layout';
    if (Planning.isHighLayout(plannings))
      return 'facebook_campaign.validate.high_layout';
  }

  static checkPastPlannings(planningsData) {
    if (!planningsData) return false;
    return planningsData.some(planning => this.checkPastPlanning(planning));
  }

  static checkPastPlanning(planning) {
    if (this.checkPast(planning.send_datetime)) return true;
    if (this.checkPast(planning.ad_start_datetime)) return true;
    if (this.checkPast(planning.ad_end_datetime)) return true;
    return false;
  }

  static checkPast(field) {
    if (field && isPastDate(field)) return true;
    return false;
  }

  static selectDirtyPlannings(plannings, apiPlannings) {
    return plannings.filter(planning => {
      return this.isDirtyPlanning(planning, apiPlannings);
    });
  }

  static isDirtyPlanning(planning, apiPlannings) {
    if (!this.isAdType(planning)) return true;
    if (!planning.ad_id) return true;
    if (planning.dirtyImages) return true;
    let isDirty = false;
    const refPlanning = apiPlannings.find(p => p.id === planning.id);

    PLANNING_DIRTY_FIELDS.forEach(field => {
      if (planning[field] && diffFields(planning[field], refPlanning[field])) {
        isDirty = true;
      }
    });
    return isDirty;
  }

  static isCreativeDirty(planning, apiPlannings) {
    if (!this.isAdType(planning)) return true;
    if (!planning.ad_id) return true;
    if (planning.dirtyImages) return true;
    let isDirty = false;
    const refPlanning = apiPlannings.find(p => p.id === planning.id);

    CREATIVE_DIRTY_FIELDS.forEach(field => {
      if (planning[field] && diffFields(planning[field], refPlanning[field])) {
        isDirty = true;
      }
    });

    CREATIVE_DIRTY_SETTINGS.forEach(setting => {
      if (
        planning.settings[setting] &&
        diffFields(planning.settings[setting], refPlanning.settings[setting])
      ) {
        isDirty = true;
      }
    });

    return isDirty;
  }
}

function diffFields(source, reference) {
  if (Array.isArray(source)) {
    if (diffLists(source, reference)) return true;
  } else if (typeof source === 'object') {
    if (diffObjects(source, reference)) return true;
  } else {
    if (source.toString() !== reference.toString()) return true;
  }
  return false;
}

function diffLists(list, reference) {
  return (
    JSON.stringify(list.map(item => item.toString())) !==
    JSON.stringify(reference.map(item => item.toString()))
  );
}

function diffObjects(source, reference) {
  return JSON.stringify(source) !== JSON.stringify(reference);
}
export default Planning;
