import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import DropdownMenu from '../common/DropdownMenu';

const CategoryContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 3px;
  background-color: ${props => props.theme.lightBackground};
  text-transform: capitalize;
`;

const TitleStyle = styled.p`
  display: inline-block;
  color: black;
  line-height: 2rem;
  font-weight: 400;
  font-size: 0.75rem;
  margin: 0.75rem 1.125rem;
`;

function AuctionCategory({ categories, onSelect, selected }) {
  const { t } = useTranslation();

  const categoryOptions = categories.map(category => {
    return { id: category.id, name: category.name_translations.en };
  });
  const categorySelected = categoryOptions.find(
    category => category.id === selected,
  );

  return (
    <CategoryContainerStyle>
      <TitleStyle>Category:</TitleStyle>
      <DropdownMenu
        selected={categorySelected ? categorySelected.id : 0}
        onSelect={onSelect}
        placeholder={t('select_category')}
        options={categoryOptions}
      />
    </CategoryContainerStyle>
  );
}

AuctionCategory.propTypes = {
  categories: PropTypes.array,
  onSelect: PropTypes.func,
  selected: PropTypes.number,
};

export default AuctionCategory;
