import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from './Card';

const TextContainerStyle = styled.div`
  padding: 0 0.5rem;
`;

const SubtitleStyle = styled.h3`
  margin: 0.25rem 0;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: ${props => props.theme.primary};
`;

const TextStyle = styled.h1`
  margin: 0;
  font-weight: 300;
  font-size: 0.625rem;
  line-height: 1rem;
  padding-bottom: 1.5rem;
`;

function CardWithDescription({
  highlight,
  image,
  subtitle,
  text,
  url,
  children,
  onChange,
}) {
  return (
    <Card border highlight={highlight} image={image} onChange={onChange}>
      {children}
      <TextContainerStyle>
        <a href={url || 'https://vavato.com/'} target="_blank" rel="noreferrer">
          <SubtitleStyle>{subtitle}</SubtitleStyle>
        </a>
        <TextStyle>{text}</TextStyle>
      </TextContainerStyle>
    </Card>
  );
}

CardWithDescription.propTypes = {
  highlight: PropTypes.bool,
  image: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  children: PropTypes.any,
  onChange: PropTypes.func,
};

export default CardWithDescription;
