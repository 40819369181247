import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import infoIcon from '../../assets/icons/info.svg';
import { useFetchCampaignLivePreviewHTML } from '../../hooks/services/CampaignsAPI';

const PreviewContentStyle = styled.div`
  width: 352px;
  height: 713px;
  display: flex;
  padding: 0 0.5rem;
  border-radius: 3px;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid rgba(97, 146, 161, 0.2);
  background-color: ${props => props.theme.lightBackground};

  iframe {
    height: 600px;
  }
`;

const PreviewInfoStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
`;

const PreviewIconStyle = styled.img`
  margin-right: 10px;
`;

const PreviewInfoTextStyle = styled.p`
  margin: 0;
  line-height: 2rem;
  font-size: 0.75rem;
  color: ${props => props.theme.text};
`;

const PreviewLoaderStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PreviewTitleStyle = styled.h3`
  margin: 24px 16px 0 16px;
  line-height: 1rem;
  font-weight: 700;
  font-size: 0.875rem;
  color: ${props => props.theme.text};
`;

function CampaignLivePreview({ campaignId, settings, lotsOrder }) {
  const { t } = useTranslation();

  const {
    data: campaignLivePreviewHTML,
    isFetching: campaignLivePreviewHTMLIsFetching,
  } = useFetchCampaignLivePreviewHTML(campaignId, settings, lotsOrder);

  function renderLoader() {
    return (
      <PreviewLoaderStyle>
        <FontAwesomeIcon
          spin
          icon={faSpinner}
          className="circle"
          color="#6092a2"
          size="3x"
        />
      </PreviewLoaderStyle>
    );
  }

  function renderIframe() {
    return (
      <div
        data-testid="live-preview"
        dangerouslySetInnerHTML={{
          __html: campaignLivePreviewHTML?.data,
        }}
      ></div>
    );
  }

  return (
    <PreviewContentStyle>
      <PreviewTitleStyle>
        {t('campaign_config.preview_title')}
      </PreviewTitleStyle>
      <PreviewInfoStyle>
        <PreviewIconStyle src={infoIcon} />
        <PreviewInfoTextStyle>
          {t('campaign_config.preview_info')}
        </PreviewInfoTextStyle>
      </PreviewInfoStyle>
      {campaignLivePreviewHTMLIsFetching ? renderLoader() : renderIframe()}
    </PreviewContentStyle>
  );
}

CampaignLivePreview.propTypes = {
  campaignId: PropTypes.number,
  settings: PropTypes.object,
  lotsOrder: PropTypes.array,
};

export default CampaignLivePreview;
