import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import queryClient from '../../../lib/queryClient';
import { useParams, useHistory } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';

import { ToasterContext } from 'vavato-ui';
import Loader from '../../../components/common/LoaderOverrided';
import LotTitleEdit from '../../../components/lot/LotTitleEdit';
import LayoutContent from '../../../components/layout/LayoutContent';
import CustomStepper from '../../../components/common/CustomStepper';
import {
  LOT_TITLE_TYPES,
  TODO_MAIL_STEPPER_LABELS,
} from '../../../lib/constants';
import {
  lotUrl,
  lotBidSubtitle,
  nameTranslationsByType,
} from '../../../lib/formatter';

import {
  useFetchAuctionData,
  useFetchAuctionLotsData,
} from '../../../hooks/services/AuctionAPI';
import { useUpdateLotData } from '../../../hooks/services/LotAPI';

const EditTitleContainerStyle = styled.div`
  display: grid;
  grid-gap: 1rem;
`;

const EditTitleTypeContainerStyle = styled.div`
  display: flex;
  margin-left: auto;
  width: calc(100% - 200px);
`;

const EditTitleTypeStyle = styled.p`
  margin: 0 auto;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

function AuctionConfigureLots() {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const toasterContext = useContext(ToasterContext);
  const { error } = toasterContext;

  const links = [
    { path: '/', name: 'home' },
    { path: '/auctions', name: 'auctions_list' },
    { path: `/auctions/${id}/plan`, name: 'auction' },
    { path: '', name: 'todo_mail' },
  ];

  const [highlightedLots, setHighlightedLots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [setupLoading, setSetupLoading] = useState(true);

  const {
    data: auctionData,
    error: auctionError,
    isError: auctionIsError,
  } = useFetchAuctionData(id);

  const {
    data: auctionLotsData,
    error: auctionLotsError,
    isError: auctionLotsIsError,
  } = useFetchAuctionLotsData(id, { highlighted_lots: true });

  const { mutateAsync: updateLotMutate } = useUpdateLotData();

  useEffect(() => {
    if (auctionLotsData) {
      const highlightedLots = auctionLotsData.data.filter(lot => lot.highlight);
      setHighlightedLots([...highlightedLots]);
      setSetupLoading(false);
    }
  }, [auctionLotsData]);

  async function updateLot(id, params) {
    await updateLotMutate({ id, params });
  }

  async function onSave() {
    if (highlightedLots.length === 0) return true;

    setLoading(true);
    const updatePromisses = highlightedLots.map(async lot => {
      const params = { payload_overrides: lot.payload_overrides };
      await updateLot(lot.id, params);
    });

    try {
      await Promise.all(updatePromisses);
      return true;
    } catch (err) {
      error(err?.message);
    } finally {
      setLoading(false);
      queryClient.invalidateQueries(['auctionLots', id]);
    }
  }

  function onChange(lotIndex, language, text, type) {
    const newHighlightedLots = [...highlightedLots];
    const newHighlightedLot = newHighlightedLots[lotIndex];

    const name_translations = nameTranslationsByType(newHighlightedLot, type);

    const payloadOverrides = {
      ...name_translations,
      [language]: text,
    };

    newHighlightedLot.payload_overrides.name_translations = {
      ...newHighlightedLot.payload_overrides.name_translations,
      [type]: payloadOverrides,
    };

    setHighlightedLots(newHighlightedLots);
  }

  if (auctionIsError || auctionLotsIsError) {
    error(auctionError?.message || auctionLotsError?.message);
    history.push('/');
  }

  return (
    <LayoutContent
      auction={auctionData?.data}
      links={links}
      loading={loading}
      saveTitle={t('footer.save')}
      onSave={onSave}
      nextTitle={t('footer.next')}
      nextLink={`/auctions/${id}/todomail/configure_campaign`}
      prevLink={`/auctions/${id}/todomail/select_lots`}
      defaultSaveAndGo={() => {
        return true;
      }}
    >
      {setupLoading ? (
        <Loader show={true} />
      ) : (
        <>
          <CustomStepper
            activeStep={1}
            steps={TODO_MAIL_STEPPER_LABELS}
            id={id}
          />
          <EditTitleContainerStyle>
            <EditTitleTypeContainerStyle>
              {LOT_TITLE_TYPES.map(type => (
                <EditTitleTypeStyle key={type}>{t(type)}</EditTitleTypeStyle>
              ))}
            </EditTitleTypeContainerStyle>

            {highlightedLots.map((lot, index) => (
              <LotTitleEdit
                key={lot.id}
                index={index}
                lot={lot}
                lotSubtitle={lotBidSubtitle(lot)}
                onChange={onChange}
                lotUrl={lotUrl(auctionData?.data, lot)}
              />
            ))}
          </EditTitleContainerStyle>
        </>
      )}
    </LayoutContent>
  );
}

export default AuctionConfigureLots;
