import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Campaign from '../../models/Campaign';
import { nameTranslation } from '../../lib/formatter';
import StatusLabel from '../../components/common/ScheduledLabel';
import infoIcon from '../../assets/icons/info.svg';
import { isPastDate } from '../../lib/dateHelper';
import { useHistory } from 'react-router-dom';
import { useFeature } from '../../hooks/contexts/FeatureContext';

const CLOSED_AUCTIONS_LIMIT = 5;

const CampaignContainer = styled.div`
  background: #f8f9fa;
  border: 1px solid rgba(89, 90, 92, 0.2);
  border-radius: 4px;
  width: 100%;
  margin-bottom: 5px;
  padding: 9px;
  color: #595a5c;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
`;

const CampaignInfoContainer = styled.div`
  cursor: pointer;
`;

const CampaignTitle = styled.div`
  margin-bottom: 3px;
  &.red {
    color: ${props => props.theme.changedText};
  }
  &.grey {
    color: ${props => props.theme.missingText};
  }
`;

const CampaignAuctionsContainer = styled.div`
  font-weight: 300;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  letter-spacing: 1px;
`;

const AuctionRowContainer = styled.div`
  position: relative;
`;

const StatusSquare = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 1px;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  ${props => {
    return `background: ${props.starting ? '#259B11' : '#B61E39'};`;
  }};
`;

const AuctionTitle = styled.span`
  display: inline-block;
  position: relative;
  margin-bottom: 3px;
  &.red {
    color: ${props => props.theme.changedText};
  }
  opacity: ${props => (props.isHidden ? '0.5' : '1')};
`;

const LoadMoreAuctions = styled.span`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
`;

const PreviewInfoStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentErrorIconStyle = styled.img`
  padding: 0.2rem;
  border-radius: 5px;
  cursor: default;
`;

function CampaignBlock({ campaign, setOpenCampaign }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const feature = useFeature();

  const campaignContentError =
    campaign?.mail_content_error && !isPastDate(campaign.scheduled_send_time);

  function integrationLabelClass() {
    if (feature?.mailchimp_integration) {
      return Campaign.integrationComplete(campaign) &&
        !campaign.integration_error
        ? ''
        : 'red';
    }

    return '';
  }

  function handleClick(id) {
    history.push(`/campaigns/${id}`);
  }

  function mailchimpStatusLabel() {
    if (feature?.mailchimp_integration) {
      return <StatusLabel status={campaign.mailer_schedule_status} />;
    }
  }

  function renderAuctionInfo(campaign, planning, index) {
    const auction = planning?.auction || planning?.settings?.auction;
    if (!open && index >= CLOSED_AUCTIONS_LIMIT) {
      return <div key={`${planning.id}_container`}></div>;
    }
    return (
      <AuctionRowContainer key={`${planning.id}_container`}>
        <StatusSquare starting={Campaign.isStarting(planning, campaign)} />
        <AuctionTitle
          isHidden={planning.hidden}
          className={integrationLabelClass()}
          key={`${planning?.id}_planning`}
        >
          {`${
            auction?.external_id || auction?.atlas_external_id || 'EXT'
          } - ${nameTranslation(auction, 'en')}`}
        </AuctionTitle>
      </AuctionRowContainer>
    );
  }

  return (
    <CampaignContainer key={`${campaign?.id}_container`}>
      <CampaignInfoContainer
        onClick={() =>
          feature?.campaign_details_modal
            ? setOpenCampaign(campaign)
            : handleClick(campaign?.id)
        }
      >
        {campaignContentError && (
          <PreviewInfoStyle>
            <ContentErrorIconStyle
              src={infoIcon}
              title={t('campaigns.mail_content_error')}
            />
          </PreviewInfoStyle>
        )}
        {mailchimpStatusLabel()}
        <CampaignTitle className={integrationLabelClass()}>
          {campaign.campaign_title}
        </CampaignTitle>
        <CampaignAuctionsContainer>
          {campaign.plannings?.map((planning, index) => {
            return renderAuctionInfo(campaign, planning, index);
          })}
          {campaign.external_plannings?.map((planning, index) => {
            return renderAuctionInfo(campaign, planning, index);
          })}
        </CampaignAuctionsContainer>
      </CampaignInfoContainer>
      {campaign.plannings.length > CLOSED_AUCTIONS_LIMIT && (
        <LoadMoreAuctions
          onClick={e => {
            e.stopPropagation();
            setOpen(prev => !prev);
          }}
        >
          {t(`campaigns.${open ? 'see_less' : 'see_more'}`)}
        </LoadMoreAuctions>
      )}
    </CampaignContainer>
  );
}

CampaignBlock.propTypes = {
  campaign: PropTypes.object,
  setOpenCampaign: PropTypes.func,
};

export default CampaignBlock;
