import { darken } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useContext } from 'react';

import { formatDate, isPastDate } from '../../lib/dateHelper';
import cancelIcon from '../../assets/icons/cancel.svg';
import { PREVIEW_LOCALES, TEMPLATE_OPTIONS } from '../../lib/constants';
import ModalDialog from '../common/ModalDialog';
import AuctionsListTableContent from '../auction/ListTableContent';
import Loader from '../common/LoaderOverrided';
import { Button, ToasterContext } from 'vavato-ui';
import {
  useFetchGroupedPlanningsData,
  useScheduleCampaign,
  useUnscheduleCampaign,
  useUpdateOnMailerCampaign,
  useRecreateIntegrationCampaign,
  useDeleteReplicaCampaign,
} from '../../hooks/services/CampaignsAPI';
import { useUpdatePlanningOrder } from '../../hooks/services/PlanningsAPI';
import Campaign from '../../models/Campaign';
import CampaignCopyModal from './CampaignCopyModal';
import CampaignReplicateModal from './CampaignReplicateModal';
import CampaignDetailsSubHeader from './CampaignDetailsSubHeader';
import { formatURLParams } from '../../lib/formatter';
import infoIcon from '../../assets/icons/info.svg';
import { useFeature } from '../../hooks/contexts/FeatureContext';

const CampaignDetailsRowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0;
`;

const CampaignDetailsHeaderStyle = styled(CampaignDetailsRowStyle)`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  &.red {
    span {
      color: ${props => props.theme.changedText};
    }
  }
  &.grey {
    span {
      color: ${props => props.theme.missingText};
    }
  }
`;

const ButtonStyle = styled.div`
  margin-right: 20px;
`;

const CampaignDetailsFooterStyle = styled(CampaignDetailsRowStyle)`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  justify-content: space-between;
`;

const CampaignDetailsRightFooterStyle = styled.div`
  display: flex;
  margin-right: -28px;
  .delete_button {
    background: #b61e39;
  }
`;

const CampaignDetailsCancelIconStyle = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const PreviewLinkStyle = styled.a`
  cursor: pointer;
  text-transform: uppercase;
  color: ${props => props.theme.primary};

  :hover {
    color: ${props => darken(0.2, props.theme.primary)};
    text-decoration: none;
  }
`;

const AuctionListTableHeader = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 4px 0;
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.lightText};
  background-color: ${props => props.theme.tableHeaders};
`;

const InfoStyle = styled.img`
  margin-left: 10px;
`;

function CampaignDetails({ campaign, setOpenCampaign }) {
  const { t } = useTranslation();
  const { error, success } = useContext(ToasterContext);
  const feature = useFeature();

  const isIntegrationComplete = Campaign.integrationComplete(campaign);
  const {
    data: groupedPlanningsData,
    error: groupedPlanningsError,
    isError: groupedPlanningsIsError,
    isFetching: groupedPlanningsIsFetching,
  } = useFetchGroupedPlanningsData(campaign.id);

  const [auctions, setAuctions] = useState(null);
  const [planningsOrder, setPlanningsOrder] = useState({});
  const [groupedPlannings, setGroupedPlannings] = useState(null);
  const [setupLoading, setSetupLoading] = useState(false);
  const [openCampaignCopy, setOpenCampaignCopy] = useState(false);
  const [openReplicateModal, setOpenReplicateModal] = useState(false);
  const [campaignConfig, setCampaignConfig] = useState({
    mail_template:
      TEMPLATE_OPTIONS.find(x => x.name === campaign?.mail_template) || null,
    mail_subtitle: campaign?.mail_subtitle || {},
    mail_subject: campaign?.mail_subject || {},
    mailchimp_segment: campaign?.mailchimp_segment || {},
    black_friday_banner: campaign?.black_friday_banner || {},
  });

  const { mutateAsync: scheduleCampaignMutate } = useScheduleCampaign(
    campaign.id,
    {
      ...campaignConfig,
      mail_template: campaignConfig.mail_template?.name,
    },
  );

  const { mutateAsync: unscheduleCampaignMutate } = useUnscheduleCampaign(
    campaign.id,
  );
  const { mutateAsync: updatePlanningOrderMutate } = useUpdatePlanningOrder(
    campaign.id,
  );
  const { mutateAsync: recreateIntegrationMutate } =
    useRecreateIntegrationCampaign(campaign.id);

  const { mutateAsync: updateOnMailerCampaignMutate } =
    useUpdateOnMailerCampaign(campaign.id);

  const { mutateAsync: deleteReplicaMutate } = useDeleteReplicaCampaign(
    campaign.id,
  );

  function isGroupedByCategory(groupedPlannings) {
    return Array.isArray(groupedPlannings) && groupedPlannings?.[0]?.[0]?.en;
  }

  function isGroupedByStartClose(groupedPlannings) {
    return !Array.isArray(groupedPlannings);
  }

  function parseGroupedPlanningsByCategory(groupedPlannings) {
    groupedPlannings.forEach(([category, plannings]) => {
      const categoryName = category.en;
      setGroupedPlannings(prevGroupedPlannings => {
        return {
          ...prevGroupedPlannings,
          [categoryName]: [...plannings],
        };
      });
    });
  }

  function parseGroupedPlanningsByStartClose(groupedPlannings) {
    Object.keys(groupedPlannings)
      .sort()
      .forEach(key => {
        const plannings = groupedPlannings[key];
        setGroupedPlannings(prevGroupedPlannings => {
          return { ...prevGroupedPlannings, [key]: [...plannings] };
        });
      });
  }

  function parseGroupedPlannings(groupedPlannings) {
    let campaignTypeName = campaign.campaign_type_name;
    if (campaignTypeName.includes('close')) {
      campaignTypeName = 'Closing';
    } else {
      campaignTypeName = 'Starting';
    }
    setGroupedPlannings(prevGroupedPlannings => {
      return {
        ...prevGroupedPlannings,
        [campaignTypeName]: [...groupedPlannings],
      };
    });
  }

  useEffect(() => {
    if (!groupedPlanningsData) return;
    const auctions = campaign.plannings.map(planning => planning.auction);
    setAuctions([...auctions]);

    const groupedPlannings = groupedPlanningsData?.data;

    if (isGroupedByCategory(groupedPlannings)) {
      parseGroupedPlanningsByCategory(groupedPlannings);
    } else if (isGroupedByStartClose(groupedPlannings)) {
      parseGroupedPlanningsByStartClose(groupedPlannings);
    } else {
      parseGroupedPlannings(groupedPlannings);
    }

    // eslint-disable-next-line
  }, [groupedPlanningsData]);

  function orderedAuctions(group) {
    const plannings = groupedPlannings[group];
    const auctionIds = plannings.map(planning => planning.auction_id);
    return auctionIds.map(auctionId =>
      auctions.find(auction => auction.id === auctionId),
    );
  }

  function swapOrder(group, dragId, dropId) {
    const plannings = [...groupedPlannings[group]];
    const auctions = plannings.map(planning => planning.auction_id);
    const dropIndex = auctions.indexOf(dropId);
    const dragIndex = auctions.indexOf(dragId);
    if (dropIndex === -1 || dragIndex === -1) return;

    const dragPlanning = plannings.find(
      planning => planning.auction_id === dragId,
    );
    plannings.splice(dragIndex, 1);
    plannings.splice(dropIndex, 0, dragPlanning);

    groupedPlannings[group] = plannings;
    setGroupedPlannings(groupedPlannings);

    updatePlanningsOrder(plannings);
  }

  function updatePlanningsOrder(plannings) {
    plannings.forEach((planning, index) => {
      setPlanningsOrder(prevPlanningsOrderr => {
        return { ...prevPlanningsOrderr, [planning.id]: index };
      });
    });
  }

  function openCampaign(event, locale, utmTags = {}) {
    event.preventDefault();
    const params = {
      locale,
      ...utmTags,
      template: campaignConfig?.mail_template?.name,
    };
    const queryParams = formatURLParams(params);
    const campaignUrl = campaign.url + queryParams;
    window.open(campaignUrl, '_blank');
  }

  function closeModal() {
    setOpenCampaign(null);
  }

  async function unscheduleCampaign(e) {
    e.preventDefault();
    try {
      setSetupLoading(true);
      await unscheduleCampaignMutate();
      success(t('toaster.processing_campaign_unschedule'));
      closeModal();
      setSetupLoading(false);
    } catch (err) {
      error(err.response.data.message);
      setSetupLoading(false);
    }
  }

  async function scheduleCampaign(e) {
    e.preventDefault();
    try {
      setSetupLoading(true);
      await scheduleCampaignMutate();
      success(t('toaster.processing_campaign_schedule'));
      closeModal();
      setSetupLoading(false);
    } catch (err) {
      error(err.response.data.message);
      setSetupLoading(false);
    }
  }

  async function recreateIntegrationCampaign(e) {
    e.preventDefault();
    try {
      setSetupLoading(true);
      await recreateIntegrationMutate();
      success(t('toaster.recreating_campaign_integration'));
      closeModal();
      setSetupLoading(false);
    } catch (err) {
      error(err.response.data.message);
      setSetupLoading(false);
    }
  }

  function disableButton() {
    if (campaign.mailer_schedule_status === 'no_integration') return true;
    return (
      campaign.mailer_schedule_status === 'processing' ||
      isPastDate(campaign.scheduled_send_time)
    );
  }

  async function saveOrder(e) {
    e.preventDefault();
    try {
      setSetupLoading(true);
      const updatePromisses = Object.keys(planningsOrder).map(
        async planningOrder => {
          const params = {
            id: planningOrder,
            order: planningsOrder[planningOrder],
          };
          await updatePlanningOrderMutate(params);
        },
      );
      await Promise.all(updatePromisses);
      await updateOnMailerCampaignMutate();
      success(t('toaster.campaign_saved'));
      setSetupLoading(false);
    } catch (err) {
      error(err.response.data.message);
      setSetupLoading(false);
    }
  }

  function renderIntegrationErrorInfo() {
    return (
      campaign?.integration_error && (
        <InfoStyle src={infoIcon} title={campaign?.integration_error} />
      )
    );
  }

  function integrationTitleClass() {
    if (feature?.mailchimp_integration) {
      if (campaign.mailer_schedule_status === 'no_integration') return 'grey';
      return isIntegrationComplete && !campaign.integration_error ? '' : 'red';
    }

    return '';
  }

  async function deleteReplica(e) {
    e.preventDefault();
    try {
      setSetupLoading(true);
      await deleteReplicaMutate();
      success(t('toaster.replica_deleted'));
      closeModal();
      setSetupLoading(false);
    } catch (err) {
      error(err.response.data.message);
      setSetupLoading(false);
    }
  }

  function recreateButton() {
    if (feature?.mailchimp_integration) {
      return (
        <Button
          primary
          disabled={campaign.mailer_schedule_status === 'scheduled'}
          onClick={e => recreateIntegrationCampaign(e)}
        >
          {t('campaigns.recreate_integration')}
        </Button>
      );
    }
  }

  function scheduleButton() {
    if (feature?.mailchimp_integration) {
      return (
        <ButtonStyle>
          {campaign.mailer_schedule_status === 'scheduled' ? (
            <Button
              onClick={e => unscheduleCampaign(e)}
              disabled={
                !isIntegrationComplete ||
                isPastDate(campaign.scheduled_send_time)
              }
            >
              {t('campaigns.unschedule')}
            </Button>
          ) : (
            <Button
              onClick={e => scheduleCampaign(e)}
              disabled={!isIntegrationComplete || disableButton()}
            >
              {t('campaigns.schedule')}
            </Button>
          )}
        </ButtonStyle>
      );
    }
  }

  if (groupedPlanningsIsError) {
    error(groupedPlanningsError?.message);
  }

  if (!auctions || groupedPlanningsIsFetching) {
    return <Loader show={true} />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <ModalDialog onClose={closeModal}>
        <CampaignDetailsHeaderStyle className={integrationTitleClass()}>
          <span>
            {formatDate(campaign.scheduled_send_time)} -{' '}
            {campaign.campaign_title}
            {(!isIntegrationComplete || campaign?.integration_error) &&
              renderIntegrationErrorInfo()}
          </span>
          <CampaignDetailsCancelIconStyle
            src={cancelIcon}
            onClick={closeModal}
          />
        </CampaignDetailsHeaderStyle>
        <CampaignDetailsSubHeader
          setCampaignConfig={setCampaignConfig}
          campaignConfig={campaignConfig}
        />
        {groupedPlannings &&
          Object.keys(groupedPlannings).map(group => {
            return (
              <div key={group}>
                <AuctionListTableHeader>
                  <span>{group}</span>
                </AuctionListTableHeader>
                <AuctionsListTableContent
                  setSetupLoading={setSetupLoading}
                  plannings={groupedPlannings[group]}
                  auctions={orderedAuctions(group)}
                  isDraggable={true}
                  isHideable={
                    !!feature?.segmentation &&
                    feature?.mailchimp_integration &&
                    campaign.mailer_schedule_status !== 'scheduled'
                  }
                  onDrop={(dragId, dropId) => swapOrder(group, dragId, dropId)}
                  showCampaignStatus={true}
                  campaign={campaign}
                />
              </div>
            );
          })}
        <CampaignDetailsFooterStyle>
          <span>
            {t('campaigns.see_newsletter')}:{' '}
            {PREVIEW_LOCALES.map(locale => {
              return (
                <PreviewLinkStyle
                  key={locale}
                  onClick={e => openCampaign(e, locale)}
                >
                  {`${locale} `}
                </PreviewLinkStyle>
              );
            })}
          </span>
          <CampaignDetailsRightFooterStyle>
            {feature?.segmentation && (
              <>
                {campaign.replica_of_id ? (
                  <Button
                    className="delete_button"
                    disabled={
                      feature?.mailchimp_integration &&
                      campaign.mailer_schedule_status === 'scheduled'
                    }
                    onClick={deleteReplica}
                  >
                    {t('campaigns.delete_replica')}
                  </Button>
                ) : (
                  <Button
                    primary
                    disabled={false}
                    onClick={() => {
                      setOpenReplicateModal(true);
                    }}
                  >
                    {t('campaigns.replicate')}
                  </Button>
                )}
              </>
            )}
            {recreateButton()}
            <Button
              primary
              onClick={() => {
                setOpenCampaignCopy(true);
              }}
            >
              {t('campaigns.create_link')}
            </Button>
            <Button
              primary
              onClick={e => saveOrder(e)}
              disabled={isPastDate(campaign.scheduled_send_time)}
            >
              {t('campaigns.save_order')}
            </Button>
            {scheduleButton()}
          </CampaignDetailsRightFooterStyle>
        </CampaignDetailsFooterStyle>
      </ModalDialog>
      {openCampaignCopy ? (
        <CampaignCopyModal
          campaign={campaign}
          openCampaign={openCampaign}
          closeModal={() => {
            setOpenCampaignCopy(false);
          }}
        />
      ) : null}
      {openReplicateModal ? (
        <CampaignReplicateModal
          setSetupLoading={setSetupLoading}
          campaign={campaign}
          closeCampaignModal={closeModal}
          closeModal={() => {
            setOpenReplicateModal(false);
          }}
        />
      ) : null}
      {setupLoading ? <Loader show={true} /> : null}
    </DndProvider>
  );
}

CampaignDetails.propTypes = {
  campaign: PropTypes.object,
  setOpenCampaign: PropTypes.func,
};

export default CampaignDetails;
