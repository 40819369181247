import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CampaignInput from './CampaignInput';

const TextAreaContainerStyle = styled.div`
  margin-right: 1rem;
`;

const TextAreaStyle = styled.textarea`
  height: 75px;
  width: 600px;
  padding: 6px;
  color: #595a5c;
  border-radius: 5px;
  border: 2px solid #c4c4c4;
  resize: none;
`;

function CampaignTextAreaInput({ index, name, value, onChange }) {
  return (
    <CampaignInput name={name}>
      <TextAreaContainerStyle>
        <TextAreaStyle
          id={`${name}${index}`}
          data-testid={`${name}${index}`}
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      </TextAreaContainerStyle>
    </CampaignInput>
  );
}

CampaignTextAreaInput.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default CampaignTextAreaInput;
