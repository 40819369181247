import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { lighten } from 'polished';
import styled from 'styled-components';
import Slider from '@material-ui/core/Slider';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { useFetch } from '../../hooks/services/CommonAPI';

import Loader from '../common/LoaderOverrided';
import dragIcon from '../../assets/icons/drag.svg';
import starIcon from '../../assets/icons/star.svg';
import starEmptyIcon from '../../assets/icons/star-empty.svg';
import cancelIcon from '../../assets/icons/cancel.svg';
import { DEFAULT_CANVAS } from '../../lib/constants';
import { clamp, drawCanvas, drawCanvasWithLogo } from '../../lib/image';
import { Button } from 'vavato-ui';
import ModalDialog from './ModalDialog';
import Checkbox from './CustomCheckbox';

const BLOCKED_CORS_BUCKET = process.env.REACT_APP_BLOCKED_CORS_BUCKET;
const SECOND_BLOCKED_CORS_BUCKET =
  process.env.REACT_APP_SECOND_BLOCKED_CORS_BUCKET;

const EditRowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EditHeaderStyle = styled(EditRowStyle)`
  margin: 1rem 0;
`;

const EditFooterStyle = styled(EditRowStyle)`
  margin: 0.5rem 0;
`;

const EditBodyStyle = styled(EditRowStyle)`
  gap: 1rem;
`;

const EditSelectorStyle = styled.div`
  gap: 0.5rem;
  display: flex;
  overflow: scroll;
  flex-direction: column;
  justify-content: flex-start;
  height: ${props => `${props.height}px`};
`;

const EditSelectorImageStyle = styled.div`
  width: 114px;
  min-height: 72px;
  background: ${props => `url(${props.image})`} no-repeat;
  background-position: center;
  background-size: 100% auto;
  position: relative;

  :hover {
    cursor: pointer;
    border: 1px solid ${props => props.theme.secondary};
  }
`;

const EditTileStyle = styled.p`
  margin: 0;
  color: black;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: normal;
  letter-spacing: 1px;
`;

const EditIconStyle = styled.img`
  pointer-events: none;
`;

const EditCancelIconStyle = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const EditTextStyle = styled.p`
  margin: 0;
  color: white;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1rem;
`;

const EditImageContainerStyle = styled.div`
  cursor: move;
  overflow: hidden;
  position: relative;
`;

const EditCanvasStyle = styled.canvas`
  border: 1px dashed ${props => lighten(0.1, props.theme.tableHeaders)};
`;

const EditImageStyle = styled.img`
  display: none;
`;

const EditContentStyle = styled.div`
  gap: 0.5rem;
  opacity: 0.5;
  width: fit-content;
  position: absolute;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme.darkText};
`;

const EditStarStyle = styled(EditContentStyle)`
  top: 0;
  display: flex;
  align-items: center;
`;

const EditSelectorStarStyle = styled.div`
  top: 5%;
  left: 3%;
  position: absolute;
`;

const EditDragStyle = styled(EditContentStyle)`
  top: 40%;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-radius: 20px;
`;

const EditSliderStyle = styled(EditContentStyle)`
  top: 80%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
`;

const EditSettingsStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

const EditUploadButtonStyle = styled(Button)`
  z-index: 1;
  position: relative;
  display: inline-block;

  & > input[type='file'] {
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 2;
    width: 145px;
    height: 42px;
    cursor: pointer;
    position: absolute;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  & > input[type='file']::-webkit-file-upload-button {
    cursor: pointer;
  }
`;

const EditSlider = withStyles({
  root: {
    width: '280px',
    color: 'white',
  },
})(Slider);

function ImageEditor({
  cardId,
  images,
  variantImages,
  canvasHeight,
  canvasWidth,
  attachmentId,
  uploadImage,
  onCancel,
  onSave,
  loading,
  saveUploadImage,
  hasCoverImage,
  isAdType,
  planningId,
}) {
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const imgRef = useRef(null);

  const [editImage, setEditImage] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editAttachmentId, setEditAttachmentId] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [zoom, setZoom] = useState(DEFAULT_CANVAS.zoom);
  const [focalPoint, setFocalPoint] = useState(DEFAULT_CANVAS.focal_point);
  const [allImages, setAllImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [borderTop, setBorderTop] = useState(false);
  const [uploadCorsImage, setUploadCorsImage] = useState(null);

  const {
    data: fetchUploadCorsData,
    isLoading: fetchUploadCorsLoading,
    isFetching: fetchUploadCorsIsFetching,
    refetch: uploadUrlRefetch,
  } = useFetch(`/plannings/${planningId}/upload_image_url`, {
    url: uploadCorsImage,
    attachment_id: editAttachmentId,
  });

  useEffect(() => {
    if (uploadCorsImage) {
      uploadUrlRefetch();
    }
  }, [uploadCorsImage, uploadUrlRefetch]);

  useEffect(() => {
    const img = imgRef.current;
    img.crossOrigin = 'anonymous';
  }, []);

  useEffect(() => {
    const img = imgRef.current;
    img.onload = () => {
      setImageLoading(false);
    };
  }, [editImage]);

  useEffect(() => {
    if (loading) {
      setEditImage(null);
      setEditIndex(null);
      setEditAttachmentId(null);
    }
  }, [loading]);

  function setUploadImage() {
    setEditImage(uploadImage);
    setEditAttachmentId(null);
    setEditIndex(images.length);
  }

  const setFirstImage = useCallback(() => {
    const imageSrc =
      variantImages.length > 0
        ? variantImages[0]?.url?.big
        : images?.[0]?.urls?.[2].large;

    if (
      imageSrc.includes(BLOCKED_CORS_BUCKET) ||
      imageSrc.includes(SECOND_BLOCKED_CORS_BUCKET)
    ) {
      setUploadCorsImage(imageSrc);
    } else {
      setEditImage(imageSrc);
      setEditAttachmentId(null);
      setEditIndex(0);
    }
  }, [images, variantImages]);

  function setCoverImage(image) {
    const index = images.indexOf(image);
    const imageSrc = image.urls?.[2].large;
    setEditImage(imageSrc);
    setEditAttachmentId(image?.id);
    setEditIndex(index);
  }

  function corsBlocked(image) {
    if (!BLOCKED_CORS_BUCKET || !SECOND_BLOCKED_CORS_BUCKET) {
      return false;
    }

    const imageSrc = image.urls?.[2]?.large;
    return (
      imageSrc.includes(BLOCKED_CORS_BUCKET) ||
      imageSrc.includes(SECOND_BLOCKED_CORS_BUCKET)
    );
  }

  useEffect(() => {
    const image = fetchUploadCorsData?.data?.url;
    if (image) setEditImage(image);
    setImageLoading(true);
    setEditImage(image);

    setZoom(DEFAULT_CANVAS.zoom);
    setFocalPoint(DEFAULT_CANVAS.focal_point);
  }, [fetchUploadCorsData, setFirstImage]);

  useEffect(() => {
    if (loading) return;
    setImageLoading(true);
    const imagesSrc = images.map(image => image.urls?.[2].large);
    if (uploadImage) {
      imagesSrc.push(uploadImage);
    }
    setAllImages(imagesSrc);
    if (uploadLoading) {
      setUploadImage();
    } else if (hasCoverImage) {
      // eslint-disable-next-line
      const image = images.find(image => image.id == attachmentId);

      if (image && !corsBlocked(image)) {
        setCoverImage(image);
      } else if (uploadImage) {
        setUploadImage();
      } else {
        setFirstImage();
      }
    } else {
      setFirstImage();
    }
    // eslint-disable-next-line
  }, [loading, attachmentId, uploadImage, images]);

  useEffect(() => {
    if (loading || imageLoading) return;

    const img = imgRef.current;
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext('2d');
    const canvasData = {
      ctx: canvasCtx,
      width: canvasWidth,
      height: canvasHeight,
    };

    isAdType
      ? drawCanvasWithLogo(img, canvasData, focalPoint, zoom, borderTop)
      : drawCanvas(img, canvasData, focalPoint, zoom);
    // eslint-disable-next-line
  }, [
    loading,
    imageLoading,
    focalPoint,
    zoom,
    canvasWidth,
    canvasHeight,
    borderTop,
  ]);

  function handleMouseMove({ movementX, movementY }) {
    if (!isDragging) return;

    const deltaX = movementX / canvasWidth;
    const deltaY = movementY / canvasHeight;

    const idealFocalX = focalPoint.x - deltaX;
    const idealFocalY = focalPoint.y - deltaY;

    const realisticFocalX = clamp(idealFocalX, 0.0, 1.0);
    const realisticFocalY = clamp(idealFocalY, 0.0, 1.0);

    setFocalPoint({ x: realisticFocalX, y: realisticFocalY });
  }

  function handleSliderChange(_event, value) {
    setIsDragging(false);
    setZoom(value);
  }

  async function save() {
    const canvas = canvasRef.current;
    const coverImage = await new Promise(resolve =>
      canvas.toBlob(resolve, 'image/jpeg', 1.0),
    );

    onSave(cardId, coverImage, editAttachmentId);
  }

  function changeImage(index, image) {
    setEditIndex(index);

    const imageId = images?.[index]?.id;
    setEditAttachmentId(imageId);

    if (
      image.includes(BLOCKED_CORS_BUCKET) ||
      image.includes(SECOND_BLOCKED_CORS_BUCKET)
    ) {
      setUploadCorsImage(image);
    } else {
      setImageLoading(true);
      setEditImage(image);

      setZoom(DEFAULT_CANVAS.zoom);
      setFocalPoint(DEFAULT_CANVAS.focal_point);
    }
  }

  function upload(e) {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];
    if (!file) return;

    reader.onloadend = () => {
      setImageLoading(true);
      setUploadLoading(true);
      saveUploadImage({ original_data: file });
    };
    reader.readAsDataURL(file);
  }

  return (
    <ModalDialog onClose={onCancel}>
      <EditHeaderStyle>
        <EditTileStyle>{t('image_editor.edit_image')}</EditTileStyle>
        <EditCancelIconStyle src={cancelIcon} onClick={onCancel} />
      </EditHeaderStyle>
      <EditBodyStyle>
        <EditImageContainerStyle
          onMouseDown={() => setIsDragging(true)}
          onMouseUp={() => setIsDragging(false)}
          onMouseMove={handleMouseMove}
        >
          <EditStarStyle>
            <EditIconStyle src={starIcon} />
            {
              // eslint-disable-next-line
              hasCoverImage && attachmentId == editAttachmentId && (
                <EditTextStyle>{t('image_editor.cover_photo')}</EditTextStyle>
              )
            }
          </EditStarStyle>
          {!isDragging && (
            <EditDragStyle>
              <EditIconStyle src={dragIcon} />
              <EditTextStyle>{t('image_editor.drag')}</EditTextStyle>
            </EditDragStyle>
          )}
          <EditSliderStyle>
            <EditSlider
              value={zoom}
              onChange={handleSliderChange}
              min={DEFAULT_CANVAS.zoom_min}
              max={DEFAULT_CANVAS.zoom_max}
              step={DEFAULT_CANVAS.zoom_step}
            />
          </EditSliderStyle>
          <EditCanvasStyle
            ref={canvasRef}
            width={canvasWidth}
            height={canvasHeight}
          />
          <EditImageStyle ref={imgRef} src={editImage} onLoad={drawCanvas} />
        </EditImageContainerStyle>
        <EditSelectorStyle height={canvasHeight}>
          {allImages.map((image, index) => {
            return (
              <EditSelectorImageStyle
                key={index}
                image={image}
                onClick={() => changeImage(index, image)}
              >
                <EditSelectorStarStyle>
                  {index === editIndex ? (
                    <EditIconStyle src={starIcon} />
                  ) : (
                    <EditIconStyle src={starEmptyIcon} />
                  )}
                </EditSelectorStarStyle>
              </EditSelectorImageStyle>
            );
          })}
        </EditSelectorStyle>
      </EditBodyStyle>
      {isAdType && (
        <EditSettingsStyle>
          <Checkbox
            value={borderTop}
            onChange={() => {
              setBorderTop(oldValue => !oldValue);
            }}
          >
            {t('image_editor.border_top')}
          </Checkbox>
        </EditSettingsStyle>
      )}
      <EditFooterStyle>
        <Button className="outline" onClick={onCancel}>
          {t('image_editor.cancel')}
        </Button>
        <div>
          <EditUploadButtonStyle primary>
            <input type="file" onChange={e => upload(e)} />
            <span>{t('image_editor.upload')}</span>
          </EditUploadButtonStyle>
          <Button onClick={save}>{t('image_editor.save')}</Button>
        </div>
      </EditFooterStyle>
      {loading || fetchUploadCorsLoading || fetchUploadCorsIsFetching ? (
        <Loader show={true} />
      ) : null}
    </ModalDialog>
  );
}

ImageEditor.propTypes = {
  cardId: PropTypes.number,
  images: PropTypes.array,
  variantImages: PropTypes.array,
  canvasHeight: PropTypes.number,
  canvasWidth: PropTypes.number,
  attachmentId: PropTypes.any,
  uploadImage: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  loading: PropTypes.bool,
  saveUploadImage: PropTypes.func,
  hasCoverImage: PropTypes.bool,
  isAdType: PropTypes.bool,
  planningId: PropTypes.number,
};

export default ImageEditor;
