import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from '../common/CustomCheckbox';
import moment from 'moment-timezone';

import CampaignInput from './CampaignInput';
import DateTimePicker from '../common/DateTimePickerOverrided';
import { DEFAULT_TIMEZONE } from '../../lib/constants';

const RowContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  margin: 5px 0px;
`;

const ColumnContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
`;

function checkedPlanningDates(dates) {
  if (dates) {
    return Object.keys(dates).map(id => {
      return {
        id: id,
        send_datetime: dates[id],
        checked: true,
      };
    });
  } else {
    return [];
  }
}

function planningDatesToObject(planningDates) {
  const datesObject = {};
  planningDates.forEach(planningDate => {
    if (planningDate.checked) {
      datesObject[planningDate.id] = planningDate.send_datetime;
    }
  });
  return datesObject;
}

function CampaignMultipleDateTimeInput({
  name,
  sendDatetimes,
  setSendDatetimes,
}) {
  const [planningDates, setPlanningDates] = useState(
    checkedPlanningDates(sendDatetimes.send_datetime),
  );

  useEffect(() => {
    const datesObj = planningDatesToObject([...planningDates]);
    if (
      JSON.stringify(datesObj) !== JSON.stringify(sendDatetimes.send_datetime)
    ) {
      setSendDatetimes(datesObj);
    }
  }, [planningDates, setSendDatetimes, sendDatetimes]);

  function changePlanningDate(date, index) {
    const datesList = [...planningDates];
    datesList[index].send_datetime = date;
    setPlanningDates(datesList);
  }

  function onCheckboxChange(value, index) {
    const datesList = [...planningDates];
    datesList[index].checked = !!value;
    setPlanningDates(datesList);
  }

  return (
    <CampaignInput name={name}>
      <ColumnContainerStyle>
        {planningDates.map((planningDate, index) => {
          return (
            <RowContainerStyle key={`multiple_${name}_${index}`}>
              <DateTimePicker
                key={`datetime_multiple_${name}_${index}`}
                datetime={planningDate.send_datetime}
                setDatetime={date => {
                  changePlanningDate(date, index);
                }}
                timezone={DEFAULT_TIMEZONE}
                disabled={!planningDate.checked}
                min={moment(planningDate.send_datetime)
                  .startOf('week')
                  .format('YYYY-MM-DDT00:00')}
                max={moment(planningDate.send_datetime)
                  .endOf('week')
                  .format('YYYY-MM-DDT23:59')}
                onKeyDown={event => {
                  event.preventDefault();
                }}
              />
              <Checkbox
                key={`checkbox_multiple_${name}_${index}`}
                value={planningDate.checked}
                onChange={value => onCheckboxChange(value, index)}
              />
            </RowContainerStyle>
          );
        })}
      </ColumnContainerStyle>
    </CampaignInput>
  );
}

CampaignMultipleDateTimeInput.propTypes = {
  name: PropTypes.string,
  sendDatetimes: PropTypes.object,
  setSendDatetimes: PropTypes.func,
};

export default CampaignMultipleDateTimeInput;
