import onePhotoSample from '../assets/images/one-sample.svg';
import twoPhotosSample from '../assets/images/two-sample.svg';
import threePhotosSample from '../assets/images/three-sample.svg';
import fourPhotosSample from '../assets/images/four-sample.svg';

export const VAVATO_WEBSITE_URL_PREFIX = 'https://vavato.com/homepage';
export const TITAN_WEBSITE_URL_PREFIX = 'https://vavato.com/a';

export const COUNTRY_CODES = [
  { code: 'NLD', name: 'Netherlands' },
  { code: 'BEL', name: 'Belgium' },
];

export const DELIVERY_TYPE_CODES = [
  { code: 'free_delivery', name: 'free' },
  { code: 'delivery_not_possible', name: 'not_possible' },
  { code: 'delivery_possible', name: 'possible' },
];

export const TODO_MAIL_STEPPER_LABELS = [
  { name: 'SELECT LOTS', url: '/select_lots' },
  { name: 'CONFIGURE LOT', url: '/configure_lots' },
  { name: 'CONFIGURE CAMPAIGN', url: '/configure_campaign' },
  { name: 'PREVIEW', url: '/preview' },
];

export const CAMPAIGN_CONFIGURATION = {
  LANGUAGE: [
    { id: 'nl', value: 'Dutch' },
    { id: 'fr', value: 'French' },
    { id: 'en', value: 'English' },
  ],
  PRICE: [
    { id: 'opening_bid', value: 'Opening bid' },
    { id: 'current_bid', value: 'Current bid' },
    { id: 'none', value: 'None' },
  ],
  PHOTO_LINK: [
    { id: 'auction', value: 'To the auction page' },
    { id: 'lot', value: 'To the lots page' },
  ],
  MAIL_CTA: [
    { id: 'bid_now', value: 'Bid now' },
    { id: 'more_info', value: 'More information' },
    { id: 'all_lots', value: 'Discover all lots' },
    { id: 'none', value: 'None' },
  ],
  AD_CTA: [
    { id: 'shop_now', value: 'Shop now' },
    { id: 'learn_more', value: 'Learn more' },
    { id: 'no_button', value: 'None' },
  ],
  LAYOUT: [
    { id: 1, value: onePhotoSample },
    { id: 2, value: twoPhotosSample },
    { id: 3, value: threePhotosSample },
    { id: 4, value: fourPhotosSample },
  ],
};

export const AUCTION_DATE_STATUS_COLORS = {
  running: '#A29060',
  announced: '#259B11',
  closed: '#B61E39',
};

export const ADSET_SCHEDULE_STATUS_COLORS = {
  processing: '#A29060',
  scheduled: '#259B11',
  unscheduled: '#B61E39',
  no_integration: '#AAA',
};

export const AUCTION_STATUS_LABEL_CLASSES = {
  new_auction: '',
  lots_available: 'secondary',
  to_do_mail: 'primary',
  to_do_fb: 'primary',
  email_ready: 'success',
  fb_ready: 'success',
  needs_review: 'error',
  all_status: 'neutral',
};

export const SCHEDULED_LABEL_CLASSES = {
  scheduled: 'success',
  unscheduled: 'error',
  processing: 'primary',
};

export const AUCTIONS_LIST_TABLE_COLUMNS = [
  '',
  'id',
  'title',
  'start_date',
  'end_date',
  'country',
  'lots',
  'bid_starts',
  'seller',
  'delivery',
  'status',
  '',
];

export const AUCTIONS_LIST_AD_STATUS_TABLE_COLUMNS = [
  '',
  'id',
  'title',
  'start_date',
  'end_date',
  'country',
  'lots',
  'bid_starts',
  'seller',
  'delivery',
  'ad_status',
  'status',
  '',
];

export const FACEBOOK_AUCTIONS_LIST_TABLE_COLUMNS = [
  '',
  '',
  'id',
  'title',
  'start_date',
  'end_date',
  'country',
  'lots',
  'bid_starts',
  'ad_status',
];

export const FACEBOOK_CAMPAIGNS_LIST_TABLE_COLUMNS = [
  '',
  'audience',
  'languages',
  'placements',
  'lots',
  'budget',
  'actions',
];

export const DEFAULT_TIMEZONE = 'Europe/Brussels';

export const AUCTION_STATUS = [
  'all_status',
  'new_auction',
  'lots_available',
  'to_do_mail',
  'email_ready',
  'needs_review',
];

export const AUCTION_AD_STATUS = [
  'all_status',
  'new_auction',
  'lots_available',
  'to_do_fb',
  'fb_ready',
  'needs_review',
];

export const NEEDS_UPDATE_STATUSES = ['needs_review', 'to_do_mail', 'to_do_fb'];

export const VAVATO_AUCTION_STATUS = [
  'all_auctions',
  'announced',
  'running',
  'closed',
];

export const CAMPAIGN_TYPES_FILTERS = [
  'all',
  'auctionsoftheday',
  'happyupdate',
  'dedicated',
  'category',
];

export const CATEGORY_CAMPAIGN_TYPES = [
  'category close',
  'category start',
  'happy update',
];

export const CATEGORY_CAMPAIGN_SUBSTRING = 'category';

export const BACKEND_AUCTION_STATUS_MAPPING = {
  not_published: 'announced',
  bidding_not_yet_open: 'announced',
  bidding_open: 'running',
  bidding_closed: 'closed',
  retired: 'closed',
};

export const MENU_LABELS = [
  { name: 'auctions', url: '/auctions' },
  { name: 'campaigns', url: '/campaigns' },
  // {
  //   name: 'calendar',
  //   children: [
  //     { name: 'auction', url: '/calendar/auctions' },
  //     { name: 'campaigns', url: '/calendar/campaigns' },
  //   ],
  // },
  // { name: 'settings', url: '/settings' },
];

export const HEADER_DATE_FORMAT = 'MMM DD';
export const FB_COPY_DATE_FORMAT = 'DD/MM';
export const PARAM_DATE_FORMAT = 'YYYY-MM-DD';
export const SIMPLE_DATE_FORMAT = 'DD/MM HH:mm';

export const EXTRA_AUCTION_CANVAS = {
  width: 300,
  height: 200,
};

export const DEFAULT_CANVAS = {
  width: 600,
  height: 400,
  zoom: 1,
  zoom_min: 0.5,
  zoom_max: 4.0,
  zoom_step: 0.01,
  zoom_area: 10,
  focal_point: { x: 0.5, y: 0.5 },
};

export const VERTICAL_DIMENSIONS = {
  width: 300,
  height: 400,
};

export const HORIZONTAL_DIMENSIONS = {
  width: 600,
  height: 300,
};

export const CAROUSEL_DIMENSIONS = {
  width: 700,
  height: 700,
};

export const LOGO_DIMENSIONS = {
  width: 459,
  height: 62,
};

export const DEFAULT_CAMPAIGN_TYPE_CONFIG_FIELDS = [
  'price',
  'photo_link',
  'cta',
  'layout',
  'viewing',
  'highlight_popular_lots',
  'send_datetime',
  'lots_selection',
  'additional_info',
];

export const CAMPAIGN_TYPES_CONFIG_FIELDS = {
  'Auctions of the day start': [
    'photo_link',
    'cta',
    'layout',
    'send_datetime',
    'lots_selection',
  ],
  'Auctions of the day close': [
    'photo_link',
    'cta',
    'layout',
    'send_datetime',
    'lots_selection',
  ],
  'Dedicated start': DEFAULT_CAMPAIGN_TYPE_CONFIG_FIELDS,
  'Dedicated close': DEFAULT_CAMPAIGN_TYPE_CONFIG_FIELDS,
  'Happy update': ['cta', 'multiple_datetime', 'auction_selection'],
  'Facebook carousel': [
    'language',
    'copy',
    'cta',
    'budget',
    'layout',
    'send_datetime',
    'lots_selection',
  ],
  'Instagram carousel': [
    'language',
    'copy',
    'cta',
    'budget',
    'layout',
    'send_datetime',
    'lots_selection',
  ],
};

export const CAMPAIGN_TYPE_EDIT_LOT = 'lot';
export const CAMPAIGN_TYPE_EDIT_AUCTION = 'auction';

export const CAMPAIGN_TYPES_EDIT_FIELD = {
  'Auctions of the day': CAMPAIGN_TYPE_EDIT_LOT,
  'Auctions of the day start': CAMPAIGN_TYPE_EDIT_LOT,
  'Auctions of the day close': CAMPAIGN_TYPE_EDIT_LOT,
  Dedicated: CAMPAIGN_TYPE_EDIT_LOT,
  'Dedicated start': CAMPAIGN_TYPE_EDIT_LOT,
  'Dedicated close': CAMPAIGN_TYPE_EDIT_LOT,
  Category: CAMPAIGN_TYPE_EDIT_LOT,
  'Category start': CAMPAIGN_TYPE_EDIT_LOT,
  'Category close': CAMPAIGN_TYPE_EDIT_LOT,
  'Happy update': CAMPAIGN_TYPE_EDIT_AUCTION,
  'Facebook carousel': CAMPAIGN_TYPE_EDIT_LOT,
};

export const CAMPAIGN_TYPE_SYNC_LOTS = [
  'Dedicated start',
  'Dedicated close',
  'Category start',
  'Category close',
  'Facebook carousel',
];

export const SCREEN_SIZE = {
  mobile: 400,
  desktop: 700,
};

export const ASPECT_RATIO_BIG = 'big';
export const ASPECT_RATIO_SMALL = 'small';
export const ASPECT_RATIO_VERTICAL = 'vertical';
export const ASPECT_RATIO_HORIZONTAL = 'horizontal';
export const ASPECT_RATIO_CAROUSEL = 'carousel';

export const PREVIEW_LOCALES = ['en', 'fr', 'nl'];

export const LOT_TITLE_TYPES = ['mail', 'ads'];

export const FB_MAX_LENGTH_TITLE = 40;
export const MAIL_MAX_LENGTH_TITLE = 35;

export const LAYOUT_TYPE_LIMITED = 'limited';
export const LAYOUT_TYPE_UNLIMITED = 'unlimited';
export const LAYOUT_TYPE_CAROUSEL = 'carousel';

export const FACEBOOK_VALIDATION = {
  MIN_BUDGET: 5,
  MIN_LAYOUT: 3,
  MAX_LAYOUT: 10,
};

export const TEMPLATE_OPTIONS = [
  { name: 'v2', id: 2 },
  { name: 'v2black', id: 4 },
  { name: 'v2worldcup', id: 5 },
];

export const FACEBOOK_LIMIT_USAGE_WARNING = 50;

export const CAMPAIGN_REPORT_FORMATS = ['csv', 'json'];

export const DEFAULT_SEGMENT = {
  id: 999999,
  name: 'Taal:EN/NL/FR (default)',
  utm_content_name: 'Taal:',
};

export const DELIVERY_TYPE_OPTIONS = [
  { id: 0, name: 'delivery possible', value: 'delivery_possible' },
  { id: 1, name: 'delivery not possible', value: 'delivery_not_possible' },
  { id: 2, name: 'free delivery', value: 'free_delivery' },
];

export const CAMPAIGN_TABS = ['campaign', 'auction', 'lots'];

export const EXTERNAL_AUCTION_TABS = ['auction', 'lots'];

export const CAMPAIGN_TYPES = [
  { id: 0, name: 'Auctions of the day start', value: '1' },
  { id: 1, name: 'Auctions of the day close', value: '2' },
  { id: 2, name: 'Happy update', value: '7' },
  { id: 3, name: 'Dedicated start', value: '5' },
  { id: 4, name: 'Dedicated close', value: '6' },
  { id: 5, name: 'Category start', value: '8' },
  { id: 6, name: 'Category close', value: '9' },
];

export const CTA_OPTIONS = [
  { id: 0, name: 'Bid now', value: 'bid_now' },
  { id: 1, name: 'More information', value: 'more_info' },
  { id: 2, name: 'Discover all lots', value: 'all_lots' },
  { id: 3, name: 'None', value: 'none' },
];

export const BID_LABEL_OPTIONS = [
  { id: 0, name: 'Starting Bid', value: 'opening_bid' },
  { id: 1, name: 'Current Bid', value: 'current_bid' },
];

export const CAMPAIGN_TYPE_WITH_LOTS = ['5', '6', '8', '9'];
