import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, ToasterContext } from 'vavato-ui';
import { useRecreateIntegrationCampaign } from '../../hooks/services/CampaignsAPI';

const RecreateContainerButtonStyle = styled.div`
  button {
    padding: 0;
    width: 150px;
    height: 24px;
    line-height: 0;
    font-size: 10px;
    border-radius: 3px;
    letter-spacing: 0.8px;
  }
`;

function FacebookRecreateButton({ planning, closeModal, setScheduleLoading }) {
  const { t } = useTranslation();
  const { error, success } = useContext(ToasterContext);
  const campaign_id = planning?.campaign_id;

  const { mutateAsync: recreateIntegrationMutate } =
    useRecreateIntegrationCampaign(campaign_id);

  async function recreateIntegration(e) {
    e.preventDefault();
    try {
      setScheduleLoading(true);
      await recreateIntegrationMutate();
      success(t('toaster.recreating_campaign_integration'));
      closeModal();
      setScheduleLoading(false);
    } catch (err) {
      error(err?.response?.data?.message);
      setScheduleLoading(false);
    }
  }

  return (
    <RecreateContainerButtonStyle>
      <Button
        primary
        onClick={e => {
          recreateIntegration(e);
        }}
      >
        {t('campaigns.recreate_integration')}
      </Button>
    </RecreateContainerButtonStyle>
  );
}

FacebookRecreateButton.propTypes = {
  planning: PropTypes.object,
  closeModal: PropTypes.func,
  setScheduleLoading: PropTypes.func,
};

export default FacebookRecreateButton;
