import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchFeatureData } from '../services/FeatureAPI';

const FeatureContext = createContext();

function FeatureProvider({ initState, children }) {
  const initialState = initState || null;
  const [feature, setFeature] = useState(initialState);

  useEffect(() => {
    if (initialState) return;
    async function fetchData() {
      const featureData = await fetchFeatureData();
      setFeature(featureData.data);
    }
    fetchData();
  }, [initialState]);

  return (
    <FeatureContext.Provider value={feature}>
      {children}
    </FeatureContext.Provider>
  );
}

function useFeature() {
  return useContext(FeatureContext);
}

FeatureProvider.propTypes = {
  children: PropTypes.any,
  initState: PropTypes.object,
};

export { FeatureProvider, useFeature };
