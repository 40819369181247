import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import CampaignDrop from './CampaignDrop';
import CampaignRadioInput from './CampaignRadioInput';
import CampaignCounterInput from './CampaignCounterInput';
import CampaignDropLots from './CampaignDropLots';
import CampaignDropAuction from './CampaignDropAuction';
import {
  CAMPAIGN_CONFIGURATION,
  LAYOUT_TYPE_LIMITED,
} from '../../lib/constants';
import Planning from '../../models/Planning';

const LayoutContentStyle = styled.div`
  height: ${props => (props.unlimited ? '60px' : '100px')};
`;

function CampaignLayout({
  auction,
  lots,
  setEdit,
  index,
  plannings,
  configuration,
  setConfiguration,
  lotsOrder,
  setLotsOrder,
  setCanvasDimensions,
  configFields,
  loadingLots,
  coverImage,
  openPlanning,
  lastEditedLot,
  setLastEditedLot,
}) {
  const { t } = useTranslation();

  const layoutType = Planning.layoutType(configuration);
  const isUnlimited =
    configuration.layout_unlimited || configuration.layout_carousel;

  return (
    <>
      {configFields?.includes('layout') && (
        <LayoutContentStyle unlimited={isUnlimited}>
          {isUnlimited ? (
            <CampaignCounterInput
              name={t('campaign_config.layout')}
              counter={configuration.layout}
              setCounter={counter => setConfiguration('layout', counter)}
            />
          ) : (
            <CampaignRadioInput
              index={index}
              name={t('campaign_config.layout')}
              type={'image'}
              options={CAMPAIGN_CONFIGURATION.LAYOUT}
              checked={configuration.layout}
              onCheck={option => setConfiguration('layout', option)}
            />
          )}
        </LayoutContentStyle>
      )}
      {!loadingLots && configFields?.includes('lots_selection') && (
        <>
          <CampaignDrop
            layout={configuration.layout}
            layoutType={layoutType}
            canDrop={true}
            plannings={plannings}
            linkAdSet={configuration.link}
            setConfiguration={setConfiguration}
            openPlanning={openPlanning}
            lastEditedLot={lastEditedLot}
          >
            <CampaignDropLots
              lots={lots}
              lotsOrder={lotsOrder}
              setLotsOrder={setLotsOrder}
              setEdit={setEdit}
              layout={configuration.layout}
              setCanvasDimensions={setCanvasDimensions}
              layoutType={layoutType}
              coverImage={coverImage}
              setLastEditedLot={setLastEditedLot}
            />
          </CampaignDrop>
        </>
      )}
      {configFields?.includes('auction_selection') && (
        <CampaignDrop
          layout={1}
          layoutType={LAYOUT_TYPE_LIMITED}
          canDrop={false}
          plannings={plannings}
        >
          <CampaignDropAuction
            layout={1}
            auction={auction}
            setEdit={setEdit}
            setCanvasDimensions={setCanvasDimensions}
            coverImage={coverImage}
          />
        </CampaignDrop>
      )}
    </>
  );
}

CampaignLayout.propTypes = {
  auction: PropTypes.object,
  lots: PropTypes.array,
  setEdit: PropTypes.func,
  index: PropTypes.number,
  plannings: PropTypes.any,
  configuration: PropTypes.object,
  setConfiguration: PropTypes.func,
  lotsOrder: PropTypes.array,
  setLotsOrder: PropTypes.func,
  setCanvasDimensions: PropTypes.func,
  configFields: PropTypes.array,
  loadingLots: PropTypes.bool,
  coverImage: PropTypes.func,
  lastEditedLot: PropTypes.object,
  setLastEditedLot: PropTypes.func,
  openPlanning: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
};

export default CampaignLayout;
