import { darken } from 'polished';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { formatDate, isPastDate, formatDateToUrl } from '../../lib/dateHelper';
import { PREVIEW_LOCALES, TEMPLATE_OPTIONS } from '../../lib/constants';
import AuctionsListTableContent from '../auction/ListTableContent';
import Loader from '../common/LoaderOverrided';
import { Button, ToasterContext } from 'vavato-ui';
import {
  useFetchGroupedPlanningsData,
  useScheduleCampaign,
  useUnscheduleCampaign,
  useUpdateOnMailerCampaign,
  useRecreateIntegrationCampaign,
  useDeleteReplicaCampaign,
  useFetchCampaignData,
} from '../../hooks/services/CampaignsAPI';

import { existingCampaignTypesAllowed } from '../../lib/externalPlanningHelper';
import { useUpdatePlanningOrder } from '../../hooks/services/PlanningsAPI';
import Campaign from '../../models/Campaign';
import CampaignCopyModal from './CampaignCopyModal';
import CampaignReplicateModal from './CampaignReplicateModal';
import AddExternalAuctionToCampaignModal from './AddExternalAuctionToCampaignModal';
import CampaignDetailsPageConfiguration from './CampaignDetailsPageConfiguration';
import { formatURLParams } from '../../lib/formatter';
import infoIcon from '../../assets/icons/info.svg';
import { useFeature } from '../../hooks/contexts/FeatureContext';
import LayoutHeaderWithSideMenu from '../../components/layout/LayoutHeaderWithSideMenu';
import LayoutHeader from '../../components/layout/LayoutContentHeader';

const CAMPAIGN_TABS = ['auctions', 'configuration'];

const CampaignDetailsRowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0;
`;

const CampaignDetailsHeaderStyle = styled(CampaignDetailsRowStyle)`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  &.red {
    span {
      color: ${props => props.theme.changedText};
    }
  }
  &.grey {
    span {
      color: ${props => props.theme.missingText};
    }
  }
`;

const ButtonStyle = styled.div`
  @media screen and (min-width: 1367px) {
    margin-right: 20px;
  }
`;

const CampaignDetailsFooterStyle = styled(CampaignDetailsRowStyle)`
  justify-content: space-between;
  letter-spacing: 1px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  position: fixed;
  bottom: -28px;
  background-color: #fff;
  width: calc(100% - 250px);
  overflow-x: auto;
`;

const CampaignDetailsRightFooterStyle = styled.div`
  display: flex;
  .delete_button {
    background: #b61e39;
  }
`;

const PreviewLinkStyle = styled.a`
  cursor: pointer;
  text-transform: uppercase;
  color: ${props => props.theme.primary};

  :hover {
    color: ${props => darken(0.2, props.theme.primary)};
    text-decoration: none;
  }
`;

const AuctionListTableHeader = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 4px 0;
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.lightText};
  background-color: ${props => props.theme.tableHeaders};
`;

const InfoStyle = styled.img`
  margin-left: 10px;
`;

const CampaignDetailsContainer = styled.div`
  margin: 20px;
  min-height: calc(100vh - 120px);
`;

const AuctionListBody = styled.div`
  padding-bottom: 3rem;
`;

const LayoutContainerStyle = styled.div`
  min-height: 100vh;
  position: relative;
`;

const TabsContainerStyle = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #595a5c;
  margin-bottom: 10px;
  padding-bottom: 5px;
`;

const TabStyle = styled.span`
  margin-right: 30px;
  font-size: 0.8em;
  cursor: pointer;
  color: #aaa;
  &.active {
    color: #595a5c;
  }
`;

function CampaignDetailsPage() {
  const { t } = useTranslation();
  const { error, success } = useContext(ToasterContext);
  const feature = useFeature();
  const { id } = useParams();
  const history = useHistory();

  const { data: campaignData, isFetching: campaignIsFetching } =
    useFetchCampaignData(id);

  const {
    data: groupedPlanningsData,
    error: groupedPlanningsError,
    isError: groupedPlanningsIsError,
    isFetching: groupedPlanningsIsFetching,
  } = useFetchGroupedPlanningsData(id);

  const [auctions, setAuctions] = useState(null);
  const [planningsOrder, setPlanningsOrder] = useState({});
  const [groupedPlannings, setGroupedPlannings] = useState(null);
  const [externalPlannings, setExternalPlannings] = useState([]);

  const [setupLoading, setSetupLoading] = useState(false);
  const [openCampaignCopy, setOpenCampaignCopy] = useState(false);
  const [openReplicateModal, setOpenReplicateModal] = useState(false);
  const [campaign, setCampaign] = useState({ plannings: [] });
  const [activeTab, setActiveTab] = useState(CAMPAIGN_TABS[0]);
  const [
    openAddExternalAuctionToCampaignModal,
    setOpenAddExternalAuctionToCampaignModal,
  ] = useState(false);

  const isIntegrationComplete =
    !!campaign && Campaign.integrationComplete(campaign);

  const [campaignConfig, setCampaignConfig] = useState({
    mail_template: null,
    mail_subtitle: {},
    mail_subject: {},
    mail_preview_text: {},
    mail_from_name: {},
    mailchimp_segment: {},
    mailchimp_tag: {},
    black_friday_banner: {},
    banner_image_url: {},
    banner_destination_link: {},
  });

  // use prevPath to get campaigns filter when returning
  const links = [
    { path: '/', name: 'home' },
    { path: '/campaigns', name: 'campaigns.title' },
    { path: `/campaigns/${id}`, name: `Campaign ${id}` },
  ];

  const { mutateAsync: scheduleCampaignMutate } = useScheduleCampaign(id, {
    ...campaignConfig,
    mail_template: campaignConfig.mail_template?.name,
  });

  const { mutateAsync: unscheduleCampaignMutate } = useUnscheduleCampaign(id);
  const { mutateAsync: updatePlanningOrderMutate } = useUpdatePlanningOrder(id);
  const { mutateAsync: recreateIntegrationMutate } =
    useRecreateIntegrationCampaign(id);

  const { mutateAsync: updateOnMailerCampaignMutate } =
    useUpdateOnMailerCampaign(id, { ...campaignConfig });

  const { mutateAsync: deleteReplicaMutate } = useDeleteReplicaCampaign(id);

  function isGroupedByCategory(groupedPlannings) {
    return Array.isArray(groupedPlannings) && groupedPlannings?.[0]?.[0]?.en;
  }

  function isGroupedByStartClose(groupedPlannings) {
    return !Array.isArray(groupedPlannings);
  }

  function isFacebookCampaignType() {
    return (
      campaignData?.data?.campaign_type.name === 'Facebook carousel' ||
      campaignData?.data?.campaign_type.name === 'Instagram carousel'
    );
  }

  function parseGroupedPlanningsByCategory(groupedPlannings) {
    groupedPlannings.forEach(([category, plannings]) => {
      if (!plannings.length) {
        return;
      }
      const categoryName = category.en;
      setGroupedPlannings(prevGroupedPlannings => {
        return {
          ...prevGroupedPlannings,
          [categoryName]: [...plannings],
        };
      });
    });
  }

  function parseGroupedPlanningsByStartClose(groupedPlannings) {
    Object.keys(groupedPlannings)
      .sort()
      .forEach(key => {
        const plannings = groupedPlannings[key];
        if (!plannings.length) {
          return;
        }
        setGroupedPlannings(prevGroupedPlannings => {
          return { ...prevGroupedPlannings, [key]: [...plannings] };
        });
      });
  }

  function parseGroupedPlannings(groupedPlannings) {
    if (!groupedPlannings.length) {
      return;
    }

    let campaignTypeName = campaignData?.data?.campaign_type?.name;
    if (campaignTypeName.includes('close')) {
      campaignTypeName = 'Closing';
    } else {
      campaignTypeName = 'Starting';
    }
    setGroupedPlannings(prevGroupedPlannings => {
      return {
        ...prevGroupedPlannings,
        [campaignTypeName]: [...groupedPlannings],
      };
    });
  }

  useEffect(() => {
    if (!campaignData) return;
    if (!groupedPlanningsData) return;
    const groupedPlannings = groupedPlanningsData?.data;

    if (isFacebookCampaignType()) {
      error(t('toaster.facebook_page_view'));
      history.push('/campaigns/');
    }

    if (isGroupedByCategory(groupedPlannings)) {
      parseGroupedPlanningsByCategory(groupedPlannings);
    } else if (isGroupedByStartClose(groupedPlannings)) {
      parseGroupedPlanningsByStartClose(groupedPlannings);
    } else {
      parseGroupedPlannings(groupedPlannings);
    }

    // eslint-disable-next-line
  }, [groupedPlanningsData, campaignData]);

  useEffect(() => {
    if (!campaignData?.data) return;

    const campaignAuctions = campaignData?.data?.plannings.map(
      planning => planning.auction,
    );

    if (campaignData?.data?.complementary_campaign?.plannings) {
      const complementaryCampaignAuctions =
        campaignData?.data?.complementary_campaign?.plannings.map(
          planning => planning.auction,
        );
      setAuctions([...campaignAuctions.concat(complementaryCampaignAuctions)]);
    } else {
      setAuctions([...campaignAuctions]);
    }

    const externalPlannings = campaignData?.data?.external_plannings || [];
    setExternalPlannings([
      ...externalPlannings.concat(
        campaignData?.data?.complementary_campaign?.external_plannings || [],
      ),
    ]);

    setCampaign(Campaign.fillCampaignFields(campaignData?.data));

    // eslint-disable-next-line
  }, [campaignData]);

  useEffect(() => {
    setCampaignConfig({
      mail_template: TEMPLATE_OPTIONS.find(
        x => x.name === campaign?.mail_template,
      ),
      mail_subtitle: campaign?.mail_subtitle,
      mail_subject: campaign?.mail_subject,
      mail_preview_text: campaign?.mail_preview_text,
      mail_from_name: campaign?.mail_from_name,
      mailchimp_segment: campaign?.mailchimp_segment,
      campaign_utm_tags: campaign?.campaign_utm_tags,
      black_friday_banner: campaign?.black_friday_banner,
      banner_destination_link: campaign?.banner_destination_link ?? {},
      banner_image_url: campaign?.banner_image_url ?? {},
    });
  }, [campaign]);

  function orderedAuctions(group) {
    const plannings = groupedPlannings[group];
    const auctionIds = plannings.map(planning => planning.auction_id);
    return auctionIds.map(auctionId =>
      auctions.find(auction => auction.id === auctionId),
    );
  }

  function swapOrder(group, dragId, dropId) {
    const plannings = [...groupedPlannings[group]];
    const auctions = plannings.map(planning => planning.auction_id);
    const dropIndex = auctions.indexOf(dropId);
    const dragIndex = auctions.indexOf(dragId);
    if (dropIndex === -1 || dragIndex === -1) return;

    const dragPlanning = plannings.find(
      planning => planning.auction_id === dragId,
    );
    plannings.splice(dragIndex, 1);
    plannings.splice(dropIndex, 0, dragPlanning);

    groupedPlannings[group] = plannings;
    setGroupedPlannings(groupedPlannings);

    updatePlanningsOrder(plannings);
  }

  function updatePlanningsOrder(plannings) {
    plannings.forEach((planning, index) => {
      setPlanningsOrder(prevPlanningsOrderr => {
        return { ...prevPlanningsOrderr, [planning.id]: index };
      });
    });
  }

  function openCampaign(event, locale, utmTags = {}) {
    event.preventDefault();
    const params = {
      locale,
      ...utmTags,
      template: campaignConfig?.mail_template?.name,
    };
    const queryParams = formatURLParams(params);
    const campaignUrl = campaign.url + queryParams;
    window.open(campaignUrl, '_blank');
  }

  async function unscheduleCampaign(e) {
    e.preventDefault();
    try {
      setSetupLoading(true);
      await unscheduleCampaignMutate();
      success(t('toaster.processing_campaign_unschedule'));
      setSetupLoading(false);
    } catch (err) {
      error(err.response.data.message);
      setSetupLoading(false);
    }
  }

  async function scheduleCampaign(e) {
    e.preventDefault();
    try {
      setSetupLoading(true);
      await scheduleCampaignMutate();
      success(t('toaster.processing_campaign_schedule'));
      setSetupLoading(false);
    } catch (err) {
      error(err.response.data.message);
      setSetupLoading(false);
    }
  }

  async function recreateIntegrationCampaign(e) {
    e.preventDefault();
    try {
      setSetupLoading(true);
      await recreateIntegrationMutate();
      success(t('toaster.recreating_campaign_integration'));
      setSetupLoading(false);
    } catch (err) {
      error(err.response.data.message);
      setSetupLoading(false);
    }
  }

  function disableButton() {
    if (campaign.mailer_schedule_status === 'no_integration') return true;
    return (
      campaign.mailer_schedule_status === 'processing' ||
      isPastDate(campaign.scheduled_send_time)
    );
  }

  async function saveOrder(e) {
    e.preventDefault();
    try {
      setSetupLoading(true);
      const updatePromisses = Object.keys(planningsOrder).map(
        async planningOrder => {
          const params = {
            id: planningOrder,
            order: planningsOrder[planningOrder],
          };
          await updatePlanningOrderMutate(params);
        },
      );
      await Promise.all(updatePromisses);
      await updateOnMailerCampaignMutate();
      success(t('toaster.campaign_saved'));
      setSetupLoading(false);
    } catch (err) {
      error(err.response.data.message);
      setSetupLoading(false);
    }
  }

  function renderIntegrationErrorInfo() {
    return (
      campaign?.integration_error && (
        <InfoStyle src={infoIcon} title={campaign?.integration_error} />
      )
    );
  }

  function integrationTitleClass() {
    if (feature?.mailchimp_integration) {
      if (campaign.mailer_schedule_status === 'no_integration') return 'grey';
      return isIntegrationComplete && !campaign.integration_error ? '' : 'red';
    }

    return '';
  }

  async function deleteReplica(e) {
    e.preventDefault();
    try {
      setSetupLoading(true);
      await deleteReplicaMutate();
      success(t('toaster.replica_deleted'));
      setSetupLoading(false);
      history.push(
        `/campaigns?date=${formatDateToUrl(campaign?.scheduled_send_time)}`,
      );
    } catch (err) {
      error(err.response.data.message);
      setSetupLoading(false);
    }
  }

  function recreateButton() {
    if (feature?.mailchimp_integration) {
      return (
        <Button
          primary
          disabled={campaign.mailer_schedule_status === 'scheduled'}
          onClick={e => recreateIntegrationCampaign(e)}
        >
          {t('campaigns.recreate_integration')}
        </Button>
      );
    }
  }

  function addExternalAuctionButton() {
    if (feature?.extra_plannings && existingCampaignTypesAllowed(campaign)) {
      return (
        <Button
          primary
          disabled={campaign.mailer_schedule_status === 'scheduled'}
          onClick={() => setOpenAddExternalAuctionToCampaignModal(true)}
        >
          {t('campaigns.add_external_auction')}
        </Button>
      );
    }
  }

  function scheduleButton() {
    if (feature?.mailchimp_integration) {
      return (
        <ButtonStyle>
          {campaign.mailer_schedule_status === 'scheduled' ? (
            <Button
              onClick={e => unscheduleCampaign(e)}
              disabled={
                !isIntegrationComplete ||
                isPastDate(campaign.scheduled_send_time)
              }
            >
              {t('campaigns.unschedule')}
            </Button>
          ) : (
            <Button
              onClick={e => scheduleCampaign(e)}
              disabled={!isIntegrationComplete || disableButton()}
            >
              {t('campaigns.schedule')}
            </Button>
          )}
        </ButtonStyle>
      );
    }
  }

  if (groupedPlanningsIsError) {
    error(groupedPlanningsError?.message);
  }

  if (!auctions || groupedPlanningsIsFetching || campaignIsFetching) {
    return <Loader show={true} />;
  }

  return (
    <LayoutContainerStyle>
      <LayoutHeaderWithSideMenu>
        <LayoutHeader prevLink={`/`} links={links} />

        <DndProvider backend={HTML5Backend}>
          {campaign && (
            <CampaignDetailsContainer>
              <CampaignDetailsHeaderStyle className={integrationTitleClass()}>
                <span>
                  {`${formatDate(campaign?.scheduled_send_time)} - ${
                    campaign?.campaign_title
                  }`}
                  {(!isIntegrationComplete || campaign?.integration_error) &&
                    renderIntegrationErrorInfo()}
                </span>
              </CampaignDetailsHeaderStyle>
              <TabsContainerStyle>
                <TabStyle
                  data-testid={'auctions-tab'}
                  className={activeTab === 'auctions' ? 'active' : ''}
                  onClick={() => {
                    setActiveTab('auctions');
                  }}
                >
                  {t('campaigns.details.auctions')}
                </TabStyle>
                <TabStyle
                  data-testid={'configuration-tab'}
                  className={activeTab === 'configuration' ? 'active' : ''}
                  onClick={() => {
                    setActiveTab('configuration');
                  }}
                >
                  {t('campaigns.details.configuration')}
                </TabStyle>
              </TabsContainerStyle>

              <AuctionListBody>
                {activeTab === 'configuration' && (
                  <CampaignDetailsPageConfiguration
                    setCampaignConfig={setCampaignConfig}
                    campaignConfig={campaignConfig}
                  />
                )}
                {activeTab === 'auctions' && (
                  <>
                    {groupedPlannings &&
                      Object.keys(groupedPlannings).map(group => {
                        return (
                          <div key={group}>
                            <AuctionListTableHeader>
                              <span>{group}</span>
                            </AuctionListTableHeader>
                            <AuctionsListTableContent
                              setSetupLoading={setSetupLoading}
                              plannings={groupedPlannings[group]}
                              auctions={orderedAuctions(group)}
                              isDraggable={true}
                              isHideable={
                                !!feature?.segmentation &&
                                feature?.mailchimp_integration &&
                                campaign.mailer_schedule_status !== 'scheduled'
                              }
                              onDrop={(dragId, dropId) =>
                                swapOrder(group, dragId, dropId)
                              }
                              showCampaignStatus={true}
                              campaign={campaign}
                            />
                          </div>
                        );
                      })}
                    {!!externalPlannings.length && (
                      <div key={'external'}>
                        <AuctionListTableHeader>
                          <span>{`${t(
                            'campaigns.external_auction.external_plannings_title',
                          )}`}</span>
                        </AuctionListTableHeader>
                        <AuctionsListTableContent
                          setSetupLoading={setSetupLoading}
                          plannings={externalPlannings}
                          auctions={externalPlannings.map(planning => {
                            return {
                              ...planning.settings.auction,
                              planning: planning,
                              payload: { ...planning.settings.auction },
                            };
                          })}
                          isDraggable={false}
                          isDeletable={true}
                          isHideable={false}
                          isExternal={true}
                          onDrop={() => {}}
                          showCampaignStatus={true}
                          campaign={campaign}
                        />
                      </div>
                    )}
                  </>
                )}
              </AuctionListBody>

              <CampaignDetailsFooterStyle>
                <span>
                  {t('campaigns.see_newsletter')}:{' '}
                  {PREVIEW_LOCALES.map(locale => {
                    return (
                      <PreviewLinkStyle
                        key={locale}
                        onClick={e => openCampaign(e, locale)}
                      >
                        {`${locale} `}
                      </PreviewLinkStyle>
                    );
                  })}
                </span>
                <CampaignDetailsRightFooterStyle>
                  {addExternalAuctionButton()}
                  {feature?.segmentation && (
                    <>
                      {campaign.replica_of_id ? (
                        <Button
                          className="delete_button"
                          disabled={
                            feature?.mailchimp_integration &&
                            campaign.mailer_schedule_status === 'scheduled'
                          }
                          onClick={deleteReplica}
                        >
                          {t('campaigns.delete_replica')}
                        </Button>
                      ) : (
                        <Button
                          primary
                          disabled={false}
                          onClick={() => {
                            setOpenReplicateModal(true);
                          }}
                        >
                          {t('campaigns.replicate')}
                        </Button>
                      )}
                    </>
                  )}
                  {recreateButton()}
                  <Button
                    primary
                    onClick={() => {
                      setOpenCampaignCopy(true);
                    }}
                  >
                    {t('campaigns.create_link')}
                  </Button>
                  <Button
                    primary
                    onClick={e => saveOrder(e)}
                    disabled={isPastDate(campaign.scheduled_send_time)}
                  >
                    {t('campaigns.save_order')}
                  </Button>
                  {scheduleButton()}
                </CampaignDetailsRightFooterStyle>
              </CampaignDetailsFooterStyle>
              {openCampaignCopy ? (
                <CampaignCopyModal
                  campaign={campaign}
                  openCampaign={openCampaign}
                  closeModal={() => {
                    setOpenCampaignCopy(false);
                  }}
                />
              ) : null}
              {openReplicateModal ? (
                <CampaignReplicateModal
                  setSetupLoading={setSetupLoading}
                  campaign={campaign}
                  closeModal={() => {
                    setOpenReplicateModal(false);
                  }}
                />
              ) : null}
              {openAddExternalAuctionToCampaignModal ? (
                <AddExternalAuctionToCampaignModal
                  setSetupLoading={setSetupLoading}
                  campaign={campaign}
                  setOpenAddExternalAuctionToCampaignModal={
                    setOpenAddExternalAuctionToCampaignModal
                  }
                />
              ) : null}
              {setupLoading ? <Loader show={true} /> : null}
            </CampaignDetailsContainer>
          )}
        </DndProvider>
      </LayoutHeaderWithSideMenu>
    </LayoutContainerStyle>
  );
}

CampaignDetailsPage.propTypes = {};

export default CampaignDetailsPage;
