import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import calendarIcon from '../../assets/icons/calendar.svg';
import calendarArrow from '../../assets/icons/calendar-arrow.svg';
import moment from 'moment-timezone';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import { PARAM_DATE_FORMAT } from '../../lib/constants';
import { formatDateRange } from '../../lib/dateHelper';
import FilterTabs from '../common/FilterTabs';
import { useHistory, useLocation } from 'react-router-dom';

const ContainerStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 40px;
  align-items: center;
  div {
    z-index: 3 !important;
  }
  .range-calendar {
    z-index: 3;
    font-weight: 300;
    .Calendar__day {
      color: #a4a4a4;
      &.-selectedStart {
        border-radius: 5px !important;
        color: #fff;
      }
      &.-selectedEnd {
        border-radius: 5px !important;
        color: #fff;
      }
    }
    .Calendar__weekDays {
      color: #505050;
    }
    .Calendar__monthArrowWrapper {
      &.-right {
        transform: rotate(-180deg);
      }
      &.-left {
        transform: none;
      }
    }
    .Calendar__monthArrow {
      background-image: ${`url(${calendarArrow})`};
    }
  }
`;

const DateTextContainer = styled.span`
  color: #595a5c;
  font-size: 16px;
  margin-left: 16px;
  letter-spacing: 1px;
`;

const DateFilterContainer = styled.div`
  height: 55px;
  display: flex;
  justify-content: center;
  overflow: visible;
`;

const DatePickerContainer = styled.input`
  height: 40px;
  width: 40px;
  border-radius: 3px;
  border: none;
  color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6192a1;
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: center;
  cursor: pointer;
  background-image: ${`url(${calendarIcon})`};
  &:focus {
    outline: none;
  }
`;

function formatToDatepicker(dates) {
  const momentFrom = moment(dates.from, PARAM_DATE_FORMAT);
  const momentTo = moment(dates.to, PARAM_DATE_FORMAT);
  return {
    from: {
      year: momentFrom.year(),
      month: momentFrom.month() + 1,
      day: momentFrom.date(),
    },
    to: {
      year: momentTo.year(),
      month: momentTo.month() + 1,
      day: momentTo.date(),
    },
  };
}

function renderDatePickerBlock({ ref }) {
  return <DatePickerContainer data-testid="datepicker-input" ref={ref} />;
}

function DateRangeFilter({
  selectedDates,
  setSelectedDates,
  setDateOrder,
  dateOrder,
}) {
  const [datepickerDates, setDatepickerDates] = useState(
    formatToDatepicker(selectedDates),
  );
  const location = useLocation();
  const history = useHistory();

  function handleChange(values) {
    setDatepickerDates(values);
    if (values.from && values.to) {
      setSelectedDates({
        from: moment({ ...values.from, month: values.from.month - 1 }).format(
          PARAM_DATE_FORMAT,
        ),
        to: moment({ ...values.to, month: values.to.month - 1 }).format(
          PARAM_DATE_FORMAT,
        ),
        order: dateOrder,
      });
    }
  }

  function handleDateFilter(_event, value) {
    setDateOrder(value);
    const params = new URLSearchParams(location.search);
    params.set('date_filter', value);
    history.push({ search: params.toString() });
  }

  return (
    <>
      <ContainerStyle>
        <DatePicker
          value={datepickerDates}
          onChange={handleChange}
          renderInput={renderDatePickerBlock}
          colorPrimary="#6192A1"
          colorPrimaryLight="#E6ECF2"
          calendarClassName="range-calendar"
          renderFooter={() => (
            <DateFilterContainer data-testid="filter-tab">
              <FilterTabs
                selectedValue={dateOrder}
                handleChange={handleDateFilter}
                options={['start_date', 'end_date']}
                translationPath="auction_list.view_modes"
              />
            </DateFilterContainer>
          )}
        />
        <DateTextContainer>
          {formatDateRange(selectedDates.from, selectedDates.to)}
        </DateTextContainer>
      </ContainerStyle>
    </>
  );
}

DateRangeFilter.propTypes = {
  selectedDates: PropTypes.object,
  setSelectedDates: PropTypes.func,
  setDateOrder: PropTypes.func,
  dateOrder: PropTypes.string,
};

export default DateRangeFilter;
