import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from 'vavato-ui';

import ModalDialog from './ModalDialog';

const RowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 20px;
`;

const HeaderStyle = styled(RowStyle)`
  width: 300px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  justify-content: center;
`;

const FooterStyle = styled(RowStyle)`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  justify-content: center;
`;

const RightFooterStyle = styled.div`
  margin-right: -20px;
`;

function ConfirmationModal({ onConfirm, onCancel, message }) {
  const { t } = useTranslation();

  return (
    <ModalDialog>
      <HeaderStyle>
        <span> {t('are_you_sure')} </span>
      </HeaderStyle>
      <RowStyle>
        <span> {message} </span>
      </RowStyle>
      <FooterStyle>
        <RightFooterStyle>
          <Button
            primary
            onClick={() => {
              onCancel();
            }}
          >
            {t('footer.cancel')}
          </Button>
          <Button
            primary
            onClick={() => {
              onConfirm();
            }}
          >
            {t('footer.confirm')}
          </Button>
        </RightFooterStyle>
      </FooterStyle>
    </ModalDialog>
  );
}

ConfirmationModal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  message: PropTypes.string,
};

export default ConfirmationModal;
