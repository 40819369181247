import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Table } from 'vavato-ui';
import FacebookAuctionScheduleRow from '../auction/FacebookAuctionScheduleRow';
import { FACEBOOK_AUCTIONS_LIST_TABLE_COLUMNS } from '../../lib/constants';
import FacebookPlanningsTable from './FacebookPlanningsTable';
import Loader from '../common/LoaderOverrided';

const TableContainerStyle = styled.div`
  display: flex;
  width: 1200px;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: scroll;
  overflow-x: visible;
  margin-right: 20px;
  margin-left: 20px;
  max-height: ${props => (props.isDraggable ? '' : 'calc(100vh - 250px)')};
  table {
    margin: 0 !important;
  }
`;

const TableHeadRow = styled.tr`
  height: 32px !important;
  td {
    font-size: 10px !important;
  }
`;

const PlanningTableContainer = styled.div`
  flex: 1;
  padding: 5px;
  display: flex;
  overflow: none;
  flex-direction: row;
`;

function FacebookAuctionTable({ auction, closeModal, campaign }) {
  const { t } = useTranslation();
  const columns = FACEBOOK_AUCTIONS_LIST_TABLE_COLUMNS;

  function tableHead() {
    return (
      <TableHeadRow>
        {columns.map((column, index) => {
          return (
            <td key={`${column}_${index}`}>
              {t(column ? `auction_list.table.${column}` : '')}
            </td>
          );
        })}
      </TableHeadRow>
    );
  }

  if (!auction) return <Loader show={true} />;

  return (
    <TableContainerStyle>
      <Table>
        <thead>{tableHead()}</thead>
        <tbody>
          <Fragment key={auction.external_id || auction.atlas_external_id}>
            <FacebookAuctionScheduleRow
              auction={auction}
              closeModal={closeModal}
            />
          </Fragment>
        </tbody>
      </Table>
      <PlanningTableContainer>
        <FacebookPlanningsTable
          closeModal={closeModal}
          plannings={auction.plannings}
          campaign={campaign}
        />
      </PlanningTableContainer>
    </TableContainerStyle>
  );
}

FacebookAuctionTable.propTypes = {
  auction: PropTypes.object,
  closeModal: PropTypes.func,
  campaign: PropTypes.any,
};

export default FacebookAuctionTable;
