import { darken } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';

import filterIcon from '../../assets/icons/filter.svg';
import Checkbox from '../common/CustomCheckbox';

const FilterContainerStyle = styled.div`
  display: flex;
  height: auto;
  position: relative;
  flex-direction: column;
`;

const FilterStyle = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.secondary};

  :active {
    background-color: ${props => darken(0.1, props.theme.secondary)};
  }
`;

const FilterMenuStyle = styled.ul`
  display: flex;
  width: auto;
  top: 40px;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 2;
  padding: 1rem;
  position: absolute;
  list-style: none;
  border-radius: 3px;
  padding-left: 0.5rem;
  flex-direction: column;
  box-shadow: 0px 0px 8px rgba(137, 150, 159, 0.3);
  background-color: ${props => props.theme.cardBackground};
`;

const FilterIconStyle = styled.img``;

const FilterLabelStyle = styled.div`
  min-width: 190px;
`;

const FilterSubmitStyle = styled.a`
  margin-top: 0.75rem;
  font-weight: 300;
  text-align: center;
  cursor: pointer;

  :hover {
    color: ${props => darken(0.2, props.theme.text)};
    text-decoration: none;
  }
`;

function SegmentFilter({ segmentList, selectedSegments, setSelectedSegments }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    if (selectedSegments.length > 0) {
      setOptions(selectedSegments);
      setAllSelected(false);
    } else {
      setAllSelected(true);
    }
  }, [selectedSegments]);

  function addOption(option) {
    setOptions(prevOptions => [...prevOptions, option]);
  }

  function removeOption(option) {
    setOptions(prevOptions =>
      prevOptions.filter(prevOption => prevOption !== option),
    );
  }

  function onSelect(option) {
    if (option === 'all') {
      setOptions([]);
      setAllSelected(prevAllOptions => !prevAllOptions);
      return;
    }

    setAllSelected(false);
    if (options.includes(option)) {
      removeOption(option);
    } else {
      addOption(option);
    }
  }

  function onFilter() {
    setOpen(false);
    setSelectedSegments(options);
  }

  return (
    <FilterContainerStyle>
      <FilterStyle onClick={() => setOpen(prevOpen => !prevOpen)}>
        <FilterIconStyle data-testid={'segment-filter'} src={filterIcon} />
      </FilterStyle>
      {open ? (
        <FilterMenuStyle>
          {segmentList.map(option => {
            let isSelected = options.includes(option);
            if (option === 'all' && allSelected) {
              isSelected = true;
            }

            return (
              <Checkbox
                key={option}
                value={isSelected}
                onChange={() => onSelect(option)}
              >
                <FilterLabelStyle>{option}</FilterLabelStyle>
              </Checkbox>
            );
          })}
          <FilterSubmitStyle onClick={onFilter}>ok</FilterSubmitStyle>
        </FilterMenuStyle>
      ) : null}
    </FilterContainerStyle>
  );
}

SegmentFilter.propTypes = {
  segmentList: PropTypes.array,
  selectedSegments: PropTypes.array,
  setSelectedSegments: PropTypes.func,
};

export default SegmentFilter;
