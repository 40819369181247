import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import CampaignInput from './CampaignInput';
import DateTimePicker from '../common/DateTimePickerOverrided';
import { DEFAULT_TIMEZONE } from '../../lib/constants';
import { isPastDate } from '../../lib/dateHelper';

const RowContainerStyle = styled.div`
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TitleContainerStyle = styled.p`
  margin: 0;
  color: black;
  line-height: 2rem;
  font-size: 0.75rem;
  margin-left: 40px;
  padding: 0 0.5rem;
`;

function CampaignDateTimeInput({
  name,
  sendDatetime,
  setSendDatetime,
  isAdType,
}) {
  const { t } = useTranslation();

  return (
    <CampaignInput name={name}>
      <RowContainerStyle>
        {sendDatetime.send_datetime ? (
          <DateTimePicker
            datetime={sendDatetime.send_datetime}
            setDatetime={setSendDatetime}
            timezone={DEFAULT_TIMEZONE}
          />
        ) : (
          <>
            <TitleContainerStyle>{t('start')}</TitleContainerStyle>
            <DateTimePicker
              datetime={sendDatetime.start_datetime}
              setDatetime={datetime => setSendDatetime(datetime, 'start')}
              timezone={DEFAULT_TIMEZONE}
              disabled={isAdType && isPastDate(sendDatetime.start_datetime)}
              max={moment(sendDatetime.close_datetime).format(
                'YYYY-MM-DDTHH:mm',
              )}
            />
            <TitleContainerStyle>{t('close')}</TitleContainerStyle>
            <DateTimePicker
              datetime={sendDatetime.close_datetime}
              setDatetime={datetime => setSendDatetime(datetime, 'close')}
              timezone={DEFAULT_TIMEZONE}
              min={moment(sendDatetime.start_datetime).format(
                'YYYY-MM-DDTHH:mm',
              )}
            />
          </>
        )}
      </RowContainerStyle>
    </CampaignInput>
  );
}

CampaignDateTimeInput.propTypes = {
  name: PropTypes.string,
  sendDatetime: PropTypes.object,
  setSendDatetime: PropTypes.func,
  isAdType: PropTypes.bool,
};

export default CampaignDateTimeInput;
