import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InputContainerStyle = styled.div`
  display: flex;
  color: #343a40;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LabelStyle = styled.p`
  margin: 0.5rem 1rem;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 2rem;
  text-transform: uppercase;
`;

const InputStyle = styled.input.attrs({ type: 'number' })`
  width: 100%;
  height: 34px;
  padding: 0.75rem;
  padding-left: 1rem;
  font-size: 0.8rem;
  line-height: 1rem;
  border-radius: 5px;
  border: 2px solid #c4c4c4;

  ::placeholder {
    color: #c4c4c4;
  }
`;

function LabeledNumberInput({ label, placeholder, minValue, value, onChange }) {
  return (
    <InputContainerStyle>
      <LabelStyle>{label ? `${label}:` : ''}</LabelStyle>
      <InputStyle
        placeholder={placeholder}
        type={'number'}
        minValue={minValue}
        value={value}
        onChange={onChange}
      />
    </InputContainerStyle>
  );
}

LabeledNumberInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  currencyPlaceholder: PropTypes.string,
  value: PropTypes.string,
  minValue: PropTypes.number,
  onChange: PropTypes.func,
};

export default LabeledNumberInput;
