import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DateTimePicker } from 'vavato-ui';

const DateTimePickerStyle = styled.div`
  input {
    width: 200px;
    max-width: 200px;
    text-align: center;
  }
`;

function DateTimePickerOverrided({
  datetime,
  setDatetime,
  timezone,
  disabled,
  min,
  max,
  onKeyDown,
}) {
  return (
    <DateTimePickerStyle>
      <DateTimePicker
        datetime={datetime}
        setDatetime={setDatetime}
        timezone={timezone}
        disabled={disabled}
        min={min}
        max={max}
        onKeyDown={onKeyDown}
      />
    </DateTimePickerStyle>
  );
}

DateTimePickerOverrided.propTypes = {
  datetime: PropTypes.any,
  setDatetime: PropTypes.func,
  timezone: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  onKeyDown: PropTypes.func,
};

export default DateTimePickerOverrided;
