import React from 'react';
import PropTypes from 'prop-types';

import DropCard from './CampaignDropCard';
import { canvasDimensions, layoutRatio } from '../../lib/image';
function CampaignDropLots({
  layout,
  lots,
  lotsOrder,
  setLotsOrder,
  setEdit,
  setCanvasDimensions,
  layoutType,
  coverImage,
  setLastEditedLot,
}) {
  const orderedLots = lotsOrder.map(lotOrder =>
    lotOrder ? lots.find(lot => lot.id === lotOrder) : null,
  );

  function onEdit(lot) {
    const firstLot = lotsOrder?.[0] === lot.id;
    const ratio = layoutRatio(layoutType, layout, firstLot);
    const dimensions = canvasDimensions(ratio);
    setCanvasDimensions(dimensions);
    setLastEditedLot({});
    setEdit(lot);
  }

  return [...Array(layout).keys()].map(index => {
    const lot = orderedLots[index];
    return (
      <DropCard
        key={index}
        index={index}
        onClick={() => onEdit(lot)}
        onDrop={setLotsOrder}
        id={lot?.id}
        imageSrc={coverImage(lot?.id)?.url}
      />
    );
  });
}

CampaignDropLots.propTypes = {
  layout: PropTypes.number,
  lots: PropTypes.array,
  lotsOrder: PropTypes.array,
  setLotsOrder: PropTypes.func,
  setEdit: PropTypes.func,
  setCanvasDimensions: PropTypes.func,
  layoutType: PropTypes.string,
  coverImage: PropTypes.func,
  setLastEditedLot: PropTypes.func,
};

export default CampaignDropLots;
