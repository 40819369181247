import React, { useState, useEffect, useContext } from 'react';
import { darken } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ToasterContext } from 'vavato-ui';

import RadioInput from '../common/RadioInput';
import ModalDialog from '../common/ModalDialog';
import cancelIcon from '../../assets/icons/cancel.svg';
import LabeledInput from '../common/LabeledInput';
import { formatDate } from '../../lib/dateHelper';
import { PREVIEW_LOCALES } from '../../lib/constants';
import LoaderOverrided from '../common/LoaderOverrided';
import { useFetchCampaignUtmTags } from '../../hooks/services/CampaignsAPI';

const RowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 20px;
`;

const HeaderStyle = styled(RowStyle)`
  width: 600px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const FooterStyle = styled(RowStyle)`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  justify-content: flex-end;
`;

const RightFooterStyle = styled.div`
  margin-right: -20px;
`;

const CancelIconStyle = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const RadioInputStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const LocaleTitleStyle = styled.span`
  color: ${props => props.theme.text};
  margin-right: 10px;
  font-size: 14px;
`;

const PreviewLinkStyle = styled.a`
  cursor: pointer;
  text-transform: uppercase;
  color: ${props => props.theme.primary};

  :hover {
    color: ${props => darken(0.2, props.theme.primary)};
    text-decoration: none;
  }
`;

const LabeledInputStyle = styled.div`
  p {
    min-width: 120px;
  }
`;

function CampaignCopyModal({ campaign, openCampaign, closeModal }) {
  const { t } = useTranslation();
  const { error } = useContext(ToasterContext);

  const [locale, setLocale] = useState('en');
  const [utmSource, setUtmSource] = useState(null);
  const [utmMedium, setUtmMedium] = useState(null);
  const [utmCampaign, setUtmCampaign] = useState(null);

  const {
    data: campaignUtmTagsData,
    error: campaignUtmTagsError,
    isError: campaignUtmTagsIsError,
    isFetching: campaignUtmTagsIsFetching,
  } = useFetchCampaignUtmTags(campaign.id, locale);

  function loadUtmTags() {
    try {
      const data = campaignUtmTagsData.data;
      if (data) {
        setUtmSource(data.utm_source);
        setUtmMedium(data.utm_medium);
        setUtmCampaign(data.utm_campaign);
      }
    } catch (err) {
      error(err?.response?.data?.message);
    }
  }

  function openCampaignReplica(e) {
    const utmTags = {
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
    };
    openCampaign(e, locale, utmTags);
  }

  useEffect(() => {
    if (campaignUtmTagsData?.data) {
      loadUtmTags();
    }
    // eslint-disable-next-line
  }, [campaignUtmTagsData]);

  if (campaignUtmTagsIsError) {
    error(campaignUtmTagsError?.message);
    closeModal();
  }

  return (
    <ModalDialog onClose={closeModal}>
      <HeaderStyle>
        <span>
          {formatDate(campaign.scheduled_send_time)} - {campaign.campaign_title}{' '}
          - {t('campaigns.html_copy')}
        </span>
        <CancelIconStyle src={cancelIcon} onClick={closeModal} />
      </HeaderStyle>
      <RadioInputStyle>
        <LocaleTitleStyle>{t('locales.title')}</LocaleTitleStyle>
        {PREVIEW_LOCALES.map(option => (
          <RadioInput
            key={`radio.${option}`}
            name={`radio.${option}`}
            option={t(`locales.${option}`)}
            checked={locale === option}
            onCheck={() => {
              setLocale(option);
            }}
          />
        ))}
      </RadioInputStyle>
      <LabeledInputStyle>
        <LabeledInput
          label={'utm campaign'}
          value={utmCampaign}
          onChange={e => {
            setUtmCampaign(e.target.value);
          }}
        />
        <LabeledInput
          label={'utm source'}
          value={utmSource}
          onChange={e => {
            setUtmSource(e.target.value);
          }}
        />
        <LabeledInput
          label={'utm medium'}
          value={utmMedium}
          onChange={e => {
            setUtmMedium(e.target.value);
          }}
        />
      </LabeledInputStyle>
      <FooterStyle>
        <RightFooterStyle>
          <PreviewLinkStyle onClick={openCampaignReplica}>
            {t('campaigns.create_link')}
          </PreviewLinkStyle>
        </RightFooterStyle>
      </FooterStyle>
      <LoaderOverrided show={campaignUtmTagsIsFetching} />
    </ModalDialog>
  );
}

CampaignCopyModal.propTypes = {
  campaign: PropTypes.object,
  openCampaign: PropTypes.func,
  closeModal: PropTypes.func,
};

export default CampaignCopyModal;
