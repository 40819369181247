import { DirectUpload } from '@rails/activestorage';

const DIRECT_UPLOAD_URL = `${process.env.REACT_APP_API_URL}rails/active_storage/direct_uploads`;

export default class Uploader {
  constructor(file, successCB, errorCB) {
    this.file = file;
    this.successCB = successCB;
    this.errorCB = errorCB;
    this.upload = new DirectUpload(this.file, DIRECT_UPLOAD_URL, this);
  }

  directUpload() {
    this.upload.create((error, blob) => {
      if (error) {
        this.errorCB(error);
      } else {
        this.successCB(blob);
      }
    });
  }
}
