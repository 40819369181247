import styled from 'styled-components';
import React, { useState, useEffect, useContext } from 'react';

import { ToasterContext } from 'vavato-ui';
import LayoutHeader from '../../components/layout/LayoutContentHeader';
import AuctionsListTable from '../../components/auction/ListTable';
import AuctionListHeader from '../../components/auction/ListHeader';
import { useFetchAuctionsData } from '../../hooks/services/AuctionAPI';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { PARAM_DATE_FORMAT } from '../../lib/constants';
import LayoutHeaderWithSideMenu from '../../components/layout/LayoutHeaderWithSideMenu';
import AuctionsExportModal from '../../components/auction/AuctionExportModal';

const LayoutContainerStyle = styled.div`
  min-height: 100vh;
`;

function AuctionList() {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = parseInt(params.get('page')) || 1;
  const searchParam = params.get('search') || '';
  const filterStatus = params.get('status') || '[]';
  const auctionStatus = JSON.parse(filterStatus);
  const { error } = useContext(ToasterContext);
  const [auctions, setAuctions] = useState([]);
  const [dateOrder, setDateOrder] = useState(
    params.get('date_filter') || 'start_date',
  );
  const [totalPages, setTotalPages] = useState(0);
  const [vavatoStatus, setVavatoStatus] = useState(
    params.get('vstatus') || 'all_auctions',
  );
  const [openExportModal, setOpenExportModal] = useState(false);

  const dateFilter = {
    from:
      params.get('from') || moment().add(-7, 'days').format(PARAM_DATE_FORMAT),
    to: params.get('to') || moment().add(7, 'days').format(PARAM_DATE_FORMAT),
    order: dateOrder,
  };

  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState('day');

  const links = [
    { path: '/', name: 'home' },
    { path: '/auctions', name: 'auctions_list' },
  ];

  const {
    data: auctionsData,
    error: auctionsError,
    isError: auctionsIsError,
    isLoading: auctionsIsLoading,
    isFetching: auctionIsFetching,
  } = useFetchAuctionsData({
    page: currentPage,
    status: filterStatus,
    search: searchParam,
    from: dateFilter.from,
    to: dateFilter.to,
    date_filter: dateFilter.order,
  });

  useEffect(() => {
    if (auctionsData) {
      if (auctionsData?.data?.result) {
        setAuctions(auctionsData?.data?.result);
        setTotalPages(auctionsData?.data?.total_pages);
      } else {
        setAuctions(auctionsData?.data);
        setTotalPages(1);
      }
      setLoading(false);
    }
  }, [auctionsData]);

  if (auctionsIsError) {
    error(auctionsError?.message);
  }

  function goToPage(page) {
    const params = new URLSearchParams(location.search);
    params.set('page', page);
    history.push({ search: params.toString() });
  }

  function onEnterSearch(searchValue) {
    const params = new URLSearchParams(location.search);
    params.set('search', searchValue);
    params.delete('page');
    history.push({ search: params.toString() });
  }

  function onChangeDateFilter(dateFilter) {
    const params = new URLSearchParams(location.search);
    params.set('from', dateFilter.from);
    params.set('to', dateFilter.to);
    params.set('date_filter', dateFilter.order);
    params.delete('page');
    history.push({ search: params.toString() });
  }

  function onChangeAuctionStatus(statuses) {
    const statusJSON = JSON.stringify(statuses);
    const params = new URLSearchParams(location.search);
    params.set('status', statusJSON);
    params.delete('page');
    history.push({ search: params.toString() });
  }

  function renderViewMode() {
    switch (viewMode) {
      case 'day':
        return (
          <AuctionsListTable
            loading={loading || auctionsIsLoading || auctionIsFetching}
            auctions={auctions}
            totalPages={totalPages}
            page={currentPage}
            changePage={goToPage}
          />
        );
      default:
        return <></>;
    }
  }

  function renderExportModal() {
    return (
      <AuctionsExportModal
        setOpenExportModal={setOpenExportModal}
        baseDate={dateFilter}
      />
    );
  }

  return (
    <LayoutContainerStyle>
      <LayoutHeaderWithSideMenu>
        <LayoutHeader prevLink={`/`} links={links} />
        <AuctionListHeader
          viewMode={viewMode}
          setViewMode={setViewMode}
          dateOrder={dateOrder}
          setDateOrder={setDateOrder}
          vavatoStatus={vavatoStatus}
          setVavatoStatus={setVavatoStatus}
          searchValue={searchParam}
          setSearchValue={onEnterSearch}
          dateFilter={dateFilter}
          setDateFilter={onChangeDateFilter}
          auctionStatus={auctionStatus}
          setAuctionStatus={onChangeAuctionStatus}
          setOpenExportModal={setOpenExportModal}
        />
        {renderViewMode()}
        {openExportModal && renderExportModal()}
      </LayoutHeaderWithSideMenu>
    </LayoutContainerStyle>
  );
}

export default AuctionList;
