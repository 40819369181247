import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../lib/textHelper';
import Loader from '../common/LoaderOverrided';
import PlanningScheduleButton from './PlanningScheduleButton';
import FacebookRecreateButton from './FacebookRecreateButton';
import { ADSET_SCHEDULE_STATUS_COLORS } from '../../lib/constants';
import infoIcon from '../../assets/icons/info.svg';

const TableSquare = styled.table`
  width: 280px;
  border-radius: 3px;
  overflow-wrap: anywhere;
  border-collapse: collapse;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const TableContainerStyle = styled.div`
  flex: 1;
  display: flex;
  grid-gap: 20px;
  padding: 10px 5px;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: scroll;
  overflow-x: visible;
  padding-bottom: 10px;
  font-family: 'Merriweather Sans';
  max-height: ${props => (props.isDraggable ? '' : 'calc(100vh - 250px)')};
`;

const TableFieldStyle = styled.td`
  width: 40%;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1.2rem;
  text-transform: uppercase;
  color: ${props => props.theme.tableHeaders};
`;

const TableValueStyle = styled.td`
  width: 60%;
  color: black;
  font-size: 0.75rem;
  line-height: 1.2rem;
  letter-spacing: 1px;
  font-weight: normal;
`;

const TableRowStyle = styled.tr`
  min-height: 35px !important;
  display: flex;
  padding: 2px 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${props => props.theme.background};
`;

const TableFirstRowStyle = styled.thead`
  background-color: ${props => props.theme.cardBackground};

  td {
    min-height: 56px !important;
    padding: 4px 8px !important;
    color: black !important;
    font-size: 0.75rem !important;
    line-height: 1.5rem !important;
    font-style: normal !important;
    font-weight: normal !important;
    letter-spacing: 1px !important;
    font-family: 'Merriweather Sans' !important;
  }
`;

const TableFirstColumnStyle = styled.td`
  display: flex;
  flex-direction: row;
  align-items: center;
  &.red {
    span {
      color: ${props => props.theme.changedText};
    }
  }
  &.grey {
    span {
      color: ${props => props.theme.missingText};
    }
  }
`;

const TableLastRowStyle = styled.thead`
  background-color: ${props => props.theme.cardBackground};

  td {
    display: flex;
    justify-content: flex-end;
  }
`;

const StatusSquare = styled.div`
  min-width: 8px;
  min-height: 8px;
  border-radius: 1px;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  ${props => {
    return `background: ${ADSET_SCHEDULE_STATUS_COLORS[props.status]};`;
  }};
`;

const InfoStyle = styled.img`
  margin-left: 10px;
`;

function FacebookPlanningsTable({ plannings, closeModal, campaign }) {
  const { t } = useTranslation();

  const [scheduleLoading, setScheduleLoading] = useState(false);

  function parseAdPlacements(planning) {
    return Object.keys(planning?.ad_placements)
      .map(placement => {
        return planning?.ad_placements[placement] ? capitalize(placement) : '';
      })
      .filter(x => !!x)
      .join(', ');
  }

  function renderIntegrationErrorInfo(planning) {
    return (
      planning?.integration_error && (
        <InfoStyle src={infoIcon} title={planning?.integration_error} />
      )
    );
  }

  function integrationTitleClass(planning) {
    if (planning.ad_active === 'no_integration') return 'grey';
    return !planning?.ad_id || planning.integration_error ? 'red' : '';
  }

  function renderPlanningSquare(planning) {
    const planningWithIntegrationError = {
      ...planning,
      integration_error:
        campaign?.facebook_integration_error[planning.id]?.integration_error,
    };

    return (
      <TableSquare key={planning.id}>
        <TableFirstRowStyle>
          <TableFirstColumnStyle
            colSpan={2}
            className={integrationTitleClass(planningWithIntegrationError)}
          >
            <StatusSquare status={planning.ad_active} />
            <span>
              {planning?.name}
              {planningWithIntegrationError?.integration_error &&
                renderIntegrationErrorInfo(planningWithIntegrationError)}
            </span>
          </TableFirstColumnStyle>
        </TableFirstRowStyle>
        <TableRowStyle>
          <TableFieldStyle>
            {t('facebook_campaign.fields.languages')}:
          </TableFieldStyle>
          <TableValueStyle>
            {planning?.settings?.audience_info?.languages}
          </TableValueStyle>
        </TableRowStyle>
        <TableRowStyle>
          <TableFieldStyle>
            {t('facebook_campaign.fields.countries')}:
          </TableFieldStyle>
          <TableValueStyle>
            {planning?.settings?.audience_info?.countries}
          </TableValueStyle>
        </TableRowStyle>
        <TableRowStyle>
          <TableFieldStyle>
            {t('facebook_campaign.fields.placements')}:
          </TableFieldStyle>
          <TableValueStyle>{parseAdPlacements(planning)}</TableValueStyle>
        </TableRowStyle>
        <TableRowStyle>
          <TableFieldStyle>
            {t('facebook_campaign.fields.budget')}:
          </TableFieldStyle>
          <TableValueStyle>{planning?.settings?.budget}€</TableValueStyle>
        </TableRowStyle>
        <TableRowStyle>
          <TableFieldStyle>
            {t('facebook_campaign.fields.lots')}:
          </TableFieldStyle>
          <TableValueStyle>{planning?.lots_order?.length}</TableValueStyle>
        </TableRowStyle>
        <TableLastRowStyle>
          <td colSpan={2}>
            <FacebookRecreateButton
              planning={planning}
              closeModal={closeModal}
              setScheduleLoading={setScheduleLoading}
            />
            <PlanningScheduleButton
              planning={planning}
              closeModal={closeModal}
              setScheduleLoading={setScheduleLoading}
            />
          </td>
        </TableLastRowStyle>
      </TableSquare>
    );
  }

  return (
    <TableContainerStyle>
      {plannings && plannings.map(renderPlanningSquare)}
      {scheduleLoading ? <Loader show={true} /> : null}
    </TableContainerStyle>
  );
}

FacebookPlanningsTable.propTypes = {
  plannings: PropTypes.array,
  closeModal: PropTypes.func,
  campaign: PropTypes.any,
};

export default FacebookPlanningsTable;
