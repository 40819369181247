import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import hamburgerButton from '../../assets/icons/hamburger-button.svg';
import vavatoLogo from '../../assets/icons/vavato-logo.svg';
import LayoutProfile from './LayoutProfile';
import { isDevEnvironment } from '../../lib/envHelper';

const HeaderStyle = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.prod {
    background-color: #343a40;
  }
  &.dev {
    background-color: #a19d9d;
  }
`;

const HeaderLeftStyle = styled.div`
  display: flex;
  align-items: center;
`;

const MenuIconStyle = styled.img`
  padding-left: 22px;
`;

const MenuButtonStyle = styled.div`
  cursor: pointer;
`;

const LogoStyle = styled.img`
  padding-left: 18px;
`;

const HeaderRightStyle = styled.div`
  padding-right: 24px;
`;

function LayoutPageHeader({ setOpen, open }) {
  return (
    <HeaderStyle className={isDevEnvironment() ? 'dev' : 'prod'}>
      <HeaderLeftStyle>
        <MenuButtonStyle>
          <MenuIconStyle onClick={() => setOpen(!open)} src={hamburgerButton} />
        </MenuButtonStyle>
        <LogoStyle src={vavatoLogo} />
      </HeaderLeftStyle>
      <HeaderRightStyle>
        <LayoutProfile />
      </HeaderRightStyle>
    </HeaderStyle>
  );
}

LayoutPageHeader.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
};

export default LayoutPageHeader;
