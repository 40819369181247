import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const SelectedCounterStyle = styled.div`
  font-family: 'Merriweather Sans';
  color: #595a5c;
`;

function SelectedCounter({ selectedLots }) {
  const { t } = useTranslation();

  return selectedLots > 0 ? (
    <SelectedCounterStyle>
      {t('selected_lots')}: {selectedLots}
    </SelectedCounterStyle>
  ) : (
    <></>
  );
}
SelectedCounter.propTypes = {
  selectedLots: PropTypes.number,
};
export default SelectedCounter;
