import React, { Redirect, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { getToken } from '../lib/token';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../hooks/contexts/AuthenticationContext';
import { SIGNIN_PATH } from '../hooks/services/AuthenticationAPI';
import Loader from '../components/common/LoaderOverrided';

const LoginContainerStyle = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 50px;
`;

function Login() {
  const { t } = useTranslation();
  const { state, search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get('token') || getToken();
  const error = params.get('error');
  let errorMessage = '';

  const { admin, authenticate } = useAuthentication();

  if (admin) {
    return <Redirect to={state?.from || '/'} />;
  }

  if (token) {
    authenticate({ token });
    return (
      <div data-testid="loader">
        <Loader show={true} />
      </div>
    );
  } else {
    if (error) {
      if (error === 'not_whitelisted') {
        errorMessage = t('toaster.not_whitelisted');
      } else {
        errorMessage = t('toaster.login_error');
      }
    }
    return (
      <LoginContainerStyle>
        <p>{t('authentication.description')}</p>
        <a href={SIGNIN_PATH}>{t('authentication.sign_in')}</a>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </LoginContainerStyle>
    );
  }
}

export default Login;
