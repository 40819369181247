import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AuctionStatusFilter from './AuctionStatusFilter';
import VavatoStatusFilter from './VavatoStatusFilter';
import DateTabs from '../common/DateTabs';
import SearchField from '../common/CustomSearchField';
import DateRangeFilter from '../common/DateRangeFilter';
import { Button } from 'vavato-ui';

const HeaderContainer = styled.div`
  margin: 20px;
`;

const UpperFilterContainer = styled.div`
  height: 55px;
  display: flex;
  justify-content: space-between;
  overflow: visible;
`;

const LowerFilterContainer = styled.div`
  height: 40px;
  display: flex;
  justify-content: flex-start;
`;

const RightFilterContainer = styled.div`
  gap: 1rem;
  display: flex;
  justify-content: space-between;
`;

const SearchFilter = styled(SearchField)`
  width: 140px;
`;
const ReportsButton = styled.div`
  overflow: hidden;
  button {
    margin: 0px;
    height: 40px;
    line-height: 0px;
    white-space: nowrap;
  }
`;

function AuctionsListHeader({
  viewMode,
  setViewMode,
  setDateOrder,
  dateOrder,
  vavatoStatus,
  setVavatoStatus,
  searchValue,
  setSearchValue,
  dateFilter,
  setDateFilter,
  auctionStatus,
  setAuctionStatus,
  setOpenExportModal,
}) {
  const { t } = useTranslation();

  function handleChange(_event, value) {
    setViewMode(value);
  }

  return (
    <HeaderContainer>
      <UpperFilterContainer>
        <DateRangeFilter
          selectedDates={dateFilter}
          setSelectedDates={setDateFilter}
          setDateOrder={setDateOrder}
          dateOrder={dateOrder}
        />
        <DateTabs
          selectedValue={viewMode}
          handleChange={handleChange}
          options={['day', 'week', 'month']}
          translationPath="auction_list.view_modes"
        />
        <RightFilterContainer>
          <ReportsButton>
            <Button
              onClick={() => {
                setOpenExportModal(true);
              }}
            >
              {t('auction_list.export.title')}
            </Button>
          </ReportsButton>
          <SearchFilter
            placeholder={t('auction_list.search_placeholder')}
            defaultValue={searchValue}
            onEnter={setSearchValue}
          />
          <AuctionStatusFilter
            auctionStatus={auctionStatus}
            setAuctionStatus={setAuctionStatus}
          />
        </RightFilterContainer>
      </UpperFilterContainer>
      <LowerFilterContainer>
        <VavatoStatusFilter
          selected={vavatoStatus}
          handleChange={setVavatoStatus}
        />
      </LowerFilterContainer>
    </HeaderContainer>
  );
}

AuctionsListHeader.propTypes = {
  viewMode: PropTypes.string,
  setViewMode: PropTypes.func,
  setDateOrder: PropTypes.func,
  dateOrder: PropTypes.string,
  vavatoStatus: PropTypes.string,
  setVavatoStatus: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  dateFilter: PropTypes.object,
  setDateFilter: PropTypes.func,
  auctionStatus: PropTypes.array,
  setAuctionStatus: PropTypes.func,
  setOpenExportModal: PropTypes.func,
};

export default AuctionsListHeader;
