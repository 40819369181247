import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { nameTranslation } from '../../lib/formatter';
import Planning from '../../models/Planning';
import { ADSET_SCHEDULE_STATUS_COLORS } from '../../lib/constants';
const CLOSED_AUCTIONS_LIMIT = 5;

const CampaignContainer = styled.div`
  background: #f8f9fa;
  border: 1px solid rgba(89, 90, 92, 0.2);
  border-radius: 4px;
  width: 100%;
  margin-bottom: 5px;
  padding: 9px;
  color: #595a5c;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
`;

const CampaignInfoContainer = styled.div`
  cursor: pointer;
`;

const CampaignTitle = styled.div`
  margin-bottom: 3px;
  overflow: hidden;
  white-space: nowrap;
`;

const CampaignAuctionsContainer = styled.div`
  font-weight: 300;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  letter-spacing: 1px;
`;

const AuctionTitle = styled.div`
  display: inline-flex;
  position: relative;
  overflow: hidden;
  &.red {
    color: ${props => props.theme.changedText};
  }
  &.grey {
    color: ${props => props.theme.missingText};
  }
`;

const LoadMoreAuctions = styled.span`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
`;

const AuctionPlanningsContainer = styled.div`
  margin-left: 0px;
`;

const PlanningRowContainer = styled.div`
  overflow: hidden;
`;

const StatusSquare = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 1px;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  ${props => {
    return `background: ${ADSET_SCHEDULE_STATUS_COLORS[props.status]};`;
  }};
`;

function FacebookAdsetsBlock({ campaign, setOpenCampaign }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const auction = Planning.groupAdPlanningsByAuction(campaign?.plannings);

  function integrationTitleClass(planning) {
    if (planning.ad_active === 'no_integration') return 'grey';
    return !planning?.ad_id || planning.integration_error ? 'red' : '';
  }

  function renderAuctionPlannings(auction) {
    return (
      <AuctionPlanningsContainer>
        {auction.plannings.map((planning, index) => {
          if (!open && index >= CLOSED_AUCTIONS_LIMIT) {
            return <div key={`${planning.id}_container`}></div>;
          }
          return (
            <PlanningRowContainer key={`planning_${planning.id}`}>
              <StatusSquare status={planning?.ad_active} />
              <AuctionTitle className={integrationTitleClass(planning)}>
                {planning.name}
              </AuctionTitle>
            </PlanningRowContainer>
          );
        })}
      </AuctionPlanningsContainer>
    );
  }

  return auction ? (
    <CampaignContainer
      onClick={_e => {
        setOpenCampaign(campaign);
      }}
      key={`${campaign?.id}_container`}
    >
      <CampaignInfoContainer>
        <CampaignTitle>
          {`${t(`campaigns.facebook_adset_title`)}: ${
            auction?.external_id || auction?.atlas_external_id
          } - ${nameTranslation(auction, 'en')}`}
        </CampaignTitle>
        <CampaignAuctionsContainer>
          {renderAuctionPlannings(auction)}
        </CampaignAuctionsContainer>
      </CampaignInfoContainer>
      {auction.plannings.length > CLOSED_AUCTIONS_LIMIT && (
        <LoadMoreAuctions
          onClick={e => {
            e.stopPropagation();
            setOpen(prev => !prev);
          }}
        >
          {t(`campaigns.${open ? 'see_less' : 'see_more'}`)}
        </LoadMoreAuctions>
      )}
    </CampaignContainer>
  ) : null;
}

FacebookAdsetsBlock.propTypes = {
  campaign: PropTypes.object,
  setOpenCampaign: PropTypes.func,
};

export default FacebookAdsetsBlock;
