import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TextContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextStyle = styled.p`
  margin: 0;
  color: black;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 2rem;
  &.changed {
    color: ${props => props.theme.changedText};
  }
`;

const TitleStyle = styled(TextStyle)`
  font-weight: 400;
`;

function AuctionInfoElement({ title, text, hadChange }) {
  return (
    <TextContainerStyle>
      <TitleStyle>{`${title}:`}</TitleStyle>
      <TextStyle className={hadChange ? 'changed' : ''}>{text}</TextStyle>
    </TextContainerStyle>
  );
}

AuctionInfoElement.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  hadChange: PropTypes.bool,
};

export default AuctionInfoElement;
