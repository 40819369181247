import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';

import { removeToken, setToken } from '../../lib/token';
import { authenticate, revoke } from '../services/AuthenticationAPI';

const AuthenticationContext = createContext();

function authenticationReducer(state, action) {
  switch (action.type) {
    case 'authenticate': {
      if (action.admin) {
        setToken(action.token);
      }
      return { ...state, admin: action.admin };
    }
    case 'revoke': {
      removeToken();
      return { ...state, admin: null };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function AuthenticationProvider({ initState, children }) {
  const initialState = initState || { admin: null };
  const [state, dispatch] = useReducer(authenticationReducer, initialState);

  const value = {
    admin: state.admin,
    authenticate: async token => {
      try {
        const admin = await authenticate(token);
        dispatch({ type: 'authenticate', admin: admin?.data, token });
      } catch (e) {
        dispatch({ type: 'revoke' });
      }
    },
    revoke: async token => {
      await revoke(token);
      dispatch({ type: 'revoke' });
    },
  };

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
}

function useAuthentication() {
  const authContext = useContext(AuthenticationContext);

  if (authContext === undefined) {
    throw new Error(
      'useAuthentication must be wrapped within a AuthenticationProvider',
    );
  }
  return authContext;
}

AuthenticationProvider.propTypes = {
  initState: PropTypes.object,
  children: PropTypes.any,
};

export { AuthenticationProvider, useAuthentication };
