import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from '../common/LoaderOverrided';
import CampaignBlock from '../campaign/CampaignBlock';
import FacebookAdsetsBlock from '../campaign/FacebookAdsetsBlock';

import moment from 'moment-timezone';
import { daysOfWeek } from '../../lib/dateHelper';

const FACEBOOK_ADSETS_PREFIX = 'Facebook';
const INSTAGRAM_ADSETS_PREFIX = 'Instagram';

const ListLayoutContainer = styled.div`
  position: relative;
`;

const WeekContainerStyle = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  padding-bottom: 10px;
  margin-right: 20px;
  margin-left: 20px;
`;

const WeekDayContainer = styled.div`
  padding: 0px 10px 10px 10px;
  flex: 1;
  min-width: 170px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid rgba(89, 90, 92, 0.2);
  :last-child {
    border-right: none;
  }
`;
const WeekDayTitleContainer = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  flex-direction: column;
  align-items: center;
  color: #595a5c;
  span {
    margin-top: 5px;
    color: #575c5a;
    font-size: 12px;
    padding: 3px 3px 3px 4px;
    &.today {
      color: #fff;
      background: #a29060;
      border-radius: 4px;
    }
  }
  margin-bottom: 15px;
`;

const SeparatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin: 0 25px;
`;

const SegmentTitle = styled.span`
  padding: 3px 5px;
  border-radius: 3px;
  background: #a29060;
  font-size: 0.6em;
  letter-spacing: 1px;
  color: #fff;
`;

const Separator = styled.div`
  border-top: 1px solid rgba(89, 90, 92, 0.2);
  margin: 15px;
  position: relative;
  display: flex;
  flex: 1;
`;

function filterCampaignsByDate(campaigns, date) {
  return campaigns.filter(campaign => {
    return (
      moment(campaign.scheduled_send_time).format('YYYY-MM-DD') ===
      date.format('YYYY-MM-DD')
    );
  });
}

function campaignsByDate(dates, campaigns) {
  const datesObject = [];
  dates.forEach((date, index) => {
    datesObject[index] = filterCampaignsByDate(campaigns, date);
  });
  return datesObject;
}

function CampaignsListWeek({
  loading,
  campaigns,
  date,
  setOpenCampaign,
  datesHeader,
  segmentName,
}) {
  const daysList = daysOfWeek(date);
  const sortedCampaigns = campaignsByDate(daysList, campaigns);

  if (loading) {
    return <Loader show={true} />;
  }

  return (
    <ListLayoutContainer>
      <SeparatorContainer>
        <SegmentTitle>{segmentName}</SegmentTitle>
        <Separator data-testid={'campaign-list-segment-separator'} />
      </SeparatorContainer>

      <WeekContainerStyle data-testid={'campaigns-list-week'}>
        {daysList.map((day, index) => {
          return (
            <WeekDayContainer
              key={`${day.format('ddd').toLowerCase()}_container`}
            >
              {datesHeader && (
                <WeekDayTitleContainer>
                  {day.format('ddd').toUpperCase()}
                  <span className={day.isSame(moment(), 'day') ? 'today' : ''}>
                    {day.format('DD').toUpperCase()}
                  </span>
                </WeekDayTitleContainer>
              )}
              <div>
                {sortedCampaigns[index].map(campaign => {
                  return campaign?.campaign_type_name.includes(
                    FACEBOOK_ADSETS_PREFIX,
                  ) ||
                    campaign?.campaign_type_name.includes(
                      INSTAGRAM_ADSETS_PREFIX,
                    ) ? (
                    <FacebookAdsetsBlock
                      key={`${campaign.id}_container`}
                      setOpenCampaign={setOpenCampaign}
                      campaign={campaign}
                    />
                  ) : (
                    <CampaignBlock
                      key={`${campaign.id}_container`}
                      setOpenCampaign={setOpenCampaign}
                      campaign={campaign}
                    />
                  );
                })}
              </div>
            </WeekDayContainer>
          );
        })}
      </WeekContainerStyle>
    </ListLayoutContainer>
  );
}

CampaignsListWeek.propTypes = {
  loading: PropTypes.bool,
  campaigns: PropTypes.array,
  date: PropTypes.string,
  setOpenCampaign: PropTypes.func,
  datesHeader: PropTypes.bool,
  segmentName: PropTypes.string,
};

export default CampaignsListWeek;
