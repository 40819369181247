import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from './CustomCheckbox';
import CardWithDescription from './CardWithDescription';

function SelectableCardWithDescription({
  highlight,
  image,
  subtitle,
  text,
  url,
  value,
  onChange,
}) {
  return (
    <CardWithDescription
      highlight={highlight}
      image={image}
      subtitle={subtitle}
      text={text}
      url={url}
      onChange={onChange}
    >
      <Checkbox inside value={value} onChange={onChange} />
    </CardWithDescription>
  );
}

SelectableCardWithDescription.propTypes = {
  highlight: PropTypes.bool,
  image: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SelectableCardWithDescription;
