import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AUCTION_DATE_STATUS_COLORS } from '../../lib/constants';

const StatusSquare = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 1px;
  ${props => {
    return `background: ${AUCTION_DATE_STATUS_COLORS[props.status]};`;
  }};
`;

function AuctionVavatoStatus({ status }) {
  if (!AUCTION_DATE_STATUS_COLORS[status]) {
    return <></>;
  }
  return <StatusSquare status={status} />;
}

AuctionVavatoStatus.propTypes = {
  status: PropTypes.string,
};

export default AuctionVavatoStatus;
