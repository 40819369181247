import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'vavato-ui';

function LoaderOverrided({ show }) {
  if (!show) return null;
  return <Loader active absolute big color="info" />;
}

LoaderOverrided.propTypes = {
  show: PropTypes.bool,
};

export default LoaderOverrided;
