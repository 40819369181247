import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import menuArrowDown from '../../assets/icons/menu-arrow-down.svg';
import menuArrowLeft from '../../assets/icons/menu-arrow-left.svg';
import { useTranslation } from 'react-i18next';

const LabelStyle = styled.div`
  font-family: Merriweather Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1px;
  list-style-type: none;
  margin-bottom: 24px;
  margin-left: -16px;
  cursor: pointer;
`;

const LayoutMenuStyle = styled.div`
  display: ${props => (props.show ? '' : 'none')};
`;

const ArrowLabelStyle = styled.img`
  padding-left: 100px;
  padding-right: 24px;
`;

const LabelRowStyle = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-between;
`;

const ChildrenLabelStyle = styled.div`
  display: ${props => (props.show ? '' : 'none')};
  cursor: pointer;
`;

const MenuContainerStyle = styled.div`
  width: 230px;
  height: 100%;
  padding-top: 10px;
  background-color: #f8f9fa;
  display: ${props => (props.isOpen ? 'block' : 'none')};
`;

const ChildLabelStyle = styled.div`
  font-family: Merriweather Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: left;
  list-style-type: none;
  text-align: left;
  padding-left: 24px;
  padding-bottom: 24px;
  cursor: pointer;
`;

const HighlightLabelStyle = styled.div`
  color: ${props => (props.isHighlight ? props.theme.primary : '')};
`;

function LayoutMenu(props) {
  const [openLabel, setOpenLabel] = useState(true);
  const imageSrc = openLabel ? menuArrowDown : menuArrowLeft;
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  function handleClick(url) {
    history.push(url);
  }

  function setHighlight(url) {
    return (
      location.pathname === url ||
      location.pathname.slice('/')[1] === url.slice('/')[1]
    );
  }

  function setHighlightLabel(url) {
    return location.pathname.slice('/')[2] === url.slice('/')[2];
  }

  function LabelChildren(props) {
    if (props.value.children) {
      return (
        <>
          <LabelStyle onClick={() => setOpenLabel(!openLabel)}>
            <LabelRowStyle>
              {t(`menu.${props.value.name}`)}
              <ArrowLabelStyle show={openLabel} src={imageSrc} />
            </LabelRowStyle>
          </LabelStyle>
          <ChildrenLabelStyle show={openLabel}>
            {props.value.children.map(child => (
              <ChildLabelStyle key={child.url}>
                <li onClick={() => handleClick(child.url)}>
                  <HighlightLabelStyle
                    isHighlight={setHighlightLabel(child.url)}
                  >
                    {t(`menu.${child.name}`)}
                  </HighlightLabelStyle>
                </li>
              </ChildLabelStyle>
            ))}
          </ChildrenLabelStyle>
        </>
      );
    } else {
      return (
        <LabelStyle key={props.value.url}>
          <li onClick={() => handleClick(props.value.url)}>
            <HighlightLabelStyle isHighlight={setHighlight(props.value.url)}>
              {t(`menu.${props.value.name}`)}
            </HighlightLabelStyle>
          </li>
        </LabelStyle>
      );
    }
  }

  return (
    <MenuContainerStyle isOpen={props.open}>
      <LayoutMenuStyle show={props.open}>
        <ul>
          {props.labels.map((label, index) => (
            <LabelChildren key={index} value={label} />
          ))}
        </ul>
      </LayoutMenuStyle>
    </MenuContainerStyle>
  );
}

LayoutMenu.propTypes = {
  labels: PropTypes.array,
  open: PropTypes.bool,
  value: PropTypes.object,
};

export default LayoutMenu;
