import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { HTML5Backend } from 'react-dnd-html5-backend';

import CampaignLotsList from './CampaignLotsList';
import CampaignRadioInput from './CampaignRadioInput';
import CampaignSwitchInput from './CampaignSwitchInput';
import CampaignDateTimeInput from './CampaignDateTimeInput';
import CampaignMultipleDateTimeInput from './CampaignMultipleDateTimeInput';

import Planning from '../../models/Planning';
import {
  CAMPAIGN_CONFIGURATION,
  FACEBOOK_VALIDATION,
} from '../../lib/constants';
import Accordion from '../common/Accordion';
import LoaderOverrided from '../common/LoaderOverrided';
import CampaignTextAreaInput from './CampaignTextAreaInput';
import CampaignTextInput from './CampaignTextInput';
import CampaignLayout from './CampaignLayout';
import CampaignLivePreview from './CampaignLivePreview';

const ConfigurationsContainerStyle = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: ${props => (props.isOpen ? '20px' : '')};
`;

const ConfigurationsGridStyle = styled.div`
  display: grid;
  grid-gap: 0.5rem 1rem;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-template-rows: auto;
  grid-template-areas:
    'config preview'
    'select select';
`;

const ConfigurationsContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem 1rem;
  grid-area: config;
`;

const LotsContentStyle = styled.div`
  width: 100%;
  margin-left: 2rem;
  grid-area: select;
`;

const PreviewContentStyle = styled.div`
  grid-area: preview;
`;

const LanguageContentStyle = styled.div`
  height: 60px;
`;

const CopyContentStyle = styled.div`
  height: 100px;
`;

const AdditionalInfoContentDiv = styled.div`
  display: grid;
  gap: 0.5rem 1rem;
`;

const AdditionalInfoContentStyle = styled.div`
  height: 100px;
`;

const PriceContentStyle = styled.div`
  height: 60px;
`;

const PhotoLinkContentStyle = styled.div`
  height: 60px;
`;

const CtaButtonContentStyle = styled.div`
  height: 60px;
`;

const ViewingContentStyle = styled.div`
  height: 60px;
`;

const HighlightContentStyle = styled.div`
  height: 60px;
`;

const SendDatetimeContentStyle = styled.div`
  min-height: 60px;
`;

function CampaignConfiguration({
  auction,
  lots,
  planning,
  plannings,
  openPlanning,
  setOpenPlanning,
  setEdit,
  index,
  title,
  configuration,
  setConfiguration,
  setAdditionalInfos,
  sendDatetime,
  setSendDatetime,
  lotsOrder,
  setLotsOrder,
  setCanvasDimensions,
  configFields,
  loadingLots,
  coverImage,
  warning,
  lastEditedLot,
  setLastEditedLot,
}) {
  const { t } = useTranslation();

  const isOpen = openPlanning === planning?.id;

  function onOpen() {
    const openId = !isOpen ? planning?.id : null;
    setOpenPlanning(openId);
  }

  function updateCopyText(locale) {
    const newCopyText = Planning.copyText(locale, auction, planning);
    setConfiguration('copy', newCopyText);
  }

  function renderCampaignConfiguration() {
    return (
      <>
        {configFields?.includes('language') && (
          <LanguageContentStyle>
            <CampaignRadioInput
              index={index}
              name={t('campaign_config.language')}
              type={'string'}
              options={CAMPAIGN_CONFIGURATION.LANGUAGE}
              checked={configuration.language}
              onCheck={value => {
                setConfiguration('language', value);
                updateCopyText(value);
              }}
            />
          </LanguageContentStyle>
        )}
        {configFields?.includes('copy') && (
          <CopyContentStyle>
            <CampaignTextAreaInput
              index={index}
              name={t('campaign_config.copy')}
              value={configuration.copy}
              onChange={value => setConfiguration('copy', value)}
            />
          </CopyContentStyle>
        )}
        {configFields?.includes('price') && (
          <PriceContentStyle>
            <CampaignRadioInput
              index={index}
              name={t('campaign_config.price')}
              type={'string'}
              options={CAMPAIGN_CONFIGURATION.PRICE}
              checked={configuration.price}
              onCheck={option => setConfiguration('price', option)}
            />
          </PriceContentStyle>
        )}
        {configFields?.includes('photo_link') && (
          <PhotoLinkContentStyle>
            <CampaignRadioInput
              index={index}
              name={t('campaign_config.photo_link')}
              type={'string'}
              options={CAMPAIGN_CONFIGURATION.PHOTO_LINK}
              checked={configuration.link}
              onCheck={option => setConfiguration('link', option)}
            />
          </PhotoLinkContentStyle>
        )}
        {configFields?.includes('cta') && (
          <CtaButtonContentStyle>
            <CampaignRadioInput
              index={index}
              name={t('campaign_config.cta')}
              type={'string'}
              options={
                Planning.isMailType(planning)
                  ? CAMPAIGN_CONFIGURATION.MAIL_CTA
                  : CAMPAIGN_CONFIGURATION.AD_CTA
              }
              checked={configuration.cta}
              onCheck={option => setConfiguration('cta', option)}
            />
          </CtaButtonContentStyle>
        )}
        {configFields?.includes('budget') && (
          <CopyContentStyle>
            <CampaignTextInput
              index={index}
              label={'€'}
              type={'number'}
              minValue={FACEBOOK_VALIDATION.MIN_BUDGET}
              name={t('campaign_config.budget')}
              value={configuration.budget}
              onChange={value => {
                setConfiguration('budget', value);
              }}
              onBlur={value => {
                if (value < FACEBOOK_VALIDATION.MIN_BUDGET)
                  warning(t('facebook_campaign.validate.low_budget'));
              }}
            />
          </CopyContentStyle>
        )}
        {configFields?.includes('viewing') && (
          <ViewingContentStyle>
            <CampaignSwitchInput
              name={t('campaign_config.viewing_info')}
              checked={configuration.viewing}
              onCheck={option => setConfiguration('viewing', option)}
            />
          </ViewingContentStyle>
        )}
        {configFields?.includes('highlight_popular_lots') && (
          <HighlightContentStyle>
            <CampaignSwitchInput
              name={t('campaign_config.highlight_lots')}
              checked={configuration.highlight}
              onCheck={option => setConfiguration('highlight', option)}
            />
          </HighlightContentStyle>
        )}
        {configFields?.includes('send_datetime') && (
          <SendDatetimeContentStyle>
            <CampaignDateTimeInput
              name={t('campaign_config.send_datetime')}
              sendDatetime={sendDatetime}
              setSendDatetime={setSendDatetime}
              isAdType={Planning.isAdType(planning)}
            />
          </SendDatetimeContentStyle>
        )}
        {configFields?.includes('multiple_datetime') && (
          <SendDatetimeContentStyle>
            <CampaignMultipleDateTimeInput
              name={t('campaign_config.send_datetime')}
              sendDatetimes={sendDatetime}
              setSendDatetimes={setSendDatetime}
            />
          </SendDatetimeContentStyle>
        )}
        {configFields?.includes('additional_info') && (
          <AdditionalInfoContentDiv>
            <AdditionalInfoContentStyle>
              <CampaignTextAreaInput
                index={index}
                name={t('campaign_config.additional_info_en')}
                value={configuration.additional_info?.en}
                onChange={value =>
                  setAdditionalInfos('additional_info', value, 'en')
                }
              />
            </AdditionalInfoContentStyle>
            <AdditionalInfoContentStyle>
              <CampaignTextAreaInput
                index={index}
                name={t('campaign_config.additional_info_nl')}
                value={configuration.additional_info?.nl}
                onChange={value =>
                  setAdditionalInfos('additional_info', value, 'nl')
                }
              />
            </AdditionalInfoContentStyle>
            <AdditionalInfoContentStyle>
              <CampaignTextAreaInput
                index={index}
                name={t('campaign_config.additional_info_fr')}
                value={configuration.additional_info?.fr}
                onChange={value =>
                  setAdditionalInfos('additional_info', value, 'fr')
                }
              />
            </AdditionalInfoContentStyle>
          </AdditionalInfoContentDiv>
        )}
      </>
    );
  }

  function renderCampaignLayout() {
    return (
      <CampaignLayout
        auction={auction}
        index={index}
        setEdit={setEdit}
        lots={lots}
        plannings={plannings}
        configFields={configFields}
        configuration={configuration}
        setConfiguration={setConfiguration}
        lotsOrder={lotsOrder}
        setLotsOrder={setLotsOrder}
        setCanvasDimensions={setCanvasDimensions}
        loadingLots={loadingLots}
        coverImage={coverImage}
        openPlanning={openPlanning}
        lastEditedLot={lastEditedLot}
        setLastEditedLot={setLastEditedLot}
      />
    );
  }

  function renderCampaignPreview() {
    if (!Planning.isAdType(planning)) return;

    return (
      <PreviewContentStyle>
        <CampaignLivePreview
          campaignId={planning.campaign_id}
          settings={configuration}
          lotsOrder={lotsOrder}
        />
      </PreviewContentStyle>
    );
  }

  function renderLotsSelection() {
    if (!loadingLots && configFields?.includes('lots_selection')) {
      return (
        <LotsContentStyle>
          <CampaignLotsList lots={lots} auction={auction} />
        </LotsContentStyle>
      );
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <ConfigurationsContainerStyle isOpen={isOpen}>
        <Accordion title={title} setOpen={onOpen} isOpen={isOpen} />
        {isOpen ? (
          <ConfigurationsGridStyle>
            <ConfigurationsContentStyle>
              {renderCampaignConfiguration()}
              {renderCampaignLayout()}
            </ConfigurationsContentStyle>
            {renderCampaignPreview()}
            {renderLotsSelection()}
          </ConfigurationsGridStyle>
        ) : null}
        {loadingLots ? <LoaderOverrided show={true} /> : null}
      </ConfigurationsContainerStyle>
    </DndProvider>
  );
}

CampaignConfiguration.propTypes = {
  planning: PropTypes.any,
  plannings: PropTypes.any,
  auction: PropTypes.object,
  lots: PropTypes.array,
  openPlanning: PropTypes.any,
  setOpenPlanning: PropTypes.func,
  setEdit: PropTypes.func,
  index: PropTypes.number,
  title: PropTypes.string,
  configuration: PropTypes.object,
  setConfiguration: PropTypes.func,
  setAdditionalInfos: PropTypes.func,
  sendDatetime: PropTypes.object,
  setSendDatetime: PropTypes.func,
  lotsOrder: PropTypes.array,
  setLotsOrder: PropTypes.func,
  setCanvasDimensions: PropTypes.func,
  configFields: PropTypes.array,
  loadingLots: PropTypes.bool,
  coverImage: PropTypes.func,
  warning: PropTypes.func,
  lastEditedLot: PropTypes.object,
  setLastEditedLot: PropTypes.func,
};

export default CampaignConfiguration;
