import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Auction from '../../models/Auction';
import AuctionInfoElement from './AuctionInfoElement';
import {
  formatDate,
  formatCountryName,
  formatAddress,
  formatCurrencyRounded,
  formatDeliveryType,
  formatDatesAddress,
} from '../../lib/formatter';

const ContainerStyle = styled.div`
  display: grid;
  border-radius: 3px;
  background-color: ${props => props.theme.lightBackground};
  grid-template-rows: 2fr 1fr 2fr;
  align-items: center;
  padding: 1.5rem;
`;

const FirstRowStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 2fr;
`;

const SecondRowStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 2fr;
`;

const LineStyle = styled.hr`
  display: block;
  border: 0;
  padding: 0;
  height: 1px;
  opacity: 0.1;
  margin: 1em 0;
  border-top: 1px solid #343a40;
`;

function AuctionInfo({ auction, lots }) {
  const { t } = useTranslation();

  return (
    <ContainerStyle>
      <FirstRowStyle>
        <AuctionInfoElement
          title={t('auction_info.start')}
          text={formatDate(auction.start_date)}
          hadChange={Auction.hasNeedsReviewChange(auction, 'start_date')}
        />
        <AuctionInfoElement
          title={t('auction_info.close')}
          text={formatDate(auction.end_date)}
          hadChange={Auction.hasNeedsReviewChange(auction, 'end_date')}
        />
        <AuctionInfoElement
          title={t('auction_info.country')}
          text={formatCountryName(auction.payload?.release_location?.country)}
        />
        <AuctionInfoElement
          title={t('auction_info.address')}
          text={formatAddress(auction.payload?.release_location)}
        />
      </FirstRowStyle>
      <LineStyle />
      <SecondRowStyle>
        <AuctionInfoElement
          title={t('auction_info.lots_count')}
          text={(auction.payload?.lots_count || '').toString()}
          hadChange={Auction.hasNeedsReviewChange(auction, 'lots_count')}
        />
        <AuctionInfoElement
          title={t('auction_info.lowest_bid')}
          text={formatCurrencyRounded(
            auction.payload?.lowest_opening_bid,
          ).toString()}
        />
        <AuctionInfoElement
          title={t('auction_info.client')}
          text={lots?.[0]?.payload?.seller_entity?.profileable_name}
        />
        <AuctionInfoElement
          title={t('auction_info.delivery')}
          text={
            auction.payload?.delivery_type
              ? t(
                  `constants.delivery_type.${formatDeliveryType(
                    auction.payload?.delivery_type,
                  )}`,
                )
              : ''
          }
        />
        <AuctionInfoElement
          title={t('auction_info.view_expo')}
          hadChange={
            Auction.hasNeedsReviewChange(auction, 'visit_from') ||
            Auction.hasNeedsReviewChange(auction, 'visit_to')
          }
          text={
            auction.payload?.visit_possible
              ? formatDatesAddress(
                  auction.payload?.visit_from,
                  auction.payload?.visit_to,
                  auction.payload?.visit_location,
                )
              : t('constants.delivery_type.not_possible')
          }
        />
      </SecondRowStyle>
    </ContainerStyle>
  );
}

AuctionInfo.propTypes = {
  auction: PropTypes.object,
  lots: PropTypes.array,
};

export default AuctionInfo;
