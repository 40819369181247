import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import React, { useState, useEffect, useContext, useRef } from 'react';

import { ToasterContext } from 'vavato-ui';
import Auction from '../../models/Auction';
import Planning from '../../models/Planning';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import LayoutContent from '../../components/layout/LayoutContent';
import PlanningsSelector from '../../components/auction/PlanningsSelector';
import NewAdSetModal from '../../components/auction/NewAdSetModal';
import AuctionTitleEdit from '../../components/auction/AuctionTitleEdit';
import AuctionInfo from '../../components/auction/AuctionInfo';
import AuctionCategory from '../../components/auction/AuctionCategory';
import AuctionLotsList from '../../components/auction/AuctionLotsList';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import Loader from '../../components/common/LoaderOverrided';
import { AUCTION_STATUS, AUCTION_AD_STATUS } from '../../lib/constants';
import {
  useFetchAuctionData,
  useUpdateAuctionData,
  useFetchAuctionLotsData,
  useFetchAuctionCampaignTypesData,
  useFetchAuctionPlanningsData,
  useCreateAuctionPlanningsData,
  useDeleteAuctionPlanningsData,
  useCreateAuctionFbCampaign,
} from '../../hooks/services/AuctionAPI';
import { useFetchCategoriesData } from '../../hooks/services/CategoriesAPI';
import { useFetchCampaignTypesData } from '../../hooks/services/CampaignTypesAPI';
import CampaignType from '../../models/CampaignType';
import { nameTranslations } from '../../lib/formatter';
import { isPastDate } from '../../lib/dateHelper';

const ContentStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 250px;
  grid-template-rows: auto;
  grid-template-areas:
    'edit      selector'
    'info      selector'
    'category  selector'
    'lots-list selector';
  grid-gap: 1rem;
`;

const EditContentStyle = styled.div`
  grid-area: edit;
`;

const InfoContentStyle = styled.div`
  grid-area: info;
`;

const CategoryContentStyle = styled.div`
  grid-area: category;
`;

const SelectorContentStyle = styled.div`
  grid-area: selector;
`;

const LotsListContentStyle = styled.div`
  grid-area: lots-list;
`;

const CARD_WIDTH = 205;

function findAdCampaignType(options, name) {
  return options.find(
    option => Planning.isAdType(option) && option.name.includes(name),
  );
}

function AuctionPlan() {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const lotsListContainerRef = useRef(null);
  const { error, success } = useContext(ToasterContext);

  const nextLink = `/auctions/${id}/todomail/select_lots`;

  const [lotsCount, setLotsCount] = useState(0);
  const [categorySelected, setCategorySelected] = useState(null);
  const [campaignTypesSelected, setCampaignTypesSelected] = useState(null);
  const [adSets, setAdSets] = useState([]);
  const [auctionTitles, setAuctionTitles] = useState(null);
  const [refLoading, setRefLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [setupLoading, setSetupLoading] = useState(true);
  const [openNewAdSetModal, setOpenNewAdSetModal] = useState(false);
  const [adCampaignType, setAdCampaignType] = useState(null);
  const [igAdCampaignType, setIgAdCampaignType] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const links = [
    { path: '/', name: 'home' },
    { path: '/auctions', name: 'auctions_list' },
    { path: '', name: 'auction' },
  ];

  const {
    data: auctionData,
    error: auctionError,
    isError: auctionIsError,
  } = useFetchAuctionData(id);

  const {
    data: auctionLotsData,
    error: auctionLotsError,
    isError: auctionLotsIsError,
  } = useFetchAuctionLotsData(id, { lots_limit: 10 });

  const {
    data: categoriesData,
    error: categoriesError,
    isError: categoriesIsError,
  } = useFetchCategoriesData();

  const {
    data: campaignTypesData,
    error: campaignTypesError,
    isError: campaignTypesIsError,
  } = useFetchCampaignTypesData();

  const {
    data: auctionCampaignTypesData,
    error: auctionCampaignTypesError,
    isError: auctionCampaignTypesIsError,
  } = useFetchAuctionCampaignTypesData(id);

  const {
    data: auctionPlanningsData,
    error: auctionPlanningsError,
    isError: auctionPlanningsIsError,
  } = useFetchAuctionPlanningsData(id);

  const { mutateAsync: updateAuctionMutate } = useUpdateAuctionData(id);
  const { mutateAsync: createAuctionPlanningsMutate } =
    useCreateAuctionPlanningsData(id);
  const { mutateAsync: deleteAuctionPlanningsMutate } =
    useDeleteAuctionPlanningsData(id);

  const { mutateAsync: createAuctionFbCampaign } =
    useCreateAuctionFbCampaign(id);

  function setLotsListCount() {
    const lotsListContainer = lotsListContainerRef.current;
    if (!lotsListContainer) return;

    const lotsListWidth = lotsListContainer.offsetWidth;
    const count = Math.floor(lotsListWidth / CARD_WIDTH);
    setLotsCount(count);
  }

  useEffect(() => {
    if (
      auctionData?.data &&
      auctionLotsData?.data &&
      categoriesData?.data &&
      campaignTypesData?.data &&
      auctionCampaignTypesData?.data &&
      auctionPlanningsData?.data
    ) {
      setAdSets(
        auctionPlanningsData.data.filter(planning =>
          Planning.isAdType(planning),
        ),
      );
      const campaignTypesObj = CampaignType.fromApiOptions(
        campaignTypesData.data,
        auctionData.data,
      );

      setAdCampaignType(findAdCampaignType(campaignTypesObj, 'Facebook'));
      setIgAdCampaignType(findAdCampaignType(campaignTypesObj, 'Instagram'));
      setSetupLoading(false);
    }
  }, [
    auctionData,
    auctionLotsData,
    categoriesData,
    campaignTypesData,
    auctionCampaignTypesData,
    auctionPlanningsData,
  ]);

  useEffect(() => {
    if (refLoading) return;

    setLotsListCount();
  }, [width, refLoading]);

  useEffect(() => {
    if (auctionData) {
      setAuctionTitles(nameTranslations(auctionData?.data));
      setCategorySelected(auctionData?.data?.category_id);
    }
  }, [auctionData]);

  useEffect(() => {
    if (auctionCampaignTypesData) {
      setCampaignTypesSelected(auctionCampaignTypesData?.data);
    }
  }, [auctionCampaignTypesData]);

  function subtractArrays(first, second) {
    return first.filter(f => !second.includes(f));
  }

  function filterMailCampaignsToRemove() {
    const campaignTypeSelectedIds = campaignTypesSelected.map(
      campaignType => campaignType.id,
    );

    const campaignTypeIds = auctionCampaignTypesData.data.map(
      campaign_type => campaign_type.id,
    );

    const removeCampaignTypeIds = subtractArrays(
      campaignTypeIds,
      campaignTypeSelectedIds,
    );

    const removePlannings = auctionPlanningsData.data.filter(planning =>
      removeCampaignTypeIds.includes(planning.campaign.campaign_type_id),
    );

    return removePlannings;
  }

  function filterAdSetsToRemove() {
    const initialAdSets = auctionPlanningsData.data.filter(planning =>
      Planning.isAdType(planning),
    );

    const initialAdSetsIds = initialAdSets.map(adSet => {
      return adSet.id;
    });

    const remainingAdSets = adSets.map(adSet => {
      return adSet.id;
    });
    return subtractArrays(initialAdSetsIds, remainingAdSets).filter(item => {
      return !!item;
    });
  }

  async function createAdCampaignIfNeeded(adSetsToCreate) {
    if (!auctionData?.data?.ad_campaign_id && adSetsToCreate.length) {
      await createAuctionFbCampaign({ category_id: categorySelected });
    }
  }

  async function savePlannings() {
    const campaignTypeSelectedIds = campaignTypesSelected.map(
      campaignType => campaignType.id,
    );

    const campaignTypeIds = auctionCampaignTypesData.data.map(
      campaign_type => campaign_type.id,
    );

    const addCampaignTypeIds = subtractArrays(
      campaignTypeSelectedIds,
      campaignTypeIds,
    );

    const removeCampaignTypeIds = subtractArrays(
      campaignTypeIds,
      campaignTypeSelectedIds,
    );

    const removePlannings = auctionPlanningsData.data.filter(planning =>
      removeCampaignTypeIds.includes(planning.campaign.campaign_type_id),
    );

    if (removePlannings.some(planning => !!planning.campaign.has_replicas)) {
      throw new Error(t('auction_planning.campaign_has_replica'));
    }

    const addCampaignTypePromises = addCampaignTypeIds.map(
      async campaignTypeId => {
        const campaignTypeData = {
          campaign_type: { id: campaignTypeId },
          category_id: categorySelected,
        };
        await createAuctionPlanningsMutate(campaignTypeData);
      },
    );

    const adSetsToCreate = adSets.filter(adSet => {
      return !adSet.id;
    });

    await createAdCampaignIfNeeded(adSetsToCreate);

    const adSetsToRemove = filterAdSetsToRemove();

    const addAdSetsPromises = adSetsToCreate.map(async adSet => {
      const adSetData = {
        campaign_type: { id: adSet.campaign_type_id },
        ad_audience_id: adSet.audience.id,
        settings: { audience_info: adSet.audience.audience_info },
        ad_placements: adSet.placements,
        name: adSet.name,
      };
      await createAuctionPlanningsMutate(adSetData);
    });

    const removePlanningsPromises = removePlannings.map(async planning => {
      await deleteAuctionPlanningsMutate(planning.id);
    });

    const removeAdSetsPromises = adSetsToRemove.map(async adSetId => {
      await deleteAuctionPlanningsMutate(adSetId);
    });

    await Promise.all(addCampaignTypePromises);
    await Promise.all(removePlanningsPromises);

    await Promise.all(addAdSetsPromises);
    await Promise.all(removeAdSetsPromises);
  }

  async function saveAuctionData() {
    let params = {
      status: checkMailStatus(auctionData?.data?.status),
      ad_status: checkAdStatus(auctionData?.data?.ad_status),
    };

    if (
      Auction.dirtyTitles(auctionTitles, nameTranslations(auctionData?.data))
    ) {
      params = {
        ...params,
        payload_overrides: { name_translations: auctionTitles },
      };
    }

    if (categorySelected) {
      params = { ...params, category_id: categorySelected };
    }

    await updateAuctionMutate(params);
  }

  function checkMailStatus(status) {
    const hasPlannings = campaignTypesSelected.some(
      campaignTypes => campaignTypes.name !== 'Facebook carousel',
    );
    if (status === 'needs_review') return status;
    if (status === 'email_ready' && hasPlannings) return status;
    return hasPlannings ? AUCTION_STATUS[3] : AUCTION_STATUS[2];
  }

  function checkAdStatus(status) {
    const hasPlannings = adSets.length > 0;

    if (status === 'needs_review') return status;
    if (status === 'fb_ready' && hasPlannings) return status;

    return hasPlannings ? AUCTION_AD_STATUS[3] : AUCTION_AD_STATUS[2];
  }

  function onSave(redirect) {
    setShouldRedirect(redirect);
    if (hasRemovedPlannings()) {
      setOpenConfirmationModal(true);
    } else {
      return save(redirect);
    }
  }

  async function save(redirect) {
    if (!categorySelected) {
      return error(t('auction_info.errors.category_unselected'));
    }

    setLoading(true);
    try {
      await saveAuctionData();
      await savePlannings();
      success(t('toaster.auction_planned'));

      setLoading(false);
      if (shouldRedirect || redirect) {
        history.push(nextLink);
      }
      return true;
    } catch (err) {
      setLoading(false);
      error(err?.message);
    }
  }

  function editTitle(language, title) {
    setAuctionTitles(prevTitles => ({ ...prevTitles, [language]: title }));
  }

  function checkOpenNewAdSetModal(flag) {
    if (isPastDate(auctionData?.data?.end_date)) {
      return error(t('auction_planning.planning_selector.create_adset_past'));
    } else {
      setOpenNewAdSetModal(flag);
    }
  }

  if (
    auctionIsError ||
    auctionLotsIsError ||
    categoriesIsError ||
    campaignTypesIsError ||
    auctionPlanningsIsError ||
    auctionCampaignTypesIsError
  ) {
    error(
      auctionError?.message ||
        auctionLotsError?.message ||
        categoriesError?.message ||
        campaignTypesError?.message ||
        auctionPlanningsError?.message ||
        auctionCampaignTypesError?.message,
    );
    history.push('/');
  }

  function hasRemovedPlannings() {
    const mailPLanningsToRemove = filterMailCampaignsToRemove();

    const adSetsToRemove = filterAdSetsToRemove();

    if (mailPLanningsToRemove.length > 0 || adSetsToRemove.length > 0) {
      return true;
    }

    return false;
  }

  function closeModalAndSave() {
    setOpenConfirmationModal(false);
    save();
  }

  function renderConfirmationModal() {
    return (
      <ConfirmationModal
        onConfirm={closeModalAndSave}
        onCancel={() => {
          setOpenConfirmationModal(false);
        }}
        message={t('remove_planning_confirmation')}
      />
    );
  }

  return (
    <LayoutContent
      auction={auctionData?.data}
      links={links}
      loading={loading}
      saveTitle={t('footer.plan')}
      onSave={onSave}
      nextTitle={t('todo_mail')}
      nextLink={nextLink}
      prevLink={`/`}
      defaultSaveAndGo={() => !hasRemovedPlannings()}
    >
      {setupLoading ? (
        <Loader show={true} />
      ) : (
        <ContentStyle>
          <EditContentStyle>
            <AuctionTitleEdit
              image={
                auctionData?.data?.payload?.attachments?.[0]?.urls?.[2]?.large
              }
              titles={auctionTitles}
              onChange={editTitle}
            />
          </EditContentStyle>
          <InfoContentStyle>
            <AuctionInfo
              auction={auctionData.data}
              lots={auctionLotsData.data}
            />
          </InfoContentStyle>
          <CategoryContentStyle>
            <AuctionCategory
              categories={categoriesData.data}
              selected={categorySelected}
              onSelect={setCategorySelected}
            />
          </CategoryContentStyle>
          <SelectorContentStyle>
            <PlanningsSelector
              campaignOptions={CampaignType.fromApiOptions(
                campaignTypesData.data,
                auctionData.data,
              )}
              campaignSelected={campaignTypesSelected}
              setCampaignsSelected={setCampaignTypesSelected}
              setOpenNewAdSetModal={checkOpenNewAdSetModal}
              adCampaignType={adCampaignType}
              adSets={adSets}
              setAdSets={setAdSets}
            />
          </SelectorContentStyle>
          <LotsListContentStyle
            ref={ref => {
              lotsListContainerRef.current = ref;
              setRefLoading(false);
            }}
          >
            <AuctionLotsList
              auction={auctionData.data}
              lots={auctionLotsData?.data?.slice(0, lotsCount)}
            />
          </LotsListContentStyle>
          {openNewAdSetModal && adCampaignType && (
            <NewAdSetModal
              setOpenNewAdSetModal={checkOpenNewAdSetModal}
              adSets={adSets}
              setAdSets={setAdSets}
              auction={auctionData.data}
              adCampaignType={adCampaignType}
              igAdCampaignType={igAdCampaignType}
              setError={error}
            />
          )}
        </ContentStyle>
      )}
      {openConfirmationModal && renderConfirmationModal()}
    </LayoutContent>
  );
}

export default AuctionPlan;
