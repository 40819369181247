import React from 'react';
import PropTypes from 'prop-types';

import DropCard from './CampaignDropCard';
import { DEFAULT_CANVAS } from '../../lib/constants';

function CampaignDropAuction({
  auction,
  setEdit,
  setCanvasDimensions,
  coverImage,
}) {
  function onEdit(auction) {
    setCanvasDimensions({
      width: DEFAULT_CANVAS.width,
      height: DEFAULT_CANVAS.height,
    });
    setEdit(auction);
  }

  return (
    <DropCard
      index={null}
      onClick={() => onEdit(auction)}
      onDrop={() => {}}
      id={auction?.id}
      imageSrc={coverImage(auction?.id)?.url}
    />
  );
}

CampaignDropAuction.propTypes = {
  auction: PropTypes.object,
  setEdit: PropTypes.func,
  setCanvasDimensions: PropTypes.func,
  coverImage: PropTypes.func,
};

export default CampaignDropAuction;
