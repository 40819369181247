import api from '../../lib/api';
import { useMutation } from 'react-query';
import queryClient from '../../lib/queryClient';

export function useCreateExternalPlanning(campaign_id) {
  return useMutation(
    async params => await api.post(`/external_plannings`, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaign', campaign_id]);
        queryClient.invalidateQueries(['campaigns']);
      },
    },
  );
}

export function useDeleteExternalPlanning(id, campaign_id, last_planning) {
  return useMutation(async () => await api.delete(`external_plannings/${id}`), {
    onSuccess: () => {
      if (!last_planning) {
        queryClient.invalidateQueries(['campaigns', campaign_id]);
        queryClient.invalidateQueries(['campaigns']);
      }
    },
  });
}
