import React from 'react';
import styled from 'styled-components';

import LabeledInput from '../../src/components/common/LabeledInput';
import LabeledNumberInput from '../../src/components/common/LabeledNumberInput';
import DateTimePicker from '../../src/components/common/DateTimePickerOverrided';
import DropdownMenu from '../../src/components/common/DropdownMenu';
import CampaignExternalAuctionUpload from '../../src/components/campaign/CampaignExternalAuctionUpload';
import CounterInput from '../../src/components/common/CounterInput';
import Checkbox from '../../src/components/common/CustomCheckbox';
import infoIcon from '../assets/icons/info.svg';

import {
  DEFAULT_TIMEZONE,
  EXTRA_AUCTION_CANVAS,
  BID_LABEL_OPTIONS,
} from '../lib/constants';

const UpperMenuTitle = styled.span`
  margin-top: 1.6rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 0.85rem;
  color: ${props => props.theme.text};
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const EditConfigContainer = styled.div`
  display: flex;
  flex-direction: row;
  p {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 10px;
    width: 20px;
  }
  input[type='text'] {
    width: 200px;
  }
  input[type='number'] {
    width: 200px;
  }
`;

const EditConfigContainerColumn = styled(EditConfigContainer)`
  flex-direction: column;
  input[type='text'] {
    margin-bottom: 50px;
  }
  div {
    align-items: baseline;
  }
`;

const SingleInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LotsCounterInputWrapper = styled(SingleInputWrapper)`
  margin-left: 1rem;
`;

const InputContainerStyle = styled.div`
  display: flex;
  color: #343a40;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LabelStyle = styled.p`
  margin: 0.5rem 1rem;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 2rem;
  text-transform: uppercase;
`;

const DropdownMenuContainer = styled.div`
  div {
    height: 34px;
    select {
      height: 34px;
    }
  }
`;

const LabeledInputColumn = styled(LabeledInput)`
  input[type='text'] {
    margin-bottom: 50px;
  }
  div {
    align-items: baseline;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top 20px;
  margin-left: 10px;
`;

const ContentErrorIconStyle = styled.img`
  padding: 0.2rem;
  border-radius: 5px;
  cursor: default;
`;

export function existingCampaignTypesAllowed(campaign) {
  const campaignTypeName = campaign?.campaign_type_name?.toLowerCase();
  if (!campaignTypeName || campaignTypeName.includes('dedicated')) {
    return false;
  }

  return true;
}

export function renderInlineLocaleInput(props) {
  const { name, onChange, value, title, index } = props;
  return (
    <>
      <UpperMenuTitle>{title}</UpperMenuTitle>
      <EditConfigContainer key={name}>
        {['en', 'fr', 'nl'].map(locale => {
          return (
            <LabeledInput
              value={value?.[locale] || ''}
              key={`${name}_input_${locale}`}
              label={locale}
              placeholder={''}
              onChange={e => {
                index !== null
                  ? onChange(e.target.value, locale, name, index)
                  : onChange(e.target.value, locale, name);
              }}
            />
          );
        })}
      </EditConfigContainer>
    </>
  );
}

export function renderColumnLocaleInput(props) {
  const { name, onChange, value, title, index } = props;
  return (
    <>
      <EditConfigContainerColumn key={name}>
        <UpperMenuTitle>{title}</UpperMenuTitle>
        {['en', 'fr', 'nl'].map(locale => {
          return (
            <LabeledInputColumn
              value={value?.[locale] || ''}
              key={`${name}_input_${locale}`}
              label={locale}
              placeholder={''}
              onChange={e => {
                index !== null
                  ? onChange(e.target.value, locale, name, index)
                  : onChange(e.target.value, locale, name);
              }}
            />
          );
        })}
      </EditConfigContainerColumn>
    </>
  );
}

export function renderSingleTextInput(props) {
  const { name, placeholder, onChange, value, title } = props;
  return (
    <SingleInputWrapper>
      <UpperMenuTitle>{title}</UpperMenuTitle>
      <LabeledInput
        label={''}
        placeholder={placeholder}
        value={value || ''}
        onChange={e => {
          onChange(e.target.value, null, name);
        }}
      />
    </SingleInputWrapper>
  );
}

export function renderSingleNumberInput(props) {
  const { name, placeholder, onChange, value, title, index } = props;
  return (
    <SingleInputWrapper>
      <UpperMenuTitle>{title}</UpperMenuTitle>
      <LabeledNumberInput
        label={''}
        placeholder={placeholder}
        minValue={0}
        value={value || ''}
        type={'number'}
        onChange={e => {
          index !== null
            ? onChange(e.target.value, null, name, index)
            : onChange(e.target.value, null, name);
        }}
      />
    </SingleInputWrapper>
  );
}

export function renderDateInput(props) {
  const { name, defaultValue, onChange, value, title } = props;
  return (
    <SingleInputWrapper>
      <UpperMenuTitle>{title}</UpperMenuTitle>
      <InputContainerStyle>
        <LabelStyle>{''}</LabelStyle>
        <DateTimePicker
          key={`datetime_replica`}
          datetime={value || defaultValue}
          setDatetime={date => {
            onChange(date, null, name);
          }}
          timezone={DEFAULT_TIMEZONE}
          onKeyDown={event => {
            event.preventDefault();
          }}
        />
      </InputContainerStyle>
    </SingleInputWrapper>
  );
}

export function renderSelectInput(props) {
  const { name, options, onSelect, value, title, index } = props;
  return (
    <SingleInputWrapper>
      <UpperMenuTitle>{title}</UpperMenuTitle>
      <InputContainerStyle>
        <LabelStyle>{''}</LabelStyle>
        <DropdownMenuContainer>
          <DropdownMenu
            selected={value?.id || options[0]}
            onSelect={e => {
              index !== null
                ? onSelect(e, name, options, index)
                : onSelect(e, name, options);
            }}
            placeholder={''}
            options={options}
          />
        </DropdownMenuContainer>
      </InputContainerStyle>
    </SingleInputWrapper>
  );
}

export function lotUploadDimensions(layout) {
  switch (layout.toString()) {
    case '1':
      return { height: 156, width: 312 }; // 250/1,6  500/1,6
    case '2':
      return { height: 209, width: 314 }; // 161*1,3  242*1,3
    default:
      return { height: 208, width: 312 }; // 104*2    156*2
  }
}

export function renderUploadInput(props) {
  const {
    image,
    onUpload,
    title,
    setSaveLoading,
    saveLoading,
    index,
    lotsLayout,
  } = props;
  const dimensions = lotsLayout ? lotUploadDimensions(lotsLayout) : null;

  return (
    <SingleInputWrapper>
      <UpperMenuTitle>{title}</UpperMenuTitle>
      <CampaignExternalAuctionUpload
        canvasWidth={dimensions?.width || EXTRA_AUCTION_CANVAS.width}
        canvasHeight={dimensions?.height || EXTRA_AUCTION_CANVAS.height}
        hasCoverImage={image != null}
        loading={saveLoading}
        setLoading={setSaveLoading}
        saveUploadImage={file => {
          index !== null ? onUpload(file, index) : onUpload(file);
        }}
        uploadImage={image}
      />
    </SingleInputWrapper>
  );
}

export function renderLotsCounterInput(props) {
  const { counter, setCounter, title } = props;

  return (
    <LotsCounterInputWrapper>
      <UpperMenuTitle>{title}</UpperMenuTitle>
      <CounterInput counter={counter} setCounter={setCounter} />
    </LotsCounterInputWrapper>
  );
}

export function renderUTMTagsCheckbox(props) {
  const { value, onChange, label, hint } = props;

  return (
    <CheckboxContainer>
      <Checkbox value={value} onChange={() => onChange(!value)}>
        {label}
        <ContentErrorIconStyle src={infoIcon} title={hint} />
      </Checkbox>
    </CheckboxContainer>
  );
}

export function validateFields(
  state,
  image,
  campaignTypeRequireLots,
  requiredSingleFields,
) {
  if (!campaignTypeRequireLots && !image) {
    return false;
  }
  const requiredLocaleFields = ['title', 'link'];

  let isValid = true;
  requiredSingleFields.forEach(field => {
    if (!state?.[field]) {
      isValid = false;
    }
  });
  requiredLocaleFields.forEach(field => {
    if (!state?.[field]?.en || !state?.[field]?.fr || !state?.[field]?.nl) {
      isValid = false;
    }
  });
  return isValid;
}

export function validateLotsFields(
  state,
  lotsImages,
  auctionLotsCount,
  campaignTypeRequireLots,
) {
  if (!campaignTypeRequireLots) {
    return true;
  }

  const requiredLocaleFields = ['title', 'link'];
  const requiredSingleFields = ['bid_label', 'bid_price'];
  let isValid = true;

  for (let i = 0; i < auctionLotsCount; i++) {
    if (!lotsImages[i].data) {
      isValid = false;
      break;
    }
    requiredSingleFields.forEach(field => {
      if (!state[i]?.[field]) {
        isValid = false;
      }
    });
    requiredLocaleFields.forEach(field => {
      if (
        !state[i]?.[field]?.en ||
        !state[i]?.[field]?.fr ||
        !state[i]?.[field]?.nl
      ) {
        isValid = false;
      }
    });
    if (isValid === false) {
      break;
    }
  }

  return isValid;
}

export function lotStructure() {
  return {
    title: { en: '', fr: '', nl: '' },
    link: { en: '', fr: '', nl: '' },
    bid_label: BID_LABEL_OPTIONS[0],
    bid_price: null,
  };
}

export function lotImageStructure(auctionLotsCount) {
  return {
    data: null,
    sequence: auctionLotsCount - 1,
  };
}

export function buildLotsPayload(lotsState) {
  return lotsState.map((lot, index) => {
    return {
      links: {
        nl: lot.link.nl,
        en: lot.link.en,
        fr: lot.link.fr,
      },
      name_translations: {
        nl: lot.title.nl,
        en: lot.title.en,
        fr: lot.title.fr,
      },
      bid_label: lot.bid_label.value,
      bid_price: lot.bid_price,
      sequence: index,
    };
  });
}
