import { COUNTRY_CODES, DELIVERY_TYPE_CODES } from './constants';
import { formatCurrency, formatDate } from 'vavato-ui';
import i18n from './i18n';

export * from 'vavato-ui';

export function formatCountryName(countryCode) {
  if (!countryCode) return '';
  const country = COUNTRY_CODES.find(country => country.code === countryCode);
  return country ? country.name : '';
}

export function formatDeliveryType(deliveryType) {
  if (!deliveryType) return '';
  const delivery_type = DELIVERY_TYPE_CODES.find(
    delivery_type => delivery_type.code === deliveryType,
  );
  return delivery_type ? delivery_type.name : '';
}

export function formatDates(from, to) {
  if (!from || !to) return '';
  const [, to_time] = formatDate(to).split(', ');
  const [from_date, from_time] = formatDate(from).split(', ');
  return `${from_date}, ${from_time} - ${to_time}`;
}

export function formatAddress(address) {
  if (!address || Object.keys(address).length === 0) return '';
  const address_line = [address.address_line_1, address.address_line_2]
    .join(' ')
    .trim();
  return `${address_line}, ${address.zip} ${address.city}`;
}

export function formatDatesAddress(from, to, address) {
  const datesStr = formatDates(from, to);
  const addressStr = formatAddress(address);
  if (addressStr === '') return datesStr;
  return `${datesStr}, ${addressStr}`;
}

export function formatCurrencyRounded(currency) {
  return currency ? formatCurrency(currency).replace(',00', '') : '';
}

export function formatURLParams(obj) {
  if (!obj) return '';

  const params = new URLSearchParams(obj).toString();
  return params ? `?${params}` : '';
}

export function lotBidSubtitle(lot) {
  if (lot.payload?.current_bid_price) {
    return `${i18n.t('current_bid')} ${formatCurrencyRounded(
      lot.payload?.current_bid_price,
    )}`;
  }
  return `${i18n.t('opening_bid')} ${formatCurrencyRounded(
    lot.payload?.opening_bid_price,
  )}`;
}

export function nameTranslations(model) {
  const nameTranslationsOverride = model?.payload_overrides?.name_translations;
  if (nameTranslationsOverride?.en) return nameTranslationsOverride;

  return model?.payload?.name_translations || model?.name_translations;
}

export function nameTranslationsByType(model, type = 'mail') {
  const nameTranslationsType =
    model?.payload_overrides?.name_translations?.[type];
  if (nameTranslationsType) return nameTranslationsType;

  return nameTranslations(model);
}

export function nameTranslationByType(model, locale, type = 'mail') {
  const names = nameTranslationsByType(model, type);
  return applyLocale(names, locale);
}

export function nameTranslation(model, locale) {
  const names = nameTranslations(model);
  return applyLocale(names, locale);
}

export function applyLocale(func, locale) {
  switch (locale) {
    case 'fr':
      return func?.fr;
    case 'nl':
      return func?.nl;
    default:
      return func?.en;
  }
}

export function auctionUrl(auction, locale = 'en') {
  if (!auction) return;

  const auctionSlug = auction?.payload?.slug;

  if (auction.atlas_external_id) {
    const localePath = locale === 'nl' ? '' : `${locale}/`;
    return `https://vavato.com/${localePath}a/${auctionSlug}`;
  }

  return `https://vavato.com/${locale}/homepage/${auctionSlug}`;
}

export function lotUrl(auction, lot, locale = 'en') {
  if (!lot) return;

  const auctionSlug = auction?.payload?.slug;
  const lotSlug = lot?.payload?.slug;

  if (lot?.atlas_external_id) {
    return `https://vavato.com/l/${lotSlug}`;
  }

  if (!auctionSlug || !lotSlug) return;

  return `https://vavato.com/${locale}/homepage/${auctionSlug}/${lotSlug}`;
}
