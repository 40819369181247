import { VavatoTheme } from 'vavato-ui';

const Theme = {
  ...VavatoTheme,
  lightBackground: '#f8f9fa',
  darkText: '#343a40',
  changedText: '#df3a58',
  missingText: '#aaa',
};

export default Theme;
