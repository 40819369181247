import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from '../common/LoaderOverrided';

import AuctionsListTableContent from './ListTableContent';
import AuctionsListTablePagination from './ListTablePagination';

const ListLayoutContainer = styled.div`
  width: inherit;
`;

function AuctionsListTable({
  loading,
  auctions,
  page,
  totalPages,
  changePage,
}) {
  if (loading) {
    return <Loader show={true} />;
  }

  return (
    <ListLayoutContainer>
      <AuctionsListTableContent
        auctions={auctions}
        isDraggable={false}
        showCampaignStatus={false}
        campaign={{}}
      />
      <AuctionsListTablePagination
        page={page}
        totalPages={totalPages}
        changePage={changePage}
      />
    </ListLayoutContainer>
  );
}

AuctionsListTable.propTypes = {
  loading: PropTypes.bool,
  auctions: PropTypes.array,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  changePage: PropTypes.func,
};

export default AuctionsListTable;
