import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from './Card';

const TextStyle = styled.div`
  margin: 0;
  line-height: 2rem;
  text-align: center;
  font-weight: 400;
  font-size: 0.625rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0 0 3px 3px;
  color: ${props => props.theme.cardBackground};
  background-color: ${props => props.theme.secondary};
`;

function CardWithText({ image, text, url }) {
  return (
    <Card image={image}>
      <a href={url || 'https://vavato.com/'} target="_blank" rel="noreferrer">
        <TextStyle>{text}</TextStyle>
      </a>
    </Card>
  );
}

CardWithText.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
};

export default CardWithText;
