import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CampaignInput from './CampaignInput';
import RadioInput from '../common/RadioInput';

const RadioInputStyle = styled.div`
  display: flex;
  flex-direction: row;
`;

function CampaignRadioInput({
  index,
  name,
  disabled,
  options,
  type,
  checked,
  onCheck,
}) {
  return (
    <CampaignInput name={name}>
      <RadioInputStyle>
        {options.map(option => (
          <RadioInput
            key={option.id}
            disabled={disabled}
            name={`${name} ${index}`}
            option={
              type === 'image' ? <img src={option.value} /> : option.value
            }
            checked={checked === option.id}
            onCheck={_e => onCheck(option.id)}
          />
        ))}
      </RadioInputStyle>
    </CampaignInput>
  );
}

CampaignRadioInput.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  type: PropTypes.string,
  checked: PropTypes.any,
  onCheck: PropTypes.func,
};

export default CampaignRadioInput;
