import React from 'react';
import PropTypes from 'prop-types';
import Planning from '../../models/Planning';
import styled from 'styled-components';
import CheckboxSelector from '../common/CheckboxSelector';
import { useTranslation } from 'react-i18next';
import adSetPlus from '../../assets/icons/adsetplus.svg';
import adSetRemove from '../../assets/icons/adsetremove.svg';
import { isPastDate } from '../../lib/dateHelper';
const ContainerStyle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  padding: 16px 20px;
  background-color: ${props => props.theme.lightBackground};
  font-size: 12px;
`;

const SectionTitle = styled.p`
  color: #c4c4c4;
  font-weight: bold;
`;

const AddAdsetButton = styled.span`
  padding: 5px;
  background-color: #c4c4c4;
  color: #595a5c;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  font-size: 10px;
  font-weight: bold;
  margin: 4px 8px;
  position: relative;
`;
const PlusIcon = styled.img`
  position: absolute;
  left: 10px;
`;
const RemoveIcon = styled.img`
  margin-left: 5px;
  cursor: pointer;
`;

const AdSetsContainer = styled.div`
  margin: 4px 8px 10px;
`;

const AdSetContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.7em 0;
`;

const AdSetTitle = styled.span`
  color: #000;
  overflow: hidden;
  word-wrap: break-word;
`;

function PlanningsSelector({
  campaignOptions,
  campaignSelected,
  setCampaignsSelected,
  setOpenNewAdSetModal,
  adCampaignType,
  adSets,
  setAdSets,
}) {
  const { t } = useTranslation();

  function filterMailCampaignTypes(options) {
    return options.filter(option => Planning.isMailType(option));
  }

  if (!campaignOptions) {
    return null;
  }

  function removeAdSet(index) {
    const updatedAdsets = [...adSets];
    updatedAdsets.splice(index, 1);
    setAdSets(updatedAdsets);
  }

  return (
    <ContainerStyle>
      <SectionTitle>
        {t('auction_planning.planning_selector.mailing')}
      </SectionTitle>
      <CheckboxSelector
        options={filterMailCampaignTypes(campaignOptions)}
        selected={campaignSelected}
        setSelected={setCampaignsSelected}
      />
      {adCampaignType && (
        <>
          <SectionTitle>
            {t('auction_planning.planning_selector.facebook')}
          </SectionTitle>

          <AdSetsContainer>
            {adSets.map((adSet, index) => {
              return (
                <AdSetContainer key={`adset_${index}`}>
                  <AdSetTitle>{adSet.name}</AdSetTitle>
                  {!isPastDate(adSet.ad_start_datetime) && (
                    <RemoveIcon
                      src={adSetRemove}
                      onClick={() => removeAdSet(index)}
                      alt={'Remove'}
                    />
                  )}
                </AdSetContainer>
              );
            })}
          </AdSetsContainer>
          <AddAdsetButton
            onClick={() => {
              setOpenNewAdSetModal(true);
            }}
          >
            <PlusIcon src={adSetPlus} />
            {t('auction_planning.planning_selector.add_adset')}
          </AddAdsetButton>
        </>
      )}
    </ContainerStyle>
  );
}

PlanningsSelector.propTypes = {
  campaignOptions: PropTypes.array,
  campaignSelected: PropTypes.array,
  setCampaignsSelected: PropTypes.func,
  setOpenNewAdSetModal: PropTypes.func,
  adCampaignType: PropTypes.object,
  adSets: PropTypes.array,
  setAdSets: PropTypes.func,
};

export default PlanningsSelector;
