import { useTranslation } from 'react-i18next';
import queryClient from '../../../lib/queryClient';
import { useParams, useHistory } from 'react-router-dom';
import React, { useState, useEffect, useContext, useCallback } from 'react';

import { ToasterContext } from 'vavato-ui';
import ImageEditor from '../../../components/common/ImageEditor';
import Loader from '../../../components/common/LoaderOverrided';
import CustomStepper from '../../../components/common/CustomStepper';
import LayoutContent from '../../../components/layout/LayoutContent';
import CampaignConfiguration from '../../../components/campaign/CampaignConfiguration';
import {
  TODO_MAIL_STEPPER_LABELS,
  CAMPAIGN_TYPES_CONFIG_FIELDS,
  DEFAULT_CAMPAIGN_TYPE_CONFIG_FIELDS,
  DEFAULT_CANVAS,
  CAMPAIGN_TYPE_EDIT_AUCTION,
  ASPECT_RATIO_BIG,
  AUCTION_AD_STATUS,
  NEEDS_UPDATE_STATUSES,
} from '../../../lib/constants';

import {
  useFetchAuctionData,
  useFetchAuctionLotsData,
  useFetchAuctionPlanningsData,
  useUpdateAuctionPlanningsData,
  useDeleteAuctionPlanningsData,
  useUpdateAuctionData,
} from '../../../hooks/services/AuctionAPI';
import { useFetchCampaignTypesData } from '../../../hooks/services/CampaignTypesAPI';
import Planning from '../../../models/Planning';
import { useUpdateLotData } from '../../../hooks/services/LotAPI';
import Uploader from '../../../lib/uploader';
import { generateRandomHex } from '../../../lib/random';

function AuctionConfigureCampaign() {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const toasterContext = useContext(ToasterContext);
  const { success, error, info } = toasterContext;

  const links = [
    { path: '/', name: 'home' },
    { path: '/auctions', name: 'auctions_list' },
    { path: `/auctions/${id}/plan`, name: 'auction' },
    { path: '', name: 'todo_mail' },
  ];

  const [openPlanning, setOpenPlanning] = useState(null);
  const [plannings, setPlannings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [setupLoading, setSetupLoading] = useState(true);
  const [editCard, setEditCard] = useState(null);
  const [canvasDimensions, setCanvasDimensions] = useState({});
  const [lastEditedLot, setLastEditedLot] = useState({});

  const {
    data: auctionData,
    error: auctionError,
    isError: auctionIsError,
  } = useFetchAuctionData(id, openPlanning);

  const {
    data: auctionLotsData,
    error: auctionLotsError,
    isError: auctionLotsIsError,
    isFetching: auctionLotsIsFetching,
  } = useFetchAuctionLotsData(id, {
    highlighted_lots: true,
    with_cover_image: true,
    with_original_image: true,
    with_attachments: true,
    planning_id: openPlanning,
  });

  const {
    data: campaignTypesData,
    error: campaignTypesError,
    isError: campaignTypesIsError,
  } = useFetchCampaignTypesData();

  const {
    data: auctionPlanningsData,
    error: auctionPlanningsError,
    isError: auctionPlanningsIsError,
  } = useFetchAuctionPlanningsData(id);

  const { mutateAsync: updateAuctionPlanningsMutate } =
    useUpdateAuctionPlanningsData(id);

  const { mutateAsync: updateLotMutate } = useUpdateLotData();
  const { mutateAsync: updateAuctionMutate } = useUpdateAuctionData(id);

  const { mutateAsync: deleteAuctionPlanningsMutate } =
    useDeleteAuctionPlanningsData(id);

  useEffect(() => {
    if (
      !campaignTypesData ||
      !auctionPlanningsData ||
      !auctionLotsData ||
      !setupLoading
    )
      return;

    const planningsData = [...auctionPlanningsData?.data];
    const formattedPlannings = planningsData.map(planningData =>
      Planning.fromApiFormat(planningData, campaignTypesData?.data),
    );
    const mergedPlannings = Planning.mergeAll(formattedPlannings);
    const sortedPlannings = Planning.sortAll(mergedPlannings);

    setPlannings([...sortedPlannings]);
    setSetupLoading(false);
    // eslint-disable-next-line
  }, [campaignTypesData, auctionPlanningsData, auctionLotsData]);

  function openedPlanning(plannings) {
    return plannings.find(planning => planning.id === openPlanning);
  }

  function presetAdLink(newPlannings) {
    const adSets = newPlannings.filter(
      p => Planning.isAdType(p) && !p.settings.link,
    );
    if (adSets.length === 0) return;

    const refAdSet = adSets[0];
    adSets.forEach(p => {
      p.settings.link = refAdSet.id;
    });
    setPlannings(newPlannings);
  }

  function presetAdCopy(newPlannings, planning) {
    if (planning.settings.copy) return;

    const language = Planning.language(planning) || 'en';
    const auction = auctionData?.data;
    planning.settings.language = language;
    planning.settings.copy = Planning.copyText(language, auction, planning);
    setPlannings(newPlannings);
  }

  function presetAdConfig(newPlannings) {
    const planning = openedPlanning(newPlannings);
    if (!Planning.isAdType(planning)) return;
    presetAdCopy(newPlannings, planning);
    presetAdLink(newPlannings);
  }

  function presetCoverImages(newPlannings) {
    const planning = openedPlanning(newPlannings);
    planning.dirtyCoverImages = {};

    if (planning.edit_type === CAMPAIGN_TYPE_EDIT_AUCTION) {
      const auction = auctionData.data;
      if (Planning.hasCoverImage(auction)) return;

      planning.dirtyCoverImages[auction.id] = Planning.presetCoverImage(
        auction,
        ASPECT_RATIO_BIG,
      );
    } else {
      const lots = auctionLotsData.data;
      const selectedLots = lots.filter(lot =>
        planning.lots_order.includes(lot.id),
      );
      selectedLots.forEach(lot => {
        if (Planning.hasCoverImage(lot)) return;

        const ratio = planning.ratio(lot);
        planning.dirtyCoverImages[lot.id] = Planning.presetCoverImage(
          lot,
          ratio,
        );
      });
    }

    setPlannings(newPlannings);
  }

  useEffect(() => {
    if (!openPlanning || auctionLotsIsFetching || !auctionData) return;
    const newPlannings = [...plannings];
    presetAdConfig(newPlannings);
    presetLotsOrder(newPlannings);
    // eslint-disable-next-line
  }, [openPlanning, auctionLotsIsFetching, auctionData]);

  const resetAdsWhoLostReference = useCallback(() => {
    if (!plannings) return;
    const newPlannings = [...plannings];
    const adSets = newPlannings.filter(p => Planning.isAdType(p));
    const updatedAdsets = adSets.map(adSet => {
      const refPlanning = adSets.find(x => x.id === adSet.settings.link);
      if (refPlanning.settings.link !== refPlanning.id) {
        adSet.settings.link = adSet.id;
      }
      return adSet;
    });

    const mailPlannings = newPlannings.filter(p => !Planning.isAdType(p));

    setPlannings(mailPlannings.concat(updatedAdsets));
  }, [plannings]);

  function fillLotsOrder(newPlannings) {
    const newPlanning = openedPlanning(newPlannings);
    const lotsOrderLength = newPlanning.lots_order.length;

    if (lotsOrderLength < newPlanning.settings.layout) {
      newPlanning.increaseLotsOrder(auctionLotsData.data);
      setPlannings(newPlannings);
    } else if (lotsOrderLength > newPlanning.settings.layout) {
      newPlanning.decreaseLotsOrder();
      setPlannings(newPlannings);
    }
  }

  function syncLotsOrder(newPlannings) {
    const newPlanning = openedPlanning(newPlannings);
    newPlanning.syncLotsOrder(newPlannings);
    setPlannings(newPlannings);
  }

  function presetLotsOrder(newPlannings) {
    fillLotsOrder(newPlannings);
    syncLotsOrder(newPlannings);
    presetCoverImages(newPlannings);
  }

  function coverImage(cardId, cardPlanning = null) {
    const planning = cardPlanning || openedPlanning(plannings);
    if (planning.edit_type === CAMPAIGN_TYPE_EDIT_AUCTION) {
      const auction = auctionData?.data;
      return planning.coverImage(auction);
    }

    const lot = auctionLotsData.data.find(
      highlightedLot => highlightedLot.id === cardId,
    );
    return planning.coverImage(lot);
  }

  async function savePlannings() {
    const planningsData = [...auctionPlanningsData?.data];
    let unmergedPlannings = Planning.unmergeSameTypePlannings(plannings);
    unmergedPlannings = Planning.unmergeHappyUpdatePlannings(
      unmergedPlannings,
      planningsData,
    );

    const toRemovePlannings = planningsData.filter(planning => {
      return (
        !unmergedPlannings.map(p => p.id).includes(planning.id) &&
        !planning.campaign.replica_of_id
      );
    });

    const toUpdatePlannings = auctionNeedsFullUpdate(auctionData?.data)
      ? unmergedPlannings
      : Planning.selectDirtyPlannings(unmergedPlannings, planningsData);

    const updatePlanningsPromises = toUpdatePlannings.map(async planning => {
      let params = Planning.toApiFormat(planning);
      if (Planning.isAdType(planning)) {
        params = {
          ...params,
          auction_ad_status: AUCTION_AD_STATUS[4],
          is_creative_dirty: Planning.isCreativeDirty(planning, planningsData),
        };
      }

      await updateAuctionPlanningsMutate({
        id: planning.id,
        params,
      });
    });

    const removePlanningsPromises = toRemovePlannings.map(async planning => {
      await deleteAuctionPlanningsMutate(planning.id);
    });

    await Promise.all(updatePlanningsPromises);
    await Promise.all(removePlanningsPromises);
  }

  function auctionNeedsFullUpdate(auction) {
    return (
      NEEDS_UPDATE_STATUSES.includes(auction?.status) ||
      NEEDS_UPDATE_STATUSES.includes(auction?.ad_status)
    );
  }

  async function savePlanningCoverImages(modelId, planningId, coverData) {
    const planningIds = Array.isArray(planningId) ? planningId : [planningId];

    const saveCoverImagePromisses = planningIds.map(async planning_id => {
      await saveCoverImage(modelId, planning_id, coverData);
    });

    await Promise.all(saveCoverImagePromisses);
  }

  async function saveDirtyCoverImages(planningId, dirtyCoverImages) {
    const savePlanningCoverImagesPromisses = Object.keys(dirtyCoverImages).map(
      async modelId => {
        const coverData = dirtyCoverImages[modelId];
        await savePlanningCoverImages(modelId, planningId, coverData);
      },
    );

    await Promise.all(savePlanningCoverImagesPromisses);
  }

  async function saveCoverImages() {
    const saveDirtyCoverImagesPromisses = plannings.map(async planning => {
      const dirtyCoverImages = planning.dirtyCoverImages;
      if (dirtyCoverImages && Object.keys(dirtyCoverImages).length > 0) {
        await saveDirtyCoverImages(planning.id, dirtyCoverImages);
        planning.dirtyCoverImages = null;
      }
    });
    await Promise.all(saveDirtyCoverImagesPromisses);
  }

  async function saveCoverImage(modelId, planningId, coverData) {
    const coverParams = {
      image_data: coverData.signed_id,
      attachment_id: coverData.attachment_id,
      planning_id: planningId,
    };

    await updateImage(modelId, coverParams);
  }

  function validateOnSave() {
    const validateResult = Planning.validate(plannings);
    if (validateResult) throw new Error(t(validateResult));
  }

  async function onSave() {
    setLoading(true);

    try {
      validateOnSave();
      await saveCoverImages();
      await savePlannings();
      success(t('toaster.campaign_saved'));
      return true;
    } catch (err) {
      error(err?.message);
    } finally {
      setOpenPlanning(null);
      setLoading(false);
    }
  }

  function checkReferencePlanning(planning) {
    if (Planning.isMailType(planning)) return false;
    if (planning.isReference() === false) {
      info(t('campaign_config.reference_planning'));
      return true;
    }
    return false;
  }

  function setPlanningSettings(index, field, value, locale) {
    const newPlannings = [...plannings];
    const newPlanning = newPlannings[index];
    if (field === 'layout' && checkReferencePlanning(newPlanning)) return;
    const settingsCopy = { ...newPlanning.settings };
    if (field === 'additional_info') {
      settingsCopy[field] = { ...settingsCopy[field], [locale]: value };
    } else {
      settingsCopy[field] = value;
    }
    newPlanning.settings = settingsCopy;
    setPlannings([...newPlannings]);
    if (field === 'layout') {
      presetLotsOrder(newPlannings);
    } else if (field === 'link') {
      syncLotsOrder(newPlannings);
      resetAdsWhoLostReference();
    }
  }

  function setPlanningSendDatetime(index, datetime, field) {
    const newPlannings = [...plannings];
    const newPlanning = newPlannings[index];

    if (field === 'start') {
      newPlanning.start_datetime = datetime;
    } else if (field === 'close') {
      newPlanning.close_datetime = datetime;
    } else {
      newPlanning.send_datetime = datetime;
    }

    setPlannings(newPlannings);
  }

  function setPlanningLotsOrder(index, lotOrder, lotId) {
    const newPlannings = [...plannings];
    const newPlanning = newPlannings[index];

    if (checkReferencePlanning(newPlanning)) return;

    newPlanning.swapLotOrder(lotOrder, lotId);
    newPlanning.syncLotsOrder(newPlannings);

    setPlannings(newPlannings);
    presetCoverImages(newPlannings);
  }

  function setDirtyImagesPlanning() {
    const planningsCopy = [...plannings];
    const refPlanning = openedPlanning(planningsCopy);
    refPlanning.dirtyImages = true;
    planningsCopy.forEach(p => {
      if (Planning.isAdType(p) && p.settings.link === refPlanning.id) {
        p.dirtyImages = true;
      }
    });
    setPlannings([...planningsCopy]);
  }

  async function updateImage(cardId, params) {
    const planning = Planning.findPlanning(plannings, params.planning_id);
    if (planning.dirtyCoverImages?.[cardId])
      delete planning.dirtyCoverImages[cardId];

    if (planning.edit_type === CAMPAIGN_TYPE_EDIT_AUCTION) {
      await updateAuctionMutate(params);
      setSaveLoading(false);
      queryClient.invalidateQueries([
        'auction',
        { id, planning_id: openPlanning },
      ]);
    } else {
      await updateLotMutate({ id: cardId, params });
      setSaveLoading(false);
      queryClient.invalidateQueries([
        'auctionLots',
        id,
        {
          highlighted_lots: true,
          with_cover_image: true,
          with_original_image: true,
          with_attachments: true,
          planning_id: openPlanning,
        },
      ]);
    }
  }

  async function saveImage(id, planningIds, coverParams) {
    setSaveLoading(true);
    const planning_ids = Planning.parsePlanningIds(planningIds, plannings);
    const blob = Object.values(coverParams)[0];
    const filename = generateRandomHex(32);
    const file = new File([blob], filename);

    const successCB = async blob => {
      const coverType = Object.keys(coverParams)[0];
      coverParams[coverType] = blob.signed_id;

      const updatePromisses = planning_ids.map(async planning_id => {
        const params = { ...coverParams, planning_id };
        await updateImage(id, params);
      });
      await Promise.all(updatePromisses);
    };

    const errorCB = async _error => {
      setSaveLoading(false);
    };

    const uploader = new Uploader(file, successCB, errorCB);
    uploader.directUpload();
  }

  async function saveEditCard(cardId, coverImage, attachmentId) {
    setDirtyImagesPlanning();

    const lotEdited = {};
    try {
      const coverParams = {
        image_data: coverImage,
        attachment_id: attachmentId,
      };
      await saveImage(cardId, openPlanning, coverParams);
      lotEdited.planning_id = openPlanning;
      lotEdited.lot_index = openedPlanning(plannings).lots_order.indexOf(
        editCard.id,
      );
    } catch (err) {
      error(err?.message);
    } finally {
      setLastEditedLot(lotEdited);
      setEditCard(null);
    }
  }

  function uploadImage(cardId) {
    const planning = openedPlanning(plannings);
    if (planning.edit_type === CAMPAIGN_TYPE_EDIT_AUCTION) {
      return auctionData?.data?.original_image_url?.url;
    } else {
      const lot = auctionLotsData.data.find(
        highlightedLot => highlightedLot.id === cardId,
      );
      return lot?.original_image_url?.url;
    }
  }

  function campaignTypeConfigFields(planning) {
    const campaignType = campaignTypesData.data.find(
      ct => ct.id === planning.campaign.campaign_type_id,
    );

    return (
      CAMPAIGN_TYPES_CONFIG_FIELDS[campaignType.name] ||
      DEFAULT_CAMPAIGN_TYPE_CONFIG_FIELDS
    );
  }

  function attachmentId(card) {
    const coverImage = card?.cover_image_url;
    if (!coverImage) return;

    return coverImage.attachment_id;
  }

  if (
    auctionIsError ||
    auctionPlanningsIsError ||
    campaignTypesIsError ||
    auctionLotsIsError
  ) {
    error(
      auctionError?.message ||
        auctionPlanningsError?.message ||
        campaignTypesError?.message ||
        auctionLotsError?.message,
    );
    history.push('/');
  }

  return (
    <LayoutContent
      auction={auctionData?.data}
      links={links}
      loading={saveLoading || loading}
      saveTitle={t('footer.save')}
      onSave={onSave}
      nextTitle={t('footer.next')}
      nextLink={`/auctions/${id}/todomail/preview_campaign`}
      prevLink={`/auctions/${id}/todomail/configure_lots`}
      defaultSaveAndGo={() => {
        return true;
      }}
    >
      {setupLoading ? (
        <Loader show={true} />
      ) : (
        <>
          <CustomStepper
            activeStep={2}
            steps={TODO_MAIL_STEPPER_LABELS}
            id={id}
          />
          {plannings.map((planning, index) => (
            <CampaignConfiguration
              auction={auctionData?.data}
              index={index}
              planning={planning}
              plannings={plannings}
              openPlanning={openPlanning}
              setOpenPlanning={setOpenPlanning}
              setEdit={setEditCard}
              lots={auctionLotsData?.data}
              key={planning?.id?.toString()}
              configFields={campaignTypeConfigFields(planning)}
              title={Planning.title(planning)}
              configuration={planning?.settings}
              setConfiguration={(field, value) =>
                setPlanningSettings(index, field, value, null)
              }
              setAdditionalInfos={(field, value, locale) =>
                setPlanningSettings(index, field, value, locale)
              }
              sendDatetime={{
                send_datetime: planning?.send_datetime,
                start_datetime:
                  planning?.start_datetime || planning?.ad_start_datetime,
                close_datetime:
                  planning?.close_datetime || planning?.ad_end_datetime,
              }}
              setSendDatetime={(datetime, field) =>
                setPlanningSendDatetime(index, datetime, field)
              }
              lotsOrder={planning?.lots_order}
              setLotsOrder={(lotOrder, lotId) =>
                setPlanningLotsOrder(index, lotOrder, lotId)
              }
              setCanvasDimensions={setCanvasDimensions}
              loadingLots={saveLoading || auctionLotsIsFetching}
              coverImage={coverImage}
              warning={info}
              lastEditedLot={lastEditedLot}
              setLastEditedLot={setLastEditedLot}
            />
          ))}
          {editCard && (
            <ImageEditor
              canvasWidth={canvasDimensions?.width || DEFAULT_CANVAS.width}
              canvasHeight={canvasDimensions?.height || DEFAULT_CANVAS.height}
              attachmentId={attachmentId(editCard)}
              hasCoverImage={editCard?.cover_image_url != null}
              uploadImage={uploadImage(editCard?.id)}
              cardId={editCard?.id}
              images={editCard?.payload?.attachments}
              variantImages={
                editCard.atlas_external_id
                  ? editCard?.attachments_image_url
                  : []
              }
              onCancel={() => {
                setEditCard(null);
              }}
              onSave={saveEditCard}
              loading={saveLoading}
              saveUploadImage={imageParams =>
                saveImage(editCard?.id, openPlanning, imageParams)
              }
              isAdType={Planning.isAdType(openedPlanning(plannings))}
              planningId={openedPlanning(plannings).id}
            />
          )}
        </>
      )}
    </LayoutContent>
  );
}

export default AuctionConfigureCampaign;
