import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useState, useContext, useEffect } from 'react';

import Auction from '../../models/Auction';
import { formatDate } from '../../lib/dateHelper';
import cancelIcon from '../../assets/icons/cancel.svg';
import ModalDialog from '../common/ModalDialog';
import Loader from '../common/LoaderOverrided';
import { Button, ToasterContext } from 'vavato-ui';
import Planning from '../../models/Planning';
import FacebookAuctionTable from '../../components/auction/FacebookAuctionTable';
import {
  useFetchAuctionData,
  useScheduleAllFbPlannings,
  useUnscheduleAllFbPlannings,
} from '../../hooks/services/AuctionAPI';

const CampaignDetailsRowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 20px;
`;

const CampaignDetailsHeaderStyle = styled(CampaignDetailsRowStyle)`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const ButtonStyle = styled.div`
  margin-right: 20px;
`;

const CampaignDetailsFooterStyle = styled(CampaignDetailsRowStyle)`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  justify-content: flex-end;
`;

const CampaignDetailsRightFooterStyle = styled.div`
  display: flex;
  margin-right: -28px;
`;

const CampaignDetailsCancelIconStyle = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

function FacebookCampaignDetails({ campaign, setOpenCampaign }) {
  const { t } = useTranslation();
  const { error, success } = useContext(ToasterContext);
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [auction, setAuction] = useState(null);

  const { data: auctionData, isFetching: auctionIsFetching } =
    useFetchAuctionData(
      campaign.plannings[0].auction.external_id ||
        campaign.plannings[0].auction.atlas_external_id,
    );

  const { mutateAsync: schedulePlanningsMutate } = useScheduleAllFbPlannings(
    auction?.external_id || auction?.atlas_external_id,
  );
  const { mutateAsync: unschedulePlanningsMutate } =
    useUnscheduleAllFbPlannings(
      auction?.external_id || auction?.atlas_external_id,
    );

  async function schedulePlannings(e) {
    e.preventDefault();
    try {
      setScheduleLoading(true);
      await schedulePlanningsMutate();
      success(t('toaster.processing_ad_schedule'));
      closeModal();
      setScheduleLoading(false);
    } catch (err) {
      error(err?.response?.data?.message);
      setScheduleLoading(false);
    }
  }

  async function unschedulePlannings(e) {
    e.preventDefault();
    try {
      setScheduleLoading(true);
      await unschedulePlanningsMutate();
      success(t('toaster.processing_ad_unschedule'));
      closeModal();
      setScheduleLoading(false);
    } catch (err) {
      error(err?.response?.data?.message);
      setScheduleLoading(false);
    }
  }

  useEffect(() => {
    if (!auctionData?.data) return;

    setAuction(
      Planning.groupAdPlanningsByAuction(
        auctionData?.data?.plannings.filter(planning => planning.name != null),
      ),
    );
  }, [auctionData]);

  function renderScheduleButton(plannings_status) {
    switch (plannings_status) {
      case 'unscheduled':
        return (
          <Button
            onClick={e => schedulePlannings(e)}
            disabled={Auction.fbDisableScheduleAll(auction)}
          >
            {t('campaigns.schedule_all')}
          </Button>
        );
      case 'scheduled':
        return (
          <Button onClick={e => unschedulePlannings(e)}>
            {t('campaigns.unschedule_all')}
          </Button>
        );
      default:
        return (
          <Button onClick={() => {}} disabled>
            {t('campaigns.processing')}
          </Button>
        );
    }
  }

  function closeModal() {
    setOpenCampaign(null);
  }

  if (!auction || auctionIsFetching) {
    return <Loader show={true} />;
  }

  return (
    <ModalDialog onClose={closeModal}>
      <CampaignDetailsHeaderStyle data-testid="fbtest">
        <span>
          {`${formatDate(campaign.scheduled_send_time)} - ${t(
            'facebook_plannings.modal_title',
          )}`}
        </span>
        <CampaignDetailsCancelIconStyle src={cancelIcon} onClick={closeModal} />
      </CampaignDetailsHeaderStyle>
      <FacebookAuctionTable
        closeModal={closeModal}
        auction={auction}
        campaign={campaign}
      />
      <CampaignDetailsFooterStyle>
        <CampaignDetailsRightFooterStyle>
          <ButtonStyle>
            <Button onClick={closeModal} className="outline">
              {t('footer.close')}
            </Button>
            {renderScheduleButton(Auction.fbPlanningsScheduleStatus(auction))}
          </ButtonStyle>
        </CampaignDetailsRightFooterStyle>
        {scheduleLoading ? <Loader show={true} /> : null}
      </CampaignDetailsFooterStyle>
    </ModalDialog>
  );
}

FacebookCampaignDetails.propTypes = {
  campaign: PropTypes.object,
  setOpenCampaign: PropTypes.func,
};

export default FacebookCampaignDetails;
