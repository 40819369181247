import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CurrencyLabelStyle = styled.div`
  padding: 0 6px;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.primary};
`;

const CurrencyContentStyle = styled.div``;

function CampaignCurrencyContent({ amount }) {
  return (
    <>
      <CurrencyLabelStyle>€</CurrencyLabelStyle>
      <CurrencyContentStyle>{amount}</CurrencyContentStyle>
    </>
  );
}

CampaignCurrencyContent.propTypes = {
  amount: PropTypes.any,
};

export default CampaignCurrencyContent;
