import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { Button, ToasterContext } from 'vavato-ui';
import {
  useScheduleCampaign,
  useUnscheduleCampaign,
} from '../../hooks/services/CampaignsAPI';

const ScheduleButtonStyle = styled.div`
  button {
    padding: 0;
    width: 100px;
    height: 24px;
    line-height: 0;
    font-size: 10px;
    border-radius: 3px;
  }
`;

function PlanningScheduleButton({ planning, closeModal, setScheduleLoading }) {
  const { t } = useTranslation();
  const { error, success } = useContext(ToasterContext);
  const campaign_id = planning?.campaign_id;
  const { mutateAsync: scheduleCampaignMutate } =
    useScheduleCampaign(campaign_id);
  const { mutateAsync: unscheduleCampaignMutate } =
    useUnscheduleCampaign(campaign_id);

  const disableButton = !isIntegrationComplete();

  function isIntegrationComplete() {
    return (
      planning.ad_active !== 'no_integration' &&
      planning.ad_id &&
      planning.ad_set_id
    );
  }

  async function schedulePlanning(e) {
    e.preventDefault();
    try {
      setScheduleLoading(true);
      await scheduleCampaignMutate();
      success(t('toaster.processing_ad_schedule'));
      closeModal();
      setScheduleLoading(false);
    } catch (err) {
      error(err?.response?.data?.message);
      setScheduleLoading(false);
    }
  }

  async function unschedulePlanning(e) {
    e.preventDefault();
    try {
      setScheduleLoading(true);
      await unscheduleCampaignMutate();
      success(t('toaster.processing_ad_unschedule'));
      closeModal();
      setScheduleLoading(false);
    } catch (err) {
      error(err?.response?.data?.message);
      setScheduleLoading(false);
    }
  }

  function renderScheduleButton(planning) {
    switch (planning.ad_active) {
      case 'unscheduled':
      case 'no_integration':
        return (
          <>
            <Button
              onClick={e => schedulePlanning(e, planning)}
              disabled={disableButton}
            >
              <div data-tip data-for={disableButton ? 'not_integrated' : ''}>
                {t('campaigns.schedule')}
              </div>
            </Button>
            <ReactTooltip id="not_integrated" place="top" effect="solid">
              {t('facebook_plannings.not_integrated')}
            </ReactTooltip>
          </>
        );
      case 'scheduled':
        return (
          <Button
            onClick={e => unschedulePlanning(e, planning)}
            disabled={disableButton}
          >
            {t('campaigns.unschedule')}
          </Button>
        );
      default:
        return (
          <Button onClick={() => {}} disabled>
            {t('campaigns.processing')}
          </Button>
        );
    }
  }

  return (
    <ScheduleButtonStyle>{renderScheduleButton(planning)}</ScheduleButtonStyle>
  );
}

PlanningScheduleButton.propTypes = {
  planning: PropTypes.object,
  closeModal: PropTypes.func,
  setScheduleLoading: PropTypes.func,
};

export default PlanningScheduleButton;
