import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Auction from '../../models/Auction';
import AutocompleteDropdown from './AutocompleteDropdown';
import { useFetch } from '../../hooks/services/CommonAPI';
import editIcon from '../../assets/icons/editicon.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const StyledLink = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 24x;
  padding: 3px 3px;
  width: 100%;

  & > span {
    flex: 1;
  }
`;

const InputStyle = styled.input.attrs({ type: 'text' })`
  width: 100%;
  height: 34px;
  padding: 0.75rem;
  font-size: 0.8rem;
  line-height: 1rem;
  border-radius: 5px;
  border: 2px solid #c4c4c4;

  &:focus,
  &:disabled {
    width: 100% !important;
  }

  ::placeholder {
    color: #c4c4c4;
  }
`;

const AutocompleteContainer = styled.div`
  position: relative;
  width: 100%;
`;

const InputImage = styled.img`
  position: absolute;
  cursor: pointer;
  top: 7px;
  right: 15px;
  width: 18px;
  filter: opacity(50%);
`;

const LoaderDiv = styled.div`
  position: absolute;
  top: 7px;
  right: 15px;
  width: 18px;
  height: 18px;
`;

const RowStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const RowInfo = styled.span`
  color: #000;
  padding: 0;
  overflow: scroll;
  width: 35%;
`;

const RowSubInfo = styled.span`
  color: #c4c4c4;
  padding: 0;
  display: flex;
  overflow: hidden;
  margin: 0px 3px;
  span {
    font-weight: bold;
  }
`;

const RowCountryInfo = styled(RowSubInfo)`
  width: 18%;
`;

const RowLangInfo = styled(RowSubInfo)`
  width: 32%;
`;
function Autocomplete({
  onSelect,
  url,
  selected,
  placeholder,
  disabled,
  setLimitUsage,
  setError,
}) {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [debouncedValue, setDebouncedValue] = useState('');
  const [timer, setTimer] = useState(null);

  const {
    data: fetchData,
    isLoading: fetchLoading,
    isFetching: fetchIsFetching,
    refetch: queryRefetch,
  } = useFetch(url, { search: debouncedValue });

  function DefaultResultRenderer(result) {
    return (
      <RowStyle>
        <RowInfo>{result?.name}</RowInfo>
        <RowCountryInfo>
          <span>{`${t('auction_planning.adset.audience.countries')}:`}</span>{' '}
          {result?.targeting?.geo_locations?.countries?.join(', ')}
        </RowCountryInfo>
        <RowLangInfo>
          <span>{`${t('auction_planning.adset.audience.language')}:`}</span>{' '}
          {Auction.getAudienceLocales(result)}
        </RowLangInfo>
      </RowStyle>
    );
  }

  function parseSelectedAudience(result) {
    return {
      ...result,
      audience_info: {
        languages: Auction.getAudienceLocales(result),
        countries: result?.targeting?.geo_locations?.countries?.join(', '),
        size: 1,
      },
    };
  }

  function closeMenu() {
    setOpen(false);
  }

  function clearSelected() {
    onSelect(null);
  }

  const applyLimitStats = useCallback(
    stats => {
      const key = Object.keys(stats)?.[0];
      const relation = stats?.[key]?.[0];
      const values = [
        relation?.call_count || 0,
        relation?.total_cputime || 0,
        relation?.total_time || 0,
      ];
      setLimitUsage({
        limit: Math.max.apply(null, values),
        regain_time: relation?.estimated_time_to_regain_access,
      });
    },
    [setLimitUsage],
  );

  const applyResults = useCallback(
    data => {
      if (inputValue.length < 3) return;
      let results = data;
      if (data.results) {
        results = data.results;
      }
      setResults(results);
      setOpen(true);
    },
    [inputValue],
  );

  useEffect(() => {
    if (fetchData?.data?.result && !selected)
      applyResults(fetchData.data.result);
    if (fetchData?.data?.stats) applyLimitStats(fetchData.data.stats);
    if (fetchData?.data?.error) setError(fetchData?.data?.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, selected, applyResults, applyLimitStats]);

  function onChangeInput(e) {
    setInputValue(e.target.value);
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      setDebouncedValue(e.target.value);
    }, 500);
    setTimer(newTimer);
  }

  useEffect(() => {
    if (debouncedValue.length < 3) {
      closeMenu();
    } else if (debouncedValue !== selected?.name) {
      queryRefetch();
    }
  }, [debouncedValue, queryRefetch, selected]);

  useEffect(() => {
    setInputValue(selected?.name || '');
    closeMenu();
  }, [selected]);

  function renderResults() {
    if (inputValue?.length < 3) return;
    if (fetchLoading || fetchIsFetching) return;
    if (!results.length)
      return (
        <StyledLink>
          <RowStyle>
            <RowInfo>{t('auction_planning.adset.audience.no_results')}</RowInfo>
          </RowStyle>
        </StyledLink>
      );
    const renderer = DefaultResultRenderer;
    return results.map(result => (
      <StyledLink
        href="/"
        key={result.id || results.indexOf(result)}
        onClick={e => {
          e.preventDefault();
          selectResult(result);
        }}
        onMouseDown={e => {
          e.preventDefault();
          selectResult(result);
        }}
      >
        {renderer(result)}
      </StyledLink>
    ));
  }

  function selectResult(result) {
    closeMenu();
    onSelect(parseSelectedAudience(result));
  }

  function handleKeyDown(e) {
    if (e.keyCode === 27) {
      closeMenu();
    }
  }

  return (
    <AutocompleteContainer>
      {disabled && (
        <InputImage
          src={editIcon}
          alt="Clear selected"
          onClick={() => {
            clearSelected();
          }}
        />
      )}
      {(fetchLoading || fetchIsFetching) && (
        <LoaderDiv>
          <FontAwesomeIcon
            spin
            icon={faSpinner}
            className="circle"
            color="#6092a2"
            size="1x"
          />
        </LoaderDiv>
      )}
      <InputStyle
        data-testid={'audience_autocomplete'}
        disabled={disabled}
        type="text"
        value={inputValue}
        placeholder={placeholder}
        onChange={e => {
          !disabled && onChangeInput(e);
        }}
        onKeyDown={handleKeyDown}
        onBlur={closeMenu}
      />
      <AutocompleteDropdown open={open}>{renderResults()}</AutocompleteDropdown>
    </AutocompleteContainer>
  );
}

Autocomplete.propTypes = {
  onSelect: PropTypes.func,
  url: PropTypes.string,
  selected: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  setLimitUsage: PropTypes.func,
  setError: PropTypes.func,
};

export default Autocomplete;
