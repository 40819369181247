import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FB_MAX_LENGTH_TITLE,
  MAIL_MAX_LENGTH_TITLE,
} from '../../lib/constants';
import infoIcon from '../../assets/icons/info.svg';
import { useTranslation } from 'react-i18next';

const InputContainerStyle = styled.div`
  display: flex;
  color: #343a40;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LabelStyle = styled.p`
  margin: 0.5rem 1rem;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 2rem;
  text-transform: uppercase;
`;

const InputStyle = styled.input.attrs({ type: 'text' })`
  width: 100%;
  height: 34px;
  padding: 0.75rem;
  font-size: 0.8rem;
  line-height: 1rem;
  border-radius: 5px;
  border: 2px solid #c4c4c4;

  ::placeholder {
    color: #c4c4c4;
  }
`;

const RedInputStyle = styled.span`
  color: rgb(223, 58, 88);
  font-size: 9px;
  line-height: 1rem;
  border-radius: 5px;
  width: ${props => (props.type === 'mail' ? '245px' : '325px')};
`;

const BlackInputStyle = styled.span`
  color: black;
  font-weight: normal;
  font-size: 9px;
  line-height: 1rem;
  border-radius: 5px;
  white-space: nowrap;
  margin-left: 68px;
`;

const WarningStyle = styled.div`
  display: flex;
  align-items: center;
  height: 16px;
  margin-top: -5px;
  margin-bottom: 5px;
  justify-content: space-between;
`;

const TextContainerStyle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  max-width: 490px;
`;

const DropIconStyle = styled.img`
  margin-left: 10px;
  vertical-align: middle;
  display: ${props => (props.showInfoIcon ? 'block' : 'none')};
`;
function LabeledInputWithWarning({
  label,
  placeholder,
  value,
  onChange,
  type,
}) {
  const { t } = useTranslation();
  const blackInputRef = React.createRef();
  const redInputRef = React.createRef();
  const [showInfoIcon, setshowInfoIcon] = useState(false);

  useEffect(() => {
    inputHandler(value);
    // eslint-disable-next-line
  }, [value]);

  function inputHandler(text) {
    let max_length;

    type === 'mail'
      ? (max_length = MAIL_MAX_LENGTH_TITLE)
      : (max_length = FB_MAX_LENGTH_TITLE);
    if ((text?.length || 0) < max_length) {
      blackInputRef.current.innerText = '';
      redInputRef.current.innerText = '';
      setshowInfoIcon(false);
      return;
    }
    blackInputRef.current.innerText = text.slice(0, max_length);
    redInputRef.current.innerText = text.slice(max_length);
    setshowInfoIcon(true);
  }

  return (
    <>
      <InputContainerStyle>
        <LabelStyle>{label}: </LabelStyle>
        <InputStyle
          placeholder={placeholder}
          value={value || ''}
          onChange={onChange}
        />
      </InputContainerStyle>
      <WarningStyle>
        <TextContainerStyle>
          <BlackInputStyle type={type} ref={blackInputRef} />
          <RedInputStyle type={type} ref={redInputRef} />
        </TextContainerStyle>
        <DropIconStyle
          showInfoIcon={showInfoIcon}
          src={infoIcon}
          title={type === 'mail' ? t('character_limit_tooltip') : ''}
        />
      </WarningStyle>
    </>
  );
}

LabeledInputWithWarning.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
};

export default LabeledInputWithWarning;
