import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';

import dragIcon from '../../assets/icons/drag-gray.svg';

const DropCardStyle = styled.div`
  width: auto;
  height: auto;
  overflow: hidden;
  position: relative;
  border-radius: 3px;
  border: 1px dashed #c4c4c4;
  opacity: ${props => (props.isOver ? 0.5 : 1)};
`;

const DropImageStyle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: ${props => `url(${props.image})`} no-repeat;
  background-size: auto 100%;
  background-position: center;
  opacity: ${props => (props.isDragging ? 0.5 : 1)};
  cursor: ${props => (props.isDragging ? 'move' : 'pointer')};
`;

const DropNumberStyle = styled.div`
  top: 0;
  right: 0;
  display: flex;
  width: 32px;
  height: 32px;
  margin: 0.5rem;
  position: absolute;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: rgba(52, 58, 64, 0.5);
  box-shadow: 0px 0px 8px rgba(137, 150, 159, 0.3);
`;

const DropTextStyle = styled.span`
  color: white;
  line-height: 2rem;
  font-size: 0.875rem;
`;

const DropIconStyle = styled.img`
  top: 9px;
  left: 9px;
  cursor: pointer;
  position: absolute;
`;

function CampaignDropCard({ id, index, imageSrc, onDrop, onClick }) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'CARD',
    drop: item => {
      onDrop(index, item.id);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'CARD',
      item: { id },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id],
  );

  return (
    <DropCardStyle ref={drop} isOver={isOver} onClick={onClick}>
      {id && <DropIconStyle src={dragIcon} />}
      <DropImageStyle ref={drag} isDragging={isDragging} image={imageSrc} />
      {index !== null || index !== undefined ? (
        <DropNumberStyle>
          <DropTextStyle>{index + 1}</DropTextStyle>
        </DropNumberStyle>
      ) : null}
    </DropCardStyle>
  );
}

CampaignDropCard.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number,
  imageSrc: PropTypes.string,
  onDrop: PropTypes.func,
  onClick: PropTypes.func,
};

export default CampaignDropCard;
