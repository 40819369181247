import api from '../../lib/api';
import { useMutation } from 'react-query';
import queryClient from '../../lib/queryClient';

export function useUpdatePlanningOrder() {
  return useMutation(async ({ id, order }) => {
    await api.patch(`plannings/${id}/order`, { order });
  });
}

export function useTogglePlanningVisibility(id, campaign_id) {
  return useMutation(
    async () => await api.patch(`plannings/${id}/toggle_visibility`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['grouped_plannings', campaign_id]);
        queryClient.invalidateQueries(['campaigns']);
      },
    },
  );
}
