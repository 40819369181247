import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Label } from 'vavato-ui';
import { AUCTION_STATUS_LABEL_CLASSES } from '../../lib/constants';

const StyledLabel = styled(Label)`
  width: 100%;
  font-size: 10px;
`;

function labelClassByStatus(status) {
  return AUCTION_STATUS_LABEL_CLASSES[status];
}

function StatusLabel({ status }) {
  const { t } = useTranslation();

  return (
    <StyledLabel className={labelClassByStatus(status)}>
      {t(`auction_list.status.${status}`)}
    </StyledLabel>
  );
}

StatusLabel.propTypes = {
  status: PropTypes.string,
};

export default StatusLabel;
